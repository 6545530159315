export const NOTIFICATION_BANNER: any = {
  close: {
    au: 'Close',
    de: 'Schließen',
    en: 'Close',
    es: 'Cerrar',
    fr: 'Fermer',
    nl: 'Sluiten',
    'pt-br': 'Fechar',
    us: 'Close',
  },
};
