/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type Acf_Table = {
  __typename?: 'ACF_Table';
  body?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  header?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  headerEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** A Field Group registered by ACF */
export type AcfFieldGroup = {
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** ACF Link field */
export type AcfLink = {
  __typename?: 'AcfLink';
  /** The target of the link (_blank, etc) */
  target?: Maybe<Scalars['String']['output']>;
  /** The title of the link */
  title?: Maybe<Scalars['String']['output']>;
  /** The url of the link */
  url?: Maybe<Scalars['String']['output']>;
};

/** Capterra options. */
export type AcfOptionsCapterra = {
  __typename?: 'AcfOptionsCapterra';
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Global: Capterra&quot; was set to Show in GraphQL. */
  acfCapterra?: Maybe<AcfOptionsCapterra_Acfcapterra>;
  pageSlug?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra';
  capterra?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra';
  de?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra_De>;
  en?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra_En>;
  es?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra_Es>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra_Fr>;
  nl?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra_Nl>;
  ptBr?: Maybe<AcfOptionsCapterra_Acfcapterra_Capterra_PtBr>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra_De = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_De';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra_En = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_En';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra_Es = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_Es';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra_Fr = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_Fr';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra_Nl = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_Nl';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsCapterra_Acfcapterra_Capterra_PtBr = AcfFieldGroup & {
  __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_PtBr';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['String']['output']>;
};

/** Notification Banner options. */
export type AcfOptionsNotificationBanner = {
  __typename?: 'AcfOptionsNotificationBanner';
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Global: Notification Banner&quot; was set to Show in GraphQL. */
  acfNotificationBanner?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner>;
  pageSlug?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    notificationBanner?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner';
    align?: Maybe<Scalars['String']['output']>;
    backgroundColor?: Maybe<Scalars['String']['output']>;
    backgroundColorGradient?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient>;
    backgroundType?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
    links?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Links>
      >
    >;
    status?: Maybe<Scalars['Boolean']['output']>;
    text?: Maybe<Scalars['String']['output']>;
    textColor?: Maybe<Scalars['String']['output']>;
    translations?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient';
    angle?: Maybe<Scalars['Float']['output']>;
    colors?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient_Colors>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient_Colors =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient_colors';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations';
    de?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De>;
    es?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    fr?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr>;
    nl?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl>;
    ptBr?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_Links>
      >
    >;
    status?: Maybe<Scalars['Boolean']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_Links =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_Links>
      >
    >;
    status?: Maybe<Scalars['Boolean']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_Links =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_Links>
      >
    >;
    status?: Maybe<Scalars['Boolean']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_Links =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_Links>
      >
    >;
    status?: Maybe<Scalars['Boolean']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_Links =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_Links>
      >
    >;
    status?: Maybe<Scalars['Boolean']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_Links =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Links =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** The App type */
export type App = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'App';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: App&quot; was set to Show in GraphQL. */
    acfApp?: Maybe<App_Acfapp>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Atoms: Multiple Authors&quot; was set to Show in GraphQL. */
    acfAuthors?: Maybe<App_Acfauthors>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Featured Template&quot; was set to Show in GraphQL. */
    acfFeaturedTemplate?: Maybe<App_Acffeaturedtemplate>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<App_Acfpagestatus>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Related Templates&quot; was set to Show in GraphQL. */
    acfRelatedTemplates?: Maybe<App_Acfrelatedtemplates>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Related Templates (Simple)&quot; was set to Show in GraphQL. */
    acfRelatedTemplatesSimple?: Maybe<App_Acfrelatedtemplatessimple>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    appId: Scalars['Int']['output'];
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the app object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the app object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** App language */
    language?: Maybe<Language>;
    /** App language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the app object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the App type and the App type */
    preview?: Maybe<AppToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the App type and the App type */
    revisions?: Maybe<AppToRevisionConnection>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** Connection between the App type and the TaxonomyCategory type */
    taxonomyCategories?: Maybe<AppToTaxonomyCategoryConnection>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the App type and the TermNode type */
    terms?: Maybe<AppToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<App>>>;
    /** App translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The App type */
export type AppAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The App type */
export type AppChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The App type */
export type AppEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The App type */
export type AppEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The App type */
export type AppRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppToRevisionConnectionWhereArgs>;
};

/** The App type */
export type AppTaxonomyCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppToTaxonomyCategoryConnectionWhereArgs>;
};

/** The App type */
export type AppTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AppToTermNodeConnectionWhereArgs>;
};

/** The App type */
export type AppTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to App Nodes */
export type AppConnection = {
  /** A list of edges (relational context) between RootQuery and connected App Nodes */
  edges: Array<AppConnectionEdge>;
  /** A list of connected App Nodes */
  nodes: Array<App>;
  /** Information about pagination in a connection. */
  pageInfo: AppConnectionPageInfo;
};

/** Edge between a Node and a connected App */
export type AppConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected App Node */
  node: App;
};

/** Page Info on the connected AppConnectionEdge */
export type AppConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum AppIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the App to TaxonomyCategories */
export type AppTaxonomyCategoriesInput = {
  /** If true, this will append the TaxonomyCategory to existing related TaxonomyCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<AppTaxonomyCategoriesNodeInput>>>;
};

/** List of TaxonomyCategories to connect the App to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type AppTaxonomyCategoriesNodeInput = {
  /** The description of the TaxonomyCategory. This field is used to set a description of the TaxonomyCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyCategory. If present, this will be used to connect to the App. If no existing TaxonomyCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomyCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomyCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the App type and the App type */
export type AppToPreviewConnectionEdge = AppConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'AppToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: App;
  };

/** Connection between the App type and the App type */
export type AppToRevisionConnection = AppConnection &
  Connection & {
    __typename?: 'AppToRevisionConnection';
    /** Edges for the AppToRevisionConnection connection */
    edges: Array<AppToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<App>;
    /** Information about pagination in a connection. */
    pageInfo: AppToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type AppToRevisionConnectionEdge = AppConnectionEdge &
  Edge & {
    __typename?: 'AppToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: App;
  };

/** Page Info on the &quot;AppToRevisionConnection&quot; */
export type AppToRevisionConnectionPageInfo = AppConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'AppToRevisionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the AppToRevisionConnection connection */
export type AppToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the App type and the TaxonomyCategory type */
export type AppToTaxonomyCategoryConnection = Connection &
  TaxonomyCategoryConnection & {
    __typename?: 'AppToTaxonomyCategoryConnection';
    /** Edges for the AppToTaxonomyCategoryConnection connection */
    edges: Array<AppToTaxonomyCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyCategory>;
    /** Information about pagination in a connection. */
    pageInfo: AppToTaxonomyCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type AppToTaxonomyCategoryConnectionEdge = Edge &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'AppToTaxonomyCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary categories */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyCategory;
  };

/** Page Info on the &quot;AppToTaxonomyCategoryConnection&quot; */
export type AppToTaxonomyCategoryConnectionPageInfo = PageInfo &
  TaxonomyCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'AppToTaxonomyCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the AppToTaxonomyCategoryConnection connection */
export type AppToTaxonomyCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the App type and the TermNode type */
export type AppToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'AppToTermNodeConnection';
    /** Edges for the AppToTermNodeConnection connection */
    edges: Array<AppToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: AppToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type AppToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'AppToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;AppToTermNodeConnection&quot; */
export type AppToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'AppToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the AppToTermNodeConnection connection */
export type AppToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Field Group */
export type App_Acfapp = AcfFieldGroup & {
  __typename?: 'App_Acfapp';
  authorShow?: Maybe<Scalars['Boolean']['output']>;
  /** Add a new field for each featured block you would like to display. */
  blocks?: Maybe<Array<Maybe<App_Acfapp_Blocks>>>;
  branchLink?: Maybe<Scalars['String']['output']>;
  contentEditor?: Maybe<Scalars['String']['output']>;
  featuredTitle?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  heroEditor?: Maybe<Scalars['String']['output']>;
  heroTitle?: Maybe<Scalars['String']['output']>;
  multipleAuthors?: Maybe<App_Acfapp_MultipleAuthors>;
  overrideAppLinks?: Maybe<Scalars['Boolean']['output']>;
  /** Show SafetyCulture Capterra information? */
  seoCapterra?: Maybe<Scalars['Boolean']['output']>;
  seoCapterraBadges?: Maybe<App_Acfapp_SeoCapterraBadges>;
  /** Show iOS and Android download links for SafetyCulture? */
  seoDownload?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type App_Acfapp_MultipleAuthors = AcfFieldGroup & {
  __typename?: 'App_Acfapp_MultipleAuthors';
  authors?: Maybe<Array<Maybe<App_Acfapp_MultipleAuthors_Authors>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfapp_MultipleAuthors_Authors = AcfFieldGroup & {
  __typename?: 'App_Acfapp_MultipleAuthors_authors';
  authorName?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfapp_SeoCapterraBadges = AcfFieldGroup & {
  __typename?: 'App_Acfapp_SeoCapterraBadges';
  /** Override Capterra Badges shown inside SafetyCulture&#039;s Capterra information */
  badges?: Maybe<Array<Maybe<App_Acfapp_SeoCapterraBadges_Badges>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type App_Acfapp_SeoCapterraBadges_Badges = AcfFieldGroup & {
  __typename?: 'App_Acfapp_SeoCapterraBadges_badges';
  badge?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfapp_Blocks = AcfFieldGroup & {
  __typename?: 'App_Acfapp_blocks';
  additionalLinks?: Maybe<Array<Maybe<App_Acfapp_Blocks_AdditionalLinks>>>;
  cta?: Maybe<App_Acfapp_Blocks_Cta>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  freeVersion?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
  platforms?: Maybe<Scalars['String']['output']>;
  pricing?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfapp_Blocks_Cta = AcfFieldGroup & {
  __typename?: 'App_Acfapp_blocks_Cta';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  nofollow?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfapp_Blocks_AdditionalLinks = AcfFieldGroup & {
  __typename?: 'App_Acfapp_blocks_additionalLinks';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  nofollow?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfauthors = AcfFieldGroup & {
  __typename?: 'App_Acfauthors';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  multipleAuthors?: Maybe<App_Acfauthors_MultipleAuthors>;
};

/** Field Group */
export type App_Acfauthors_MultipleAuthors = AcfFieldGroup & {
  __typename?: 'App_Acfauthors_MultipleAuthors';
  authors?: Maybe<Array<Maybe<App_Acfauthors_MultipleAuthors_Authors>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfauthors_MultipleAuthors_Authors = AcfFieldGroup & {
  __typename?: 'App_Acfauthors_MultipleAuthors_authors';
  /** Any addition authors to this post, list down below */
  authorName?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acffeaturedtemplate = AcfFieldGroup & {
  __typename?: 'App_Acffeaturedtemplate';
  featuredTemplate?: Maybe<App_Acffeaturedtemplate_FeaturedTemplate>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acffeaturedtemplate_FeaturedTemplate = AcfFieldGroup & {
  __typename?: 'App_Acffeaturedtemplate_FeaturedTemplate';
  cta?: Maybe<App_Acffeaturedtemplate_FeaturedTemplate_Cta>;
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
  previewLinks?: Maybe<App_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acffeaturedtemplate_FeaturedTemplate_Cta = AcfFieldGroup & {
  __typename?: 'App_Acffeaturedtemplate_FeaturedTemplate_Cta';
  ctaText?: Maybe<Scalars['String']['output']>;
  ctaUrl?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks =
  AcfFieldGroup & {
    __typename?: 'App_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks';
    digitalReport?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    pdfReport?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'App_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfrelatedtemplates = AcfFieldGroup & {
  __typename?: 'App_Acfrelatedtemplates';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  relatedTemplatesEditor?: Maybe<Scalars['String']['output']>;
  relatedTemplatesTemplates?: Maybe<
    Array<Maybe<App_Acfrelatedtemplates_RelatedTemplatesTemplates>>
  >;
  relatedTemplatesTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfrelatedtemplates_RelatedTemplatesTemplates =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplates_relatedTemplatesTemplates';
    cta?: Maybe<App_Acfrelatedtemplates_RelatedTemplatesTemplates_Cta>;
    downloadType?: Maybe<Scalars['String']['output']>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    previewLinks?: Maybe<App_Acfrelatedtemplates_RelatedTemplatesTemplates_PreviewLinks>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfrelatedtemplates_RelatedTemplatesTemplates_Cta =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplates_relatedTemplatesTemplates_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfrelatedtemplates_RelatedTemplatesTemplates_PreviewLinks =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplates_relatedTemplatesTemplates_PreviewLinks';
    digitalReport?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    pdfReport?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfrelatedtemplatessimple = AcfFieldGroup & {
  __typename?: 'App_Acfrelatedtemplatessimple';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  relatedTemplatesTemplates?: Maybe<
    Array<Maybe<App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates>>
  >;
  relatedTemplatesTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplatessimple_relatedTemplatesTemplates';
    cta?: Maybe<App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Cta>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links>
      >
    >;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Cta =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplatessimple_relatedTemplatesTemplates_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type App_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'App_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Apps Globals options. */
export type AppsGlobals = {
  __typename?: 'AppsGlobals';
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Options Page: Apps Globals&quot; was set to Show in GraphQL. */
  acfAppsGlobals?: Maybe<AppsGlobals_Acfappsglobals>;
  pageSlug?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AppsGlobals_Acfappsglobals = AcfFieldGroup & {
  __typename?: 'AppsGlobals_Acfappsglobals';
  /** Override the branch link when clicking on app store buttons for the /app channel of the website. */
  branchLink?: Maybe<Scalars['String']['output']>;
  capterra?: Maybe<AppsGlobals_Acfappsglobals_Capterra>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AppsGlobals_Acfappsglobals_Capterra = AcfFieldGroup & {
  __typename?: 'AppsGlobals_Acfappsglobals_Capterra';
  badges?: Maybe<AppsGlobals_Acfappsglobals_Capterra_Badges>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AppsGlobals_Acfappsglobals_Capterra_Badges = AcfFieldGroup & {
  __typename?: 'AppsGlobals_Acfappsglobals_Capterra_Badges';
  badges?: Maybe<
    Array<Maybe<AppsGlobals_Acfappsglobals_Capterra_Badges_Badges>>
  >;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type AppsGlobals_Acfappsglobals_Capterra_Badges_Badges =
  AcfFieldGroup & {
    __typename?: 'AppsGlobals_Acfappsglobals_Capterra_Badges_badges';
    badge?: Maybe<MediaItem>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from. */
export type Avatar = {
  __typename?: 'Avatar';
  /** URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo). */
  default?: Maybe<Scalars['String']['output']>;
  /** HTML attributes to insert in the IMG element. Is not sanitized. */
  extraAttr?: Maybe<Scalars['String']['output']>;
  /** Whether to always show the default image, never the Gravatar. */
  forceDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the avatar was successfully found. */
  foundAvatar?: Maybe<Scalars['Boolean']['output']>;
  /** Height of the avatar image. */
  height?: Maybe<Scalars['Int']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order. */
  rating?: Maybe<Scalars['String']['output']>;
  /** Type of url scheme to use. Typically HTTP vs. HTTPS. */
  scheme?: Maybe<Scalars['String']['output']>;
  /** The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image. */
  size?: Maybe<Scalars['Int']['output']>;
  /** URL for the gravatar image source. */
  url?: Maybe<Scalars['String']['output']>;
  /** Width of the avatar image. */
  width?: Maybe<Scalars['Int']['output']>;
};

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  Pg = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X',
}

/** The category type */
export type Category = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Category';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<CategoryToAncestorsCategoryConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    categoryId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the category type and its children categories. */
    children?: Maybe<CategoryToCategoryConnection>;
    /** Connection between the Category type and the ContentNode type */
    contentNodes?: Maybe<CategoryToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Category language */
    language?: Maybe<Language>;
    /** Category language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** Connection between the category type and its parent category. */
    parent?: Maybe<CategoryToParentCategoryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the Category type and the post type */
    posts?: Maybe<CategoryToPostConnection>;
    /** The Yoast SEO data of the Categories taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the Category type and the Taxonomy type */
    taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** Category translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** Category translations */
    translations?: Maybe<Array<Maybe<Category>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The category type */
export type CategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The category type */
export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryToCategoryConnectionWhereArgs>;
};

/** The category type */
export type CategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryToContentNodeConnectionWhereArgs>;
};

/** The category type */
export type CategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The category type */
export type CategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The category type */
export type CategoryPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryToPostConnectionWhereArgs>;
};

/** Connection to category Nodes */
export type CategoryConnection = {
  /** A list of edges (relational context) between RootQuery and connected category Nodes */
  edges: Array<CategoryConnectionEdge>;
  /** A list of connected category Nodes */
  nodes: Array<Category>;
  /** Information about pagination in a connection. */
  pageInfo: CategoryConnectionPageInfo;
};

/** Edge between a Node and a connected category */
export type CategoryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected category Node */
  node: Category;
};

/** Page Info on the connected CategoryConnectionEdge */
export type CategoryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Category type and the category type */
export type CategoryToAncestorsCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'CategoryToAncestorsCategoryConnection';
    /** Edges for the CategoryToAncestorsCategoryConnection connection */
    edges: Array<CategoryToAncestorsCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToAncestorsCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToAncestorsCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'CategoryToAncestorsCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;CategoryToAncestorsCategoryConnection&quot; */
export type CategoryToAncestorsCategoryConnectionPageInfo =
  CategoryConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'CategoryToAncestorsCategoryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the Category type and the category type */
export type CategoryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'CategoryToCategoryConnection';
    /** Edges for the CategoryToCategoryConnection connection */
    edges: Array<CategoryToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'CategoryToCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;CategoryToCategoryConnection&quot; */
export type CategoryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Category type and the ContentNode type */
export type CategoryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'CategoryToContentNodeConnection';
    /** Edges for the CategoryToContentNodeConnection connection */
    edges: Array<CategoryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'CategoryToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;CategoryToContentNodeConnection&quot; */
export type CategoryToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'CategoryToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Category type and the category type */
export type CategoryToParentCategoryConnectionEdge = CategoryConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CategoryToParentCategoryConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Category;
  };

/** Connection between the Category type and the post type */
export type CategoryToPostConnection = Connection &
  PostConnection & {
    __typename?: 'CategoryToPostConnection';
    /** Edges for the CategoryToPostConnection connection */
    edges: Array<CategoryToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'CategoryToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;CategoryToPostConnection&quot; */
export type CategoryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Category type and the Taxonomy type */
export type CategoryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'CategoryToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Checklist type */
export type Checklist = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Checklist';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Atoms: Multiple Authors&quot; was set to Show in GraphQL. */
    acfAuthors?: Maybe<Checklist_Acfauthors>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Checklist&quot; was set to Show in GraphQL. */
    acfChecklist?: Maybe<Checklist_Acfchecklist>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Featured Template&quot; was set to Show in GraphQL. */
    acfFeaturedTemplate?: Maybe<Checklist_Acffeaturedtemplate>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Checklist_Acfpagestatus>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Related Templates&quot; was set to Show in GraphQL. */
    acfRelatedTemplates?: Maybe<Checklist_Acfrelatedtemplates>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Related Templates (Simple)&quot; was set to Show in GraphQL. */
    acfRelatedTemplatesSimple?: Maybe<Checklist_Acfrelatedtemplatessimple>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    checklistId: Scalars['Int']['output'];
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the checklists object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the checklists object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Checklist language */
    language?: Maybe<Language>;
    /** Checklist language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the checklists object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Checklist type and the Checklist type */
    preview?: Maybe<ChecklistToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Checklist type and the Checklist type */
    revisions?: Maybe<ChecklistToRevisionConnection>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** Connection between the Checklist type and the TaxonomyCategory type */
    taxonomyCategories?: Maybe<ChecklistToTaxonomyCategoryConnection>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Checklist type and the TermNode type */
    terms?: Maybe<ChecklistToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Checklist>>>;
    /** Checklist translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Checklist type */
export type ChecklistAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The Checklist type */
export type ChecklistChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The Checklist type */
export type ChecklistEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Checklist type */
export type ChecklistEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Checklist type */
export type ChecklistRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChecklistToRevisionConnectionWhereArgs>;
};

/** The Checklist type */
export type ChecklistTaxonomyCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChecklistToTaxonomyCategoryConnectionWhereArgs>;
};

/** The Checklist type */
export type ChecklistTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChecklistToTermNodeConnectionWhereArgs>;
};

/** The Checklist type */
export type ChecklistTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Checklist Nodes */
export type ChecklistConnection = {
  /** A list of edges (relational context) between RootQuery and connected Checklist Nodes */
  edges: Array<ChecklistConnectionEdge>;
  /** A list of connected Checklist Nodes */
  nodes: Array<Checklist>;
  /** Information about pagination in a connection. */
  pageInfo: ChecklistConnectionPageInfo;
};

/** Edge between a Node and a connected Checklist */
export type ChecklistConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Checklist Node */
  node: Checklist;
};

/** Page Info on the connected ChecklistConnectionEdge */
export type ChecklistConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ChecklistIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Checklist to TaxonomyCategories */
export type ChecklistTaxonomyCategoriesInput = {
  /** If true, this will append the TaxonomyCategory to existing related TaxonomyCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ChecklistTaxonomyCategoriesNodeInput>>>;
};

/** List of TaxonomyCategories to connect the Checklist to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ChecklistTaxonomyCategoriesNodeInput = {
  /** The description of the TaxonomyCategory. This field is used to set a description of the TaxonomyCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyCategory. If present, this will be used to connect to the Checklist. If no existing TaxonomyCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomyCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomyCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Checklist type and the Checklist type */
export type ChecklistToPreviewConnectionEdge = ChecklistConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'ChecklistToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Checklist;
  };

/** Connection between the Checklist type and the Checklist type */
export type ChecklistToRevisionConnection = ChecklistConnection &
  Connection & {
    __typename?: 'ChecklistToRevisionConnection';
    /** Edges for the ChecklistToRevisionConnection connection */
    edges: Array<ChecklistToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Checklist>;
    /** Information about pagination in a connection. */
    pageInfo: ChecklistToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type ChecklistToRevisionConnectionEdge = ChecklistConnectionEdge &
  Edge & {
    __typename?: 'ChecklistToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Checklist;
  };

/** Page Info on the &quot;ChecklistToRevisionConnection&quot; */
export type ChecklistToRevisionConnectionPageInfo =
  ChecklistConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ChecklistToRevisionConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the ChecklistToRevisionConnection connection */
export type ChecklistToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Checklist type and the TaxonomyCategory type */
export type ChecklistToTaxonomyCategoryConnection = Connection &
  TaxonomyCategoryConnection & {
    __typename?: 'ChecklistToTaxonomyCategoryConnection';
    /** Edges for the ChecklistToTaxonomyCategoryConnection connection */
    edges: Array<ChecklistToTaxonomyCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyCategory>;
    /** Information about pagination in a connection. */
    pageInfo: ChecklistToTaxonomyCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type ChecklistToTaxonomyCategoryConnectionEdge = Edge &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'ChecklistToTaxonomyCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary categories */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyCategory;
  };

/** Page Info on the &quot;ChecklistToTaxonomyCategoryConnection&quot; */
export type ChecklistToTaxonomyCategoryConnectionPageInfo = PageInfo &
  TaxonomyCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ChecklistToTaxonomyCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ChecklistToTaxonomyCategoryConnection connection */
export type ChecklistToTaxonomyCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Checklist type and the TermNode type */
export type ChecklistToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'ChecklistToTermNodeConnection';
    /** Edges for the ChecklistToTermNodeConnection connection */
    edges: Array<ChecklistToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: ChecklistToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ChecklistToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'ChecklistToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;ChecklistToTermNodeConnection&quot; */
export type ChecklistToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ChecklistToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the ChecklistToTermNodeConnection connection */
export type ChecklistToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Field Group */
export type Checklist_Acfauthors = AcfFieldGroup & {
  __typename?: 'Checklist_Acfauthors';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  multipleAuthors?: Maybe<Checklist_Acfauthors_MultipleAuthors>;
};

/** Field Group */
export type Checklist_Acfauthors_MultipleAuthors = AcfFieldGroup & {
  __typename?: 'Checklist_Acfauthors_MultipleAuthors';
  authors?: Maybe<Array<Maybe<Checklist_Acfauthors_MultipleAuthors_Authors>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acfauthors_MultipleAuthors_Authors = AcfFieldGroup & {
  __typename?: 'Checklist_Acfauthors_MultipleAuthors_authors';
  /** Any addition authors to this post, list down below */
  authorName?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acfchecklist = AcfFieldGroup & {
  __typename?: 'Checklist_Acfchecklist';
  authorShow?: Maybe<Scalars['Boolean']['output']>;
  contentEditor?: Maybe<Scalars['String']['output']>;
  faqFrequentlyAskedQuestions?: Maybe<
    Array<Maybe<Checklist_Acfchecklist_FaqFrequentlyAskedQuestions>>
  >;
  faqTitle?: Maybe<Scalars['String']['output']>;
  featuredTemplateStatus?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  heroEditor?: Maybe<Scalars['String']['output']>;
  heroTitle?: Maybe<Scalars['String']['output']>;
  introEditor?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acfchecklist_FaqFrequentlyAskedQuestions =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfchecklist_faqFrequentlyAskedQuestions';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acffeaturedtemplate = AcfFieldGroup & {
  __typename?: 'Checklist_Acffeaturedtemplate';
  featuredTemplate?: Maybe<Checklist_Acffeaturedtemplate_FeaturedTemplate>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acffeaturedtemplate_FeaturedTemplate = AcfFieldGroup & {
  __typename?: 'Checklist_Acffeaturedtemplate_FeaturedTemplate';
  cta?: Maybe<Checklist_Acffeaturedtemplate_FeaturedTemplate_Cta>;
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
  previewLinks?: Maybe<Checklist_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acffeaturedtemplate_FeaturedTemplate_Cta =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acffeaturedtemplate_FeaturedTemplate_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks';
    digitalReport?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    pdfReport?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Checklist_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acfrelatedtemplates = AcfFieldGroup & {
  __typename?: 'Checklist_Acfrelatedtemplates';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  relatedTemplatesEditor?: Maybe<Scalars['String']['output']>;
  relatedTemplatesTemplates?: Maybe<
    Array<Maybe<Checklist_Acfrelatedtemplates_RelatedTemplatesTemplates>>
  >;
  relatedTemplatesTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acfrelatedtemplates_RelatedTemplatesTemplates =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplates_relatedTemplatesTemplates';
    cta?: Maybe<Checklist_Acfrelatedtemplates_RelatedTemplatesTemplates_Cta>;
    downloadType?: Maybe<Scalars['String']['output']>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    previewLinks?: Maybe<Checklist_Acfrelatedtemplates_RelatedTemplatesTemplates_PreviewLinks>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfrelatedtemplates_RelatedTemplatesTemplates_Cta =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplates_relatedTemplatesTemplates_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfrelatedtemplates_RelatedTemplatesTemplates_PreviewLinks =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplates_relatedTemplatesTemplates_PreviewLinks';
    digitalReport?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    pdfReport?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfrelatedtemplatessimple = AcfFieldGroup & {
  __typename?: 'Checklist_Acfrelatedtemplatessimple';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  relatedTemplatesTemplates?: Maybe<
    Array<Maybe<Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates>>
  >;
  relatedTemplatesTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplatessimple_relatedTemplatesTemplates';
    cta?: Maybe<Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Cta>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links>
      >
    >;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Cta =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplatessimple_relatedTemplatesTemplates_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Checklist_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Checklist_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** A Comment object */
export type Comment = DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'Comment';
    /** User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL. */
    agent?: Maybe<Scalars['String']['output']>;
    /**
     * The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL.
     * @deprecated Deprecated in favor of the `status` field
     */
    approved?: Maybe<Scalars['Boolean']['output']>;
    /** The author of the comment */
    author?: Maybe<CommentToCommenterConnectionEdge>;
    /** IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL. */
    authorIp?: Maybe<Scalars['String']['output']>;
    /**
     * ID for the comment, unique among comments.
     * @deprecated Deprecated in favor of databaseId
     */
    commentId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the Comment type and the ContentNode type */
    commentedOn?: Maybe<CommentToContentNodeConnectionEdge>;
    /** Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL. */
    content?: Maybe<Scalars['String']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL. */
    date?: Maybe<Scalars['String']['output']>;
    /** Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier for the comment object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL. */
    karma?: Maybe<Scalars['Int']['output']>;
    /** Comment language */
    language?: Maybe<Language>;
    /** Comment language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The permalink of the comment */
    link?: Maybe<Scalars['String']['output']>;
    /** Connection between the Comment type and the Comment type */
    parent?: Maybe<CommentToParentCommentConnectionEdge>;
    /** The database id of the parent comment node or null if it is the root comment */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent comment node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the Comment type and the Comment type */
    replies?: Maybe<CommentToCommentConnection>;
    /** The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL. */
    status?: Maybe<CommentStatusEnum>;
    /** Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL. */
    type?: Maybe<Scalars['String']['output']>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** A Comment object */
export type CommentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A Comment object */
export type CommentParentArgs = {
  where?: InputMaybe<CommentToParentCommentConnectionWhereArgs>;
};

/** A Comment object */
export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentToCommentConnectionWhereArgs>;
};

/** A Comment Author object */
export type CommentAuthor = Commenter &
  DatabaseIdentifier &
  Node & {
    __typename?: 'CommentAuthor';
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Avatar>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The email for the comment author */
    email?: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier for the comment author object */
    id: Scalars['ID']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** The name for the comment author. */
    name?: Maybe<Scalars['String']['output']>;
    /** The url the comment author. */
    url?: Maybe<Scalars['String']['output']>;
  };

/** A Comment Author object */
export type CommentAuthorAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to Comment Nodes */
export type CommentConnection = {
  /** A list of edges (relational context) between RootQuery and connected Comment Nodes */
  edges: Array<CommentConnectionEdge>;
  /** A list of connected Comment Nodes */
  nodes: Array<Comment>;
  /** Information about pagination in a connection. */
  pageInfo: CommentConnectionPageInfo;
};

/** Edge between a Node and a connected Comment */
export type CommentConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Comment Node */
  node: Comment;
};

/** Page Info on the connected CommentConnectionEdge */
export type CommentConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** The status of the comment object. */
export enum CommentStatusEnum {
  /** Comments with the Approved status */
  Approve = 'APPROVE',
  /** Comments with the Unapproved status */
  Hold = 'HOLD',
  /** Comments with the Spam status */
  Spam = 'SPAM',
  /** Comments with the Trash status */
  Trash = 'TRASH',
}

/** Connection between the Comment type and the Comment type */
export type CommentToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'CommentToCommentConnection';
    /** Edges for the CommentToCommentConnection connection */
    edges: Array<CommentToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: CommentToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type CommentToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'CommentToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;CommentToCommentConnection&quot; */
export type CommentToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CommentToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Comment type and the Commenter type */
export type CommentToCommenterConnectionEdge = CommenterConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToCommenterConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Commenter;
  };

/** Connection between the Comment type and the ContentNode type */
export type CommentToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToContentNodeConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** Connection between the Comment type and the Comment type */
export type CommentToParentCommentConnectionEdge = CommentConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToParentCommentConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Comment;
  };

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** The author of a comment */
export type Commenter = {
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Avatar>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output'];
  /** The email address of the author of a comment. */
  email?: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier for the comment author. */
  id: Scalars['ID']['output'];
  /** Whether the author information is considered restricted. (not fully public) */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the author of a comment. */
  name?: Maybe<Scalars['String']['output']>;
  /** The url of the author of a comment. */
  url?: Maybe<Scalars['String']['output']>;
};

/** Edge between a Node and a connected Commenter */
export type CommenterConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Commenter Node */
  node: Commenter;
};

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = 'COMMENT_AGENT',
  /** Order by approval status of the comment. */
  CommentApproved = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  CommentAuthor = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  CommentContent = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  CommentDate = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  CommentId = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = 'COMMENT_IN',
  /** Order by the comment karma score. */
  CommentKarma = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  CommentParent = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  CommentPostId = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = 'COMMENT_TYPE',
  /** Order by the user ID. */
  UserId = 'USER_ID',
}

/** A plural connection from one Node Type in the Graph to another Node Type, with support for relational data via &quot;edges&quot;. */
export type Connection = {
  /** A list of edges (relational context) between connected nodes */
  edges: Array<Edge>;
  /** A list of connected nodes */
  nodes: Array<Node>;
  /** Information about pagination in a connection. */
  pageInfo: PageInfo;
};

/** Nodes used to manage content */
export type ContentNode = {
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output'];
  /** The ID of the node in the database. */
  databaseId: Scalars['Int']['output'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']['output']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']['output']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']['output']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']['output']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']['output']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']['output']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']['output']>;
  /** The Yoast SEO data of the ContentNode */
  seo?: Maybe<PostTypeSeo>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']['output']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>;
};

/** Nodes used to manage content */
export type ContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Nodes used to manage content */
export type ContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to ContentNode Nodes */
export type ContentNodeConnection = {
  /** A list of edges (relational context) between ContentType and connected ContentNode Nodes */
  edges: Array<ContentNodeConnectionEdge>;
  /** A list of connected ContentNode Nodes */
  nodes: Array<ContentNode>;
  /** Information about pagination in a connection. */
  pageInfo: ContentNodeConnectionPageInfo;
};

/** Edge between a Node and a connected ContentNode */
export type ContentNodeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected ContentNode Node */
  node: ContentNode;
};

/** Page Info on the connected ContentNodeConnectionEdge */
export type ContentNodeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the ContentNode type and the ContentType type */
export type ContentNodeToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'ContentNodeToContentTypeConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: ContentType;
  };

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLastConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'ContentNodeToEditLastConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLockConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'ContentNodeToEditLockConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The timestamp for when the node was last edited */
    lockTimestamp?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** Connection between the ContentNode type and the EnqueuedScript type */
export type ContentNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'ContentNodeToEnqueuedScriptConnection';
    /** Edges for the ContentNodeToEnqueuedScriptConnection connection */
    edges: Array<ContentNodeToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: ContentNodeToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'ContentNodeToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;ContentNodeToEnqueuedScriptConnection&quot; */
export type ContentNodeToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentNodeToEnqueuedScriptConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the ContentNode type and the EnqueuedStylesheet type */
export type ContentNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'ContentNodeToEnqueuedStylesheetConnection';
    /** Edges for the ContentNodeToEnqueuedStylesheetConnection connection */
    edges: Array<ContentNodeToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'ContentNodeToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;ContentNodeToEnqueuedStylesheetConnection&quot; */
export type ContentNodeToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentNodeToEnqueuedStylesheetConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** The template assigned to a node of content */
export type ContentTemplate = {
  /** The name of the template */
  templateName?: Maybe<Scalars['String']['output']>;
};

/** An Post Type object */
export type ContentType = Node &
  UniformResourceIdentifiable & {
    __typename?: 'ContentType';
    /** Whether this content type should can be exported. */
    canExport?: Maybe<Scalars['Boolean']['output']>;
    /** Connection between the ContentType type and the Taxonomy type */
    connectedTaxonomies?: Maybe<ContentTypeToTaxonomyConnection>;
    /** Connection between the ContentType type and the ContentNode type */
    contentNodes?: Maybe<ContentTypeToContentNodeConnection>;
    /** Whether content of this type should be deleted when the author of it is deleted from the system. */
    deleteWithUser?: Maybe<Scalars['Boolean']['output']>;
    /** Description of the content type. */
    description?: Maybe<Scalars['String']['output']>;
    /** Whether to exclude nodes of this content type from front end search results. */
    excludeFromSearch?: Maybe<Scalars['Boolean']['output']>;
    /** The plural name of the content type within the GraphQL Schema. */
    graphqlPluralName?: Maybe<Scalars['String']['output']>;
    /** The singular name of the content type within the GraphQL Schema. */
    graphqlSingleName?: Maybe<Scalars['String']['output']>;
    /** Whether this content type should have archives. Content archives are generated by type and by date. */
    hasArchive?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the content type is hierarchical, for example pages. */
    hierarchical?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the post-type object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Display name of the content type. */
    label?: Maybe<Scalars['String']['output']>;
    /** Details about the content type labels. */
    labels?: Maybe<PostTypeLabelDetails>;
    /** The name of the icon file to display as a menu icon. */
    menuIcon?: Maybe<Scalars['String']['output']>;
    /** The position of this post type in the menu. Only applies if show_in_menu is true. */
    menuPosition?: Maybe<Scalars['Int']['output']>;
    /** The internal name of the post type. This should not be used for display purposes. */
    name?: Maybe<Scalars['String']['output']>;
    /** Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention. */
    public?: Maybe<Scalars['Boolean']['output']>;
    /** Whether queries can be performed on the front end for the content type as part of parse_request(). */
    publiclyQueryable?: Maybe<Scalars['Boolean']['output']>;
    /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
    restBase?: Maybe<Scalars['String']['output']>;
    /** The REST Controller class assigned to handling this content type. */
    restControllerClass?: Maybe<Scalars['String']['output']>;
    /** Makes this content type available via the admin bar. */
    showInAdminBar?: Maybe<Scalars['Boolean']['output']>;
    /** Whether to add the content type to the GraphQL Schema. */
    showInGraphql?: Maybe<Scalars['Boolean']['output']>;
    /** Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that. */
    showInMenu?: Maybe<Scalars['Boolean']['output']>;
    /** Makes this content type available for selection in navigation menus. */
    showInNavMenus?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace. */
    showInRest?: Maybe<Scalars['Boolean']['output']>;
    /** Whether to generate and allow a UI for managing this content type in the admin. */
    showUi?: Maybe<Scalars['Boolean']['output']>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** An Post Type object */
export type ContentTypeConnectedTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An Post Type object */
export type ContentTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgs>;
};

/** Connection to ContentType Nodes */
export type ContentTypeConnection = {
  /** A list of edges (relational context) between RootQuery and connected ContentType Nodes */
  edges: Array<ContentTypeConnectionEdge>;
  /** A list of connected ContentType Nodes */
  nodes: Array<ContentType>;
  /** Information about pagination in a connection. */
  pageInfo: ContentTypeConnectionPageInfo;
};

/** Edge between a Node and a connected ContentType */
export type ContentTypeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected ContentType Node */
  node: ContentType;
};

/** Page Info on the connected ContentTypeConnectionEdge */
export type ContentTypeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  App = 'APP',
  /** The Type of Content object */
  Attachment = 'ATTACHMENT',
  /** The Type of Content object */
  Checklists = 'CHECKLISTS',
  /** The Type of Content object */
  Customers = 'CUSTOMERS',
  /** The Type of Content object */
  Ebooks = 'EBOOKS',
  /** The Type of Content object */
  EventsAndWebinars = 'EVENTS_AND_WEBINARS',
  /** The Type of Content object */
  GraphqlDocument = 'GRAPHQL_DOCUMENT',
  /** The Type of Content object */
  Legal = 'LEGAL',
  /** The Type of Content object */
  Page = 'PAGE',
  /** The Type of Content object */
  Partnerships = 'PARTNERSHIPS',
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Testimonial = 'TESTIMONIAL',
  /** The Type of Content object */
  Topics = 'TOPICS',
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the content type. */
  Name = 'NAME',
}

/** Connection between the ContentType type and the ContentNode type */
export type ContentTypeToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'ContentTypeToContentNodeConnection';
    /** Edges for the ContentTypeToContentNodeConnection connection */
    edges: Array<ContentTypeToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ContentTypeToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentTypeToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'ContentTypeToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ContentTypeToContentNodeConnection&quot; */
export type ContentTypeToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentTypeToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the ContentType type and the Taxonomy type */
export type ContentTypeToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: 'ContentTypeToTaxonomyConnection';
    /** Edges for the ContentTypeToTaxonomyConnection connection */
    edges: Array<ContentTypeToTaxonomyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Taxonomy>;
    /** Information about pagination in a connection. */
    pageInfo: ContentTypeToTaxonomyConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentTypeToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: 'ContentTypeToTaxonomyConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Taxonomy;
  };

/** Page Info on the &quot;ContentTypeToTaxonomyConnection&quot; */
export type ContentTypeToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ContentTypeToTaxonomyConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the GraphqlDocumentGroup taxonomy. */
export enum ContentTypesOfGraphqlDocumentGroupEnum {
  /** The Type of Content object */
  GraphqlDocument = 'GRAPHQL_DOCUMENT',
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the TaxonomyCategory taxonomy. */
export enum ContentTypesOfTaxonomyCategoryEnum {
  /** The Type of Content object */
  App = 'APP',
  /** The Type of Content object */
  Checklists = 'CHECKLISTS',
  /** The Type of Content object */
  Topics = 'TOPICS',
}

/** Allowed Content Types of the TaxonomyIndustry taxonomy. */
export enum ContentTypesOfTaxonomyIndustryEnum {
  /** The Type of Content object */
  Customers = 'CUSTOMERS',
  /** The Type of Content object */
  Testimonial = 'TESTIMONIAL',
}

/** Allowed Content Types of the TaxonomyRegion taxonomy. */
export enum ContentTypesOfTaxonomyRegionEnum {
  /** The Type of Content object */
  Customers = 'CUSTOMERS',
}

/** Allowed Content Types of the TaxonomySolution taxonomy. */
export enum ContentTypesOfTaxonomySolutionEnum {
  /** The Type of Content object */
  Customers = 'CUSTOMERS',
}

/** Input for the createApp mutation. */
export type CreateAppInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the App and TaxonomyCategories */
  taxonomyCategories?: InputMaybe<AppTaxonomyCategoriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createApp mutation. */
export type CreateAppPayload = {
  __typename?: 'CreateAppPayload';
  /** The Post object mutation type. */
  app?: Maybe<App>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the createCategory mutation. */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the category object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createCategory mutation. */
export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  /** The created category */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the createChecklist mutation. */
export type CreateChecklistInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Checklist and TaxonomyCategories */
  taxonomyCategories?: InputMaybe<ChecklistTaxonomyCategoriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createChecklist mutation. */
export type CreateChecklistPayload = {
  __typename?: 'CreateChecklistPayload';
  /** The Post object mutation type. */
  checklist?: Maybe<Checklist>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the createComment mutation. */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']['input']>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']['input']>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']['input']>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** The approval status of the comment */
  status?: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createComment mutation. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Input for the createCustomerStory mutation. */
export type CreateCustomerStoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the CustomerStory and TaxonomyIndustries */
  taxonomyIndustries?: InputMaybe<CustomerStoryTaxonomyIndustriesInput>;
  /** Set connections between the CustomerStory and TaxonomyRegions */
  taxonomyRegions?: InputMaybe<CustomerStoryTaxonomyRegionsInput>;
  /** Set connections between the CustomerStory and TaxonomySolutions */
  taxonomySolutions?: InputMaybe<CustomerStoryTaxonomySolutionsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createCustomerStory mutation. */
export type CreateCustomerStoryPayload = {
  __typename?: 'CreateCustomerStoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  customerStory?: Maybe<CustomerStory>;
};

/** Input for the createEbook mutation. */
export type CreateEbookInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createEbook mutation. */
export type CreateEbookPayload = {
  __typename?: 'CreateEbookPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  ebook?: Maybe<Ebook>;
};

/** Input for the createEvent mutation. */
export type CreateEventInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createEvent mutation. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  event?: Maybe<Event>;
};

/** Input for the createGraphqlDocumentGroup mutation. */
export type CreateGraphqlDocumentGroupInput = {
  /** The slug that the graphql_document_group will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the graphql_document_group object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the graphql_document_group object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createGraphqlDocumentGroup mutation. */
export type CreateGraphqlDocumentGroupPayload = {
  __typename?: 'CreateGraphqlDocumentGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created graphql_document_group */
  graphqlDocumentGroup?: Maybe<GraphqlDocumentGroup>;
};

/** Input for the createGraphqlDocument mutation. */
export type CreateGraphqlDocumentInput = {
  /** Alias names for saved GraphQL query documents */
  alias?: InputMaybe<Array<Scalars['String']['input']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Description for the saved GraphQL document */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Allow, deny or default access grant for specific query */
  grant?: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the graphqlDocument and graphqlDocumentGroups */
  graphqlDocumentGroups?: InputMaybe<GraphqlDocumentGraphqlDocumentGroupsInput>;
  /** HTTP Cache-Control max-age directive for a saved GraphQL document */
  maxAgeHeader?: InputMaybe<Scalars['Int']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createGraphqlDocument mutation. */
export type CreateGraphqlDocumentPayload = {
  __typename?: 'CreateGraphqlDocumentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  graphqlDocument?: Maybe<GraphqlDocument>;
};

/** Input for the createLegal mutation. */
export type CreateLegalInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createLegal mutation. */
export type CreateLegalPayload = {
  __typename?: 'CreateLegalPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  legal?: Maybe<Legal>;
};

/** Input for the createMediaItem mutation. */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']['input']>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']['input']>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']['input']>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createMediaItem mutation. */
export type CreateMediaItemPayload = {
  __typename?: 'CreateMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the createPage mutation. */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPage mutation. */
export type CreatePagePayload = {
  __typename?: 'CreatePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  page?: Maybe<Page>;
};

/** Input for the createPartnership mutation. */
export type CreatePartnershipInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPartnership mutation. */
export type CreatePartnershipPayload = {
  __typename?: 'CreatePartnershipPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  partnership?: Maybe<Partnership>;
};

/** Input for the createPostFormat mutation. */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the post_format object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createPostFormat mutation. */
export type CreatePostFormatPayload = {
  __typename?: 'CreatePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created post_format */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the createPost mutation. */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']['input']>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the createPost mutation. */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  post?: Maybe<Post>;
};

/** Input for the createTag mutation. */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the post_tag object to mutate */
  name: Scalars['String']['input'];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTag mutation. */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created post_tag */
  tag?: Maybe<Tag>;
};

/** Input for the createTaxonomyCategory mutation. */
export type CreateTaxonomyCategoryInput = {
  /** The slug that the categories will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the categories object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the categories object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the categories that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTaxonomyCategory mutation. */
export type CreateTaxonomyCategoryPayload = {
  __typename?: 'CreateTaxonomyCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created categories */
  taxonomyCategory?: Maybe<TaxonomyCategory>;
};

/** Input for the createTaxonomyIndustry mutation. */
export type CreateTaxonomyIndustryInput = {
  /** The slug that the industry will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the industry object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the industry object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the industry that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTaxonomyIndustry mutation. */
export type CreateTaxonomyIndustryPayload = {
  __typename?: 'CreateTaxonomyIndustryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created industry */
  taxonomyIndustry?: Maybe<TaxonomyIndustry>;
};

/** Input for the createTaxonomyRegion mutation. */
export type CreateTaxonomyRegionInput = {
  /** The slug that the region will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the region object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the region object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the region that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTaxonomyRegion mutation. */
export type CreateTaxonomyRegionPayload = {
  __typename?: 'CreateTaxonomyRegionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created region */
  taxonomyRegion?: Maybe<TaxonomyRegion>;
};

/** Input for the createTaxonomySolution mutation. */
export type CreateTaxonomySolutionInput = {
  /** The slug that the solution will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the solution object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the solution object to mutate */
  name: Scalars['String']['input'];
  /** The ID of the solution that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTaxonomySolution mutation. */
export type CreateTaxonomySolutionPayload = {
  __typename?: 'CreateTaxonomySolutionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created solution */
  taxonomySolution?: Maybe<TaxonomySolution>;
};

/** Input for the createTestimonial mutation. */
export type CreateTestimonialInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Testimonial and TaxonomyIndustries */
  taxonomyIndustries?: InputMaybe<TestimonialTaxonomyIndustriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTestimonial mutation. */
export type CreateTestimonialPayload = {
  __typename?: 'CreateTestimonialPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  testimonial?: Maybe<Testimonial>;
};

/** Input for the createTopic mutation. */
export type CreateTopicInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Topic and TaxonomyCategories */
  taxonomyCategories?: InputMaybe<TopicTaxonomyCategoriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createTopic mutation. */
export type CreateTopicPayload = {
  __typename?: 'CreateTopicPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  topic?: Maybe<Topic>;
};

/** Input for the createUser mutation. */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']['input']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']['input']>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars['String']['input'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the createUser mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** Customer Stories Archive options. */
export type CustomerStoriesArchive = {
  __typename?: 'CustomerStoriesArchive';
  /** Added to the GraphQL Schema because the ACF Field Group &quot;Options Page: Customer Stories Archive&quot; was set to Show in GraphQL. */
  acfCustomerStroriesArchive?: Maybe<CustomerStoriesArchive_Acfcustomerstroriesarchive>;
  pageSlug?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type CustomerStoriesArchive_Acfcustomerstroriesarchive =
  AcfFieldGroup & {
    __typename?: 'CustomerStoriesArchive_Acfcustomerstroriesarchive';
    featured?: Maybe<CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured =
  AcfFieldGroup & {
    __typename?: 'CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured';
    featured?: Maybe<
      Array<
        Maybe<CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured_Featured>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured_Featured =
  AcfFieldGroup & {
    __typename?: 'CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured_featured';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    post?: Maybe<CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured_Featured_Post>;
  };

export type CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured_Featured_Post =
  CustomerStory;

/** The CustomerStory type */
export type CustomerStory = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'CustomerStory';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Customer Story&quot; was set to Show in GraphQL. */
    acfCustomerStory?: Maybe<CustomerStory_Acfcustomerstory>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<CustomerStory_Acfpagestatus>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    customerStoryId: Scalars['Int']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the customers object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the customers object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** CustomerStory language */
    language?: Maybe<Language>;
    /** CustomerStory language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the customers object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the CustomerStory type and the CustomerStory type */
    preview?: Maybe<CustomerStoryToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** Connection between the CustomerStory type and the TaxonomyIndustry type */
    taxonomyIndustries?: Maybe<CustomerStoryToTaxonomyIndustryConnection>;
    /** Connection between the CustomerStory type and the TaxonomyRegion type */
    taxonomyRegions?: Maybe<CustomerStoryToTaxonomyRegionConnection>;
    /** Connection between the CustomerStory type and the TaxonomySolution type */
    taxonomySolutions?: Maybe<CustomerStoryToTaxonomySolutionConnection>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the CustomerStory type and the TermNode type */
    terms?: Maybe<CustomerStoryToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<CustomerStory>>>;
    /** CustomerStory translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The CustomerStory type */
export type CustomerStoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The CustomerStory type */
export type CustomerStoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The CustomerStory type */
export type CustomerStoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The CustomerStory type */
export type CustomerStoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The CustomerStory type */
export type CustomerStoryTaxonomyIndustriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerStoryToTaxonomyIndustryConnectionWhereArgs>;
};

/** The CustomerStory type */
export type CustomerStoryTaxonomyRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerStoryToTaxonomyRegionConnectionWhereArgs>;
};

/** The CustomerStory type */
export type CustomerStoryTaxonomySolutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerStoryToTaxonomySolutionConnectionWhereArgs>;
};

/** The CustomerStory type */
export type CustomerStoryTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerStoryToTermNodeConnectionWhereArgs>;
};

/** The CustomerStory type */
export type CustomerStoryTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to CustomerStory Nodes */
export type CustomerStoryConnection = {
  /** A list of edges (relational context) between RootQuery and connected CustomerStory Nodes */
  edges: Array<CustomerStoryConnectionEdge>;
  /** A list of connected CustomerStory Nodes */
  nodes: Array<CustomerStory>;
  /** Information about pagination in a connection. */
  pageInfo: CustomerStoryConnectionPageInfo;
};

/** Edge between a Node and a connected CustomerStory */
export type CustomerStoryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected CustomerStory Node */
  node: CustomerStory;
};

/** Page Info on the connected CustomerStoryConnectionEdge */
export type CustomerStoryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CustomerStoryIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the CustomerStory to TaxonomyIndustries */
export type CustomerStoryTaxonomyIndustriesInput = {
  /** If true, this will append the TaxonomyIndustry to existing related TaxonomyIndustries. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<CustomerStoryTaxonomyIndustriesNodeInput>>
  >;
};

/** List of TaxonomyIndustries to connect the CustomerStory to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CustomerStoryTaxonomyIndustriesNodeInput = {
  /** The description of the TaxonomyIndustry. This field is used to set a description of the TaxonomyIndustry if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyIndustry. If present, this will be used to connect to the CustomerStory. If no existing TaxonomyIndustry exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomyIndustry. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomyIndustry. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Set relationships between the CustomerStory to TaxonomyRegions */
export type CustomerStoryTaxonomyRegionsInput = {
  /** If true, this will append the TaxonomyRegion to existing related TaxonomyRegions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CustomerStoryTaxonomyRegionsNodeInput>>>;
};

/** List of TaxonomyRegions to connect the CustomerStory to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CustomerStoryTaxonomyRegionsNodeInput = {
  /** The description of the TaxonomyRegion. This field is used to set a description of the TaxonomyRegion if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyRegion. If present, this will be used to connect to the CustomerStory. If no existing TaxonomyRegion exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomyRegion. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomyRegion. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Set relationships between the CustomerStory to TaxonomySolutions */
export type CustomerStoryTaxonomySolutionsInput = {
  /** If true, this will append the TaxonomySolution to existing related TaxonomySolutions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<CustomerStoryTaxonomySolutionsNodeInput>>
  >;
};

/** List of TaxonomySolutions to connect the CustomerStory to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CustomerStoryTaxonomySolutionsNodeInput = {
  /** The description of the TaxonomySolution. This field is used to set a description of the TaxonomySolution if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomySolution. If present, this will be used to connect to the CustomerStory. If no existing TaxonomySolution exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomySolution. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomySolution. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the CustomerStory type and the CustomerStory type */
export type CustomerStoryToPreviewConnectionEdge = CustomerStoryConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CustomerStoryToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: CustomerStory;
  };

/** Connection between the CustomerStory type and the TaxonomyIndustry type */
export type CustomerStoryToTaxonomyIndustryConnection = Connection &
  TaxonomyIndustryConnection & {
    __typename?: 'CustomerStoryToTaxonomyIndustryConnection';
    /** Edges for the CustomerStoryToTaxonomyIndustryConnection connection */
    edges: Array<CustomerStoryToTaxonomyIndustryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyIndustry>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerStoryToTaxonomyIndustryConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerStoryToTaxonomyIndustryConnectionEdge = Edge &
  TaxonomyIndustryConnectionEdge & {
    __typename?: 'CustomerStoryToTaxonomyIndustryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary industry */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyIndustry;
  };

/** Page Info on the &quot;CustomerStoryToTaxonomyIndustryConnection&quot; */
export type CustomerStoryToTaxonomyIndustryConnectionPageInfo = PageInfo &
  TaxonomyIndustryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CustomerStoryToTaxonomyIndustryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerStoryToTaxonomyIndustryConnection connection */
export type CustomerStoryToTaxonomyIndustryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the CustomerStory type and the TaxonomyRegion type */
export type CustomerStoryToTaxonomyRegionConnection = Connection &
  TaxonomyRegionConnection & {
    __typename?: 'CustomerStoryToTaxonomyRegionConnection';
    /** Edges for the CustomerStoryToTaxonomyRegionConnection connection */
    edges: Array<CustomerStoryToTaxonomyRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyRegion>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerStoryToTaxonomyRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerStoryToTaxonomyRegionConnectionEdge = Edge &
  TaxonomyRegionConnectionEdge & {
    __typename?: 'CustomerStoryToTaxonomyRegionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary region */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyRegion;
  };

/** Page Info on the &quot;CustomerStoryToTaxonomyRegionConnection&quot; */
export type CustomerStoryToTaxonomyRegionConnectionPageInfo = PageInfo &
  TaxonomyRegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CustomerStoryToTaxonomyRegionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerStoryToTaxonomyRegionConnection connection */
export type CustomerStoryToTaxonomyRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the CustomerStory type and the TaxonomySolution type */
export type CustomerStoryToTaxonomySolutionConnection = Connection &
  TaxonomySolutionConnection & {
    __typename?: 'CustomerStoryToTaxonomySolutionConnection';
    /** Edges for the CustomerStoryToTaxonomySolutionConnection connection */
    edges: Array<CustomerStoryToTaxonomySolutionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomySolution>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerStoryToTaxonomySolutionConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerStoryToTaxonomySolutionConnectionEdge = Edge &
  TaxonomySolutionConnectionEdge & {
    __typename?: 'CustomerStoryToTaxonomySolutionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary solution */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomySolution;
  };

/** Page Info on the &quot;CustomerStoryToTaxonomySolutionConnection&quot; */
export type CustomerStoryToTaxonomySolutionConnectionPageInfo = PageInfo &
  TaxonomySolutionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CustomerStoryToTaxonomySolutionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerStoryToTaxonomySolutionConnection connection */
export type CustomerStoryToTaxonomySolutionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the CustomerStory type and the TermNode type */
export type CustomerStoryToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'CustomerStoryToTermNodeConnection';
    /** Edges for the CustomerStoryToTermNodeConnection connection */
    edges: Array<CustomerStoryToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: CustomerStoryToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CustomerStoryToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'CustomerStoryToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;CustomerStoryToTermNodeConnection&quot; */
export type CustomerStoryToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CustomerStoryToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the CustomerStoryToTermNodeConnection connection */
export type CustomerStoryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Field Group */
export type CustomerStory_Acfcustomerstory = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<CustomerStory_Acfcustomerstory_Highlights>;
  image?: Maybe<CustomerStory_Acfcustomerstory_Image>;
  intro?: Maybe<CustomerStory_Acfcustomerstory_Intro>;
  logo?: Maybe<CustomerStory_Acfcustomerstory_Logo>;
  section?: Maybe<Array<Maybe<CustomerStory_Acfcustomerstory_Section>>>;
};

/** Field Group */
export type CustomerStory_Acfcustomerstory_Highlights = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Highlights';
  customise?: Maybe<CustomerStory_Acfcustomerstory_Highlights_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<
    Array<Maybe<CustomerStory_Acfcustomerstory_Highlights_Highlights>>
  >;
};

/** Field Group */
export type CustomerStory_Acfcustomerstory_Highlights_Customise =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Highlights_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Highlights_Highlights =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Highlights_highlights';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<CustomerStory_Acfcustomerstory_Highlights_Highlights_Highlight>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Highlights_Highlights_Highlight =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Highlights_highlights_Highlight';
    customise?: Maybe<CustomerStory_Acfcustomerstory_Highlights_Highlights_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Highlights_Highlights_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Highlights_highlights_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Image = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
};

/** Field Group */
export type CustomerStory_Acfcustomerstory_Intro = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Intro';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type CustomerStory_Acfcustomerstory_Logo = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Logo';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
};

export type CustomerStory_Acfcustomerstory_Section =
  | CustomerStory_Acfcustomerstory_Section_Block
  | CustomerStory_Acfcustomerstory_Section_Cta
  | CustomerStory_Acfcustomerstory_Section_Editor
  | CustomerStory_Acfcustomerstory_Section_Image
  | CustomerStory_Acfcustomerstory_Section_Quote
  | CustomerStory_Acfcustomerstory_Section_Title
  | CustomerStory_Acfcustomerstory_Section_Video;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Block = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Block';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<
    Array<Maybe<CustomerStory_Acfcustomerstory_Section_Block_Gallery>>
  >;
};

export type CustomerStory_Acfcustomerstory_Section_Block_Gallery =
  | CustomerStory_Acfcustomerstory_Section_Block_Gallery_Image
  | CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Image =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Array<Maybe<MediaItem>>>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    video?: Maybe<
      Array<
        Maybe<CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video>
      >
    >;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    thumbnail?: Maybe<MediaItem>;
    video?: Maybe<
      Array<
        Maybe<CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video>
      >
    >;
  };

export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video =

    | CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Wistia
    | CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Youtube;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta';
  cta?: Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta';
  content?: Maybe<
    Array<Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content>>
  >;
  customise?: Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content =
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Editor
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Embed
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Product
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Search
  | CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Title;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks';
    checks?: Maybe<
      Array<
        Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks_Checks>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks_Checks =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks_checks';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Embed =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Embed';
    embed?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite';
    eventbrite?: Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite_Eventbrite>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    iconsList?: Maybe<
      Array<
        Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList_IconsList>
      >
    >;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList_IconsList =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList_iconsList';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_Links>
      >
    >;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Product =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Product';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    product?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Search =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Search';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    search?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Title =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Cta_Cta_Customise =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Customise';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    width?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Editor = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Image = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<
    Array<Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote>>
  >;
};

export type CustomerStory_Acfcustomerstory_Section_Quote_Quote =
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Chip
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Editor
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Link
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Rating
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Title
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author';
    author?: Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author_Author>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author_Author =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author_Author';
    avatar?: Maybe<MediaItem>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Chip =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Editor =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image_Image>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image_Image =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Link =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Link';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logo?: Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo_Logo>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo_Logo =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    logo?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Rating =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Rating';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    rating?: Maybe<Scalars['Float']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Title =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    video?: Maybe<
      Array<
        Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video>
      >
    >;
  };

export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video =
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Wistia
  | CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Youtube;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Title = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Video = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfcustomerstory_Section_Video';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  video?: Maybe<
    Array<Maybe<CustomerStory_Acfcustomerstory_Section_Video_Video>>
  >;
};

export type CustomerStory_Acfcustomerstory_Section_Video_Video =
  | CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia
  | CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube;

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type CustomerStory_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'CustomerStory_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Object that can be identified with a Database ID */
export type DatabaseIdentifier = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
};

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']['input']>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']['input']>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after?: InputMaybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: InputMaybe<DateInput>;
  /** Column to query against */
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: InputMaybe<Scalars['String']['input']>;
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars['Int']['input']>;
  /** Hour (from 0 to 23) */
  hour?: InputMaybe<Scalars['Int']['input']>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Minute (from 0 to 59) */
  minute?: InputMaybe<Scalars['Int']['input']>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars['Int']['input']>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: InputMaybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: InputMaybe<Scalars['Int']['input']>;
  /** Week of the year (from 0 to 53) */
  week?: InputMaybe<Scalars['Int']['input']>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** The template assigned to the node */
export type DefaultTemplate = ContentTemplate & {
  __typename?: 'DefaultTemplate';
  /** The name of the template */
  templateName?: Maybe<Scalars['String']['output']>;
};

/** Input for the deleteApp mutation. */
export type DeleteAppInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the App to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteApp mutation. */
export type DeleteAppPayload = {
  __typename?: 'DeleteAppPayload';
  /** The object before it was deleted */
  app?: Maybe<App>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteCategory mutation. */
export type DeleteCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteCategory mutation. */
export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  /** The deleted term object */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteChecklist mutation. */
export type DeleteChecklistInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Checklist to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteChecklist mutation. */
export type DeleteChecklistPayload = {
  __typename?: 'DeleteChecklistPayload';
  /** The object before it was deleted */
  checklist?: Maybe<Checklist>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteComment mutation. */
export type DeleteCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The deleted comment ID */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteComment mutation. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The deleted comment object */
  comment?: Maybe<Comment>;
  /** The deleted comment ID */
  deletedId?: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteCustomerStory mutation. */
export type DeleteCustomerStoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the CustomerStory to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteCustomerStory mutation. */
export type DeleteCustomerStoryPayload = {
  __typename?: 'DeleteCustomerStoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The object before it was deleted */
  customerStory?: Maybe<CustomerStory>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
};

/** Input for the deleteEbook mutation. */
export type DeleteEbookInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Ebook to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteEbook mutation. */
export type DeleteEbookPayload = {
  __typename?: 'DeleteEbookPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  ebook?: Maybe<Ebook>;
};

/** Input for the deleteEvent mutation. */
export type DeleteEventInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Event to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteEvent mutation. */
export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  event?: Maybe<Event>;
};

/** Input for the deleteGraphqlDocumentGroup mutation. */
export type DeleteGraphqlDocumentGroupInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the graphqlDocumentGroup to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteGraphqlDocumentGroup mutation. */
export type DeleteGraphqlDocumentGroupPayload = {
  __typename?: 'DeleteGraphqlDocumentGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  graphqlDocumentGroup?: Maybe<GraphqlDocumentGroup>;
};

/** Input for the deleteGraphqlDocument mutation. */
export type DeleteGraphqlDocumentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the graphqlDocument to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteGraphqlDocument mutation. */
export type DeleteGraphqlDocumentPayload = {
  __typename?: 'DeleteGraphqlDocumentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  graphqlDocument?: Maybe<GraphqlDocument>;
};

/** Input for the deleteLegal mutation. */
export type DeleteLegalInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Legal to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteLegal mutation. */
export type DeleteLegalPayload = {
  __typename?: 'DeleteLegalPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  legal?: Maybe<Legal>;
};

/** Input for the deleteMediaItem mutation. */
export type DeleteMediaItemInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the mediaItem to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteMediaItem mutation. */
export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted mediaItem */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The mediaItem before it was deleted */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the deletePage mutation. */
export type DeletePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the page to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deletePage mutation. */
export type DeletePagePayload = {
  __typename?: 'DeletePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  page?: Maybe<Page>;
};

/** Input for the deletePartnership mutation. */
export type DeletePartnershipInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Partnership to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deletePartnership mutation. */
export type DeletePartnershipPayload = {
  __typename?: 'DeletePartnershipPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  partnership?: Maybe<Partnership>;
};

/** Input for the deletePostFormat mutation. */
export type DeletePostFormatInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the postFormat to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deletePostFormat mutation. */
export type DeletePostFormatPayload = {
  __typename?: 'DeletePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the deletePost mutation. */
export type DeletePostInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the post to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deletePost mutation. */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  post?: Maybe<Post>;
};

/** Input for the deleteTag mutation. */
export type DeleteTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tag to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteTag mutation. */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  tag?: Maybe<Tag>;
};

/** Input for the deleteTaxonomyCategory mutation. */
export type DeleteTaxonomyCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyCategory to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteTaxonomyCategory mutation. */
export type DeleteTaxonomyCategoryPayload = {
  __typename?: 'DeleteTaxonomyCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  taxonomyCategory?: Maybe<TaxonomyCategory>;
};

/** Input for the deleteTaxonomyIndustry mutation. */
export type DeleteTaxonomyIndustryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyIndustry to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteTaxonomyIndustry mutation. */
export type DeleteTaxonomyIndustryPayload = {
  __typename?: 'DeleteTaxonomyIndustryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  taxonomyIndustry?: Maybe<TaxonomyIndustry>;
};

/** Input for the deleteTaxonomyRegion mutation. */
export type DeleteTaxonomyRegionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyRegion to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteTaxonomyRegion mutation. */
export type DeleteTaxonomyRegionPayload = {
  __typename?: 'DeleteTaxonomyRegionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  taxonomyRegion?: Maybe<TaxonomyRegion>;
};

/** Input for the deleteTaxonomySolution mutation. */
export type DeleteTaxonomySolutionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomySolution to delete */
  id: Scalars['ID']['input'];
};

/** The payload for the deleteTaxonomySolution mutation. */
export type DeleteTaxonomySolutionPayload = {
  __typename?: 'DeleteTaxonomySolutionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted term object */
  taxonomySolution?: Maybe<TaxonomySolution>;
};

/** Input for the deleteTestimonial mutation. */
export type DeleteTestimonialInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Testimonial to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteTestimonial mutation. */
export type DeleteTestimonialPayload = {
  __typename?: 'DeleteTestimonialPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  testimonial?: Maybe<Testimonial>;
};

/** Input for the deleteTopic mutation. */
export type DeleteTopicInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the Topic to delete */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the deleteTopic mutation. */
export type DeleteTopicPayload = {
  __typename?: 'DeleteTopicPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The object before it was deleted */
  topic?: Maybe<Topic>;
};

/** Input for the deleteUser mutation. */
export type DeleteUserInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user you want to delete */
  id: Scalars['ID']['input'];
  /** Reassign posts and links to new User ID. */
  reassignId?: InputMaybe<Scalars['ID']['input']>;
};

/** The payload for the deleteUser mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The ID of the user that you just deleted */
  deletedId?: Maybe<Scalars['ID']['output']>;
  /** The deleted user object */
  user?: Maybe<User>;
};

/** The discussion setting type */
export type DiscussionSettings = {
  __typename?: 'DiscussionSettings';
  /** Allow people to submit comments on new posts. */
  defaultCommentStatus?: Maybe<Scalars['String']['output']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  defaultPingStatus?: Maybe<Scalars['String']['output']>;
};

/** The Ebook type */
export type Ebook = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Ebook';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Ebook&quot; was set to Show in GraphQL. */
    acfEbook?: Maybe<Ebook_Acfebook>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Ebook_Acfpagestatus>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    ebookId: Scalars['Int']['output'];
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the ebooks object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the ebooks object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Ebook language */
    language?: Maybe<Language>;
    /** Ebook language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The password for the ebooks object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Ebook type and the Ebook type */
    preview?: Maybe<EbookToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Ebook>>>;
    /** Ebook translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Ebook type */
export type EbookEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Ebook type */
export type EbookEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Ebook type */
export type EbookTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Ebook Nodes */
export type EbookConnection = {
  /** A list of edges (relational context) between RootQuery and connected Ebook Nodes */
  edges: Array<EbookConnectionEdge>;
  /** A list of connected Ebook Nodes */
  nodes: Array<Ebook>;
  /** Information about pagination in a connection. */
  pageInfo: EbookConnectionPageInfo;
};

/** Edge between a Node and a connected Ebook */
export type EbookConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Ebook Node */
  node: Ebook;
};

/** Page Info on the connected EbookConnectionEdge */
export type EbookConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum EbookIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the Ebook type and the Ebook type */
export type EbookToPreviewConnectionEdge = EbookConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'EbookToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Ebook;
  };

/** Field Group */
export type Ebook_Acfebook = AcfFieldGroup & {
  __typename?: 'Ebook_Acfebook';
  contentEditor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  heroEditor?: Maybe<Scalars['String']['output']>;
  heroTitle?: Maybe<Scalars['String']['output']>;
  /** Marketo */
  hubspotForm?: Maybe<Ebook_Acfebook_HubspotForm>;
};

/** Field Group */
export type Ebook_Acfebook_HubspotForm = AcfFieldGroup & {
  __typename?: 'Ebook_Acfebook_HubspotForm';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  hubspotForm?: Maybe<Ebook_Acfebook_HubspotForm_HubspotForm>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Ebook_Acfebook_HubspotForm_HubspotForm = AcfFieldGroup & {
  __typename?: 'Ebook_Acfebook_HubspotForm_HubspotForm';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Ebook_Acfebook_HubspotForm_HubspotForm_Id>;
  message?: Maybe<Scalars['String']['output']>;
  redirect?: Maybe<AcfLink>;
};

/** Field Group */
export type Ebook_Acfebook_HubspotForm_HubspotForm_Id = AcfFieldGroup & {
  __typename?: 'Ebook_Acfebook_HubspotForm_HubspotForm_Id';
  contentId?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  formId?: Maybe<Scalars['String']['output']>;
  portalId?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Ebook_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Ebook_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Relational context between connected nodes */
export type Edge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected node */
  node: Node;
};

/** Asset enqueued by the CMS */
export type EnqueuedAsset = {
  /** The inline code to be run after the asset is loaded. */
  after?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Deprecated
   * @deprecated Use `EnqueuedAsset.media` instead.
   */
  args?: Maybe<Scalars['Boolean']['output']>;
  /** The inline code to be run before the asset is loaded. */
  before?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
  conditional?: Maybe<Scalars['String']['output']>;
  /** Dependencies needed to use this asset */
  dependencies?: Maybe<Array<Maybe<EnqueuedAsset>>>;
  /**
   * Extra information needed for the script
   * @deprecated Use `EnqueuedScript.extraData` instead.
   */
  extra?: Maybe<Scalars['String']['output']>;
  /** The handle of the enqueued asset */
  handle?: Maybe<Scalars['String']['output']>;
  /** The ID of the enqueued asset */
  id: Scalars['ID']['output'];
  /** The source of the asset */
  src?: Maybe<Scalars['String']['output']>;
  /** The version of the enqueued asset */
  version?: Maybe<Scalars['String']['output']>;
};

/** Script enqueued by the CMS */
export type EnqueuedScript = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedScript';
    /** The inline code to be run after the asset is loaded. */
    after?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * Deprecated
     * @deprecated Use `EnqueuedAsset.media` instead.
     */
    args?: Maybe<Scalars['Boolean']['output']>;
    /** The inline code to be run before the asset is loaded. */
    before?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
    conditional?: Maybe<Scalars['String']['output']>;
    /** Dependencies needed to use this asset */
    dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
    /**
     * Extra information needed for the script
     * @deprecated Use `EnqueuedScript.extraData` instead.
     */
    extra?: Maybe<Scalars['String']['output']>;
    /** Extra data supplied to the enqueued script */
    extraData?: Maybe<Scalars['String']['output']>;
    /** The handle of the enqueued asset */
    handle?: Maybe<Scalars['String']['output']>;
    /** The global ID of the enqueued script */
    id: Scalars['ID']['output'];
    /** The source of the asset */
    src?: Maybe<Scalars['String']['output']>;
    /** The loading strategy to use on the script tag */
    strategy?: Maybe<ScriptLoadingStrategyEnum>;
    /** The version of the enqueued script */
    version?: Maybe<Scalars['String']['output']>;
  };

/** Connection to EnqueuedScript Nodes */
export type EnqueuedScriptConnection = {
  /** A list of edges (relational context) between ContentNode and connected EnqueuedScript Nodes */
  edges: Array<EnqueuedScriptConnectionEdge>;
  /** A list of connected EnqueuedScript Nodes */
  nodes: Array<EnqueuedScript>;
  /** Information about pagination in a connection. */
  pageInfo: EnqueuedScriptConnectionPageInfo;
};

/** Edge between a Node and a connected EnqueuedScript */
export type EnqueuedScriptConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected EnqueuedScript Node */
  node: EnqueuedScript;
};

/** Page Info on the connected EnqueuedScriptConnectionEdge */
export type EnqueuedScriptConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Stylesheet enqueued by the CMS */
export type EnqueuedStylesheet = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedStylesheet';
    /** The inline code to be run after the asset is loaded. */
    after?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /**
     * Deprecated
     * @deprecated Use `EnqueuedAsset.media` instead.
     */
    args?: Maybe<Scalars['Boolean']['output']>;
    /** The inline code to be run before the asset is loaded. */
    before?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
    conditional?: Maybe<Scalars['String']['output']>;
    /** Dependencies needed to use this asset */
    dependencies?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
    /**
     * Extra information needed for the script
     * @deprecated Use `EnqueuedScript.extraData` instead.
     */
    extra?: Maybe<Scalars['String']['output']>;
    /** The handle of the enqueued asset */
    handle?: Maybe<Scalars['String']['output']>;
    /** The global ID of the enqueued stylesheet */
    id: Scalars['ID']['output'];
    /** Whether the enqueued style is RTL or not */
    isRtl?: Maybe<Scalars['Boolean']['output']>;
    /** The media attribute to use for the link */
    media?: Maybe<Scalars['String']['output']>;
    /** The absolute path to the enqueued style. Set when the stylesheet is meant to load inline. */
    path?: Maybe<Scalars['String']['output']>;
    /** The `rel` attribute to use for the link */
    rel?: Maybe<Scalars['String']['output']>;
    /** The source of the asset */
    src?: Maybe<Scalars['String']['output']>;
    /** Optional suffix, used in combination with RTL */
    suffix?: Maybe<Scalars['String']['output']>;
    /** The title of the enqueued style. Used for preferred/alternate stylesheets. */
    title?: Maybe<Scalars['String']['output']>;
    /** The version of the enqueued style */
    version?: Maybe<Scalars['String']['output']>;
  };

/** Connection to EnqueuedStylesheet Nodes */
export type EnqueuedStylesheetConnection = {
  /** A list of edges (relational context) between ContentNode and connected EnqueuedStylesheet Nodes */
  edges: Array<EnqueuedStylesheetConnectionEdge>;
  /** A list of connected EnqueuedStylesheet Nodes */
  nodes: Array<EnqueuedStylesheet>;
  /** Information about pagination in a connection. */
  pageInfo: EnqueuedStylesheetConnectionPageInfo;
};

/** Edge between a Node and a connected EnqueuedStylesheet */
export type EnqueuedStylesheetConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected EnqueuedStylesheet Node */
  node: EnqueuedStylesheet;
};

/** Page Info on the connected EnqueuedStylesheetConnectionEdge */
export type EnqueuedStylesheetConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Event type */
export type Event = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Event';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Event&quot; was set to Show in GraphQL. */
    acfEvent?: Maybe<Event_Acfevent>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Event_Acfpagestatus>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    eventId: Scalars['Int']['output'];
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the events-and-webinars object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the events-and-webinars object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Event language */
    language?: Maybe<Language>;
    /** Event language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The password for the events-and-webinars object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Event type and the Event type */
    preview?: Maybe<EventToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Event>>>;
    /** Event translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Event type */
export type EventEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Event type */
export type EventEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Event type */
export type EventTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Event Nodes */
export type EventConnection = {
  /** A list of edges (relational context) between RootQuery and connected Event Nodes */
  edges: Array<EventConnectionEdge>;
  /** A list of connected Event Nodes */
  nodes: Array<Event>;
  /** Information about pagination in a connection. */
  pageInfo: EventConnectionPageInfo;
};

/** Edge between a Node and a connected Event */
export type EventConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Event Node */
  node: Event;
};

/** Page Info on the connected EventConnectionEdge */
export type EventConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum EventIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the Event type and the Event type */
export type EventToPreviewConnectionEdge = Edge &
  EventConnectionEdge &
  OneToOneConnection & {
    __typename?: 'EventToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Event;
  };

/** Field Group */
export type Event_Acfevent = AcfFieldGroup & {
  __typename?: 'Event_Acfevent';
  background?: Maybe<Scalars['String']['output']>;
  contentContent?: Maybe<Array<Maybe<Event_Acfevent_ContentContent>>>;
  customiseEditor?: Maybe<Scalars['String']['output']>;
  customiseEventDate?: Maybe<Scalars['String']['output']>;
  customiseEventDateTo?: Maybe<Scalars['String']['output']>;
  customiseEventDeliveryType?: Maybe<Scalars['String']['output']>;
  /** Enabling this option would push the event to Featured section and display in order by date */
  customiseEventFeatured?: Maybe<Scalars['Boolean']['output']>;
  customiseEventGmt?: Maybe<Scalars['String']['output']>;
  customiseEventProductArea?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  customiseEventTimeEnd?: Maybe<Scalars['String']['output']>;
  customiseEventTimeStart?: Maybe<Scalars['String']['output']>;
  customiseEventType?: Maybe<Scalars['String']['output']>;
  /** Optional, ex: SafetyCulture&#039;s Sydney Office */
  customiseLocation?: Maybe<Scalars['String']['output']>;
  customiseRegions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Use this field to link this event to either 3rd party website or internal page on this website.
   * &lt;strong&gt;NOTE!&lt;/strong&gt;Leave this field empty if you need this event to be linked to the basic event template
   */
  customiseRegisterPageUrl?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  heroImage?: Maybe<MediaItem>;
  heroIntro?: Maybe<Scalars['String']['output']>;
  heroTitle?: Maybe<Scalars['String']['output']>;
  sidebarSidebar?: Maybe<Array<Maybe<Event_Acfevent_SidebarSidebar>>>;
};

export type Event_Acfevent_ContentContent =
  | Event_Acfevent_ContentContent_Editor
  | Event_Acfevent_ContentContent_Eventbrite
  | Event_Acfevent_ContentContent_Image
  | Event_Acfevent_ContentContent_Table
  | Event_Acfevent_ContentContent_Title
  | Event_Acfevent_ContentContent_Video;

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Editor = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Eventbrite = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Eventbrite';
  eventbrite?: Maybe<Event_Acfevent_ContentContent_Eventbrite_Eventbrite>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Event_Acfevent_ContentContent_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_ContentContent_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Image = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Table = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Table';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Acf_Table>;
};

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Title = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Video = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Video';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Array<Maybe<Event_Acfevent_ContentContent_Video_Video>>>;
};

export type Event_Acfevent_ContentContent_Video_Video =
  | Event_Acfevent_ContentContent_Video_Video_Wistia
  | Event_Acfevent_ContentContent_Video_Video_Youtube;

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Video_Video_Wistia = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_ContentContent_Video_Video_Wistia';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  wistia?: Maybe<Event_Acfevent_ContentContent_Video_Video_Wistia_Wistia>;
};

/** Field Group */
export type Event_Acfevent_ContentContent_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_ContentContent_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Event_Acfevent_ContentContent_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_ContentContent_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Event_Acfevent_ContentContent_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Event_Acfevent_ContentContent_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_ContentContent_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

export type Event_Acfevent_SidebarSidebar =
  | Event_Acfevent_SidebarSidebar_Editor
  | Event_Acfevent_SidebarSidebar_Eventbrite
  | Event_Acfevent_SidebarSidebar_HubspotForm
  | Event_Acfevent_SidebarSidebar_Link
  | Event_Acfevent_SidebarSidebar_Title
  | Event_Acfevent_SidebarSidebar_Wpforms;

/** Group within the flex field */
export type Event_Acfevent_SidebarSidebar_Editor = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_SidebarSidebar_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Event_Acfevent_SidebarSidebar_Eventbrite = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_SidebarSidebar_Eventbrite';
  eventbrite?: Maybe<Event_Acfevent_SidebarSidebar_Eventbrite_Eventbrite>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Event_Acfevent_SidebarSidebar_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_SidebarSidebar_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Event_Acfevent_SidebarSidebar_HubspotForm = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_SidebarSidebar_HubspotForm';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  hubspotForm?: Maybe<Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm>;
};

/** Field Group */
export type Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm_Id>;
    message?: Maybe<Scalars['String']['output']>;
    redirect?: Maybe<AcfLink>;
  };

/** Field Group */
export type Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm_Id =
  AcfFieldGroup & {
    __typename?: 'Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm_Id';
    contentId?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    portalId?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Event_Acfevent_SidebarSidebar_Link = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_SidebarSidebar_Link';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  link?: Maybe<AcfLink>;
};

/** Group within the flex field */
export type Event_Acfevent_SidebarSidebar_Title = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_SidebarSidebar_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Event_Acfevent_SidebarSidebar_Wpforms = AcfFieldGroup & {
  __typename?: 'Event_Acfevent_SidebarSidebar_Wpforms';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  wpforms?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Event_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Event_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** The general setting type */
export type GeneralSettings = {
  __typename?: 'GeneralSettings';
  /** A date format for all date strings. */
  dateFormat?: Maybe<Scalars['String']['output']>;
  /** Site tagline. */
  description?: Maybe<Scalars['String']['output']>;
  /** WordPress locale code. */
  language?: Maybe<Scalars['String']['output']>;
  /** A day number of the week that the week should start on. */
  startOfWeek?: Maybe<Scalars['Int']['output']>;
  /** A time format for all time strings. */
  timeFormat?: Maybe<Scalars['String']['output']>;
  /** A city in the same timezone as you. */
  timezone?: Maybe<Scalars['String']['output']>;
  /** Site title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Site URL. */
  url?: Maybe<Scalars['String']['output']>;
};

/** The graphqlDocument type */
export type GraphqlDocument = ContentNode &
  DatabaseIdentifier &
  Node &
  NodeWithContentEditor &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'GraphqlDocument';
    /** Alias names for saved GraphQL query documents */
    alias?: Maybe<Array<Scalars['String']['output']>>;
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** Description for the saved GraphQL document */
    description?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Allow, deny or default access grant for specific query */
    grant?: Maybe<Scalars['String']['output']>;
    /** Connection between the GraphqlDocument type and the graphqlDocumentGroup type */
    graphqlDocumentGroups?: Maybe<GraphqlDocumentToGraphqlDocumentGroupConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    graphqlDocumentId: Scalars['Int']['output'];
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the graphql_document object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the graphql_document object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** HTTP Cache-Control max-age directive for a saved GraphQL document */
    maxAgeHeader?: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The password for the graphql_document object. */
    password?: Maybe<Scalars['String']['output']>;
    /**
     * Connection between the GraphqlDocument type and the graphqlDocument type
     * @deprecated The &quot;GraphqlDocument&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
     */
    preview?: Maybe<GraphqlDocumentToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** Connection between the GraphqlDocument type and the TermNode type */
    terms?: Maybe<GraphqlDocumentToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<GraphqlDocument>>>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The graphqlDocument type */
export type GraphqlDocumentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The graphqlDocument type */
export type GraphqlDocumentEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocument type */
export type GraphqlDocumentEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocument type */
export type GraphqlDocumentGraphqlDocumentGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphqlDocumentToGraphqlDocumentGroupConnectionWhereArgs>;
};

/** The graphqlDocument type */
export type GraphqlDocumentTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphqlDocumentToTermNodeConnectionWhereArgs>;
};

/** The graphqlDocument type */
export type GraphqlDocumentTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to graphqlDocument Nodes */
export type GraphqlDocumentConnection = {
  /** A list of edges (relational context) between RootQuery and connected graphqlDocument Nodes */
  edges: Array<GraphqlDocumentConnectionEdge>;
  /** A list of connected graphqlDocument Nodes */
  nodes: Array<GraphqlDocument>;
  /** Information about pagination in a connection. */
  pageInfo: GraphqlDocumentConnectionPageInfo;
};

/** Edge between a Node and a connected graphqlDocument */
export type GraphqlDocumentConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected graphqlDocument Node */
  node: GraphqlDocument;
};

/** Page Info on the connected GraphqlDocumentConnectionEdge */
export type GraphqlDocumentConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Set relationships between the graphqlDocument to graphqlDocumentGroups */
export type GraphqlDocumentGraphqlDocumentGroupsInput = {
  /** If true, this will append the graphqlDocumentGroup to existing related graphqlDocumentGroups. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<
    Array<InputMaybe<GraphqlDocumentGraphqlDocumentGroupsNodeInput>>
  >;
};

/** List of graphqlDocumentGroups to connect the graphqlDocument to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type GraphqlDocumentGraphqlDocumentGroupsNodeInput = {
  /** The description of the graphqlDocumentGroup. This field is used to set a description of the graphqlDocumentGroup if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the graphqlDocumentGroup. If present, this will be used to connect to the graphqlDocument. If no existing graphqlDocumentGroup exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the graphqlDocumentGroup. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the graphqlDocumentGroup. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroup = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'GraphqlDocumentGroup';
    /** Connection between the GraphqlDocumentGroup type and the ContentNode type */
    contentNodes?: Maybe<GraphqlDocumentGroupToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    graphqlDocumentGroupId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the GraphqlDocumentGroup type and the graphqlDocument type */
    graphqlDocuments?: Maybe<GraphqlDocumentGroupToGraphqlDocumentConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO data of the Groups taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the GraphqlDocumentGroup type and the Taxonomy type */
    taxonomy?: Maybe<GraphqlDocumentGroupToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphqlDocumentGroupToContentNodeConnectionWhereArgs>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The graphqlDocumentGroup type */
export type GraphqlDocumentGroupGraphqlDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GraphqlDocumentGroupToGraphqlDocumentConnectionWhereArgs>;
};

/** Connection to graphqlDocumentGroup Nodes */
export type GraphqlDocumentGroupConnection = {
  /** A list of edges (relational context) between RootQuery and connected graphqlDocumentGroup Nodes */
  edges: Array<GraphqlDocumentGroupConnectionEdge>;
  /** A list of connected graphqlDocumentGroup Nodes */
  nodes: Array<GraphqlDocumentGroup>;
  /** Information about pagination in a connection. */
  pageInfo: GraphqlDocumentGroupConnectionPageInfo;
};

/** Edge between a Node and a connected graphqlDocumentGroup */
export type GraphqlDocumentGroupConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected graphqlDocumentGroup Node */
  node: GraphqlDocumentGroup;
};

/** Page Info on the connected GraphqlDocumentGroupConnectionEdge */
export type GraphqlDocumentGroupConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentGroupIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the GraphqlDocumentGroup type and the ContentNode type */
export type GraphqlDocumentGroupToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'GraphqlDocumentGroupToContentNodeConnection';
    /** Edges for the GraphqlDocumentGroupToContentNodeConnection connection */
    edges: Array<GraphqlDocumentGroupToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentGroupToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentGroupToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'GraphqlDocumentGroupToContentNodeConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;GraphqlDocumentGroupToContentNodeConnection&quot; */
export type GraphqlDocumentGroupToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'GraphqlDocumentGroupToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the GraphqlDocumentGroupToContentNodeConnection connection */
export type GraphqlDocumentGroupToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfGraphqlDocumentGroupEnum>>
  >;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the GraphqlDocumentGroup type and the graphqlDocument type */
export type GraphqlDocumentGroupToGraphqlDocumentConnection = Connection &
  GraphqlDocumentConnection & {
    __typename?: 'GraphqlDocumentGroupToGraphqlDocumentConnection';
    /** Edges for the GraphqlDocumentGroupToGraphqlDocumentConnection connection */
    edges: Array<GraphqlDocumentGroupToGraphqlDocumentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocument>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentGroupToGraphqlDocumentConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionEdge = Edge &
  GraphqlDocumentConnectionEdge & {
    __typename?: 'GraphqlDocumentGroupToGraphqlDocumentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocument;
  };

/** Page Info on the &quot;GraphqlDocumentGroupToGraphqlDocumentConnection&quot; */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionPageInfo =
  GraphqlDocumentConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'GraphqlDocumentGroupToGraphqlDocumentConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the GraphqlDocumentGroupToGraphqlDocumentConnection connection */
export type GraphqlDocumentGroupToGraphqlDocumentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the GraphqlDocumentGroup type and the Taxonomy type */
export type GraphqlDocumentGroupToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'GraphqlDocumentGroupToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GraphqlDocumentIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the GraphqlDocument type and the graphqlDocumentGroup type */
export type GraphqlDocumentToGraphqlDocumentGroupConnection = Connection &
  GraphqlDocumentGroupConnection & {
    __typename?: 'GraphqlDocumentToGraphqlDocumentGroupConnection';
    /** Edges for the GraphqlDocumentToGraphqlDocumentGroupConnection connection */
    edges: Array<GraphqlDocumentToGraphqlDocumentGroupConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocumentGroup>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentToGraphqlDocumentGroupConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionEdge = Edge &
  GraphqlDocumentGroupConnectionEdge & {
    __typename?: 'GraphqlDocumentToGraphqlDocumentGroupConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary graphql_document_group */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocumentGroup;
  };

/** Page Info on the &quot;GraphqlDocumentToGraphqlDocumentGroupConnection&quot; */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionPageInfo =
  GraphqlDocumentGroupConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'GraphqlDocumentToGraphqlDocumentGroupConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the GraphqlDocumentToGraphqlDocumentGroupConnection connection */
export type GraphqlDocumentToGraphqlDocumentGroupConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the GraphqlDocument type and the graphqlDocument type */
export type GraphqlDocumentToPreviewConnectionEdge = Edge &
  GraphqlDocumentConnectionEdge &
  OneToOneConnection & {
    __typename?: 'GraphqlDocumentToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /**
     * The node of the connection, without the edges
     * @deprecated The &quot;GraphqlDocument&quot; Type is not publicly queryable and does not support previews. This field will be removed in the future.
     */
    node: GraphqlDocument;
  };

/** Connection between the GraphqlDocument type and the TermNode type */
export type GraphqlDocumentToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'GraphqlDocumentToTermNodeConnection';
    /** Edges for the GraphqlDocumentToTermNodeConnection connection */
    edges: Array<GraphqlDocumentToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: GraphqlDocumentToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type GraphqlDocumentToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'GraphqlDocumentToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;GraphqlDocumentToTermNodeConnection&quot; */
export type GraphqlDocumentToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'GraphqlDocumentToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the GraphqlDocumentToTermNodeConnection connection */
export type GraphqlDocumentToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNode = {
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars['String']['output'];
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** Post publishing date. */
  date?: Maybe<Scalars['String']['output']>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars['String']['output']>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars['String']['output']>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars['String']['output']>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars['String']['output']>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars['String']['output']>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars['String']['output']>;
  /** The parent of the node. The parent object can be of various types */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']['output']>;
  /** The Yoast SEO data of the ContentNode */
  seo?: Maybe<PostTypeSeo>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The current status of the object */
  status?: Maybe<Scalars['String']['output']>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeAncestorsConnection =
  Connection &
    ContentNodeConnection & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnection';
      /** Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
      edges: Array<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>;
      /** The nodes of the connection, without the edges */
      nodes: Array<ContentNode>;
      /** Information about pagination in a connection. */
      pageInfo: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
    };

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;HierarchicalContentNodeToContentNodeAncestorsConnection&quot; */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeChildrenConnection =
  Connection &
    ContentNodeConnection & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
      /** Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection */
      edges: Array<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>;
      /** The nodes of the connection, without the edges */
      nodes: Array<ContentNode>;
      /** Information about pagination in a connection. */
      pageInfo: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
    };

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;HierarchicalContentNodeToContentNodeChildrenConnection&quot; */
export type HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToParentContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge &
    OneToOneConnection & {
      __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
      /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The node of the connection, without the edges */
      node: ContentNode;
    };

/** Node with hierarchical (parent/child) relationships */
export type HierarchicalNode = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNode = {
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** The description of the object */
  description?: Maybe<Scalars['String']['output']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The link to the term */
  link?: Maybe<Scalars['String']['output']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']['output']>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']['output']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']['output']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']['output']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Language */
export type Language = {
  __typename?: 'Language';
  /** Language code */
  code: Scalars['String']['output'];
  /** Language country flag URL */
  country_flag_url?: Maybe<Scalars['String']['output']>;
  /** Language default locale */
  default_locale?: Maybe<Scalars['String']['output']>;
  /** Language ID */
  id: Scalars['ID']['output'];
  /** Language code */
  language_code: Scalars['String']['output'];
  /** Language name in its own language */
  native_name?: Maybe<Scalars['String']['output']>;
  /** Language name in the default language */
  translated_name?: Maybe<Scalars['String']['output']>;
  /** Language front page URL */
  url?: Maybe<Scalars['String']['output']>;
};

/** Locale Info (WPML) */
export type LanguageInfo = {
  __typename?: 'LanguageInfo';
  /** Language code (WPML) */
  code?: Maybe<Scalars['String']['output']>;
  /** Language locale (WPML) */
  default_locale?: Maybe<Scalars['String']['output']>;
  /** Language ID (WPML) */
  id: Scalars['ID']['output'];
  /** Is the language hidden? */
  is_hidden?: Maybe<Scalars['Boolean']['output']>;
  /** Language native name (WPML) */
  native_name?: Maybe<Scalars['String']['output']>;
  /** Language translated name (WPML) */
  translated_name?: Maybe<Scalars['String']['output']>;
};

/** The Legal type */
export type Legal = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithPageAttributes &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Legal';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Legal&quot; was set to Show in GraphQL. */
    acfLegal?: Maybe<Legal_Acflegal>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Legal_Acfpagestatus>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the legal object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the legal object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Legal language */
    language?: Maybe<Language>;
    /** Legal language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    legalId: Scalars['Int']['output'];
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the legal object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Legal type and the Legal type */
    preview?: Maybe<LegalToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Legal>>>;
    /** Legal translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Legal type */
export type LegalAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The Legal type */
export type LegalChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The Legal type */
export type LegalEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Legal type */
export type LegalEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Legal type */
export type LegalTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Legal Nodes */
export type LegalConnection = {
  /** A list of edges (relational context) between RootQuery and connected Legal Nodes */
  edges: Array<LegalConnectionEdge>;
  /** A list of connected Legal Nodes */
  nodes: Array<Legal>;
  /** Information about pagination in a connection. */
  pageInfo: LegalConnectionPageInfo;
};

/** Edge between a Node and a connected Legal */
export type LegalConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Legal Node */
  node: Legal;
};

/** Page Info on the connected LegalConnectionEdge */
export type LegalConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum LegalIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the Legal type and the Legal type */
export type LegalToPreviewConnectionEdge = Edge &
  LegalConnectionEdge &
  OneToOneConnection & {
    __typename?: 'LegalToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Legal;
  };

/** Field Group */
export type Legal_Acflegal = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Array<Maybe<Legal_Acflegal_Section>>>;
};

export type Legal_Acflegal_Section =
  | Legal_Acflegal_Section_Article
  | Legal_Acflegal_Section_Form;

/** Group within the flex field */
export type Legal_Acflegal_Section_Article = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Article';
  article?: Maybe<Legal_Acflegal_Section_Article_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Legal_Acflegal_Section_Article_Article = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Article_Article';
  content?: Maybe<Array<Maybe<Legal_Acflegal_Section_Article_Article_Content>>>;
  customise?: Maybe<Legal_Acflegal_Section_Article_Article_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Legal_Acflegal_Section_Article_Article_Content =
  Legal_Acflegal_Section_Article_Article_Content_Editor;

/** Group within the flex field */
export type Legal_Acflegal_Section_Article_Article_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Article_Article_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Article_Article_Customise = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Article_Article_Customise';
  archive?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  tableOfContents?: Maybe<Scalars['Boolean']['output']>;
};

/** Group within the flex field */
export type Legal_Acflegal_Section_Form = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form';
  customise?: Maybe<Legal_Acflegal_Section_Form_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Array<Maybe<Legal_Acflegal_Section_Form_Form>>>;
  id?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Legal_Acflegal_Section_Form_Customise = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form_Customise';
  background?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

export type Legal_Acflegal_Section_Form_Form =
  | Legal_Acflegal_Section_Form_Form_Chip
  | Legal_Acflegal_Section_Form_Form_Disclaimer
  | Legal_Acflegal_Section_Form_Form_Editor
  | Legal_Acflegal_Section_Form_Form_Form
  | Legal_Acflegal_Section_Form_Form_Title;

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Chip = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form_Form_Chip';
  chip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Disclaimer = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form_Form_Disclaimer';
  disclaimer?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Editor = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form_Form_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Form = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form_Form_Form';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Array<Maybe<Legal_Acflegal_Section_Form_Form_Form_Form>>>;
};

export type Legal_Acflegal_Section_Form_Form_Form_Form =
  | Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm
  | Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm
  | Legal_Acflegal_Section_Form_Form_Form_Form_SignUp
  | Legal_Acflegal_Section_Form_Form_Form_Form_Wpforms;

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hubspotForm?: Maybe<Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm_Id>;
    message?: Maybe<Scalars['String']['output']>;
    redirect?: Maybe<AcfLink>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm_Id =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm_Id';
    contentId?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    portalId?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    kustomerForm?: Maybe<Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm_KustomerForm>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm_KustomerForm =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm_KustomerForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    fileSelectText?: Maybe<Scalars['String']['output']>;
    fileUpload?: Maybe<Scalars['Boolean']['output']>;
    /** You need to create a web hook in Kustomer, this is where the form will be submitted to */
    formWebHook?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    message?: Maybe<Scalars['String']['output']>;
    onSubmitModal?: Maybe<Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal>;
    submitButtonText?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal';
    cta?: Maybe<AcfLink>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Form_Form_SignUp =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_SignUp';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    signUpForm?: Maybe<Legal_Acflegal_Section_Form_Form_Form_Form_SignUp_SignUpForm>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Form_Form_Form_Form_SignUp_SignUpForm =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_SignUp_SignUpForm';
    /** This will prompt visitors to select if they are either &quot;Wool Grower&quot; or &quot;Shearing Contractor&quot; */
    areYouAWoolGrower?: Maybe<Scalars['String']['output']>;
    buttonText?: Maybe<Scalars['String']['output']>;
    /** Add &quot;Coupon&quot; field to the form */
    couponCode?: Maybe<Scalars['Boolean']['output']>;
    /** If &quot;yes&quot; is selected, it will set a flag in Segment to exclude signups from this campaign from standard SC onboarding */
    excludeFromSafetycultureOnboardingComms?: Maybe<
      Scalars['String']['output']
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    preloadTemplates?: Maybe<
      Array<
        Maybe<Legal_Acflegal_Section_Form_Form_Form_Form_SignUp_SignUpForm_PreloadTemplates>
      >
    >;
    preloadTemplatesBy?: Maybe<Scalars['String']['output']>;
    /**
     * Provide a redirect path on successful sign up, for example &#039;/templates&#039; or &#039;/dashboard&#039;. Do not provide the entire URL to the app, this is being handled in the codebase.
     * &lt;/br&gt;
     * &lt;strong&gt;Leave empty if not sure&lt;/strong&gt;
     */
    redirect?: Maybe<Scalars['String']['output']>;
    salesforceCampaignId?: Maybe<Scalars['String']['output']>;
    salesforceCampaignTag?: Maybe<Scalars['String']['output']>;
    salesforceLeadSource?: Maybe<Scalars['String']['output']>;
    /** If &quot;yes&quot; it will add &quot;Country dropdown, Street number and other address fields to the form&quot; */
    showAddressFields?: Maybe<Scalars['String']['output']>;
    /** Does this partner want to capture a membership ID in their form submissions? If checked &#039;yes&#039;, the form will display a membership ID field. */
    showMembershipId?: Maybe<Scalars['String']['output']>;
    /** Add in role ID to preload templates */
    templateSource?: Maybe<Scalars['String']['output']>;
    /** Goes right before submit button and after Marketing Opt-in Checkbox */
    termsAndConditions?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Legal_Acflegal_Section_Form_Form_Form_Form_SignUp_SignUpForm_PreloadTemplates =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_SignUp_SignUpForm_preloadTemplates';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Form_Form_Wpforms =
  AcfFieldGroup & {
    __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_Wpforms';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wpforms?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Legal_Acflegal_Section_Form_Form_Title = AcfFieldGroup & {
  __typename?: 'Legal_Acflegal_Section_Form_Form_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Legal_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Legal_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Locale (WPML) */
export type Locale = {
  __typename?: 'Locale';
  /** Language ID (WPML) */
  id: Scalars['ID']['output'];
  /** Language locale (WPML) */
  locale?: Maybe<Scalars['String']['output']>;
};

/** Input for the login mutation. */
export type LoginInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The plain-text password for the user logging in. */
  password: Scalars['String']['input'];
  /** The username used for login. Typically a unique or email address depending on specific configuration */
  username: Scalars['String']['input'];
};

/** The payload for the login mutation. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** JWT Token that can be used in future requests for Authentication */
  authToken?: Maybe<Scalars['String']['output']>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** A JWT token that can be used in future requests to get a refreshed jwtAuthToken. If the refresh token used in a request is revoked or otherwise invalid, a valid Auth token will NOT be issued in the response headers. */
  refreshToken?: Maybe<Scalars['String']['output']>;
  /** The user that was logged in */
  user?: Maybe<User>;
};

/** File details for a Media Item */
export type MediaDetails = {
  __typename?: 'MediaDetails';
  /** The filename of the mediaItem */
  file?: Maybe<Scalars['String']['output']>;
  /** The height of the mediaItem */
  height?: Maybe<Scalars['Int']['output']>;
  /** Meta information associated with the mediaItem */
  meta?: Maybe<MediaItemMeta>;
  /** The available sizes of the mediaItem */
  sizes?: Maybe<Array<Maybe<MediaSize>>>;
  /** The width of the mediaItem */
  width?: Maybe<Scalars['Int']['output']>;
};

/** File details for a Media Item */
export type MediaDetailsSizesArgs = {
  exclude?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
  include?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
};

/** The mediaItem type */
export type MediaItem = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'MediaItem';
    /** Alternative text to display when resource is not displayed */
    altText?: Maybe<Scalars['String']['output']>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /** The caption for the resource */
    caption?: Maybe<Scalars['String']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>;
    /** Connection between the MediaItem type and the Comment type */
    comments?: Maybe<MediaItemToCommentConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** Description of the image (stored as post_content) */
    description?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The filesize in bytes of the resource */
    fileSize?: Maybe<Scalars['Int']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the attachment object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the attachment object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** MediaItem language */
    language?: Maybe<Language>;
    /** MediaItem language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** Details about the mediaItem */
    mediaDetails?: Maybe<MediaDetails>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    mediaItemId: Scalars['Int']['output'];
    /** Url of the mediaItem */
    mediaItemUrl?: Maybe<Scalars['String']['output']>;
    /** Type of resource */
    mediaType?: Maybe<Scalars['String']['output']>;
    /** The mime type of the mediaItem */
    mimeType?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the attachment object. */
    password?: Maybe<Scalars['String']['output']>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The sizes attribute value for an image. */
    sizes?: Maybe<Scalars['String']['output']>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Url of the mediaItem */
    sourceUrl?: Maybe<Scalars['String']['output']>;
    /** The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths. */
    srcSet?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<MediaItem>>>;
    /** MediaItem translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The mediaItem type */
export type MediaItemAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemCaptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The mediaItem type */
export type MediaItemChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MediaItemToCommentConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemDescriptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The mediaItem type */
export type MediaItemEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The mediaItem type */
export type MediaItemEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The mediaItem type */
export type MediaItemFileSizeArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSizesArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSourceUrlArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSrcSetArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to mediaItem Nodes */
export type MediaItemConnection = {
  /** A list of edges (relational context) between RootQuery and connected mediaItem Nodes */
  edges: Array<MediaItemConnectionEdge>;
  /** A list of connected mediaItem Nodes */
  nodes: Array<MediaItem>;
  /** Information about pagination in a connection. */
  pageInfo: MediaItemConnectionPageInfo;
};

/** Edge between a Node and a connected mediaItem */
export type MediaItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected mediaItem Node */
  node: MediaItem;
};

/** Page Info on the connected MediaItemConnectionEdge */
export type MediaItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a media item by its source url */
  SourceUrl = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Meta connected to a MediaItem */
export type MediaItemMeta = {
  __typename?: 'MediaItemMeta';
  /** Aperture measurement of the media item. */
  aperture?: Maybe<Scalars['Float']['output']>;
  /** Information about the camera used to create the media item. */
  camera?: Maybe<Scalars['String']['output']>;
  /** The text string description associated with the media item. */
  caption?: Maybe<Scalars['String']['output']>;
  /** Copyright information associated with the media item. */
  copyright?: Maybe<Scalars['String']['output']>;
  /** The date/time when the media was created. */
  createdTimestamp?: Maybe<Scalars['Int']['output']>;
  /** The original creator of the media item. */
  credit?: Maybe<Scalars['String']['output']>;
  /** The focal length value of the media item. */
  focalLength?: Maybe<Scalars['Float']['output']>;
  /** The ISO (International Organization for Standardization) value of the media item. */
  iso?: Maybe<Scalars['Int']['output']>;
  /** List of keywords used to describe or identfy the media item. */
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The vertical or horizontal aspect of the media item. */
  orientation?: Maybe<Scalars['String']['output']>;
  /** The shutter speed information of the media item. */
  shutterSpeed?: Maybe<Scalars['Float']['output']>;
  /** A useful title for the media item. */
  title?: Maybe<Scalars['String']['output']>;
};

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  Large = 'LARGE',
  /** MediaItem with the medium size */
  Medium = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MediumLarge = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  Thumbnail = 'THUMBNAIL',
  /** MediaItem with the 1536x1536 size */
  '1536X1536' = '_1536X1536',
  /** MediaItem with the 2048x2048 size */
  '2048X2048' = '_2048X2048',
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Connection between the MediaItem type and the Comment type */
export type MediaItemToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'MediaItemToCommentConnection';
    /** Edges for the MediaItemToCommentConnection connection */
    edges: Array<MediaItemToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: MediaItemToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type MediaItemToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'MediaItemToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;MediaItemToCommentConnection&quot; */
export type MediaItemToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MediaItemToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Details of an available size for a media item */
export type MediaSize = {
  __typename?: 'MediaSize';
  /** The filename of the referenced size */
  file?: Maybe<Scalars['String']['output']>;
  /** The filesize of the resource */
  fileSize?: Maybe<Scalars['Int']['output']>;
  /** The height of the referenced size */
  height?: Maybe<Scalars['String']['output']>;
  /** The mime type of the referenced size */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** The referenced size name */
  name?: Maybe<Scalars['String']['output']>;
  /** The url of the referenced size */
  sourceUrl?: Maybe<Scalars['String']['output']>;
  /** The width of the referenced size */
  width?: Maybe<Scalars['String']['output']>;
};

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type Menu = DatabaseIdentifier &
  Node & {
    __typename?: 'Menu';
    /** The number of items in the menu */
    count?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The globally unique identifier of the nav menu object. */
    id: Scalars['ID']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** the language of the menu */
    language?: Maybe<Scalars['String']['output']>;
    /** The locations a menu is assigned to */
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /**
     * WP ID of the nav menu.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the Menu type and the MenuItem type */
    menuItems?: Maybe<MenuToMenuItemConnection>;
    /** Display name of the menu. Equivalent to WP_Term-&gt;name. */
    name?: Maybe<Scalars['String']['output']>;
    /** The url friendly name of the menu. Equivalent to WP_Term-&gt;slug */
    slug?: Maybe<Scalars['String']['output']>;
  };

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type MenuMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuToMenuItemConnectionWhereArgs>;
};

/** Connection to Menu Nodes */
export type MenuConnection = {
  /** A list of edges (relational context) between RootQuery and connected Menu Nodes */
  edges: Array<MenuConnectionEdge>;
  /** A list of connected Menu Nodes */
  nodes: Array<Menu>;
  /** Information about pagination in a connection. */
  pageInfo: MenuConnectionPageInfo;
};

/** Edge between a Node and a connected Menu */
export type MenuConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Menu Node */
  node: Menu;
};

/** Page Info on the connected MenuConnectionEdge */
export type MenuConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItem = DatabaseIdentifier &
  Node & {
    __typename?: 'MenuItem';
    /** Connection between the MenuItem type and the MenuItem type */
    childItems?: Maybe<MenuItemToMenuItemConnection>;
    /** Connection from MenuItem to it&#039;s connected node */
    connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
    /**
     * The object connected to this menu item.
     * @deprecated Deprecated in favor of the connectedNode field
     */
    connectedObject?: Maybe<MenuItemObjectUnion>;
    /** Class attribute for the menu item link */
    cssClasses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Description of the menu item. */
    description?: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier of the nav menu item object. */
    id: Scalars['ID']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Label or title of the menu item. */
    label?: Maybe<Scalars['String']['output']>;
    /** Link relationship (XFN) of the menu item. */
    linkRelationship?: Maybe<Scalars['String']['output']>;
    /** The locations the menu item&#039;s Menu is assigned to */
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /** The Menu a MenuItem is part of */
    menu?: Maybe<MenuItemToMenuConnectionEdge>;
    /**
     * WP ID of the menu item.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuItemId?: Maybe<Scalars['Int']['output']>;
    /** Menu item order */
    order?: Maybe<Scalars['Int']['output']>;
    /** The database id of the parent menu item or null if it is the root */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent nav menu item object. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** Path for the resource. Relative path for internal resources. Absolute path for external resources. */
    path?: Maybe<Scalars['String']['output']>;
    /** Target attribute for the menu item link. */
    target?: Maybe<Scalars['String']['output']>;
    /** Title attribute for the menu item link */
    title?: Maybe<Scalars['String']['output']>;
    /** The uri of the resource the menu item links to */
    uri?: Maybe<Scalars['String']['output']>;
    /** URL or destination of the menu item. */
    url?: Maybe<Scalars['String']['output']>;
  };

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItemChildItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MenuItemToMenuItemConnectionWhereArgs>;
};

/** Connection to MenuItem Nodes */
export type MenuItemConnection = {
  /** A list of edges (relational context) between RootQuery and connected MenuItem Nodes */
  edges: Array<MenuItemConnectionEdge>;
  /** A list of connected MenuItem Nodes */
  nodes: Array<MenuItem>;
  /** Information about pagination in a connection. */
  pageInfo: MenuItemConnectionPageInfo;
};

/** Edge between a Node and a connected MenuItem */
export type MenuItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected MenuItem Node */
  node: MenuItem;
};

/** Page Info on the connected MenuItemConnectionEdge */
export type MenuItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Nodes that can be linked to as Menu Items */
export type MenuItemLinkable = {
  /** The unique identifier stored in the database */
  databaseId: Scalars['Int']['output'];
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>;
};

/** Edge between a Node and a connected MenuItemLinkable */
export type MenuItemLinkableConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected MenuItemLinkable Node */
  node: MenuItemLinkable;
};

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** Deprecated in favor of MenuItemLinkeable Interface */
export type MenuItemObjectUnion =
  | App
  | Category
  | Checklist
  | CustomerStory
  | Ebook
  | Event
  | Legal
  | Page
  | Partnership
  | Post
  | Tag
  | TaxonomyCategory
  | TaxonomyIndustry
  | TaxonomyRegion
  | TaxonomySolution
  | Testimonial
  | Topic;

/** Connection between the MenuItem type and the Menu type */
export type MenuItemToMenuConnectionEdge = Edge &
  MenuConnectionEdge &
  OneToOneConnection & {
    __typename?: 'MenuItemToMenuConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Menu;
  };

/** Connection between the MenuItem type and the MenuItem type */
export type MenuItemToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'MenuItemToMenuItemConnection';
    /** Edges for the MenuItemToMenuItemConnection connection */
    edges: Array<MenuItemToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: MenuItemToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type MenuItemToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'MenuItemToMenuItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;MenuItemToMenuItemConnection&quot; */
export type MenuItemToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MenuItemToMenuItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the MenuItem type and the MenuItemLinkable type */
export type MenuItemToMenuItemLinkableConnectionEdge = Edge &
  MenuItemLinkableConnectionEdge &
  OneToOneConnection & {
    __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: MenuItemLinkable;
  };

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Put the menu in the footer location */
  Footer = 'FOOTER',
  /** Put the menu in the header location */
  Header = 'HEADER',
  /** Put the menu in the legal location */
  Legal = 'LEGAL',
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a menu node by the slug of menu location to which it is assigned */
  Location = 'LOCATION',
  /** Identify a menu node by its name */
  Name = 'NAME',
  /** Identify a menu node by its slug */
  Slug = 'SLUG',
}

/** Connection between the Menu type and the MenuItem type */
export type MenuToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'MenuToMenuItemConnection';
    /** Edges for the MenuToMenuItemConnection connection */
    edges: Array<MenuToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: MenuToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type MenuToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'MenuToMenuItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;MenuToMenuItemConnection&quot; */
export type MenuToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MenuToMenuItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** application/msword mime type. */
  ApplicationMsword = 'APPLICATION_MSWORD',
  /** application/pdf mime type. */
  ApplicationPdf = 'APPLICATION_PDF',
  /** application/vnd.apple.keynote mime type. */
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** application/vnd.ms-excel mime type. */
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  /** application/vnd.ms-excel.sheet.binary.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** application/vnd.ms-excel.sheet.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** application/vnd.ms-powerpoint mime type. */
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  /** application/vnd.ms-powerpoint.presentation.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** application/vnd.ms-powerpoint.slideshow.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** application/vnd.ms-word.document.macroEnabled.12 mime type. */
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** application/vnd.oasis.opendocument.text mime type. */
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** application/vnd.openxmlformats-officedocument.presentationml.presentation mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** application/vnd.openxmlformats-officedocument.presentationml.slideshow mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.sheet mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.document mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** audio/midi mime type. */
  AudioMidi = 'AUDIO_MIDI',
  /** audio/mpeg mime type. */
  AudioMpeg = 'AUDIO_MPEG',
  /** audio/ogg mime type. */
  AudioOgg = 'AUDIO_OGG',
  /** audio/wav mime type. */
  AudioWav = 'AUDIO_WAV',
  /** audio/x-ms-wma mime type. */
  AudioXMsWma = 'AUDIO_X_MS_WMA',
  /** image/gif mime type. */
  ImageGif = 'IMAGE_GIF',
  /** image/jpeg mime type. */
  ImageJpeg = 'IMAGE_JPEG',
  /** image/png mime type. */
  ImagePng = 'IMAGE_PNG',
  /** image/svg+xml mime type. */
  ImageSvgXml = 'IMAGE_SVG_XML',
  /** video/3gpp mime type. */
  Video_3Gpp = 'VIDEO_3GPP',
  /** video/3gpp2 mime type. */
  Video_3Gpp2 = 'VIDEO_3GPP2',
  /** video/avi mime type. */
  VideoAvi = 'VIDEO_AVI',
  /** video/mp4 mime type. */
  VideoMp4 = 'VIDEO_MP4',
  /** video/mpeg mime type. */
  VideoMpeg = 'VIDEO_MPEG',
  /** video/ogg mime type. */
  VideoOgg = 'VIDEO_OGG',
  /** video/quicktime mime type. */
  VideoQuicktime = 'VIDEO_QUICKTIME',
  /** video/webm mime type. */
  VideoWebm = 'VIDEO_WEBM',
  /** video/x-flv mime type. */
  VideoXFlv = 'VIDEO_X_FLV',
  /** video/x-ms-wmv mime type. */
  VideoXMsWmv = 'VIDEO_X_MS_WMV',
}

/** An object with an ID */
export type Node = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that can have an author assigned to it */
export type NodeWithAuthor = {
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars['ID']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** Connection between the NodeWithAuthor type and the User type */
export type NodeWithAuthorToUserConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'NodeWithAuthorToUserConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** A node that can have comments associated with it */
export type NodeWithComments = {
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount?: Maybe<Scalars['Int']['output']>;
  /** Whether the comments are open or closed for this particular post. */
  commentStatus?: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that supports the content editor */
export type NodeWithContentEditor = {
  /** The content of the post. */
  content?: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that supports the content editor */
export type NodeWithContentEditorContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have an excerpt */
export type NodeWithExcerpt = {
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars['String']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** A node that can have an excerpt */
export type NodeWithExcerptExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have a featured image set */
export type NodeWithFeaturedImage = {
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars['ID']['output']>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
};

/** Connection between the NodeWithFeaturedImage type and the MediaItem type */
export type NodeWithFeaturedImageToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge &
  OneToOneConnection & {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: MediaItem;
  };

/** A node that can have page attributes */
export type NodeWithPageAttributes = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars['Int']['output']>;
};

/** A node that can have revisions */
export type NodeWithRevisions = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars['Boolean']['output']>;
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
};

/** Connection between the NodeWithRevisions type and the ContentNode type */
export type NodeWithRevisionsToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge &
    OneToOneConnection & {
      __typename?: 'NodeWithRevisionsToContentNodeConnectionEdge';
      /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The node of the connection, without the edges */
      node: ContentNode;
    };

/** A node that can have a template associated with it */
export type NodeWithTemplate = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
};

/** A node that NodeWith a title */
export type NodeWithTitle = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** The Yoast SEO data of the ContentNode */
  seo?: Maybe<PostTypeSeo>;
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars['String']['output']>;
};

/** A node that NodeWith a title */
export type NodeWithTitleTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have trackbacks and pingbacks */
export type NodeWithTrackbacks = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the pings are open or closed for this particular post. */
  pingStatus?: Maybe<Scalars['String']['output']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** A singular connection from one Node to another, with support for relational data on the &quot;edge&quot; of the connection. */
export type OneToOneConnection = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected node */
  node: Node;
};

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = 'ASC',
  /** Sort the query result set in a descending order */
  Desc = 'DESC',
}

/** The page type */
export type Page = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Page';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Page&quot; was set to Show in GraphQL. */
    acfPage?: Maybe<Page_Acfpage>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Sidebar: Page Settings&quot; was set to Show in GraphQL. */
    acfPageSettings?: Maybe<Page_Acfpagesettings>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Page_Acfpagestatus>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Sidebar: Product&quot; was set to Show in GraphQL. */
    acfProduct?: Maybe<Page_Acfproduct>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Sidebar: Store&quot; was set to Show in GraphQL. */
    acfStore?: Maybe<Page_Acfstore>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>;
    /** Connection between the Page type and the Comment type */
    comments?: Maybe<PageToCommentConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the page object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the page object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether this page is set to the privacy page. */
    isPrivacyPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Page language */
    language?: Maybe<Language>;
    /** Page language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    pageId: Scalars['Int']['output'];
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the page object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Page type and the page type */
    preview?: Maybe<PageToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Page type and the page type */
    revisions?: Maybe<PageToRevisionConnection>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Page>>>;
    /** Page translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The page type */
export type PageAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The page type */
export type PageChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The page type */
export type PageCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageToCommentConnectionWhereArgs>;
};

/** The page type */
export type PageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The page type */
export type PageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The page type */
export type PageRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageToRevisionConnectionWhereArgs>;
};

/** The page type */
export type PageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to page Nodes */
export type PageConnection = {
  /** A list of edges (relational context) between RootQuery and connected page Nodes */
  edges: Array<PageConnectionEdge>;
  /** A list of connected page Nodes */
  nodes: Array<Page>;
  /** Information about pagination in a connection. */
  pageInfo: PageConnectionPageInfo;
};

/** Edge between a Node and a connected page */
export type PageConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected page Node */
  node: Page;
};

/** Page Info on the connected PageConnectionEdge */
export type PageConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Connection between the Page type and the Comment type */
export type PageToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'PageToCommentConnection';
    /** Edges for the PageToCommentConnection connection */
    edges: Array<PageToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: PageToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type PageToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'PageToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;PageToCommentConnection&quot; */
export type PageToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PageToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PageToCommentConnection connection */
export type PageToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Page type and the page type */
export type PageToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PageConnectionEdge & {
    __typename?: 'PageToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Page;
  };

/** Connection between the Page type and the page type */
export type PageToRevisionConnection = Connection &
  PageConnection & {
    __typename?: 'PageToRevisionConnection';
    /** Edges for the PageToRevisionConnection connection */
    edges: Array<PageToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: PageToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type PageToRevisionConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'PageToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;PageToRevisionConnection&quot; */
export type PageToRevisionConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PageToRevisionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Field Group */
export type Page_Acfpage = AcfFieldGroup & {
  __typename?: 'Page_Acfpage';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Array<Maybe<Page_Acfpage_Section>>>;
};

export type Page_Acfpage_Section =
  | Page_Acfpage_Section_AppFeatures
  | Page_Acfpage_Section_Article
  | Page_Acfpage_Section_Block
  | Page_Acfpage_Section_ContentNavigation
  | Page_Acfpage_Section_Cta
  | Page_Acfpage_Section_Hero
  | Page_Acfpage_Section_Media
  | Page_Acfpage_Section_Notification
  | Page_Acfpage_Section_Pricing
  | Page_Acfpage_Section_Slider;

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_AppFeatures';
  appFeatures?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures';
  cards?: Maybe<
    Array<Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards>>
  >;
  content?: Maybe<
    Array<Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Content>>
  >;
  customise?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Customise>;
  features?: Maybe<
    Array<Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Features>>
  >;
  featuresTitle?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  leftImage?: Maybe<MediaItem>;
  middleImage?: Maybe<MediaItem>;
  rightImage?: Maybe<MediaItem>;
};

export type Page_Acfpage_Section_AppFeatures_AppFeatures_Content =
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Editor
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards';
    card?: Maybe<
      Array<Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card>>
    >;
    customise?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card =
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Chip
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Editor
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Highlight
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Icon
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Image
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Links
  | Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Highlight_Highlight>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Highlight_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight_Highlight';
    customise?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Highlight_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Highlight_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Icon_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Icon_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Icon_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    titleOptions?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title_TitleOptions>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title_TitleOptions =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title_TitleOptions';
    badge?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title_TitleOptions_Badge>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title_TitleOptions_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title_TitleOptions_Badge =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title_TitleOptions_Badge';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Card_Title_TitleOptions_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title_TitleOptions_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Cards_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Field Group */
export type Page_Acfpage_Section_AppFeatures_AppFeatures_Features =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_features';
    button?: Maybe<AcfLink>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
    /** Add a Badge &quot;New&quot; to the feature */
    new?: Maybe<Scalars['Boolean']['output']>;
    region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Article = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Article';
  article?: Maybe<Page_Acfpage_Section_Article_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Article_Article = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Article_Article';
  content?: Maybe<Array<Maybe<Page_Acfpage_Section_Article_Article_Content>>>;
  customise?: Maybe<Page_Acfpage_Section_Article_Article_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Page_Acfpage_Section_Article_Article_Content =
  Page_Acfpage_Section_Article_Article_Content_Editor;

/** Group within the flex field */
export type Page_Acfpage_Section_Article_Article_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Article_Article_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Article_Article_Customise = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Article_Article_Customise';
  archive?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  tableOfContents?: Maybe<Scalars['Boolean']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block';
  block?: Maybe<Page_Acfpage_Section_Block_Block>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block';
  content?: Maybe<Array<Maybe<Page_Acfpage_Section_Block_Block_Content>>>;
  customise?: Maybe<Page_Acfpage_Section_Block_Block_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Page_Acfpage_Section_Block_Block_Content =
  | Page_Acfpage_Section_Block_Block_Content_Box
  | Page_Acfpage_Section_Block_Block_Content_Cards
  | Page_Acfpage_Section_Block_Block_Content_Chip
  | Page_Acfpage_Section_Block_Block_Content_Columns
  | Page_Acfpage_Section_Block_Block_Content_Disclaimer
  | Page_Acfpage_Section_Block_Block_Content_Editor
  | Page_Acfpage_Section_Block_Block_Content_Embed
  | Page_Acfpage_Section_Block_Block_Content_Eventbrite
  | Page_Acfpage_Section_Block_Block_Content_Faq
  | Page_Acfpage_Section_Block_Block_Content_Features
  | Page_Acfpage_Section_Block_Block_Content_Gallery
  | Page_Acfpage_Section_Block_Block_Content_Highlights
  | Page_Acfpage_Section_Block_Block_Content_HubspotForm
  | Page_Acfpage_Section_Block_Block_Content_IconsList
  | Page_Acfpage_Section_Block_Block_Content_Image
  | Page_Acfpage_Section_Block_Block_Content_Lever
  | Page_Acfpage_Section_Block_Block_Content_Links
  | Page_Acfpage_Section_Block_Block_Content_LinksColumns
  | Page_Acfpage_Section_Block_Block_Content_Logos
  | Page_Acfpage_Section_Block_Block_Content_Product
  | Page_Acfpage_Section_Block_Block_Content_Quotes
  | Page_Acfpage_Section_Block_Block_Content_RecentPosts
  | Page_Acfpage_Section_Block_Block_Content_RoiCalculator
  | Page_Acfpage_Section_Block_Block_Content_SignUp
  | Page_Acfpage_Section_Block_Block_Content_Spacer
  | Page_Acfpage_Section_Block_Block_Content_Split
  | Page_Acfpage_Section_Block_Block_Content_Spotlights
  | Page_Acfpage_Section_Block_Block_Content_Stats
  | Page_Acfpage_Section_Block_Block_Content_Table
  | Page_Acfpage_Section_Block_Block_Content_TestimonialCta
  | Page_Acfpage_Section_Block_Block_Content_Testimonials
  | Page_Acfpage_Section_Block_Block_Content_TestimonialsNew
  | Page_Acfpage_Section_Block_Block_Content_Title
  | Page_Acfpage_Section_Block_Block_Content_Video
  | Page_Acfpage_Section_Block_Block_Content_Wpforms;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box';
  box?: Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box';
  content?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box_Content>>
  >;
  customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  media?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box_Media>>
  >;
};

export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content =
  | Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Chip
  | Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Editor
  | Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon
  | Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links
  | Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Customise';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    reverse?: Maybe<Scalars['Boolean']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Media =
  Page_Acfpage_Section_Block_Block_Content_Box_Box_Media_Image;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Box_Box_Media_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Media_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards';
  cards?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards>>
  >;
  customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards';
    card?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card>>
    >;
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card =
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Chip
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Editor
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Highlight
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Icon
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Image
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Links
  | Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Highlight_Highlight>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Highlight_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight_Highlight';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Highlight_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Highlight_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Icon_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Icon_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Icon_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    titleOptions?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title_TitleOptions>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title_TitleOptions =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Title_TitleOptions';
    badge?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title_TitleOptions_Badge>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title_TitleOptions_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title_TitleOptions_Badge =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Title_TitleOptions_Badge';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Card_Title_TitleOptions_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Title_TitleOptions_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Cards_Cards_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Chip = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Chip';
  chip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Columns = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns';
  columns?: Maybe<Page_Acfpage_Section_Block_Block_Content_Columns_Columns>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Columns_Columns =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns_Columns';
    columns?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Columns_Columns_Columns>
      >
    >;
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Columns_Columns_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Columns_Columns_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns_Columns_Customise';
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Columns_Columns_Columns =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns_Columns_columns';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Disclaimer =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Disclaimer';
    disclaimer?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Editor = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Embed = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Embed';
  embed?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Eventbrite';
    eventbrite?: Maybe<Page_Acfpage_Section_Block_Block_Content_Eventbrite_Eventbrite>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Faq = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Faq';
  faq?: Maybe<Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Faq_Faq>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Faq_Faq = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Faq_faq';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Features =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Customise>;
    features?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features>>
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Customise>;
    feature?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Customise';
    background?: Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Customise_Background>;
    border?: Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Customise_Border>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hollow?: Maybe<Scalars['Boolean']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Customise_Background =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Customise_Background';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Customise_Border =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Customise_Border';
    border?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature =

    | Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Editor
    | Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Icon
    | Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Links
    | Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Note
    | Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Note =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Note';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    note?: Maybe<Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Note_Note>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Note_Note =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Note_Note';
    background?: Maybe<Scalars['String']['output']>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Features_Features_Feature_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Gallery = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery>>
  >;
};

export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery =
  | Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Image
  | Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Array<Maybe<MediaItem>>>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    video?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    thumbnail?: Maybe<MediaItem>;
    video?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video =

    | Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Wistia
    | Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Youtube;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Highlights =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Highlights_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlights?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Highlights_Highlights>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Highlights_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Highlights_Highlights =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_highlights';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<Page_Acfpage_Section_Block_Block_Content_Highlights_Highlights_Highlight>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Highlights_Highlights_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_highlights_Highlight';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Highlights_Highlights_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Highlights_Highlights_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_highlights_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hubspotForm?: Maybe<Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm_Id>;
    message?: Maybe<Scalars['String']['output']>;
    redirect?: Maybe<AcfLink>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm_Id =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm_Id';
    contentId?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    portalId?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_IconsList =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_IconsList';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    iconsList?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Block_Block_Content_IconsList_IconsList>>
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_IconsList_IconsList =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_IconsList_iconsList';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Image = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Page_Acfpage_Section_Block_Block_Content_Image_Image>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Image_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Image_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Lever = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Lever';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  lever?: Maybe<Page_Acfpage_Section_Block_Block_Content_Lever_Lever>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Lever_Lever =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Lever_Lever';
    /** Toggle this to show all jobs */
    allJobs?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    maxJobs?: Maybe<Scalars['Float']['output']>;
    url?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Links = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Links';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  links?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Links_Links>>
  >;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_LinksColumns =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    linksColumns?: Maybe<Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    layout?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Logos = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Logos';
  customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Logos_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  logos?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Logos_Logos>>
  >;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Logos_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Logos_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    grayscale?: Maybe<Scalars['Boolean']['output']>;
    size?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Logos_Logos =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Logos_logos';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logo?: Maybe<MediaItem>;
    region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Product = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Product';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes';
  customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  quotes?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes>>
  >;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    quote?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote>>
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote =
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Author
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Chip
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Editor
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Image
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Link
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Logo
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Rating
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Title
  | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Author';
    author?: Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Author_Author>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Author_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Author_Author';
    avatar?: Maybe<MediaItem>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Image_Image>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Image_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Image_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Link =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Link';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Logo =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logo?: Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Logo_Logo>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Logo_Logo =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Logo_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    logo?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Rating =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Rating';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    rating?: Maybe<Scalars['Float']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    video?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video =

    | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Wistia
    | Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Youtube;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Quotes_Quotes_Quote_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_RecentPosts =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_RecentPosts';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    header?: Maybe<Scalars['String']['output']>;
    postType?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_RoiCalculator =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_RoiCalculator';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    roiCalculator?: Maybe<Page_Acfpage_Section_Block_Block_Content_RoiCalculator_RoiCalculator>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_RoiCalculator_RoiCalculator =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_RoiCalculator_RoiCalculator';
    afterCalculatorDisclaimer?: Maybe<Scalars['String']['output']>;
    afterCalculatorDisclaimerTitle?: Maybe<Scalars['String']['output']>;
    emailSectionTitle?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    resultsDisclaimer?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_SignUp = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_SignUp';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  signUpForm?: Maybe<Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm';
    /** This will prompt visitors to select if they are either &quot;Wool Grower&quot; or &quot;Shearing Contractor&quot; */
    areYouAWoolGrower?: Maybe<Scalars['String']['output']>;
    buttonText?: Maybe<Scalars['String']['output']>;
    /** Add &quot;Coupon&quot; field to the form */
    couponCode?: Maybe<Scalars['Boolean']['output']>;
    /** If &quot;yes&quot; is selected, it will set a flag in Segment to exclude signups from this campaign from standard SC onboarding */
    excludeFromSafetycultureOnboardingComms?: Maybe<
      Scalars['String']['output']
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    preloadTemplates?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm_PreloadTemplates>
      >
    >;
    preloadTemplatesBy?: Maybe<Scalars['String']['output']>;
    /**
     * Provide a redirect path on successful sign up, for example &#039;/templates&#039; or &#039;/dashboard&#039;. Do not provide the entire URL to the app, this is being handled in the codebase.
     * &lt;/br&gt;
     * &lt;strong&gt;Leave empty if not sure&lt;/strong&gt;
     */
    redirect?: Maybe<Scalars['String']['output']>;
    salesforceCampaignId?: Maybe<Scalars['String']['output']>;
    salesforceCampaignTag?: Maybe<Scalars['String']['output']>;
    salesforceLeadSource?: Maybe<Scalars['String']['output']>;
    /** If &quot;yes&quot; it will add &quot;Country dropdown, Street number and other address fields to the form&quot; */
    showAddressFields?: Maybe<Scalars['String']['output']>;
    /** Does this partner want to capture a membership ID in their form submissions? If checked &#039;yes&#039;, the form will display a membership ID field. */
    showMembershipId?: Maybe<Scalars['String']['output']>;
    /** Add in role ID to preload templates */
    templateSource?: Maybe<Scalars['String']['output']>;
    /** Goes right before submit button and after Marketing Opt-in Checkbox */
    termsAndConditions?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm_PreloadTemplates =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm_preloadTemplates';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spacer = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spacer';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  spacer?: Maybe<Scalars['Float']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  split?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split';
    content?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content>>
    >;
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    media?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media>>
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content =
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Chip
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Disclaimer
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Editor
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Product
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Spacer
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Table
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Title
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Wpforms;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion';
    accordion?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion';
    content?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content_Checks
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content_Editor;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content_Checks =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Checks';
    checks?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content_Checks_Checks>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content_Checks_Checks =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Checks_checks';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_Accordion_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks';
    checks?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks_Checks>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks_Checks =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks_checks';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Disclaimer =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Disclaimer';
    disclaimer?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature';
    feature?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise>;
    feature?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise';
    background?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Background>;
    border?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Border>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hollow?: Maybe<Scalars['Boolean']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Background =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Background';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Border =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Border';
    border?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Editor
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Icon
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    note?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note_Note>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note_Note =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note_Note';
    background?: Maybe<Scalars['String']['output']>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hubspotForm?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm_Id>;
    message?: Maybe<Scalars['String']['output']>;
    redirect?: Maybe<AcfLink>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm_Id =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm_Id';
    contentId?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    portalId?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logos?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_Logos>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    grayscale?: Maybe<Scalars['Boolean']['output']>;
    size?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_Logos =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_logos';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logo?: Maybe<MediaItem>;
    region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Product =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Product';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    product?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    quote?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Chip
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Editor
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Link
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Rating
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Title
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author';
    author?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author_Author>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author_Author';
    avatar?: Maybe<MediaItem>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image_Image>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Link =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Link';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logo?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo_Logo>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo_Logo =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    logo?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Rating =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Rating';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    rating?: Maybe<Scalars['Float']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    video?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Wistia
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Youtube;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Spacer =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Spacer';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    spacer?: Maybe<Scalars['Float']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Table =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Table';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    table?: Maybe<Acf_Table>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Wpforms =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Wpforms';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wpforms?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Customise';
    disableReverseOnMobile?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    reverse?: Maybe<Scalars['Boolean']['output']>;
    vertical?: Maybe<Scalars['String']['output']>;
    width?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media =
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_CustomerStoryLink
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Embed
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form
  | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Image;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card';
    card?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card>
      >
    >;
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Chip
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Editor
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Image
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    titleOptions?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions';
    badge?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions_Badge>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions_Badge =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions_Badge';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title_TitleOptions_Icon';
    border?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_CustomerStoryLink =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_CustomerStoryLink';
    companyName?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    linkText?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Embed =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Embed';
    embed?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite';
    eventbrite?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite_Eventbrite>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    form?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Customise';
    background?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Chip
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Disclaimer
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Editor
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Disclaimer =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Disclaimer';
    disclaimer?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    form?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form =

    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp
    | Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_Wpforms;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hubspotForm?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id>;
    message?: Maybe<Scalars['String']['output']>;
    redirect?: Maybe<AcfLink>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id';
    contentId?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    portalId?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    kustomerForm?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    fileSelectText?: Maybe<Scalars['String']['output']>;
    fileUpload?: Maybe<Scalars['Boolean']['output']>;
    /** You need to create a web hook in Kustomer, this is where the form will be submitted to */
    formWebHook?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    message?: Maybe<Scalars['String']['output']>;
    onSubmitModal?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal>;
    submitButtonText?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal';
    cta?: Maybe<AcfLink>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    signUpForm?: Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm';
    /** This will prompt visitors to select if they are either &quot;Wool Grower&quot; or &quot;Shearing Contractor&quot; */
    areYouAWoolGrower?: Maybe<Scalars['String']['output']>;
    buttonText?: Maybe<Scalars['String']['output']>;
    /** Add &quot;Coupon&quot; field to the form */
    couponCode?: Maybe<Scalars['Boolean']['output']>;
    /** If &quot;yes&quot; is selected, it will set a flag in Segment to exclude signups from this campaign from standard SC onboarding */
    excludeFromSafetycultureOnboardingComms?: Maybe<
      Scalars['String']['output']
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    preloadTemplates?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm_PreloadTemplates>
      >
    >;
    preloadTemplatesBy?: Maybe<Scalars['String']['output']>;
    /**
     * Provide a redirect path on successful sign up, for example &#039;/templates&#039; or &#039;/dashboard&#039;. Do not provide the entire URL to the app, this is being handled in the codebase.
     * &lt;/br&gt;
     * &lt;strong&gt;Leave empty if not sure&lt;/strong&gt;
     */
    redirect?: Maybe<Scalars['String']['output']>;
    salesforceCampaignId?: Maybe<Scalars['String']['output']>;
    salesforceCampaignTag?: Maybe<Scalars['String']['output']>;
    salesforceLeadSource?: Maybe<Scalars['String']['output']>;
    /** If &quot;yes&quot; it will add &quot;Country dropdown, Street number and other address fields to the form&quot; */
    showAddressFields?: Maybe<Scalars['String']['output']>;
    /** Does this partner want to capture a membership ID in their form submissions? If checked &#039;yes&#039;, the form will display a membership ID field. */
    showMembershipId?: Maybe<Scalars['String']['output']>;
    /** Add in role ID to preload templates */
    templateSource?: Maybe<Scalars['String']['output']>;
    /** Goes right before submit button and after Marketing Opt-in Checkbox */
    termsAndConditions?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm_PreloadTemplates =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm_preloadTemplates';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_Wpforms =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_Wpforms';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wpforms?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    spotlights?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Customise>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    spotlight?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight =

    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Chip
    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Editor
    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Highlight
    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Icon
    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Image
    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Links
    | Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    highlight?: Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Highlight_Highlight>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Highlight_Highlight =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight_Highlight';
    customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Highlight_Highlight_Customise>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Highlight_Highlight_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight_Highlight_Customise';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Icon_Icon>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Icon_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Icon_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    icon?: Maybe<MediaItem>;
    placement?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Links_Links>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Spotlights_Spotlights_Spotlight_Title =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Title';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Stats = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Stats';
  customise?: Maybe<Page_Acfpage_Section_Block_Block_Content_Stats_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Stats_Stats>>
  >;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Stats_Customise =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Stats_Customise';
    alignment?: Maybe<Scalars['String']['output']>;
    columns?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Stats_Stats =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Stats_stats';
    border?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    number?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Table = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Table';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Acf_Table>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_TestimonialCta =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    testimonialCta?: Maybe<Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta';
    author?: Maybe<Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Author>;
    cta?: Maybe<Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Cta>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Author';
    avatar?: Maybe<MediaItem>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Cta =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials';
    design?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    testimonials?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials>
      >
    >;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_TestimonialsNew =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialsNew';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    testimonialsNew?: Maybe<Page_Acfpage_Section_Block_Block_Content_TestimonialsNew_TestimonialsNew>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_TestimonialsNew_TestimonialsNew =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialsNew_TestimonialsNew';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    testimonials?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_TestimonialsNew_TestimonialsNew_Testimonials>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_TestimonialsNew_TestimonialsNew_Testimonials =
  Testimonial;

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    testimonial?: Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial';
    content?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content>
      >
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    media?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media>
      >
    >;
  };

export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content =

    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Author
    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Chip
    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Editor
    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Link
    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Stats;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Author';
    author?: Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Author_Author>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Author_Author =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Author_Author';
    avatar?: Maybe<MediaItem>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Chip =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Chip';
    chip?: Maybe<Scalars['String']['output']>;
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Link =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Link';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Stats =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Stats';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    stats?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Stats_Stats>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Content_Stats_Stats =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Stats_stats';
    border?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    number?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media =

    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Icon
    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Image
    | Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Logo;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Image =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Image';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Logo =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    logo?: Maybe<Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Logo_Logo>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Testimonials_Testimonials_Testimonial_Media_Logo_Logo =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Logo_Logo';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    height?: Maybe<Scalars['Float']['output']>;
    logo?: Maybe<MediaItem>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Title = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Video = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  video?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Block_Block_Content_Video_Video>>
  >;
};

export type Page_Acfpage_Section_Block_Block_Content_Video_Video =
  | Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia
  | Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube;

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Block_Block_Content_Wpforms = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Wpforms';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  wpforms?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Customise = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Block_Block_Customise';
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  classnames?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<MediaItem>;
  display?: Maybe<Page_Acfpage_Section_Block_Block_Customise_Display>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  /** This option is to allow inner element to be visible outside the block, required for drop downs and select menus */
  overflow?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Block_Block_Customise_Display =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Block_Block_Customise_Display';
    desktop?: Maybe<Scalars['Boolean']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    mobile?: Maybe<Scalars['Boolean']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_ContentNavigation = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_ContentNavigation';
  contentNavigation?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Cta = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta';
  cta?: Maybe<Page_Acfpage_Section_Cta_Cta>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta';
  content?: Maybe<Array<Maybe<Page_Acfpage_Section_Cta_Cta_Content>>>;
  customise?: Maybe<Page_Acfpage_Section_Cta_Cta_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Page_Acfpage_Section_Cta_Cta_Content =
  | Page_Acfpage_Section_Cta_Cta_Content_Checks
  | Page_Acfpage_Section_Cta_Cta_Content_Editor
  | Page_Acfpage_Section_Cta_Cta_Content_Embed
  | Page_Acfpage_Section_Cta_Cta_Content_Eventbrite
  | Page_Acfpage_Section_Cta_Cta_Content_IconsList
  | Page_Acfpage_Section_Cta_Cta_Content_Links
  | Page_Acfpage_Section_Cta_Cta_Content_Product
  | Page_Acfpage_Section_Cta_Cta_Content_Search
  | Page_Acfpage_Section_Cta_Cta_Content_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Checks = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Checks';
  checks?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Cta_Cta_Content_Checks_Checks>>
  >;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta_Content_Checks_Checks =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Checks_checks';
    color?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Editor = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Embed = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Embed';
  embed?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Eventbrite = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Eventbrite';
  eventbrite?: Maybe<Page_Acfpage_Section_Cta_Cta_Content_Eventbrite_Eventbrite>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta_Content_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_IconsList = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_IconsList';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  iconsList?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Cta_Cta_Content_IconsList_IconsList>>
  >;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta_Content_IconsList_IconsList =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_IconsList_iconsList';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Links = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Links';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<Page_Acfpage_Section_Cta_Cta_Content_Links_Links>>>;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta_Content_Links_Links = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Links_links';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Page_Acfpage_Section_Cta_Cta_Content_Links_Links_Icon>;
  link?: Maybe<AcfLink>;
  style?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Product = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Product';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Search = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Search';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  search?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Cta_Cta_Content_Title = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Cta_Cta_Customise = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Cta_Cta_Customise';
  background?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Hero = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  hero?: Maybe<Page_Acfpage_Section_Hero_Hero>;
};

/** Field Group */
export type Page_Acfpage_Section_Hero_Hero = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero';
  content?: Maybe<Array<Maybe<Page_Acfpage_Section_Hero_Hero_Content>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<Maybe<Page_Acfpage_Section_Hero_Hero_Media>>>;
};

export type Page_Acfpage_Section_Hero_Hero_Content =
  | Page_Acfpage_Section_Hero_Hero_Content_Chip
  | Page_Acfpage_Section_Hero_Hero_Content_Editor
  | Page_Acfpage_Section_Hero_Hero_Content_Links
  | Page_Acfpage_Section_Hero_Hero_Content_Product
  | Page_Acfpage_Section_Hero_Hero_Content_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Content_Chip = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Chip';
  chip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Content_Editor = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Content_Links = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Links';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  links?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Hero_Hero_Content_Links_Links>>
  >;
};

/** Field Group */
export type Page_Acfpage_Section_Hero_Hero_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Hero_Hero_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Hero_Hero_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Content_Product = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Product';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Content_Title = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Page_Acfpage_Section_Hero_Hero_Media =
  | Page_Acfpage_Section_Hero_Hero_Media_Cover
  | Page_Acfpage_Section_Hero_Hero_Media_Image
  | Page_Acfpage_Section_Hero_Hero_Media_Pill;

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Media_Cover = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Media_Cover';
  cover?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Media_Image = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Media_Image';
  background?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Hero_Hero_Media_Pill = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Hero_Hero_Media_Pill';
  border?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  pill?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Media = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<Maybe<Page_Acfpage_Section_Media_Media>>>;
};

export type Page_Acfpage_Section_Media_Media =
  | Page_Acfpage_Section_Media_Media_Embed
  | Page_Acfpage_Section_Media_Media_Eventbrite
  | Page_Acfpage_Section_Media_Media_Form
  | Page_Acfpage_Section_Media_Media_Image;

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Embed = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Embed';
  embed?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Eventbrite = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Eventbrite';
  eventbrite?: Maybe<Page_Acfpage_Section_Media_Media_Eventbrite_Eventbrite>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Eventbrite_Eventbrite =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Eventbrite_Eventbrite';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    /** Set Minimum Height of the widget (defined in pixels as number, for example 450) */
    height?: Maybe<Scalars['Float']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Form';
  customise?: Maybe<Page_Acfpage_Section_Media_Media_Form_Customise>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Array<Maybe<Page_Acfpage_Section_Media_Media_Form_Form>>>;
};

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Customise = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Form_Customise';
  background?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

export type Page_Acfpage_Section_Media_Media_Form_Form =
  | Page_Acfpage_Section_Media_Media_Form_Form_Chip
  | Page_Acfpage_Section_Media_Media_Form_Form_Disclaimer
  | Page_Acfpage_Section_Media_Media_Form_Form_Editor
  | Page_Acfpage_Section_Media_Media_Form_Form_Form
  | Page_Acfpage_Section_Media_Media_Form_Form_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Chip = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Chip';
  chip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Disclaimer =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Disclaimer';
    disclaimer?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  form?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form>>
  >;
};

export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form =
  | Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm
  | Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm
  | Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp
  | Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_Wpforms;

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    hubspotForm?: Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm_HubspotForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm_HubspotForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm_HubspotForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id>;
    message?: Maybe<Scalars['String']['output']>;
    redirect?: Maybe<AcfLink>;
  };

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id';
    contentId?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    portalId?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    kustomerForm?: Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm_KustomerForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm_KustomerForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm_KustomerForm';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    fileSelectText?: Maybe<Scalars['String']['output']>;
    fileUpload?: Maybe<Scalars['Boolean']['output']>;
    /** You need to create a web hook in Kustomer, this is where the form will be submitted to */
    formWebHook?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    message?: Maybe<Scalars['String']['output']>;
    onSubmitModal?: Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal>;
    submitButtonText?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal';
    cta?: Maybe<AcfLink>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    signUpForm?: Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp_SignUpForm>;
  };

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp_SignUpForm =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp_SignUpForm';
    /** This will prompt visitors to select if they are either &quot;Wool Grower&quot; or &quot;Shearing Contractor&quot; */
    areYouAWoolGrower?: Maybe<Scalars['String']['output']>;
    buttonText?: Maybe<Scalars['String']['output']>;
    /** Add &quot;Coupon&quot; field to the form */
    couponCode?: Maybe<Scalars['Boolean']['output']>;
    /** If &quot;yes&quot; is selected, it will set a flag in Segment to exclude signups from this campaign from standard SC onboarding */
    excludeFromSafetycultureOnboardingComms?: Maybe<
      Scalars['String']['output']
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    formId?: Maybe<Scalars['String']['output']>;
    preloadTemplates?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp_SignUpForm_PreloadTemplates>
      >
    >;
    preloadTemplatesBy?: Maybe<Scalars['String']['output']>;
    /**
     * Provide a redirect path on successful sign up, for example &#039;/templates&#039; or &#039;/dashboard&#039;. Do not provide the entire URL to the app, this is being handled in the codebase.
     * &lt;/br&gt;
     * &lt;strong&gt;Leave empty if not sure&lt;/strong&gt;
     */
    redirect?: Maybe<Scalars['String']['output']>;
    salesforceCampaignId?: Maybe<Scalars['String']['output']>;
    salesforceCampaignTag?: Maybe<Scalars['String']['output']>;
    salesforceLeadSource?: Maybe<Scalars['String']['output']>;
    /** If &quot;yes&quot; it will add &quot;Country dropdown, Street number and other address fields to the form&quot; */
    showAddressFields?: Maybe<Scalars['String']['output']>;
    /** Does this partner want to capture a membership ID in their form submissions? If checked &#039;yes&#039;, the form will display a membership ID field. */
    showMembershipId?: Maybe<Scalars['String']['output']>;
    /** Add in role ID to preload templates */
    templateSource?: Maybe<Scalars['String']['output']>;
    /** Goes right before submit button and after Marketing Opt-in Checkbox */
    termsAndConditions?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp_SignUpForm_PreloadTemplates =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp_SignUpForm_preloadTemplates';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_Wpforms =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_Wpforms';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wpforms?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Form_Form_Title = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Media_Media_Image = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Page_Acfpage_Section_Media_Media_Image_Image>;
};

/** Field Group */
export type Page_Acfpage_Section_Media_Media_Image_Image = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Media_Media_Image_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  image?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Notification = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Notification';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Page_Acfpage_Section_Notification_Notification>;
};

/** Field Group */
export type Page_Acfpage_Section_Notification_Notification = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Notification_Notification';
  content?: Maybe<Page_Acfpage_Section_Notification_Notification_Content>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Notification_Notification_Content =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Notification_Notification_Content';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    link?: Maybe<AcfLink>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Pricing = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Pricing';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  pricing?: Maybe<Page_Acfpage_Section_Pricing_Pricing>;
};

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Pricing_Pricing';
  comparison?: Maybe<Page_Acfpage_Section_Pricing_Pricing_Comparison>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  payAsYouGo?: Maybe<Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo>;
  planFeatures?: Maybe<Page_Acfpage_Section_Pricing_Pricing_PlanFeatures>;
  priceCalculated?: Maybe<Page_Acfpage_Section_Pricing_Pricing_PriceCalculated>;
  pricingTable?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_PricingTable>>
  >;
  pricingTableCta?: Maybe<AcfLink>;
  pricingTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_Comparison = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison';
  disclaimer?: Maybe<Scalars['String']['output']>;
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  /** Full seat */
  fullSeat?: Maybe<Page_Acfpage_Section_Pricing_Pricing_Comparison_FullSeat>;
  /** Lite Seat */
  liteSeat?: Maybe<Page_Acfpage_Section_Pricing_Pricing_Comparison_LiteSeat>;
  tables?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_Comparison_Tables>>
  >;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_Comparison_FullSeat =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_FullSeat';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_Comparison_LiteSeat =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_LiteSeat';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_Comparison_Tables =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_tables';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    row?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_Comparison_Tables_Row>>
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_Comparison_Tables_Row =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_tables_row';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    tds?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Pricing_Pricing_Comparison_Tables_Row_Tds>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_Comparison_Tables_Row_Tds =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_tables_row_tds';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    td?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo';
  /** Set to &quot;Yes&quot; and Rebuild the App to show the section on the page */
  active?: Maybe<Scalars['Boolean']['output']>;
  editor?: Maybe<Scalars['String']['output']>;
  featureTitle?: Maybe<Scalars['String']['output']>;
  features?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo_Features>>
  >;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  monthlyPrice?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  yearlyPrice?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo_Features =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo_features';
    feature?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    included?: Maybe<Scalars['Boolean']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PlanFeatures =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures';
    ctas?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Ctas>>
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    tables?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Tables>>
    >;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Ctas =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_ctas';
    cta?: Maybe<AcfLink>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    premium?: Maybe<Scalars['Boolean']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Tables =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_tables';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    heading?: Maybe<Scalars['String']['output']>;
    row?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Tables_Row>>
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Tables_Row =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_tables_row';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    tds?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Tables_Row_Tds>
      >
    >;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_Tables_Row_Tds =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_tables_row_tds';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    td?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PriceCalculated =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PriceCalculated';
    /** Set to &quot;Yes&quot; and Rebuild the App to show the section on the page */
    active?: Maybe<Scalars['Boolean']['output']>;
    contentColumns?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Pricing_Pricing_PriceCalculated_ContentColumns>
      >
    >;
    cta?: Maybe<AcfLink>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PriceCalculated_ContentColumns =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PriceCalculated_contentColumns';
    cta?: Maybe<AcfLink>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PricingTable =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_pricingTable';
    cta?: Maybe<AcfLink>;
    editor?: Maybe<Scalars['String']['output']>;
    featureTitle?: Maybe<Scalars['String']['output']>;
    /** Features list */
    features?: Maybe<
      Array<Maybe<Page_Acfpage_Section_Pricing_Pricing_PricingTable_Features>>
    >;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    monthlyPrice?: Maybe<Scalars['String']['output']>;
    premium?: Maybe<Scalars['Boolean']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    titleBeforeCta?: Maybe<Scalars['String']['output']>;
    /** Features list */
    usageFeatures?: Maybe<
      Array<
        Maybe<Page_Acfpage_Section_Pricing_Pricing_PricingTable_UsageFeatures>
      >
    >;
    yearlyPrice?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PricingTable_Features =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_pricingTable_features';
    feature?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    included?: Maybe<Scalars['Boolean']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Pricing_Pricing_PricingTable_UsageFeatures =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_pricingTable_usageFeatures';
    feature?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    included?: Maybe<Scalars['Boolean']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Slider = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Slider';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  slider?: Maybe<Page_Acfpage_Section_Slider_Slider>;
};

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Slider_Slider';
  content?: Maybe<Array<Maybe<Page_Acfpage_Section_Slider_Slider_Content>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  mobileImage?: Maybe<MediaItem>;
  slides?: Maybe<Array<Maybe<Page_Acfpage_Section_Slider_Slider_Slides>>>;
};

export type Page_Acfpage_Section_Slider_Slider_Content =
  | Page_Acfpage_Section_Slider_Slider_Content_Chip
  | Page_Acfpage_Section_Slider_Slider_Content_Editor
  | Page_Acfpage_Section_Slider_Slider_Content_Links
  | Page_Acfpage_Section_Slider_Slider_Content_Title;

/** Group within the flex field */
export type Page_Acfpage_Section_Slider_Slider_Content_Chip = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Chip';
  chip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Page_Acfpage_Section_Slider_Slider_Content_Editor =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Editor';
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Slider_Slider_Content_Links = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Links';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  links?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Slider_Slider_Content_Links_Links>>
  >;
};

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Content_Links_Links =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Links_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Page_Acfpage_Section_Slider_Slider_Content_Links_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Content_Links_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Links_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Page_Acfpage_Section_Slider_Slider_Content_Title = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides = AcfFieldGroup & {
  __typename?: 'Page_Acfpage_Section_Slider_Slider_slides';
  caption?: Maybe<Page_Acfpage_Section_Slider_Slider_Slides_Caption>;
  features?: Maybe<
    Array<Maybe<Page_Acfpage_Section_Slider_Slider_Slides_Features>>
  >;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
  /** The main slide for a region */
  mainSlide?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides_Caption =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_Caption';
    cta?: Maybe<Page_Acfpage_Section_Slider_Slider_Slides_Caption_Cta>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    jobTitle?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides_Caption_Cta =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_Caption_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides_Features =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features';
    button?: Maybe<Page_Acfpage_Section_Slider_Slider_Slides_Features_Button>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    image?: Maybe<MediaItem>;
    imageLocation?: Maybe<Scalars['String']['output']>;
    /** Values are in &quot;rem&quot; as a number only */
    positioning?: Maybe<Page_Acfpage_Section_Slider_Slider_Slides_Features_Positioning>;
  };

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides_Features_Button =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features_Button';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<MediaItem>;
    iconLocation?: Maybe<Scalars['String']['output']>;
    text?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides_Features_Positioning =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features_Positioning';
    /** Desktop positioning */
    desktop?: Maybe<Page_Acfpage_Section_Slider_Slider_Slides_Features_Positioning_Desktop>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Page_Acfpage_Section_Slider_Slider_Slides_Features_Positioning_Desktop =
  AcfFieldGroup & {
    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features_Positioning_Desktop';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    left?: Maybe<Scalars['Float']['output']>;
    right?: Maybe<Scalars['Float']['output']>;
    top?: Maybe<Scalars['Float']['output']>;
  };

/** Field Group */
export type Page_Acfpagesettings = AcfFieldGroup & {
  __typename?: 'Page_Acfpagesettings';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  pageSettings?: Maybe<Page_Acfpagesettings_PageSettings>;
};

/** Field Group */
export type Page_Acfpagesettings_PageSettings = AcfFieldGroup & {
  __typename?: 'Page_Acfpagesettings_PageSettings';
  /** Show or hide Breadcrumbs */
  breadcrumbsStatus?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  /** Show or hide Footer menu */
  footerLegalMenuStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide Footer menu */
  footerMenuStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide Header menu */
  headerMenuStatus?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide Breadcrumbs */
  languageToggle?: Maybe<Scalars['Boolean']['output']>;
  /** Show or hide Breadcrumbs */
  loginAndSignUpButtonsStatus?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type Page_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Page_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Page_Acfproduct = AcfFieldGroup & {
  __typename?: 'Page_Acfproduct';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Page_Acfproduct_Product>;
};

/** Field Group */
export type Page_Acfproduct_Product = AcfFieldGroup & {
  __typename?: 'Page_Acfproduct_Product';
  edapp?: Maybe<Page_Acfproduct_Product_Edapp>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  /** Override default Branch links. */
  override?: Maybe<Scalars['Boolean']['output']>;
  safetyculture?: Maybe<Page_Acfproduct_Product_Safetyculture>;
  sheqsy?: Maybe<Page_Acfproduct_Product_Sheqsy>;
};

/** Field Group */
export type Page_Acfproduct_Product_Edapp = AcfFieldGroup & {
  __typename?: 'Page_Acfproduct_Product_Edapp';
  appStore?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  googlePlay?: Maybe<Scalars['String']['output']>;
  override?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type Page_Acfproduct_Product_Safetyculture = AcfFieldGroup & {
  __typename?: 'Page_Acfproduct_Product_Safetyculture';
  appStore?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  googlePlay?: Maybe<Scalars['String']['output']>;
  override?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type Page_Acfproduct_Product_Sheqsy = AcfFieldGroup & {
  __typename?: 'Page_Acfproduct_Product_Sheqsy';
  appStore?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  googlePlay?: Maybe<Scalars['String']['output']>;
  override?: Maybe<Scalars['Boolean']['output']>;
};

/** Field Group */
export type Page_Acfstore = AcfFieldGroup & {
  __typename?: 'Page_Acfstore';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Page_Acfstore_Store>;
};

/** Field Group */
export type Page_Acfstore_Store = AcfFieldGroup & {
  __typename?: 'Page_Acfstore_Store';
  banner?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  getApp?: Maybe<Scalars['String']['output']>;
  /** Override default Branch link. */
  override?: Maybe<Scalars['Boolean']['output']>;
};

/** The Partnership type */
export type Partnership = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Partnership';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Partnership_Acfpagestatus>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Partnership&quot; was set to Show in GraphQL. */
    acfPartnership?: Maybe<Partnership_Acfpartnership>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the partnerships object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the partnerships object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Partnership language */
    language?: Maybe<Language>;
    /** Partnership language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    partnershipId: Scalars['Int']['output'];
    /** The password for the partnerships object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Partnership type and the Partnership type */
    preview?: Maybe<PartnershipToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Partnership>>>;
    /** Partnership translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Partnership type */
export type PartnershipAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The Partnership type */
export type PartnershipChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The Partnership type */
export type PartnershipEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Partnership type */
export type PartnershipEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Partnership type */
export type PartnershipTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Partnership Nodes */
export type PartnershipConnection = {
  /** A list of edges (relational context) between RootQuery and connected Partnership Nodes */
  edges: Array<PartnershipConnectionEdge>;
  /** A list of connected Partnership Nodes */
  nodes: Array<Partnership>;
  /** Information about pagination in a connection. */
  pageInfo: PartnershipConnectionPageInfo;
};

/** Edge between a Node and a connected Partnership */
export type PartnershipConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Partnership Node */
  node: Partnership;
};

/** Page Info on the connected PartnershipConnectionEdge */
export type PartnershipConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PartnershipIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Connection between the Partnership type and the Partnership type */
export type PartnershipToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PartnershipConnectionEdge & {
    __typename?: 'PartnershipToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Partnership;
  };

/** Field Group */
export type Partnership_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Partnership_Acfpartnership = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  header?: Maybe<Partnership_Acfpartnership_Header>;
  hero?: Maybe<Partnership_Acfpartnership_Hero>;
  intro?: Maybe<Partnership_Acfpartnership_Intro>;
  salesContactDetails?: Maybe<Partnership_Acfpartnership_SalesContactDetails>;
  section?: Maybe<Array<Maybe<Partnership_Acfpartnership_Section>>>;
  signUpForm?: Maybe<Partnership_Acfpartnership_SignUpForm>;
};

/** Field Group */
export type Partnership_Acfpartnership_Header = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Header';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<MediaItem>;
};

/** Field Group */
export type Partnership_Acfpartnership_Hero = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Hero';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
};

/** Field Group */
export type Partnership_Acfpartnership_Intro = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Intro';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Partnership_Acfpartnership_SalesContactDetails = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_SalesContactDetails';
  email?: Maybe<AcfLink>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  profilePhoto?: Maybe<MediaItem>;
  showSalesPerson?: Maybe<Scalars['String']['output']>;
};

export type Partnership_Acfpartnership_Section =
  | Partnership_Acfpartnership_Section_Block
  | Partnership_Acfpartnership_Section_Image
  | Partnership_Acfpartnership_Section_Title
  | Partnership_Acfpartnership_Section_Video;

/** Group within the flex field */
export type Partnership_Acfpartnership_Section_Block = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Section_Block';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Partnership_Acfpartnership_Section_Image = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Section_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Partnership_Acfpartnership_Section_Image_Image>;
};

/** Field Group */
export type Partnership_Acfpartnership_Section_Image_Image = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Section_Image_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  image?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Partnership_Acfpartnership_Section_Title = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Section_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Partnership_Acfpartnership_Section_Video = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_Section_Video';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Array<Maybe<Partnership_Acfpartnership_Section_Video_Video>>>;
};

export type Partnership_Acfpartnership_Section_Video_Video =
  | Partnership_Acfpartnership_Section_Video_Video_Wistia
  | Partnership_Acfpartnership_Section_Video_Video_Youtube;

/** Group within the flex field */
export type Partnership_Acfpartnership_Section_Video_Video_Wistia =
  AcfFieldGroup & {
    __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    wistia?: Maybe<Partnership_Acfpartnership_Section_Video_Video_Wistia_Wistia>;
  };

/** Field Group */
export type Partnership_Acfpartnership_Section_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Partnership_Acfpartnership_Section_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Partnership_Acfpartnership_Section_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Partnership_Acfpartnership_Section_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Partnership_Acfpartnership_SignUpForm = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_SignUpForm';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  signUpForm?: Maybe<Partnership_Acfpartnership_SignUpForm_SignUpForm>;
};

/** Field Group */
export type Partnership_Acfpartnership_SignUpForm_SignUpForm = AcfFieldGroup & {
  __typename?: 'Partnership_Acfpartnership_SignUpForm_SignUpForm';
  /** This will prompt visitors to select if they are either &quot;Wool Grower&quot; or &quot;Shearing Contractor&quot; */
  areYouAWoolGrower?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  /** Add &quot;Coupon&quot; field to the form */
  couponCode?: Maybe<Scalars['Boolean']['output']>;
  /** If &quot;yes&quot; is selected, it will set a flag in Segment to exclude signups from this campaign from standard SC onboarding */
  excludeFromSafetycultureOnboardingComms?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  formId?: Maybe<Scalars['String']['output']>;
  preloadTemplates?: Maybe<
    Array<
      Maybe<Partnership_Acfpartnership_SignUpForm_SignUpForm_PreloadTemplates>
    >
  >;
  preloadTemplatesBy?: Maybe<Scalars['String']['output']>;
  /**
   * Provide a redirect path on successful sign up, for example &#039;/templates&#039; or &#039;/dashboard&#039;. Do not provide the entire URL to the app, this is being handled in the codebase.
   * &lt;/br&gt;
   * &lt;strong&gt;Leave empty if not sure&lt;/strong&gt;
   */
  redirect?: Maybe<Scalars['String']['output']>;
  salesforceCampaignId?: Maybe<Scalars['String']['output']>;
  salesforceCampaignTag?: Maybe<Scalars['String']['output']>;
  salesforceLeadSource?: Maybe<Scalars['String']['output']>;
  /** If &quot;yes&quot; it will add &quot;Country dropdown, Street number and other address fields to the form&quot; */
  showAddressFields?: Maybe<Scalars['String']['output']>;
  /** Does this partner want to capture a membership ID in their form submissions? If checked &#039;yes&#039;, the form will display a membership ID field. */
  showMembershipId?: Maybe<Scalars['String']['output']>;
  /** Add in role ID to preload templates */
  templateSource?: Maybe<Scalars['String']['output']>;
  /** Goes right before submit button and after Marketing Opt-in Checkbox */
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Partnership_Acfpartnership_SignUpForm_SignUpForm_PreloadTemplates =
  AcfFieldGroup & {
    __typename?: 'Partnership_Acfpartnership_SignUpForm_SignUpForm_preloadTemplates';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** An plugin object */
export type Plugin = Node & {
  __typename?: 'Plugin';
  /** Name of the plugin author(s), may also be a company name. */
  author?: Maybe<Scalars['String']['output']>;
  /** URI for the related author(s)/company website. */
  authorUri?: Maybe<Scalars['String']['output']>;
  /** Description of the plugin. */
  description?: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier of the plugin object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Display name of the plugin. */
  name?: Maybe<Scalars['String']['output']>;
  /** Plugin path. */
  path?: Maybe<Scalars['String']['output']>;
  /** URI for the plugin website. This is useful for directing users for support requests etc. */
  pluginUri?: Maybe<Scalars['String']['output']>;
  /** Current version of the plugin. */
  version?: Maybe<Scalars['String']['output']>;
};

/** Connection to Plugin Nodes */
export type PluginConnection = {
  /** A list of edges (relational context) between RootQuery and connected Plugin Nodes */
  edges: Array<PluginConnectionEdge>;
  /** A list of connected Plugin Nodes */
  nodes: Array<Plugin>;
  /** Information about pagination in a connection. */
  pageInfo: PluginConnectionPageInfo;
};

/** Edge between a Node and a connected Plugin */
export type PluginConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Plugin Node */
  node: Plugin;
};

/** Page Info on the connected PluginConnectionEdge */
export type PluginConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  Active = 'ACTIVE',
  /** The plugin is a drop-in plugin. */
  DropIn = 'DROP_IN',
  /** The plugin is currently inactive. */
  Inactive = 'INACTIVE',
  /** The plugin is a must-use plugin. */
  MustUse = 'MUST_USE',
  /** The plugin is activated on the multisite network. */
  NetworkActivated = 'NETWORK_ACTIVATED',
  /** The plugin is installed on the multisite network, but is currently inactive. */
  NetworkInactive = 'NETWORK_INACTIVE',
  /** The plugin is technically active but was paused while loading. */
  Paused = 'PAUSED',
  /** The plugin was active recently. */
  RecentlyActive = 'RECENTLY_ACTIVE',
  /** The plugin has an upgrade available. */
  Upgrade = 'UPGRADE',
}

/** The post type */
export type Post = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  NodeWithTrackbacks &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Post';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Post_Acfpagestatus>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the Post type and the category type */
    categories?: Maybe<PostToCategoryConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars['Int']['output']>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars['String']['output']>;
    /** Connection between the Post type and the Comment type */
    comments?: Maybe<PostToCommentConnection>;
    /** The content of the post. */
    content?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt?: Maybe<Scalars['String']['output']>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the post object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the post object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Whether this page is sticky */
    isSticky: Scalars['Boolean']['output'];
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Post language */
    language?: Maybe<Language>;
    /** Post language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The password for the post object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Whether the pings are open or closed for this particular post. */
    pingStatus?: Maybe<Scalars['String']['output']>;
    /** URLs that have been pinged. */
    pinged?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Connection between the Post type and the postFormat type */
    postFormats?: Maybe<PostToPostFormatConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    postId: Scalars['Int']['output'];
    /** Connection between the Post type and the post type */
    preview?: Maybe<PostToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Post type and the post type */
    revisions?: Maybe<PostToRevisionConnection>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** Connection between the Post type and the tag type */
    tags?: Maybe<PostToTagConnection>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Post type and the TermNode type */
    terms?: Maybe<PostToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** URLs queued to be pinged. */
    toPing?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Post>>>;
    /** Post translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The post type */
export type PostCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostToCategoryConnectionWhereArgs>;
};

/** The post type */
export type PostCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostToCommentConnectionWhereArgs>;
};

/** The post type */
export type PostContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The post type */
export type PostEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The post type */
export type PostExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostToPostFormatConnectionWhereArgs>;
};

/** The post type */
export type PostRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostToRevisionConnectionWhereArgs>;
};

/** The post type */
export type PostTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostToTagConnectionWhereArgs>;
};

/** The post type */
export type PostTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostToTermNodeConnectionWhereArgs>;
};

/** The post type */
export type PostTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
};

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection to post Nodes */
export type PostConnection = {
  /** A list of edges (relational context) between RootQuery and connected post Nodes */
  edges: Array<PostConnectionEdge>;
  /** A list of connected post Nodes */
  nodes: Array<Post>;
  /** Information about pagination in a connection. */
  pageInfo: PostConnectionPageInfo;
};

/** Edge between a Node and a connected post */
export type PostConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected post Node */
  node: Post;
};

/** Page Info on the connected PostConnectionEdge */
export type PostConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The postFormat type */
export type PostFormat = DatabaseIdentifier &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'PostFormat';
    /** Connection between the PostFormat type and the ContentNode type */
    contentNodes?: Maybe<PostFormatToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    postFormatId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the PostFormat type and the post type */
    posts?: Maybe<PostFormatToPostConnection>;
    /** The Yoast SEO data of the Formats taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the PostFormat type and the Taxonomy type */
    taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The postFormat type */
export type PostFormatContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostFormatToContentNodeConnectionWhereArgs>;
};

/** The postFormat type */
export type PostFormatEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The postFormat type */
export type PostFormatEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The postFormat type */
export type PostFormatPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PostFormatToPostConnectionWhereArgs>;
};

/** Connection to postFormat Nodes */
export type PostFormatConnection = {
  /** A list of edges (relational context) between RootQuery and connected postFormat Nodes */
  edges: Array<PostFormatConnectionEdge>;
  /** A list of connected postFormat Nodes */
  nodes: Array<PostFormat>;
  /** Information about pagination in a connection. */
  pageInfo: PostFormatConnectionPageInfo;
};

/** Edge between a Node and a connected postFormat */
export type PostFormatConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected postFormat Node */
  node: PostFormat;
};

/** Page Info on the connected PostFormatConnectionEdge */
export type PostFormatConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the PostFormat type and the ContentNode type */
export type PostFormatToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'PostFormatToContentNodeConnection';
    /** Edges for the PostFormatToContentNodeConnection connection */
    edges: Array<PostFormatToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: PostFormatToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PostFormatToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'PostFormatToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;PostFormatToContentNodeConnection&quot; */
export type PostFormatToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'PostFormatToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the PostFormat type and the post type */
export type PostFormatToPostConnection = Connection &
  PostConnection & {
    __typename?: 'PostFormatToPostConnection';
    /** Edges for the PostFormatToPostConnection connection */
    edges: Array<PostFormatToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: PostFormatToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type PostFormatToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostFormatToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;PostFormatToPostConnection&quot; */
export type PostFormatToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostFormatToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the PostFormat type and the Taxonomy type */
export type PostFormatToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'PostFormatToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  Raw = 'RAW',
  /** Provide the field value as rendered by WordPress. Default. */
  Rendered = 'RENDERED',
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = 'DATE',
  /** The most recent modification date of the comment. */
  Modified = 'MODIFIED',
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  CommentCount = 'COMMENT_COUNT',
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by title */
  Title = 'TITLE',
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
};

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
};

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostPostFormatsNodeInput = {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the dp-rewrite-republish status */
  DpRewriteRepublish = 'DP_REWRITE_REPUBLISH',
  /** Objects with the draft status */
  Draft = 'DRAFT',
  /** Objects with the future status */
  Future = 'FUTURE',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the pending status */
  Pending = 'PENDING',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the publish status */
  Publish = 'PUBLISH',
  /** Objects with the request-completed status */
  RequestCompleted = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  RequestConfirmed = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  RequestFailed = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  RequestPending = 'REQUEST_PENDING',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
};

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Post type and the category type */
export type PostToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'PostToCategoryConnection';
    /** Edges for the PostToCategoryConnection connection */
    edges: Array<PostToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: PostToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'PostToCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary category */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;PostToCategoryConnection&quot; */
export type PostToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostToCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Post type and the Comment type */
export type PostToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'PostToCommentConnection';
    /** Edges for the PostToCommentConnection connection */
    edges: Array<PostToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: PostToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'PostToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;PostToCommentConnection&quot; */
export type PostToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToCommentConnection connection */
export type PostToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the Post type and the postFormat type */
export type PostToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: 'PostToPostFormatConnection';
    /** Edges for the PostToPostFormatConnection connection */
    edges: Array<PostToPostFormatConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PostFormat>;
    /** Information about pagination in a connection. */
    pageInfo: PostToPostFormatConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: 'PostToPostFormatConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary post_format */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: PostFormat;
  };

/** Page Info on the &quot;PostToPostFormatConnection&quot; */
export type PostToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToPostFormatConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Post type and the post type */
export type PostToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PostConnectionEdge & {
    __typename?: 'PostToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Post;
  };

/** Connection between the Post type and the post type */
export type PostToRevisionConnection = Connection &
  PostConnection & {
    __typename?: 'PostToRevisionConnection';
    /** Edges for the PostToRevisionConnection connection */
    edges: Array<PostToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: PostToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToRevisionConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;PostToRevisionConnection&quot; */
export type PostToRevisionConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToRevisionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Post type and the tag type */
export type PostToTagConnection = Connection &
  TagConnection & {
    __typename?: 'PostToTagConnection';
    /** Edges for the PostToTagConnection connection */
    edges: Array<PostToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: PostToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: 'PostToTagConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary post_tag */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;PostToTagConnection&quot; */
export type PostToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToTagConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Post type and the TermNode type */
export type PostToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'PostToTermNodeConnection';
    /** Edges for the PostToTermNodeConnection connection */
    edges: Array<PostToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: PostToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'PostToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;PostToTermNodeConnection&quot; */
export type PostToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Details for labels of the PostType */
export type PostTypeLabelDetails = {
  __typename?: 'PostTypeLabelDetails';
  /** Default is ‘Add New’ for both hierarchical and non-hierarchical types. */
  addNew?: Maybe<Scalars['String']['output']>;
  /** Label for adding a new singular item. */
  addNewItem?: Maybe<Scalars['String']['output']>;
  /** Label to signify all items in a submenu link. */
  allItems?: Maybe<Scalars['String']['output']>;
  /** Label for archives in nav menus */
  archives?: Maybe<Scalars['String']['output']>;
  /** Label for the attributes meta box. */
  attributes?: Maybe<Scalars['String']['output']>;
  /** Label for editing a singular item. */
  editItem?: Maybe<Scalars['String']['output']>;
  /** Label for the Featured Image meta box title. */
  featuredImage?: Maybe<Scalars['String']['output']>;
  /** Label for the table views hidden heading. */
  filterItemsList?: Maybe<Scalars['String']['output']>;
  /** Label for the media frame button. */
  insertIntoItem?: Maybe<Scalars['String']['output']>;
  /** Label for the table hidden heading. */
  itemsList?: Maybe<Scalars['String']['output']>;
  /** Label for the table pagination hidden heading. */
  itemsListNavigation?: Maybe<Scalars['String']['output']>;
  /** Label for the menu name. */
  menuName?: Maybe<Scalars['String']['output']>;
  /** General name for the post type, usually plural. */
  name?: Maybe<Scalars['String']['output']>;
  /** Label for the new item page title. */
  newItem?: Maybe<Scalars['String']['output']>;
  /** Label used when no items are found. */
  notFound?: Maybe<Scalars['String']['output']>;
  /** Label used when no items are in the trash. */
  notFoundInTrash?: Maybe<Scalars['String']['output']>;
  /** Label used to prefix parents of hierarchical items. */
  parentItemColon?: Maybe<Scalars['String']['output']>;
  /** Label for removing the featured image. */
  removeFeaturedImage?: Maybe<Scalars['String']['output']>;
  /** Label for searching plural items. */
  searchItems?: Maybe<Scalars['String']['output']>;
  /** Label for setting the featured image. */
  setFeaturedImage?: Maybe<Scalars['String']['output']>;
  /** Name for one object of this post type. */
  singularName?: Maybe<Scalars['String']['output']>;
  /** Label for the media frame filter. */
  uploadedToThisItem?: Maybe<Scalars['String']['output']>;
  /** Label in the media frame for using a featured image. */
  useFeaturedImage?: Maybe<Scalars['String']['output']>;
  /** Label for viewing a singular item. */
  viewItem?: Maybe<Scalars['String']['output']>;
  /** Label for viewing post type archives. */
  viewItems?: Maybe<Scalars['String']['output']>;
};

export type PostTypeSeo = {
  __typename?: 'PostTypeSEO';
  breadcrumbs?: Maybe<Array<Maybe<SeoPostTypeBreadcrumbs>>>;
  canonical?: Maybe<Scalars['String']['output']>;
  cornerstone?: Maybe<Scalars['Boolean']['output']>;
  focuskw?: Maybe<Scalars['String']['output']>;
  fullHead?: Maybe<Scalars['String']['output']>;
  metaDesc?: Maybe<Scalars['String']['output']>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  metaRobotsNofollow?: Maybe<Scalars['String']['output']>;
  metaRobotsNoindex?: Maybe<Scalars['String']['output']>;
  opengraphAuthor?: Maybe<Scalars['String']['output']>;
  opengraphDescription?: Maybe<Scalars['String']['output']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<Scalars['String']['output']>;
  opengraphPublishedTime?: Maybe<Scalars['String']['output']>;
  opengraphPublisher?: Maybe<Scalars['String']['output']>;
  opengraphSiteName?: Maybe<Scalars['String']['output']>;
  opengraphTitle?: Maybe<Scalars['String']['output']>;
  opengraphType?: Maybe<Scalars['String']['output']>;
  opengraphUrl?: Maybe<Scalars['String']['output']>;
  readingTime?: Maybe<Scalars['Float']['output']>;
  schema?: Maybe<SeoPostTypeSchema>;
  title?: Maybe<Scalars['String']['output']>;
  twitterDescription?: Maybe<Scalars['String']['output']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Post_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Post_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Nodes that can be seen in a preview (unpublished) state. */
export type Previewable = {
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars['Boolean']['output']>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars['ID']['output']>;
};

/** The reading setting type */
export type ReadingSettings = {
  __typename?: 'ReadingSettings';
  /** The ID of the page that should display the latest posts */
  pageForPosts?: Maybe<Scalars['Int']['output']>;
  /** The ID of the page that should be displayed on the front page */
  pageOnFront?: Maybe<Scalars['Int']['output']>;
  /** Blog pages show at most. */
  postsPerPage?: Maybe<Scalars['Int']['output']>;
  /** What to show on the front page */
  showOnFront?: Maybe<Scalars['String']['output']>;
};

/** Input for the refreshJwtAuthToken mutation. */
export type RefreshJwtAuthTokenInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A valid, previously issued JWT refresh token. If valid a new Auth token will be provided. If invalid, expired, revoked or otherwise invalid, a new AuthToken will not be provided. */
  jwtRefreshToken: Scalars['String']['input'];
};

/** The payload for the refreshJwtAuthToken mutation. */
export type RefreshJwtAuthTokenPayload = {
  __typename?: 'RefreshJwtAuthTokenPayload';
  /** JWT Token that can be used in future requests for Authentication */
  authToken?: Maybe<Scalars['String']['output']>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the registerUser mutation. */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']['input']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the user's username. */
  username: Scalars['String']['input'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the registerUser mutation. */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = 'OR',
}

/** Input for the resetUserPassword mutation. */
export type ResetUserPasswordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Password reset key */
  key?: InputMaybe<Scalars['String']['input']>;
  /** The user's login (username). */
  login?: InputMaybe<Scalars['String']['input']>;
  /** The new password. */
  password?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the resetUserPassword mutation. */
export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** Input for the restoreComment mutation. */
export type RestoreCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the comment to be restored */
  id: Scalars['ID']['input'];
};

/** The payload for the restoreComment mutation. */
export type RestoreCommentPayload = {
  __typename?: 'RestoreCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The restored comment object */
  comment?: Maybe<Comment>;
  /** The ID of the restored comment */
  restoredId?: Maybe<Scalars['ID']['output']>;
};

/** The root mutation */
export type RootMutation = {
  __typename?: 'RootMutation';
  /** The createApp mutation */
  createApp?: Maybe<CreateAppPayload>;
  /** The createCategory mutation */
  createCategory?: Maybe<CreateCategoryPayload>;
  /** The createChecklist mutation */
  createChecklist?: Maybe<CreateChecklistPayload>;
  /** The createComment mutation */
  createComment?: Maybe<CreateCommentPayload>;
  /** The createCustomerStory mutation */
  createCustomerStory?: Maybe<CreateCustomerStoryPayload>;
  /** The createEbook mutation */
  createEbook?: Maybe<CreateEbookPayload>;
  /** The createEvent mutation */
  createEvent?: Maybe<CreateEventPayload>;
  /** The createGraphqlDocument mutation */
  createGraphqlDocument?: Maybe<CreateGraphqlDocumentPayload>;
  /** The createGraphqlDocumentGroup mutation */
  createGraphqlDocumentGroup?: Maybe<CreateGraphqlDocumentGroupPayload>;
  /** The createLegal mutation */
  createLegal?: Maybe<CreateLegalPayload>;
  /** The createMediaItem mutation */
  createMediaItem?: Maybe<CreateMediaItemPayload>;
  /** The createPage mutation */
  createPage?: Maybe<CreatePagePayload>;
  /** The createPartnership mutation */
  createPartnership?: Maybe<CreatePartnershipPayload>;
  /** The createPost mutation */
  createPost?: Maybe<CreatePostPayload>;
  /** The createPostFormat mutation */
  createPostFormat?: Maybe<CreatePostFormatPayload>;
  /** The createTag mutation */
  createTag?: Maybe<CreateTagPayload>;
  /** The createTaxonomyCategory mutation */
  createTaxonomyCategory?: Maybe<CreateTaxonomyCategoryPayload>;
  /** The createTaxonomyIndustry mutation */
  createTaxonomyIndustry?: Maybe<CreateTaxonomyIndustryPayload>;
  /** The createTaxonomyRegion mutation */
  createTaxonomyRegion?: Maybe<CreateTaxonomyRegionPayload>;
  /** The createTaxonomySolution mutation */
  createTaxonomySolution?: Maybe<CreateTaxonomySolutionPayload>;
  /** The createTestimonial mutation */
  createTestimonial?: Maybe<CreateTestimonialPayload>;
  /** The createTopic mutation */
  createTopic?: Maybe<CreateTopicPayload>;
  /** The createUser mutation */
  createUser?: Maybe<CreateUserPayload>;
  /** The deleteApp mutation */
  deleteApp?: Maybe<DeleteAppPayload>;
  /** The deleteCategory mutation */
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  /** The deleteChecklist mutation */
  deleteChecklist?: Maybe<DeleteChecklistPayload>;
  /** The deleteComment mutation */
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** The deleteCustomerStory mutation */
  deleteCustomerStory?: Maybe<DeleteCustomerStoryPayload>;
  /** The deleteEbook mutation */
  deleteEbook?: Maybe<DeleteEbookPayload>;
  /** The deleteEvent mutation */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** The deleteGraphqlDocument mutation */
  deleteGraphqlDocument?: Maybe<DeleteGraphqlDocumentPayload>;
  /** The deleteGraphqlDocumentGroup mutation */
  deleteGraphqlDocumentGroup?: Maybe<DeleteGraphqlDocumentGroupPayload>;
  /** The deleteLegal mutation */
  deleteLegal?: Maybe<DeleteLegalPayload>;
  /** The deleteMediaItem mutation */
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>;
  /** The deletePage mutation */
  deletePage?: Maybe<DeletePagePayload>;
  /** The deletePartnership mutation */
  deletePartnership?: Maybe<DeletePartnershipPayload>;
  /** The deletePost mutation */
  deletePost?: Maybe<DeletePostPayload>;
  /** The deletePostFormat mutation */
  deletePostFormat?: Maybe<DeletePostFormatPayload>;
  /** The deleteTag mutation */
  deleteTag?: Maybe<DeleteTagPayload>;
  /** The deleteTaxonomyCategory mutation */
  deleteTaxonomyCategory?: Maybe<DeleteTaxonomyCategoryPayload>;
  /** The deleteTaxonomyIndustry mutation */
  deleteTaxonomyIndustry?: Maybe<DeleteTaxonomyIndustryPayload>;
  /** The deleteTaxonomyRegion mutation */
  deleteTaxonomyRegion?: Maybe<DeleteTaxonomyRegionPayload>;
  /** The deleteTaxonomySolution mutation */
  deleteTaxonomySolution?: Maybe<DeleteTaxonomySolutionPayload>;
  /** The deleteTestimonial mutation */
  deleteTestimonial?: Maybe<DeleteTestimonialPayload>;
  /** The deleteTopic mutation */
  deleteTopic?: Maybe<DeleteTopicPayload>;
  /** The deleteUser mutation */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Increase the count. */
  increaseCount?: Maybe<Scalars['Int']['output']>;
  /** Login a user. Request for an authToken and User details in response */
  login?: Maybe<LoginPayload>;
  /** Use a valid JWT Refresh token to retrieve a new JWT Auth Token */
  refreshJwtAuthToken?: Maybe<RefreshJwtAuthTokenPayload>;
  /** The registerUser mutation */
  registerUser?: Maybe<RegisterUserPayload>;
  /** The resetUserPassword mutation */
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  /** The restoreComment mutation */
  restoreComment?: Maybe<RestoreCommentPayload>;
  /** Send password reset email to user */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>;
  /** The updateApp mutation */
  updateApp?: Maybe<UpdateAppPayload>;
  /** The updateCategory mutation */
  updateCategory?: Maybe<UpdateCategoryPayload>;
  /** The updateChecklist mutation */
  updateChecklist?: Maybe<UpdateChecklistPayload>;
  /** The updateComment mutation */
  updateComment?: Maybe<UpdateCommentPayload>;
  /** The updateCustomerStory mutation */
  updateCustomerStory?: Maybe<UpdateCustomerStoryPayload>;
  /** The updateEbook mutation */
  updateEbook?: Maybe<UpdateEbookPayload>;
  /** The updateEvent mutation */
  updateEvent?: Maybe<UpdateEventPayload>;
  /** The updateGraphqlDocument mutation */
  updateGraphqlDocument?: Maybe<UpdateGraphqlDocumentPayload>;
  /** The updateGraphqlDocumentGroup mutation */
  updateGraphqlDocumentGroup?: Maybe<UpdateGraphqlDocumentGroupPayload>;
  /** The updateLegal mutation */
  updateLegal?: Maybe<UpdateLegalPayload>;
  /** The updateMediaItem mutation */
  updateMediaItem?: Maybe<UpdateMediaItemPayload>;
  /** The updatePage mutation */
  updatePage?: Maybe<UpdatePagePayload>;
  /** The updatePartnership mutation */
  updatePartnership?: Maybe<UpdatePartnershipPayload>;
  /** The updatePost mutation */
  updatePost?: Maybe<UpdatePostPayload>;
  /** The updatePostFormat mutation */
  updatePostFormat?: Maybe<UpdatePostFormatPayload>;
  /** The updateSettings mutation */
  updateSettings?: Maybe<UpdateSettingsPayload>;
  /** The updateTag mutation */
  updateTag?: Maybe<UpdateTagPayload>;
  /** The updateTaxonomyCategory mutation */
  updateTaxonomyCategory?: Maybe<UpdateTaxonomyCategoryPayload>;
  /** The updateTaxonomyIndustry mutation */
  updateTaxonomyIndustry?: Maybe<UpdateTaxonomyIndustryPayload>;
  /** The updateTaxonomyRegion mutation */
  updateTaxonomyRegion?: Maybe<UpdateTaxonomyRegionPayload>;
  /** The updateTaxonomySolution mutation */
  updateTaxonomySolution?: Maybe<UpdateTaxonomySolutionPayload>;
  /** The updateTestimonial mutation */
  updateTestimonial?: Maybe<UpdateTestimonialPayload>;
  /** The updateTopic mutation */
  updateTopic?: Maybe<UpdateTopicPayload>;
  /** The updateUser mutation */
  updateUser?: Maybe<UpdateUserPayload>;
};

/** The root mutation */
export type RootMutationCreateAppArgs = {
  input: CreateAppInput;
};

/** The root mutation */
export type RootMutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

/** The root mutation */
export type RootMutationCreateChecklistArgs = {
  input: CreateChecklistInput;
};

/** The root mutation */
export type RootMutationCreateCommentArgs = {
  input: CreateCommentInput;
};

/** The root mutation */
export type RootMutationCreateCustomerStoryArgs = {
  input: CreateCustomerStoryInput;
};

/** The root mutation */
export type RootMutationCreateEbookArgs = {
  input: CreateEbookInput;
};

/** The root mutation */
export type RootMutationCreateEventArgs = {
  input: CreateEventInput;
};

/** The root mutation */
export type RootMutationCreateGraphqlDocumentArgs = {
  input: CreateGraphqlDocumentInput;
};

/** The root mutation */
export type RootMutationCreateGraphqlDocumentGroupArgs = {
  input: CreateGraphqlDocumentGroupInput;
};

/** The root mutation */
export type RootMutationCreateLegalArgs = {
  input: CreateLegalInput;
};

/** The root mutation */
export type RootMutationCreateMediaItemArgs = {
  input: CreateMediaItemInput;
};

/** The root mutation */
export type RootMutationCreatePageArgs = {
  input: CreatePageInput;
};

/** The root mutation */
export type RootMutationCreatePartnershipArgs = {
  input: CreatePartnershipInput;
};

/** The root mutation */
export type RootMutationCreatePostArgs = {
  input: CreatePostInput;
};

/** The root mutation */
export type RootMutationCreatePostFormatArgs = {
  input: CreatePostFormatInput;
};

/** The root mutation */
export type RootMutationCreateTagArgs = {
  input: CreateTagInput;
};

/** The root mutation */
export type RootMutationCreateTaxonomyCategoryArgs = {
  input: CreateTaxonomyCategoryInput;
};

/** The root mutation */
export type RootMutationCreateTaxonomyIndustryArgs = {
  input: CreateTaxonomyIndustryInput;
};

/** The root mutation */
export type RootMutationCreateTaxonomyRegionArgs = {
  input: CreateTaxonomyRegionInput;
};

/** The root mutation */
export type RootMutationCreateTaxonomySolutionArgs = {
  input: CreateTaxonomySolutionInput;
};

/** The root mutation */
export type RootMutationCreateTestimonialArgs = {
  input: CreateTestimonialInput;
};

/** The root mutation */
export type RootMutationCreateTopicArgs = {
  input: CreateTopicInput;
};

/** The root mutation */
export type RootMutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation */
export type RootMutationDeleteAppArgs = {
  input: DeleteAppInput;
};

/** The root mutation */
export type RootMutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};

/** The root mutation */
export type RootMutationDeleteChecklistArgs = {
  input: DeleteChecklistInput;
};

/** The root mutation */
export type RootMutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

/** The root mutation */
export type RootMutationDeleteCustomerStoryArgs = {
  input: DeleteCustomerStoryInput;
};

/** The root mutation */
export type RootMutationDeleteEbookArgs = {
  input: DeleteEbookInput;
};

/** The root mutation */
export type RootMutationDeleteEventArgs = {
  input: DeleteEventInput;
};

/** The root mutation */
export type RootMutationDeleteGraphqlDocumentArgs = {
  input: DeleteGraphqlDocumentInput;
};

/** The root mutation */
export type RootMutationDeleteGraphqlDocumentGroupArgs = {
  input: DeleteGraphqlDocumentGroupInput;
};

/** The root mutation */
export type RootMutationDeleteLegalArgs = {
  input: DeleteLegalInput;
};

/** The root mutation */
export type RootMutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};

/** The root mutation */
export type RootMutationDeletePageArgs = {
  input: DeletePageInput;
};

/** The root mutation */
export type RootMutationDeletePartnershipArgs = {
  input: DeletePartnershipInput;
};

/** The root mutation */
export type RootMutationDeletePostArgs = {
  input: DeletePostInput;
};

/** The root mutation */
export type RootMutationDeletePostFormatArgs = {
  input: DeletePostFormatInput;
};

/** The root mutation */
export type RootMutationDeleteTagArgs = {
  input: DeleteTagInput;
};

/** The root mutation */
export type RootMutationDeleteTaxonomyCategoryArgs = {
  input: DeleteTaxonomyCategoryInput;
};

/** The root mutation */
export type RootMutationDeleteTaxonomyIndustryArgs = {
  input: DeleteTaxonomyIndustryInput;
};

/** The root mutation */
export type RootMutationDeleteTaxonomyRegionArgs = {
  input: DeleteTaxonomyRegionInput;
};

/** The root mutation */
export type RootMutationDeleteTaxonomySolutionArgs = {
  input: DeleteTaxonomySolutionInput;
};

/** The root mutation */
export type RootMutationDeleteTestimonialArgs = {
  input: DeleteTestimonialInput;
};

/** The root mutation */
export type RootMutationDeleteTopicArgs = {
  input: DeleteTopicInput;
};

/** The root mutation */
export type RootMutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation */
export type RootMutationIncreaseCountArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

/** The root mutation */
export type RootMutationLoginArgs = {
  input: LoginInput;
};

/** The root mutation */
export type RootMutationRefreshJwtAuthTokenArgs = {
  input: RefreshJwtAuthTokenInput;
};

/** The root mutation */
export type RootMutationRegisterUserArgs = {
  input: RegisterUserInput;
};

/** The root mutation */
export type RootMutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

/** The root mutation */
export type RootMutationRestoreCommentArgs = {
  input: RestoreCommentInput;
};

/** The root mutation */
export type RootMutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};

/** The root mutation */
export type RootMutationUpdateAppArgs = {
  input: UpdateAppInput;
};

/** The root mutation */
export type RootMutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

/** The root mutation */
export type RootMutationUpdateChecklistArgs = {
  input: UpdateChecklistInput;
};

/** The root mutation */
export type RootMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

/** The root mutation */
export type RootMutationUpdateCustomerStoryArgs = {
  input: UpdateCustomerStoryInput;
};

/** The root mutation */
export type RootMutationUpdateEbookArgs = {
  input: UpdateEbookInput;
};

/** The root mutation */
export type RootMutationUpdateEventArgs = {
  input: UpdateEventInput;
};

/** The root mutation */
export type RootMutationUpdateGraphqlDocumentArgs = {
  input: UpdateGraphqlDocumentInput;
};

/** The root mutation */
export type RootMutationUpdateGraphqlDocumentGroupArgs = {
  input: UpdateGraphqlDocumentGroupInput;
};

/** The root mutation */
export type RootMutationUpdateLegalArgs = {
  input: UpdateLegalInput;
};

/** The root mutation */
export type RootMutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput;
};

/** The root mutation */
export type RootMutationUpdatePageArgs = {
  input: UpdatePageInput;
};

/** The root mutation */
export type RootMutationUpdatePartnershipArgs = {
  input: UpdatePartnershipInput;
};

/** The root mutation */
export type RootMutationUpdatePostArgs = {
  input: UpdatePostInput;
};

/** The root mutation */
export type RootMutationUpdatePostFormatArgs = {
  input: UpdatePostFormatInput;
};

/** The root mutation */
export type RootMutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

/** The root mutation */
export type RootMutationUpdateTagArgs = {
  input: UpdateTagInput;
};

/** The root mutation */
export type RootMutationUpdateTaxonomyCategoryArgs = {
  input: UpdateTaxonomyCategoryInput;
};

/** The root mutation */
export type RootMutationUpdateTaxonomyIndustryArgs = {
  input: UpdateTaxonomyIndustryInput;
};

/** The root mutation */
export type RootMutationUpdateTaxonomyRegionArgs = {
  input: UpdateTaxonomyRegionInput;
};

/** The root mutation */
export type RootMutationUpdateTaxonomySolutionArgs = {
  input: UpdateTaxonomySolutionInput;
};

/** The root mutation */
export type RootMutationUpdateTestimonialArgs = {
  input: UpdateTestimonialInput;
};

/** The root mutation */
export type RootMutationUpdateTopicArgs = {
  input: UpdateTopicInput;
};

/** The root mutation */
export type RootMutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root entry point into the Graph */
export type RootQuery = {
  __typename?: 'RootQuery';
  /** Capterra options. */
  acfOptionsCapterra?: Maybe<AcfOptionsCapterra>;
  /** Notification Banner options. */
  acfOptionsNotificationBanner?: Maybe<AcfOptionsNotificationBanner>;
  /** Entry point to get all settings for the site */
  allSettings?: Maybe<Settings>;
  /** An object of the App Type.  */
  app?: Maybe<App>;
  /**
   * A App object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  appBy?: Maybe<App>;
  /** Connection between the RootQuery type and the App type */
  apps?: Maybe<RootQueryToAppConnection>;
  /** Apps Globals options. */
  appsGlobals?: Maybe<AppsGlobals>;
  /** Connection between the RootQuery type and the category type */
  categories?: Maybe<RootQueryToCategoryConnection>;
  /** A 0bject */
  category?: Maybe<Category>;
  /** An object of the Checklist Type.  */
  checklist?: Maybe<Checklist>;
  /**
   * A Checklist object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  checklistBy?: Maybe<Checklist>;
  /** Connection between the RootQuery type and the Checklist type */
  checklists?: Maybe<RootQueryToChecklistConnection>;
  /** Returns a Comment */
  comment?: Maybe<Comment>;
  /** Connection between the RootQuery type and the Comment type */
  comments?: Maybe<RootQueryToCommentConnection>;
  /** A node used to manage content */
  contentNode?: Maybe<ContentNode>;
  /** Connection between the RootQuery type and the ContentNode type */
  contentNodes?: Maybe<RootQueryToContentNodeConnection>;
  /** Fetch a Content Type node by unique Identifier */
  contentType?: Maybe<ContentType>;
  /** Connection between the RootQuery type and the ContentType type */
  contentTypes?: Maybe<RootQueryToContentTypeConnection>;
  /** Connection between the RootQuery type and the CustomerStory type */
  customerStories?: Maybe<RootQueryToCustomerStoryConnection>;
  /** Customer Stories Archive options. */
  customerStoriesArchive?: Maybe<CustomerStoriesArchive>;
  /** An object of the CustomerStory Type.  */
  customerStory?: Maybe<CustomerStory>;
  /**
   * A CustomerStory object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  customerStoryBy?: Maybe<CustomerStory>;
  /** Get default language */
  defaultLanguage?: Maybe<Language>;
  /** Fields of the &#039;DiscussionSettings&#039; settings group */
  discussionSettings?: Maybe<DiscussionSettings>;
  /** An object of the Ebook Type.  */
  ebook?: Maybe<Ebook>;
  /**
   * A Ebook object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  ebookBy?: Maybe<Ebook>;
  /** Connection between the RootQuery type and the Ebook type */
  ebooks?: Maybe<RootQueryToEbookConnection>;
  /** An object of the Event Type.  */
  event?: Maybe<Event>;
  /**
   * A Event object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  eventBy?: Maybe<Event>;
  /** Connection between the RootQuery type and the Event type */
  events?: Maybe<RootQueryToEventConnection>;
  /** Fields of the &#039;GeneralSettings&#039; settings group */
  generalSettings?: Maybe<GeneralSettings>;
  /** An object of the graphqlDocument Type. Saved GraphQL Documents */
  graphqlDocument?: Maybe<GraphqlDocument>;
  /**
   * A graphqlDocument object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  graphqlDocumentBy?: Maybe<GraphqlDocument>;
  /** A 0bject */
  graphqlDocumentGroup?: Maybe<GraphqlDocumentGroup>;
  /** Connection between the RootQuery type and the graphqlDocumentGroup type */
  graphqlDocumentGroups?: Maybe<RootQueryToGraphqlDocumentGroupConnection>;
  /** Connection between the RootQuery type and the graphqlDocument type */
  graphqlDocuments?: Maybe<RootQueryToGraphqlDocumentConnection>;
  /** List available languages */
  languages?: Maybe<Array<Maybe<LanguageInfo>>>;
  /** An object of the Legal Type.  */
  legal?: Maybe<Legal>;
  /**
   * A Legal object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  legalBy?: Maybe<Legal>;
  /** Connection between the RootQuery type and the Legal type */
  legals?: Maybe<RootQueryToLegalConnection>;
  /** List available locales */
  locales?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** An object of the mediaItem Type.  */
  mediaItem?: Maybe<MediaItem>;
  /**
   * A mediaItem object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  mediaItemBy?: Maybe<MediaItem>;
  /** Connection between the RootQuery type and the mediaItem type */
  mediaItems?: Maybe<RootQueryToMediaItemConnection>;
  /** A WordPress navigation menu */
  menu?: Maybe<Menu>;
  /** A WordPress navigation menu item */
  menuItem?: Maybe<MenuItem>;
  /** Connection between the RootQuery type and the MenuItem type */
  menuItems?: Maybe<RootQueryToMenuItemConnection>;
  /** Connection between the RootQuery type and the Menu type */
  menus?: Maybe<RootQueryToMenuConnection>;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches an object given its Unique Resource Identifier */
  nodeByUri?: Maybe<UniformResourceIdentifiable>;
  /** An object of the page Type.  */
  page?: Maybe<Page>;
  /**
   * A page object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  pageBy?: Maybe<Page>;
  /** Connection between the RootQuery type and the page type */
  pages?: Maybe<RootQueryToPageConnection>;
  /** An object of the Partnership Type.  */
  partnership?: Maybe<Partnership>;
  /**
   * A Partnership object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  partnershipBy?: Maybe<Partnership>;
  /** Connection between the RootQuery type and the Partnership type */
  partnerships?: Maybe<RootQueryToPartnershipConnection>;
  /** A WordPress plugin */
  plugin?: Maybe<Plugin>;
  /** Connection between the RootQuery type and the Plugin type */
  plugins?: Maybe<RootQueryToPluginConnection>;
  /** An object of the post Type.  */
  post?: Maybe<Post>;
  /**
   * A post object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  postBy?: Maybe<Post>;
  /** A 0bject */
  postFormat?: Maybe<PostFormat>;
  /** Connection between the RootQuery type and the postFormat type */
  postFormats?: Maybe<RootQueryToPostFormatConnection>;
  /** Connection between the RootQuery type and the post type */
  posts?: Maybe<RootQueryToPostConnection>;
  /** Fields of the &#039;ReadingSettings&#039; settings group */
  readingSettings?: Maybe<ReadingSettings>;
  /** Connection between the RootQuery type and the EnqueuedScript type */
  registeredScripts?: Maybe<RootQueryToEnqueuedScriptConnection>;
  /** Connection between the RootQuery type and the EnqueuedStylesheet type */
  registeredStylesheets?: Maybe<RootQueryToEnqueuedStylesheetConnection>;
  /** Connection between the RootQuery type and the ContentNode type */
  revisions?: Maybe<RootQueryToRevisionsConnection>;
  /** Returns seo site data */
  seo?: Maybe<SeoConfig>;
  /** A 0bject */
  tag?: Maybe<Tag>;
  /** Connection between the RootQuery type and the tag type */
  tags?: Maybe<RootQueryToTagConnection>;
  /** Connection between the RootQuery type and the Taxonomy type */
  taxonomies?: Maybe<RootQueryToTaxonomyConnection>;
  /** Fetch a Taxonomy node by unique Identifier */
  taxonomy?: Maybe<Taxonomy>;
  /** Connection between the RootQuery type and the TaxonomyCategory type */
  taxonomyCategories?: Maybe<RootQueryToTaxonomyCategoryConnection>;
  /** A 0bject */
  taxonomyCategory?: Maybe<TaxonomyCategory>;
  /** Connection between the RootQuery type and the TaxonomyIndustry type */
  taxonomyIndustries?: Maybe<RootQueryToTaxonomyIndustryConnection>;
  /** A 0bject */
  taxonomyIndustry?: Maybe<TaxonomyIndustry>;
  /** A 0bject */
  taxonomyRegion?: Maybe<TaxonomyRegion>;
  /** Connection between the RootQuery type and the TaxonomyRegion type */
  taxonomyRegions?: Maybe<RootQueryToTaxonomyRegionConnection>;
  /** A 0bject */
  taxonomySolution?: Maybe<TaxonomySolution>;
  /** Connection between the RootQuery type and the TaxonomySolution type */
  taxonomySolutions?: Maybe<RootQueryToTaxonomySolutionConnection>;
  /** A node in a taxonomy used to group and relate content nodes */
  termNode?: Maybe<TermNode>;
  /** Connection between the RootQuery type and the TermNode type */
  terms?: Maybe<RootQueryToTermNodeConnection>;
  /** An object of the Testimonial Type.  */
  testimonial?: Maybe<Testimonial>;
  /**
   * A Testimonial object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  testimonialBy?: Maybe<Testimonial>;
  /** Connection between the RootQuery type and the Testimonial type */
  testimonials?: Maybe<RootQueryToTestimonialConnection>;
  /** A Theme object */
  theme?: Maybe<Theme>;
  /** Connection between the RootQuery type and the Theme type */
  themes?: Maybe<RootQueryToThemeConnection>;
  /** An object of the Topic Type.  */
  topic?: Maybe<Topic>;
  /**
   * A Topic object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  topicBy?: Maybe<Topic>;
  /** Connection between the RootQuery type and the Topic type */
  topics?: Maybe<RootQueryToTopicConnection>;
  /** Returns a user */
  user?: Maybe<User>;
  /** Returns a user role */
  userRole?: Maybe<UserRole>;
  /** Connection between the RootQuery type and the UserRole type */
  userRoles?: Maybe<RootQueryToUserRoleConnection>;
  /** Connection between the RootQuery type and the User type */
  users?: Maybe<RootQueryToUserConnection>;
  /** Returns the current user */
  viewer?: Maybe<User>;
  /** Fields of the &#039;WritingSettings&#039; settings group */
  writingSettings?: Maybe<WritingSettings>;
};

/** The root entry point into the Graph */
export type RootQueryAppArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<AppIdType>;
};

/** The root entry point into the Graph */
export type RootQueryAppByArgs = {
  appId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToAppConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToCategoryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCategoryArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<CategoryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryChecklistArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<ChecklistIdType>;
};

/** The root entry point into the Graph */
export type RootQueryChecklistByArgs = {
  checklistId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryChecklistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToChecklistConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCommentArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<CommentNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToCommentConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentNodeArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<ContentTypeEnum>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<ContentNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToContentNodeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentTypeArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<ContentTypeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryContentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryCustomerStoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToCustomerStoryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCustomerStoryArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<CustomerStoryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryCustomerStoryByArgs = {
  customerStoryId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryEbookArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<EbookIdType>;
};

/** The root entry point into the Graph */
export type RootQueryEbookByArgs = {
  ebookId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryEbooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToEbookConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryEventArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<EventIdType>;
};

/** The root entry point into the Graph */
export type RootQueryEventByArgs = {
  eventId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToEventConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<GraphqlDocumentIdType>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentByArgs = {
  graphqlDocumentId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentGroupArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<GraphqlDocumentGroupIdType>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToGraphqlDocumentGroupConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryGraphqlDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToGraphqlDocumentConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryLegalArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<LegalIdType>;
};

/** The root entry point into the Graph */
export type RootQueryLegalByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  legalId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryLegalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToLegalConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<MediaItemIdType>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  mediaItemId?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToMediaItemConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMenuArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<MenuNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMenuItemArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<MenuItemNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMenusArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToMenuConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryNodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryNodeByUriArgs = {
  uri: Scalars['String']['input'];
};

/** The root entry point into the Graph */
export type RootQueryPageArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<PageIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPageByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pageId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToPageConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPartnershipArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<PartnershipIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPartnershipByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  partnershipId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryPartnershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToPartnershipConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPluginArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryPluginsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToPluginConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPostArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<PostIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPostByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  postId?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryPostFormatArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<PostFormatIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToPostFormatConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryRegisteredScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryRegisteredStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToRevisionsConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTagArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TagIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTagConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TaxonomyIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTaxonomyCategoryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyCategoryArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TaxonomyCategoryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyIndustriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTaxonomyIndustryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyIndustryArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TaxonomyIndustryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyRegionArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TaxonomyRegionIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTaxonomyRegionConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomySolutionArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TaxonomySolutionIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomySolutionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTaxonomySolutionConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTermNodeArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TermNodeIdTypeEnum>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTermNodeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTestimonialArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TestimonialIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTestimonialByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  testimonialId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryTestimonialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTestimonialConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryThemeArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryThemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryTopicArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  idType?: InputMaybe<TopicIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTopicByArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  topicId?: InputMaybe<Scalars['Int']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToTopicConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryUserArgs = {
  id: Scalars['ID']['input'];
  idType?: InputMaybe<UserNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryUserRoleArgs = {
  id: Scalars['ID']['input'];
};

/** The root entry point into the Graph */
export type RootQueryUserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The root entry point into the Graph */
export type RootQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>;
};

/** Connection between the RootQuery type and the App type */
export type RootQueryToAppConnection = AppConnection &
  Connection & {
    __typename?: 'RootQueryToAppConnection';
    /** Edges for the RootQueryToAppConnection connection */
    edges: Array<RootQueryToAppConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<App>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToAppConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToAppConnectionEdge = AppConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToAppConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: App;
  };

/** Page Info on the &quot;RootQueryToAppConnection&quot; */
export type RootQueryToAppConnectionPageInfo = AppConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToAppConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToAppConnection connection */
export type RootQueryToAppConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter App objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the category type */
export type RootQueryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'RootQueryToCategoryConnection';
    /** Edges for the RootQueryToCategoryConnection connection */
    edges: Array<RootQueryToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;RootQueryToCategoryConnection&quot; */
export type RootQueryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Category objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Checklist type */
export type RootQueryToChecklistConnection = ChecklistConnection &
  Connection & {
    __typename?: 'RootQueryToChecklistConnection';
    /** Edges for the RootQueryToChecklistConnection connection */
    edges: Array<RootQueryToChecklistConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Checklist>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToChecklistConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToChecklistConnectionEdge = ChecklistConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToChecklistConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Checklist;
  };

/** Page Info on the &quot;RootQueryToChecklistConnection&quot; */
export type RootQueryToChecklistConnectionPageInfo =
  ChecklistConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToChecklistConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToChecklistConnection connection */
export type RootQueryToChecklistConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Checklist objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Comment type */
export type RootQueryToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'RootQueryToCommentConnection';
    /** Edges for the RootQueryToCommentConnection connection */
    edges: Array<RootQueryToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;RootQueryToCommentConnection&quot; */
export type RootQueryToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** Filter Comment objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'RootQueryToContentNodeConnection';
    /** Edges for the RootQueryToContentNodeConnection connection */
    edges: Array<RootQueryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RootQueryToContentNodeConnection&quot; */
export type RootQueryToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter content nodes by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the ContentType type */
export type RootQueryToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: 'RootQueryToContentTypeConnection';
    /** Edges for the RootQueryToContentTypeConnection connection */
    edges: Array<RootQueryToContentTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentType>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToContentTypeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentType;
  };

/** Page Info on the &quot;RootQueryToContentTypeConnection&quot; */
export type RootQueryToContentTypeConnectionPageInfo =
  ContentTypeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToContentTypeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the RootQuery type and the CustomerStory type */
export type RootQueryToCustomerStoryConnection = Connection &
  CustomerStoryConnection & {
    __typename?: 'RootQueryToCustomerStoryConnection';
    /** Edges for the RootQueryToCustomerStoryConnection connection */
    edges: Array<RootQueryToCustomerStoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CustomerStory>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCustomerStoryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCustomerStoryConnectionEdge =
  CustomerStoryConnectionEdge &
    Edge & {
      __typename?: 'RootQueryToCustomerStoryConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: CustomerStory;
    };

/** Page Info on the &quot;RootQueryToCustomerStoryConnection&quot; */
export type RootQueryToCustomerStoryConnectionPageInfo =
  CustomerStoryConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToCustomerStoryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToCustomerStoryConnection connection */
export type RootQueryToCustomerStoryConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter CustomerStory objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Ebook type */
export type RootQueryToEbookConnection = Connection &
  EbookConnection & {
    __typename?: 'RootQueryToEbookConnection';
    /** Edges for the RootQueryToEbookConnection connection */
    edges: Array<RootQueryToEbookConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Ebook>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEbookConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEbookConnectionEdge = EbookConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToEbookConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Ebook;
  };

/** Page Info on the &quot;RootQueryToEbookConnection&quot; */
export type RootQueryToEbookConnectionPageInfo = EbookConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToEbookConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToEbookConnection connection */
export type RootQueryToEbookConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Ebook objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the EnqueuedScript type */
export type RootQueryToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'RootQueryToEnqueuedScriptConnection';
    /** Edges for the RootQueryToEnqueuedScriptConnection connection */
    edges: Array<RootQueryToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'RootQueryToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;RootQueryToEnqueuedScriptConnection&quot; */
export type RootQueryToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToEnqueuedScriptConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the RootQuery type and the EnqueuedStylesheet type */
export type RootQueryToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnection';
    /** Edges for the RootQueryToEnqueuedStylesheetConnection connection */
    edges: Array<RootQueryToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;RootQueryToEnqueuedStylesheetConnection&quot; */
export type RootQueryToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToEnqueuedStylesheetConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the RootQuery type and the Event type */
export type RootQueryToEventConnection = Connection &
  EventConnection & {
    __typename?: 'RootQueryToEventConnection';
    /** Edges for the RootQueryToEventConnection connection */
    edges: Array<RootQueryToEventConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Event>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEventConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEventConnectionEdge = Edge &
  EventConnectionEdge & {
    __typename?: 'RootQueryToEventConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Event;
  };

/** Page Info on the &quot;RootQueryToEventConnection&quot; */
export type RootQueryToEventConnectionPageInfo = EventConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToEventConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToEventConnection connection */
export type RootQueryToEventConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Event objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the graphqlDocument type */
export type RootQueryToGraphqlDocumentConnection = Connection &
  GraphqlDocumentConnection & {
    __typename?: 'RootQueryToGraphqlDocumentConnection';
    /** Edges for the RootQueryToGraphqlDocumentConnection connection */
    edges: Array<RootQueryToGraphqlDocumentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocument>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToGraphqlDocumentConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToGraphqlDocumentConnectionEdge = Edge &
  GraphqlDocumentConnectionEdge & {
    __typename?: 'RootQueryToGraphqlDocumentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocument;
  };

/** Page Info on the &quot;RootQueryToGraphqlDocumentConnection&quot; */
export type RootQueryToGraphqlDocumentConnectionPageInfo =
  GraphqlDocumentConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToGraphqlDocumentConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToGraphqlDocumentConnection connection */
export type RootQueryToGraphqlDocumentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the graphqlDocumentGroup type */
export type RootQueryToGraphqlDocumentGroupConnection = Connection &
  GraphqlDocumentGroupConnection & {
    __typename?: 'RootQueryToGraphqlDocumentGroupConnection';
    /** Edges for the RootQueryToGraphqlDocumentGroupConnection connection */
    edges: Array<RootQueryToGraphqlDocumentGroupConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GraphqlDocumentGroup>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToGraphqlDocumentGroupConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToGraphqlDocumentGroupConnectionEdge = Edge &
  GraphqlDocumentGroupConnectionEdge & {
    __typename?: 'RootQueryToGraphqlDocumentGroupConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: GraphqlDocumentGroup;
  };

/** Page Info on the &quot;RootQueryToGraphqlDocumentGroupConnection&quot; */
export type RootQueryToGraphqlDocumentGroupConnectionPageInfo =
  GraphqlDocumentGroupConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToGraphqlDocumentGroupConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToGraphqlDocumentGroupConnection connection */
export type RootQueryToGraphqlDocumentGroupConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Legal type */
export type RootQueryToLegalConnection = Connection &
  LegalConnection & {
    __typename?: 'RootQueryToLegalConnection';
    /** Edges for the RootQueryToLegalConnection connection */
    edges: Array<RootQueryToLegalConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Legal>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToLegalConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToLegalConnectionEdge = Edge &
  LegalConnectionEdge & {
    __typename?: 'RootQueryToLegalConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Legal;
  };

/** Page Info on the &quot;RootQueryToLegalConnection&quot; */
export type RootQueryToLegalConnectionPageInfo = LegalConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToLegalConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToLegalConnection connection */
export type RootQueryToLegalConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Legal objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the mediaItem type */
export type RootQueryToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'RootQueryToMediaItemConnection';
    /** Edges for the RootQueryToMediaItemConnection connection */
    edges: Array<RootQueryToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'RootQueryToMediaItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;RootQueryToMediaItemConnection&quot; */
export type RootQueryToMediaItemConnectionPageInfo =
  MediaItemConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToMediaItemConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter MediaItem objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Menu type */
export type RootQueryToMenuConnection = Connection &
  MenuConnection & {
    __typename?: 'RootQueryToMenuConnection';
    /** Edges for the RootQueryToMenuConnection connection */
    edges: Array<RootQueryToMenuConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Menu>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMenuConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMenuConnectionEdge = Edge &
  MenuConnectionEdge & {
    __typename?: 'RootQueryToMenuConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Menu;
  };

/** Page Info on the &quot;RootQueryToMenuConnection&quot; */
export type RootQueryToMenuConnectionPageInfo = MenuConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMenuConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** filters the menus by language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the MenuItem type */
export type RootQueryToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'RootQueryToMenuItemConnection';
    /** Edges for the RootQueryToMenuItemConnection connection */
    edges: Array<RootQueryToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'RootQueryToMenuItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;RootQueryToMenuItemConnection&quot; */
export type RootQueryToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMenuItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** filters the menu items by language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the RootQuery type and the page type */
export type RootQueryToPageConnection = Connection &
  PageConnection & {
    __typename?: 'RootQueryToPageConnection';
    /** Edges for the RootQueryToPageConnection connection */
    edges: Array<RootQueryToPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPageConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'RootQueryToPageConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;RootQueryToPageConnection&quot; */
export type RootQueryToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPageConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Page objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Partnership type */
export type RootQueryToPartnershipConnection = Connection &
  PartnershipConnection & {
    __typename?: 'RootQueryToPartnershipConnection';
    /** Edges for the RootQueryToPartnershipConnection connection */
    edges: Array<RootQueryToPartnershipConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Partnership>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPartnershipConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPartnershipConnectionEdge = Edge &
  PartnershipConnectionEdge & {
    __typename?: 'RootQueryToPartnershipConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Partnership;
  };

/** Page Info on the &quot;RootQueryToPartnershipConnection&quot; */
export type RootQueryToPartnershipConnectionPageInfo = PageInfo &
  PartnershipConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPartnershipConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPartnershipConnection connection */
export type RootQueryToPartnershipConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Partnership objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Plugin type */
export type RootQueryToPluginConnection = Connection &
  PluginConnection & {
    __typename?: 'RootQueryToPluginConnection';
    /** Edges for the RootQueryToPluginConnection connection */
    edges: Array<RootQueryToPluginConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Plugin>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPluginConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPluginConnectionEdge = Edge &
  PluginConnectionEdge & {
    __typename?: 'RootQueryToPluginConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Plugin;
  };

/** Page Info on the &quot;RootQueryToPluginConnection&quot; */
export type RootQueryToPluginConnectionPageInfo = PageInfo &
  PluginConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPluginConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPluginConnection connection */
export type RootQueryToPluginConnectionWhereArgs = {
  /** Show plugin based on a keyword search. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve plugins where plugin status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status?: InputMaybe<PluginStatusEnum>;
};

/** Connection between the RootQuery type and the post type */
export type RootQueryToPostConnection = Connection &
  PostConnection & {
    __typename?: 'RootQueryToPostConnection';
    /** Edges for the RootQueryToPostConnection connection */
    edges: Array<RootQueryToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'RootQueryToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;RootQueryToPostConnection&quot; */
export type RootQueryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Post objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the postFormat type */
export type RootQueryToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: 'RootQueryToPostFormatConnection';
    /** Edges for the RootQueryToPostFormatConnection connection */
    edges: Array<RootQueryToPostFormatConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PostFormat>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPostFormatConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: 'RootQueryToPostFormatConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: PostFormat;
  };

/** Page Info on the &quot;RootQueryToPostFormatConnection&quot; */
export type RootQueryToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPostFormatConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'RootQueryToRevisionsConnection';
    /** Edges for the RootQueryToRevisionsConnection connection */
    edges: Array<RootQueryToRevisionsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToRevisionsConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToRevisionsConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RootQueryToRevisionsConnection&quot; */
export type RootQueryToRevisionsConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToRevisionsConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the RootQueryToRevisionsConnection connection */
export type RootQueryToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the tag type */
export type RootQueryToTagConnection = Connection &
  TagConnection & {
    __typename?: 'RootQueryToTagConnection';
    /** Edges for the RootQueryToTagConnection connection */
    edges: Array<RootQueryToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: 'RootQueryToTagConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;RootQueryToTagConnection&quot; */
export type RootQueryToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTagConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Tag objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the TaxonomyCategory type */
export type RootQueryToTaxonomyCategoryConnection = Connection &
  TaxonomyCategoryConnection & {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    /** Edges for the RootQueryToTaxonomyCategoryConnection connection */
    edges: Array<RootQueryToTaxonomyCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyCategory>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomyCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomyCategoryConnectionEdge = Edge &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'RootQueryToTaxonomyCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyCategory;
  };

/** Page Info on the &quot;RootQueryToTaxonomyCategoryConnection&quot; */
export type RootQueryToTaxonomyCategoryConnectionPageInfo = PageInfo &
  TaxonomyCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomyCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTaxonomyCategoryConnection connection */
export type RootQueryToTaxonomyCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter TaxonomyCategory objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Taxonomy type */
export type RootQueryToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: 'RootQueryToTaxonomyConnection';
    /** Edges for the RootQueryToTaxonomyConnection connection */
    edges: Array<RootQueryToTaxonomyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Taxonomy>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomyConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: 'RootQueryToTaxonomyConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Taxonomy;
  };

/** Page Info on the &quot;RootQueryToTaxonomyConnection&quot; */
export type RootQueryToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomyConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the TaxonomyIndustry type */
export type RootQueryToTaxonomyIndustryConnection = Connection &
  TaxonomyIndustryConnection & {
    __typename?: 'RootQueryToTaxonomyIndustryConnection';
    /** Edges for the RootQueryToTaxonomyIndustryConnection connection */
    edges: Array<RootQueryToTaxonomyIndustryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyIndustry>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomyIndustryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomyIndustryConnectionEdge = Edge &
  TaxonomyIndustryConnectionEdge & {
    __typename?: 'RootQueryToTaxonomyIndustryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyIndustry;
  };

/** Page Info on the &quot;RootQueryToTaxonomyIndustryConnection&quot; */
export type RootQueryToTaxonomyIndustryConnectionPageInfo = PageInfo &
  TaxonomyIndustryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomyIndustryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTaxonomyIndustryConnection connection */
export type RootQueryToTaxonomyIndustryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter TaxonomyIndustry objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the TaxonomyRegion type */
export type RootQueryToTaxonomyRegionConnection = Connection &
  TaxonomyRegionConnection & {
    __typename?: 'RootQueryToTaxonomyRegionConnection';
    /** Edges for the RootQueryToTaxonomyRegionConnection connection */
    edges: Array<RootQueryToTaxonomyRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyRegion>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomyRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomyRegionConnectionEdge = Edge &
  TaxonomyRegionConnectionEdge & {
    __typename?: 'RootQueryToTaxonomyRegionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyRegion;
  };

/** Page Info on the &quot;RootQueryToTaxonomyRegionConnection&quot; */
export type RootQueryToTaxonomyRegionConnectionPageInfo = PageInfo &
  TaxonomyRegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomyRegionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTaxonomyRegionConnection connection */
export type RootQueryToTaxonomyRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter TaxonomyRegion objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the TaxonomySolution type */
export type RootQueryToTaxonomySolutionConnection = Connection &
  TaxonomySolutionConnection & {
    __typename?: 'RootQueryToTaxonomySolutionConnection';
    /** Edges for the RootQueryToTaxonomySolutionConnection connection */
    edges: Array<RootQueryToTaxonomySolutionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomySolution>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomySolutionConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomySolutionConnectionEdge = Edge &
  TaxonomySolutionConnectionEdge & {
    __typename?: 'RootQueryToTaxonomySolutionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomySolution;
  };

/** Page Info on the &quot;RootQueryToTaxonomySolutionConnection&quot; */
export type RootQueryToTaxonomySolutionConnectionPageInfo = PageInfo &
  TaxonomySolutionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomySolutionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTaxonomySolutionConnection connection */
export type RootQueryToTaxonomySolutionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter TaxonomySolution objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the TermNode type */
export type RootQueryToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'RootQueryToTermNodeConnection';
    /** Edges for the RootQueryToTermNodeConnection connection */
    edges: Array<RootQueryToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'RootQueryToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;RootQueryToTermNodeConnection&quot; */
export type RootQueryToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the RootQuery type and the Testimonial type */
export type RootQueryToTestimonialConnection = Connection &
  TestimonialConnection & {
    __typename?: 'RootQueryToTestimonialConnection';
    /** Edges for the RootQueryToTestimonialConnection connection */
    edges: Array<RootQueryToTestimonialConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Testimonial>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTestimonialConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTestimonialConnectionEdge = Edge &
  TestimonialConnectionEdge & {
    __typename?: 'RootQueryToTestimonialConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Testimonial;
  };

/** Page Info on the &quot;RootQueryToTestimonialConnection&quot; */
export type RootQueryToTestimonialConnectionPageInfo = PageInfo &
  TestimonialConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTestimonialConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTestimonialConnection connection */
export type RootQueryToTestimonialConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the Theme type */
export type RootQueryToThemeConnection = Connection &
  ThemeConnection & {
    __typename?: 'RootQueryToThemeConnection';
    /** Edges for the RootQueryToThemeConnection connection */
    edges: Array<RootQueryToThemeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Theme>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToThemeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToThemeConnectionEdge = Edge &
  ThemeConnectionEdge & {
    __typename?: 'RootQueryToThemeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Theme;
  };

/** Page Info on the &quot;RootQueryToThemeConnection&quot; */
export type RootQueryToThemeConnectionPageInfo = PageInfo &
  ThemeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToThemeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Connection between the RootQuery type and the Topic type */
export type RootQueryToTopicConnection = Connection &
  TopicConnection & {
    __typename?: 'RootQueryToTopicConnection';
    /** Edges for the RootQueryToTopicConnection connection */
    edges: Array<RootQueryToTopicConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Topic>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTopicConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTopicConnectionEdge = Edge &
  TopicConnectionEdge & {
    __typename?: 'RootQueryToTopicConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Topic;
  };

/** Page Info on the &quot;RootQueryToTopicConnection&quot; */
export type RootQueryToTopicConnectionPageInfo = PageInfo &
  TopicConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTopicConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToTopicConnection connection */
export type RootQueryToTopicConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter Topic objects by language code */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Filter by WPML language code */
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the RootQuery type and the User type */
export type RootQueryToUserConnection = Connection &
  UserConnection & {
    __typename?: 'RootQueryToUserConnection';
    /** Edges for the RootQueryToUserConnection connection */
    edges: Array<RootQueryToUserConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<User>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToUserConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToUserConnectionEdge = Edge &
  UserConnectionEdge & {
    __typename?: 'RootQueryToUserConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: User;
  };

/** Page Info on the &quot;RootQueryToUserConnection&quot; */
export type RootQueryToUserConnectionPageInfo = PageInfo &
  UserConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToUserConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** Array of userIds to exclude. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** The user login. */
  login?: InputMaybe<Scalars['String']['input']>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The user nicename. */
  nicename?: InputMaybe<Scalars['String']['input']>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: InputMaybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: InputMaybe<
    Array<InputMaybe<UsersConnectionSearchColumnEnum>>
  >;
};

/** Connection between the RootQuery type and the UserRole type */
export type RootQueryToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: 'RootQueryToUserRoleConnection';
    /** Edges for the RootQueryToUserRoleConnection connection */
    edges: Array<RootQueryToUserRoleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<UserRole>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToUserRoleConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: 'RootQueryToUserRoleConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: UserRole;
  };

/** Page Info on the &quot;RootQueryToUserRoleConnection&quot; */
export type RootQueryToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToUserRoleConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** The Yoast SEO breadcrumb config */
export type SeoBreadcrumbs = {
  __typename?: 'SEOBreadcrumbs';
  archivePrefix?: Maybe<Scalars['String']['output']>;
  boldLast?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  homeText?: Maybe<Scalars['String']['output']>;
  notFoundText?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  searchPrefix?: Maybe<Scalars['String']['output']>;
  separator?: Maybe<Scalars['String']['output']>;
  showBlogPage?: Maybe<Scalars['Boolean']['output']>;
};

/** Types of cards */
export enum SeoCardType {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
}

/** The Yoast SEO site level configuration data */
export type SeoConfig = {
  __typename?: 'SEOConfig';
  breadcrumbs?: Maybe<SeoBreadcrumbs>;
  contentTypes?: Maybe<SeoContentTypes>;
  meta?: Maybe<SeoGlobalMeta>;
  openGraph?: Maybe<SeoOpenGraph>;
  redirects?: Maybe<Array<Maybe<SeoRedirect>>>;
  schema?: Maybe<SeoSchema>;
  social?: Maybe<SeoSocial>;
  webmaster?: Maybe<SeoWebmaster>;
};

/** The Yoast SEO search appearance content types fields */
export type SeoContentType = {
  __typename?: 'SEOContentType';
  archive?: Maybe<SeoContentTypeArchive>;
  metaDesc?: Maybe<Scalars['String']['output']>;
  metaRobotsNoindex?: Maybe<Scalars['Boolean']['output']>;
  schema?: Maybe<SeoPageInfoSchema>;
  schemaType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO search appearance content types fields */
export type SeoContentTypeArchive = {
  __typename?: 'SEOContentTypeArchive';
  archiveLink?: Maybe<Scalars['String']['output']>;
  breadcrumbTitle?: Maybe<Scalars['String']['output']>;
  fullHead?: Maybe<Scalars['String']['output']>;
  hasArchive?: Maybe<Scalars['Boolean']['output']>;
  metaDesc?: Maybe<Scalars['String']['output']>;
  metaRobotsFollow?: Maybe<Scalars['String']['output']>;
  metaRobotsIndex?: Maybe<Scalars['String']['output']>;
  metaRobotsNofollow?: Maybe<Scalars['Boolean']['output']>;
  metaRobotsNoindex?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO search appearance content types */
export type SeoContentTypes = {
  __typename?: 'SEOContentTypes';
  app?: Maybe<SeoContentType>;
  checklist?: Maybe<SeoContentType>;
  customerStory?: Maybe<SeoContentType>;
  ebook?: Maybe<SeoContentType>;
  event?: Maybe<SeoContentType>;
  graphqlDocument?: Maybe<SeoContentType>;
  legal?: Maybe<SeoContentType>;
  mediaItem?: Maybe<SeoContentType>;
  page?: Maybe<SeoContentType>;
  partnership?: Maybe<SeoContentType>;
  post?: Maybe<SeoContentType>;
  testimonial?: Maybe<SeoContentType>;
  topic?: Maybe<SeoContentType>;
};

/** The Yoast SEO meta data */
export type SeoGlobalMeta = {
  __typename?: 'SEOGlobalMeta';
  author?: Maybe<SeoGlobalMetaAuthor>;
  config?: Maybe<SeoGlobalMetaConfig>;
  date?: Maybe<SeoGlobalMetaDate>;
  homepage?: Maybe<SeoGlobalMetaHome>;
  notFound?: Maybe<SeoGlobalMeta404>;
};

/** The Yoast SEO meta 404 data */
export type SeoGlobalMeta404 = {
  __typename?: 'SEOGlobalMeta404';
  breadcrumb?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO Author data */
export type SeoGlobalMetaAuthor = {
  __typename?: 'SEOGlobalMetaAuthor';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO meta config data */
export type SeoGlobalMetaConfig = {
  __typename?: 'SEOGlobalMetaConfig';
  separator?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO Date data */
export type SeoGlobalMetaDate = {
  __typename?: 'SEOGlobalMetaDate';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO homepage data */
export type SeoGlobalMetaHome = {
  __typename?: 'SEOGlobalMetaHome';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Open Graph data */
export type SeoOpenGraph = {
  __typename?: 'SEOOpenGraph';
  defaultImage?: Maybe<MediaItem>;
  frontPage?: Maybe<SeoOpenGraphFrontPage>;
};

/** The Open Graph Front page data */
export type SeoOpenGraphFrontPage = {
  __typename?: 'SEOOpenGraphFrontPage';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
  title?: Maybe<Scalars['String']['output']>;
};

/** The Schema for post type */
export type SeoPageInfoSchema = {
  __typename?: 'SEOPageInfoSchema';
  raw?: Maybe<Scalars['String']['output']>;
};

export type SeoPostTypeBreadcrumbs = {
  __typename?: 'SEOPostTypeBreadcrumbs';
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** The page info SEO details */
export type SeoPostTypePageInfo = {
  __typename?: 'SEOPostTypePageInfo';
  schema?: Maybe<SeoPageInfoSchema>;
};

/** The Schema types */
export type SeoPostTypeSchema = {
  __typename?: 'SEOPostTypeSchema';
  articleType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pageType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  raw?: Maybe<Scalars['String']['output']>;
};

/** The Yoast redirect data  (Yoast Premium only) */
export type SeoRedirect = {
  __typename?: 'SEORedirect';
  format?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

/** The Yoast SEO schema data */
export type SeoSchema = {
  __typename?: 'SEOSchema';
  companyLogo?: Maybe<MediaItem>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyOrPerson?: Maybe<Scalars['String']['output']>;
  homeUrl?: Maybe<Scalars['String']['output']>;
  inLanguage?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<MediaItem>;
  personLogo?: Maybe<MediaItem>;
  personName?: Maybe<Scalars['String']['output']>;
  siteName?: Maybe<Scalars['String']['output']>;
  siteUrl?: Maybe<Scalars['String']['output']>;
  wordpressSiteName?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO Social media links */
export type SeoSocial = {
  __typename?: 'SEOSocial';
  facebook?: Maybe<SeoSocialFacebook>;
  instagram?: Maybe<SeoSocialInstagram>;
  linkedIn?: Maybe<SeoSocialLinkedIn>;
  mySpace?: Maybe<SeoSocialMySpace>;
  otherSocials?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pinterest?: Maybe<SeoSocialPinterest>;
  twitter?: Maybe<SeoSocialTwitter>;
  wikipedia?: Maybe<SeoSocialWikipedia>;
  youTube?: Maybe<SeoSocialYoutube>;
};

export type SeoSocialFacebook = {
  __typename?: 'SEOSocialFacebook';
  defaultImage?: Maybe<MediaItem>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialInstagram = {
  __typename?: 'SEOSocialInstagram';
  url?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialLinkedIn = {
  __typename?: 'SEOSocialLinkedIn';
  url?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialMySpace = {
  __typename?: 'SEOSocialMySpace';
  url?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialPinterest = {
  __typename?: 'SEOSocialPinterest';
  metaTag?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialTwitter = {
  __typename?: 'SEOSocialTwitter';
  cardType?: Maybe<SeoCardType>;
  username?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialWikipedia = {
  __typename?: 'SEOSocialWikipedia';
  url?: Maybe<Scalars['String']['output']>;
};

export type SeoSocialYoutube = {
  __typename?: 'SEOSocialYoutube';
  url?: Maybe<Scalars['String']['output']>;
};

/** The Schema types for Taxonomy */
export type SeoTaxonomySchema = {
  __typename?: 'SEOTaxonomySchema';
  raw?: Maybe<Scalars['String']['output']>;
};

export type SeoUser = {
  __typename?: 'SEOUser';
  breadcrumbTitle?: Maybe<Scalars['String']['output']>;
  canonical?: Maybe<Scalars['String']['output']>;
  fullHead?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  metaDesc?: Maybe<Scalars['String']['output']>;
  metaRobotsNofollow?: Maybe<Scalars['String']['output']>;
  metaRobotsNoindex?: Maybe<Scalars['String']['output']>;
  opengraphDescription?: Maybe<Scalars['String']['output']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphTitle?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<SeoUserSchema>;
  social?: Maybe<SeoUserSocial>;
  title?: Maybe<Scalars['String']['output']>;
  twitterDescription?: Maybe<Scalars['String']['output']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']['output']>;
};

/** The Schema types for User */
export type SeoUserSchema = {
  __typename?: 'SEOUserSchema';
  articleType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pageType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  raw?: Maybe<Scalars['String']['output']>;
};

export type SeoUserSocial = {
  __typename?: 'SEOUserSocial';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedIn?: Maybe<Scalars['String']['output']>;
  mySpace?: Maybe<Scalars['String']['output']>;
  pinterest?: Maybe<Scalars['String']['output']>;
  soundCloud?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  wikipedia?: Maybe<Scalars['String']['output']>;
  youTube?: Maybe<Scalars['String']['output']>;
};

/** The Yoast SEO  webmaster fields */
export type SeoWebmaster = {
  __typename?: 'SEOWebmaster';
  baiduVerify?: Maybe<Scalars['String']['output']>;
  googleVerify?: Maybe<Scalars['String']['output']>;
  msVerify?: Maybe<Scalars['String']['output']>;
  yandexVerify?: Maybe<Scalars['String']['output']>;
};

/** The strategy to use when loading the script */
export enum ScriptLoadingStrategyEnum {
  /** Use the script `async` attribute */
  Async = 'ASYNC',
  /** Use the script `defer` attribute */
  Defer = 'DEFER',
}

/** Input for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the user's username or email address. */
  username: Scalars['String']['input'];
};

/** The payload for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Whether the mutation completed successfully. This does NOT necessarily mean that an email was sent. */
  success?: Maybe<Scalars['Boolean']['output']>;
  /**
   * The user that the password reset email was sent to
   * @deprecated This field will be removed in a future version of WPGraphQL
   */
  user?: Maybe<User>;
};

/** All of the registered settings */
export type Settings = {
  __typename?: 'Settings';
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsDateFormat?: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsDescription?: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsLanguage?: Maybe<Scalars['String']['output']>;
  /** Settings of the the integer Settings Group */
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsTimeFormat?: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsTimezone?: Maybe<Scalars['String']['output']>;
  /** Settings of the the string Settings Group */
  generalSettingsTitle?: Maybe<Scalars['String']['output']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPageForPosts?: Maybe<Scalars['Int']['output']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPageOnFront?: Maybe<Scalars['Int']['output']>;
  /** Settings of the the integer Settings Group */
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']['output']>;
  /** Settings of the the string Settings Group */
  readingSettingsShowOnFront?: Maybe<Scalars['String']['output']>;
  /** Settings of the the integer Settings Group */
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']['output']>;
  /** Settings of the the string Settings Group */
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']['output']>;
  /** Settings of the the boolean Settings Group */
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']['output']>;
};

/** The tag type */
export type Tag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Tag';
    /** Connection between the Tag type and the ContentNode type */
    contentNodes?: Maybe<TagToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Tag language */
    language?: Maybe<Language>;
    /** Tag language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** Connection between the Tag type and the post type */
    posts?: Maybe<TagToPostConnection>;
    /** The Yoast SEO data of the Tags taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    tagId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the Tag type and the Taxonomy type */
    taxonomy?: Maybe<TagToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** Tag translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** Tag translations */
    translations?: Maybe<Array<Maybe<Tag>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The tag type */
export type TagContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagToContentNodeConnectionWhereArgs>;
};

/** The tag type */
export type TagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The tag type */
export type TagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The tag type */
export type TagPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagToPostConnectionWhereArgs>;
};

/** Connection to tag Nodes */
export type TagConnection = {
  /** A list of edges (relational context) between RootQuery and connected tag Nodes */
  edges: Array<TagConnectionEdge>;
  /** A list of connected tag Nodes */
  nodes: Array<Tag>;
  /** Information about pagination in a connection. */
  pageInfo: TagConnectionPageInfo;
};

/** Edge between a Node and a connected tag */
export type TagConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected tag Node */
  node: Tag;
};

/** Page Info on the connected TagConnectionEdge */
export type TagConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the Tag type and the ContentNode type */
export type TagToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TagToContentNodeConnection';
    /** Edges for the TagToContentNodeConnection connection */
    edges: Array<TagToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TagToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'TagToContentNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;TagToContentNodeConnection&quot; */
export type TagToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TagToContentNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Tag type and the post type */
export type TagToPostConnection = Connection &
  PostConnection & {
    __typename?: 'TagToPostConnection';
    /** Edges for the TagToPostConnection connection */
    edges: Array<TagToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: TagToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'TagToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;TagToPostConnection&quot; */
export type TagToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TagToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Tag type and the Taxonomy type */
export type TagToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TagToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** A taxonomy object */
export type Taxonomy = Node & {
  __typename?: 'Taxonomy';
  /** List of Content Types associated with the Taxonomy */
  connectedContentTypes?: Maybe<TaxonomyToContentTypeConnection>;
  /** List of Term Nodes associated with the Taxonomy */
  connectedTerms?: Maybe<TaxonomyToTermNodeConnection>;
  /** Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description */
  description?: Maybe<Scalars['String']['output']>;
  /** The plural name of the post type within the GraphQL Schema. */
  graphqlPluralName?: Maybe<Scalars['String']['output']>;
  /** The singular name of the post type within the GraphQL Schema. */
  graphqlSingleName?: Maybe<Scalars['String']['output']>;
  /** Whether the taxonomy is hierarchical */
  hierarchical?: Maybe<Scalars['Boolean']['output']>;
  /** The globally unique identifier of the taxonomy object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the taxonomy shown in the menu. Usually plural. */
  label?: Maybe<Scalars['String']['output']>;
  /** The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label */
  name?: Maybe<Scalars['String']['output']>;
  /** Whether the taxonomy is publicly queryable */
  public?: Maybe<Scalars['Boolean']['output']>;
  /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
  restBase?: Maybe<Scalars['String']['output']>;
  /** The REST Controller class assigned to handling this content type. */
  restControllerClass?: Maybe<Scalars['String']['output']>;
  /** Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud */
  showCloud?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to display a column for the taxonomy on its post type listing screens. */
  showInAdminColumn?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to add the post type to the GraphQL Schema. */
  showInGraphql?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the taxonomy in the admin menu */
  showInMenu?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the taxonomy is available for selection in navigation menus. */
  showInNavMenus?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to show the taxonomy in the quick/bulk edit panel. */
  showInQuickEdit?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace. */
  showInRest?: Maybe<Scalars['Boolean']['output']>;
  /** Whether to generate and allow a UI for managing terms in this taxonomy in the admin */
  showUi?: Maybe<Scalars['Boolean']['output']>;
};

/** A taxonomy object */
export type TaxonomyConnectedContentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A taxonomy object */
export type TaxonomyConnectedTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategory = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'TaxonomyCategory';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<TaxonomyCategoryToAncestorsTaxonomyCategoryConnection>;
    /** Connection between the TaxonomyCategory type and the App type */
    apps?: Maybe<TaxonomyCategoryToAppConnection>;
    /** Connection between the TaxonomyCategory type and the Checklist type */
    checklists?: Maybe<TaxonomyCategoryToChecklistConnection>;
    /** Connection between the TaxonomyCategory type and its children TaxonomyCategories. */
    children?: Maybe<TaxonomyCategoryToTaxonomyCategoryConnection>;
    /** Connection between the TaxonomyCategory type and the ContentNode type */
    contentNodes?: Maybe<TaxonomyCategoryToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** TaxonomyCategory language */
    language?: Maybe<Language>;
    /** TaxonomyCategory language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomyCategory type and its parent TaxonomyCategory. */
    parent?: Maybe<TaxonomyCategoryToParentTaxonomyCategoryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the Categories taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomyCategory type and the Taxonomy type */
    taxonomy?: Maybe<TaxonomyCategoryToTaxonomyConnectionEdge>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    taxonomyCategoryId?: Maybe<Scalars['Int']['output']>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the TaxonomyCategory type and the Topic type */
    topics?: Maybe<TaxonomyCategoryToTopicConnection>;
    /** TaxonomyCategory translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** TaxonomyCategory translations */
    translations?: Maybe<Array<Maybe<TaxonomyCategory>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The TaxonomyCategory type */
export type TaxonomyCategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyCategoryToAppConnectionWhereArgs>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryChecklistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyCategoryToChecklistConnectionWhereArgs>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyCategoryToTaxonomyCategoryConnectionWhereArgs>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyCategoryToContentNodeConnectionWhereArgs>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyCategory type */
export type TaxonomyCategoryTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyCategoryToTopicConnectionWhereArgs>;
};

/** Connection to TaxonomyCategory Nodes */
export type TaxonomyCategoryConnection = {
  /** A list of edges (relational context) between RootQuery and connected TaxonomyCategory Nodes */
  edges: Array<TaxonomyCategoryConnectionEdge>;
  /** A list of connected TaxonomyCategory Nodes */
  nodes: Array<TaxonomyCategory>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomyCategoryConnectionPageInfo;
};

/** Edge between a Node and a connected TaxonomyCategory */
export type TaxonomyCategoryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected TaxonomyCategory Node */
  node: TaxonomyCategory;
};

/** Page Info on the connected TaxonomyCategoryConnectionEdge */
export type TaxonomyCategoryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TaxonomyCategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TaxonomyCategory type and the TaxonomyCategory type */
export type TaxonomyCategoryToAncestorsTaxonomyCategoryConnection = Connection &
  TaxonomyCategoryConnection & {
    __typename?: 'TaxonomyCategoryToAncestorsTaxonomyCategoryConnection';
    /** Edges for the TaxonomyCategoryToAncestorsTaxonomyCategoryConnection connection */
    edges: Array<TaxonomyCategoryToAncestorsTaxonomyCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyCategory>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyCategoryToAncestorsTaxonomyCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyCategoryToAncestorsTaxonomyCategoryConnectionEdge = Edge &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'TaxonomyCategoryToAncestorsTaxonomyCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyCategory;
  };

/** Page Info on the &quot;TaxonomyCategoryToAncestorsTaxonomyCategoryConnection&quot; */
export type TaxonomyCategoryToAncestorsTaxonomyCategoryConnectionPageInfo =
  PageInfo &
    TaxonomyCategoryConnectionPageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyCategoryToAncestorsTaxonomyCategoryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the TaxonomyCategory type and the App type */
export type TaxonomyCategoryToAppConnection = AppConnection &
  Connection & {
    __typename?: 'TaxonomyCategoryToAppConnection';
    /** Edges for the TaxonomyCategoryToAppConnection connection */
    edges: Array<TaxonomyCategoryToAppConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<App>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyCategoryToAppConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyCategoryToAppConnectionEdge = AppConnectionEdge &
  Edge & {
    __typename?: 'TaxonomyCategoryToAppConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: App;
  };

/** Page Info on the &quot;TaxonomyCategoryToAppConnection&quot; */
export type TaxonomyCategoryToAppConnectionPageInfo = AppConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyCategoryToAppConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomyCategoryToAppConnection connection */
export type TaxonomyCategoryToAppConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyCategory type and the Checklist type */
export type TaxonomyCategoryToChecklistConnection = ChecklistConnection &
  Connection & {
    __typename?: 'TaxonomyCategoryToChecklistConnection';
    /** Edges for the TaxonomyCategoryToChecklistConnection connection */
    edges: Array<TaxonomyCategoryToChecklistConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Checklist>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyCategoryToChecklistConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyCategoryToChecklistConnectionEdge =
  ChecklistConnectionEdge &
    Edge & {
      __typename?: 'TaxonomyCategoryToChecklistConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: Checklist;
    };

/** Page Info on the &quot;TaxonomyCategoryToChecklistConnection&quot; */
export type TaxonomyCategoryToChecklistConnectionPageInfo =
  ChecklistConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyCategoryToChecklistConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomyCategoryToChecklistConnection connection */
export type TaxonomyCategoryToChecklistConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyCategory type and the ContentNode type */
export type TaxonomyCategoryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TaxonomyCategoryToContentNodeConnection';
    /** Edges for the TaxonomyCategoryToContentNodeConnection connection */
    edges: Array<TaxonomyCategoryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyCategoryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyCategoryToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'TaxonomyCategoryToContentNodeConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;TaxonomyCategoryToContentNodeConnection&quot; */
export type TaxonomyCategoryToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyCategoryToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomyCategoryToContentNodeConnection connection */
export type TaxonomyCategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfTaxonomyCategoryEnum>>
  >;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyCategory type and the TaxonomyCategory type */
export type TaxonomyCategoryToParentTaxonomyCategoryConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'TaxonomyCategoryToParentTaxonomyCategoryConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: TaxonomyCategory;
  };

/** Connection between the TaxonomyCategory type and the TaxonomyCategory type */
export type TaxonomyCategoryToTaxonomyCategoryConnection = Connection &
  TaxonomyCategoryConnection & {
    __typename?: 'TaxonomyCategoryToTaxonomyCategoryConnection';
    /** Edges for the TaxonomyCategoryToTaxonomyCategoryConnection connection */
    edges: Array<TaxonomyCategoryToTaxonomyCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyCategory>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyCategoryToTaxonomyCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyCategoryToTaxonomyCategoryConnectionEdge = Edge &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'TaxonomyCategoryToTaxonomyCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyCategory;
  };

/** Page Info on the &quot;TaxonomyCategoryToTaxonomyCategoryConnection&quot; */
export type TaxonomyCategoryToTaxonomyCategoryConnectionPageInfo = PageInfo &
  TaxonomyCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyCategoryToTaxonomyCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomyCategoryToTaxonomyCategoryConnection connection */
export type TaxonomyCategoryToTaxonomyCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the TaxonomyCategory type and the Taxonomy type */
export type TaxonomyCategoryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TaxonomyCategoryToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Connection between the TaxonomyCategory type and the Topic type */
export type TaxonomyCategoryToTopicConnection = Connection &
  TopicConnection & {
    __typename?: 'TaxonomyCategoryToTopicConnection';
    /** Edges for the TaxonomyCategoryToTopicConnection connection */
    edges: Array<TaxonomyCategoryToTopicConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Topic>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyCategoryToTopicConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyCategoryToTopicConnectionEdge = Edge &
  TopicConnectionEdge & {
    __typename?: 'TaxonomyCategoryToTopicConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Topic;
  };

/** Page Info on the &quot;TaxonomyCategoryToTopicConnection&quot; */
export type TaxonomyCategoryToTopicConnectionPageInfo = PageInfo &
  TopicConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyCategoryToTopicConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomyCategoryToTopicConnection connection */
export type TaxonomyCategoryToTopicConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection to Taxonomy Nodes */
export type TaxonomyConnection = {
  /** A list of edges (relational context) between RootQuery and connected Taxonomy Nodes */
  edges: Array<TaxonomyConnectionEdge>;
  /** A list of connected Taxonomy Nodes */
  nodes: Array<Taxonomy>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomyConnectionPageInfo;
};

/** Edge between a Node and a connected Taxonomy */
export type TaxonomyConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Taxonomy Node */
  node: Taxonomy;
};

/** Page Info on the connected TaxonomyConnectionEdge */
export type TaxonomyConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  Category = 'CATEGORY',
  /** Taxonomy enum graphql_document_group */
  Graphqldocumentgroup = 'GRAPHQLDOCUMENTGROUP',
  /** Taxonomy enum post_format */
  Postformat = 'POSTFORMAT',
  /** Taxonomy enum post_tag */
  Tag = 'TAG',
  /** Taxonomy enum categories */
  Taxonomycategory = 'TAXONOMYCATEGORY',
  /** Taxonomy enum industry */
  Taxonomyindustry = 'TAXONOMYINDUSTRY',
  /** Taxonomy enum region */
  Taxonomyregion = 'TAXONOMYREGION',
  /** Taxonomy enum solution */
  Taxonomysolution = 'TAXONOMYSOLUTION',
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the taxonomy */
  Name = 'NAME',
}

/** The TaxonomyIndustry type */
export type TaxonomyIndustry = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'TaxonomyIndustry';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<TaxonomyIndustryToAncestorsTaxonomyIndustryConnection>;
    /** Connection between the TaxonomyIndustry type and its children TaxonomyIndustries. */
    children?: Maybe<TaxonomyIndustryToTaxonomyIndustryConnection>;
    /** Connection between the TaxonomyIndustry type and the ContentNode type */
    contentNodes?: Maybe<TaxonomyIndustryToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** Connection between the TaxonomyIndustry type and the CustomerStory type */
    customerStories?: Maybe<TaxonomyIndustryToCustomerStoryConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** TaxonomyIndustry language */
    language?: Maybe<Language>;
    /** TaxonomyIndustry language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomyIndustry type and its parent TaxonomyIndustry. */
    parent?: Maybe<TaxonomyIndustryToParentTaxonomyIndustryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the Industries taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomyIndustry type and the Taxonomy type */
    taxonomy?: Maybe<TaxonomyIndustryToTaxonomyConnectionEdge>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    taxonomyIndustryId?: Maybe<Scalars['Int']['output']>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** Connection between the TaxonomyIndustry type and the Testimonial type */
    testimonials?: Maybe<TaxonomyIndustryToTestimonialConnection>;
    /** TaxonomyIndustry translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** TaxonomyIndustry translations */
    translations?: Maybe<Array<Maybe<TaxonomyIndustry>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The TaxonomyIndustry type */
export type TaxonomyIndustryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyIndustry type */
export type TaxonomyIndustryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyIndustryToTaxonomyIndustryConnectionWhereArgs>;
};

/** The TaxonomyIndustry type */
export type TaxonomyIndustryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyIndustryToContentNodeConnectionWhereArgs>;
};

/** The TaxonomyIndustry type */
export type TaxonomyIndustryCustomerStoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyIndustryToCustomerStoryConnectionWhereArgs>;
};

/** The TaxonomyIndustry type */
export type TaxonomyIndustryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyIndustry type */
export type TaxonomyIndustryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyIndustry type */
export type TaxonomyIndustryTestimonialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyIndustryToTestimonialConnectionWhereArgs>;
};

/** Connection to TaxonomyIndustry Nodes */
export type TaxonomyIndustryConnection = {
  /** A list of edges (relational context) between RootQuery and connected TaxonomyIndustry Nodes */
  edges: Array<TaxonomyIndustryConnectionEdge>;
  /** A list of connected TaxonomyIndustry Nodes */
  nodes: Array<TaxonomyIndustry>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomyIndustryConnectionPageInfo;
};

/** Edge between a Node and a connected TaxonomyIndustry */
export type TaxonomyIndustryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected TaxonomyIndustry Node */
  node: TaxonomyIndustry;
};

/** Page Info on the connected TaxonomyIndustryConnectionEdge */
export type TaxonomyIndustryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TaxonomyIndustryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TaxonomyIndustry type and the TaxonomyIndustry type */
export type TaxonomyIndustryToAncestorsTaxonomyIndustryConnection = Connection &
  TaxonomyIndustryConnection & {
    __typename?: 'TaxonomyIndustryToAncestorsTaxonomyIndustryConnection';
    /** Edges for the TaxonomyIndustryToAncestorsTaxonomyIndustryConnection connection */
    edges: Array<TaxonomyIndustryToAncestorsTaxonomyIndustryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyIndustry>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyIndustryToAncestorsTaxonomyIndustryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyIndustryToAncestorsTaxonomyIndustryConnectionEdge = Edge &
  TaxonomyIndustryConnectionEdge & {
    __typename?: 'TaxonomyIndustryToAncestorsTaxonomyIndustryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyIndustry;
  };

/** Page Info on the &quot;TaxonomyIndustryToAncestorsTaxonomyIndustryConnection&quot; */
export type TaxonomyIndustryToAncestorsTaxonomyIndustryConnectionPageInfo =
  PageInfo &
    TaxonomyIndustryConnectionPageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyIndustryToAncestorsTaxonomyIndustryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the TaxonomyIndustry type and the ContentNode type */
export type TaxonomyIndustryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TaxonomyIndustryToContentNodeConnection';
    /** Edges for the TaxonomyIndustryToContentNodeConnection connection */
    edges: Array<TaxonomyIndustryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyIndustryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyIndustryToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'TaxonomyIndustryToContentNodeConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;TaxonomyIndustryToContentNodeConnection&quot; */
export type TaxonomyIndustryToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyIndustryToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomyIndustryToContentNodeConnection connection */
export type TaxonomyIndustryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfTaxonomyIndustryEnum>>
  >;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyIndustry type and the CustomerStory type */
export type TaxonomyIndustryToCustomerStoryConnection = Connection &
  CustomerStoryConnection & {
    __typename?: 'TaxonomyIndustryToCustomerStoryConnection';
    /** Edges for the TaxonomyIndustryToCustomerStoryConnection connection */
    edges: Array<TaxonomyIndustryToCustomerStoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CustomerStory>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyIndustryToCustomerStoryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyIndustryToCustomerStoryConnectionEdge =
  CustomerStoryConnectionEdge &
    Edge & {
      __typename?: 'TaxonomyIndustryToCustomerStoryConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: CustomerStory;
    };

/** Page Info on the &quot;TaxonomyIndustryToCustomerStoryConnection&quot; */
export type TaxonomyIndustryToCustomerStoryConnectionPageInfo =
  CustomerStoryConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyIndustryToCustomerStoryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomyIndustryToCustomerStoryConnection connection */
export type TaxonomyIndustryToCustomerStoryConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  wpmlLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyIndustry type and the TaxonomyIndustry type */
export type TaxonomyIndustryToParentTaxonomyIndustryConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyIndustryConnectionEdge & {
    __typename?: 'TaxonomyIndustryToParentTaxonomyIndustryConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: TaxonomyIndustry;
  };

/** Connection between the TaxonomyIndustry type and the Taxonomy type */
export type TaxonomyIndustryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TaxonomyIndustryToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Connection between the TaxonomyIndustry type and the TaxonomyIndustry type */
export type TaxonomyIndustryToTaxonomyIndustryConnection = Connection &
  TaxonomyIndustryConnection & {
    __typename?: 'TaxonomyIndustryToTaxonomyIndustryConnection';
    /** Edges for the TaxonomyIndustryToTaxonomyIndustryConnection connection */
    edges: Array<TaxonomyIndustryToTaxonomyIndustryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyIndustry>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyIndustryToTaxonomyIndustryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyIndustryToTaxonomyIndustryConnectionEdge = Edge &
  TaxonomyIndustryConnectionEdge & {
    __typename?: 'TaxonomyIndustryToTaxonomyIndustryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyIndustry;
  };

/** Page Info on the &quot;TaxonomyIndustryToTaxonomyIndustryConnection&quot; */
export type TaxonomyIndustryToTaxonomyIndustryConnectionPageInfo = PageInfo &
  TaxonomyIndustryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyIndustryToTaxonomyIndustryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomyIndustryToTaxonomyIndustryConnection connection */
export type TaxonomyIndustryToTaxonomyIndustryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the TaxonomyIndustry type and the Testimonial type */
export type TaxonomyIndustryToTestimonialConnection = Connection &
  TestimonialConnection & {
    __typename?: 'TaxonomyIndustryToTestimonialConnection';
    /** Edges for the TaxonomyIndustryToTestimonialConnection connection */
    edges: Array<TaxonomyIndustryToTestimonialConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Testimonial>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyIndustryToTestimonialConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyIndustryToTestimonialConnectionEdge = Edge &
  TestimonialConnectionEdge & {
    __typename?: 'TaxonomyIndustryToTestimonialConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Testimonial;
  };

/** Page Info on the &quot;TaxonomyIndustryToTestimonialConnection&quot; */
export type TaxonomyIndustryToTestimonialConnectionPageInfo = PageInfo &
  TestimonialConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyIndustryToTestimonialConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomyIndustryToTestimonialConnection connection */
export type TaxonomyIndustryToTestimonialConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The TaxonomyRegion type */
export type TaxonomyRegion = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'TaxonomyRegion';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<TaxonomyRegionToAncestorsTaxonomyRegionConnection>;
    /** Connection between the TaxonomyRegion type and its children TaxonomyRegions. */
    children?: Maybe<TaxonomyRegionToTaxonomyRegionConnection>;
    /** Connection between the TaxonomyRegion type and the ContentNode type */
    contentNodes?: Maybe<TaxonomyRegionToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** Connection between the TaxonomyRegion type and the CustomerStory type */
    customerStories?: Maybe<TaxonomyRegionToCustomerStoryConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** TaxonomyRegion language */
    language?: Maybe<Language>;
    /** TaxonomyRegion language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomyRegion type and its parent TaxonomyRegion. */
    parent?: Maybe<TaxonomyRegionToParentTaxonomyRegionConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the Regions taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomyRegion type and the Taxonomy type */
    taxonomy?: Maybe<TaxonomyRegionToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    taxonomyRegionId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** TaxonomyRegion translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** TaxonomyRegion translations */
    translations?: Maybe<Array<Maybe<TaxonomyRegion>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The TaxonomyRegion type */
export type TaxonomyRegionAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyRegion type */
export type TaxonomyRegionChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyRegionToTaxonomyRegionConnectionWhereArgs>;
};

/** The TaxonomyRegion type */
export type TaxonomyRegionContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyRegionToContentNodeConnectionWhereArgs>;
};

/** The TaxonomyRegion type */
export type TaxonomyRegionCustomerStoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomyRegionToCustomerStoryConnectionWhereArgs>;
};

/** The TaxonomyRegion type */
export type TaxonomyRegionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomyRegion type */
export type TaxonomyRegionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to TaxonomyRegion Nodes */
export type TaxonomyRegionConnection = {
  /** A list of edges (relational context) between RootQuery and connected TaxonomyRegion Nodes */
  edges: Array<TaxonomyRegionConnectionEdge>;
  /** A list of connected TaxonomyRegion Nodes */
  nodes: Array<TaxonomyRegion>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomyRegionConnectionPageInfo;
};

/** Edge between a Node and a connected TaxonomyRegion */
export type TaxonomyRegionConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected TaxonomyRegion Node */
  node: TaxonomyRegion;
};

/** Page Info on the connected TaxonomyRegionConnectionEdge */
export type TaxonomyRegionConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TaxonomyRegionIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TaxonomyRegion type and the TaxonomyRegion type */
export type TaxonomyRegionToAncestorsTaxonomyRegionConnection = Connection &
  TaxonomyRegionConnection & {
    __typename?: 'TaxonomyRegionToAncestorsTaxonomyRegionConnection';
    /** Edges for the TaxonomyRegionToAncestorsTaxonomyRegionConnection connection */
    edges: Array<TaxonomyRegionToAncestorsTaxonomyRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyRegion>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyRegionToAncestorsTaxonomyRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyRegionToAncestorsTaxonomyRegionConnectionEdge = Edge &
  TaxonomyRegionConnectionEdge & {
    __typename?: 'TaxonomyRegionToAncestorsTaxonomyRegionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyRegion;
  };

/** Page Info on the &quot;TaxonomyRegionToAncestorsTaxonomyRegionConnection&quot; */
export type TaxonomyRegionToAncestorsTaxonomyRegionConnectionPageInfo =
  PageInfo &
    TaxonomyRegionConnectionPageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyRegionToAncestorsTaxonomyRegionConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the TaxonomyRegion type and the ContentNode type */
export type TaxonomyRegionToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TaxonomyRegionToContentNodeConnection';
    /** Edges for the TaxonomyRegionToContentNodeConnection connection */
    edges: Array<TaxonomyRegionToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyRegionToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyRegionToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'TaxonomyRegionToContentNodeConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;TaxonomyRegionToContentNodeConnection&quot; */
export type TaxonomyRegionToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyRegionToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomyRegionToContentNodeConnection connection */
export type TaxonomyRegionToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfTaxonomyRegionEnum>>
  >;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyRegion type and the CustomerStory type */
export type TaxonomyRegionToCustomerStoryConnection = Connection &
  CustomerStoryConnection & {
    __typename?: 'TaxonomyRegionToCustomerStoryConnection';
    /** Edges for the TaxonomyRegionToCustomerStoryConnection connection */
    edges: Array<TaxonomyRegionToCustomerStoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CustomerStory>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyRegionToCustomerStoryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyRegionToCustomerStoryConnectionEdge =
  CustomerStoryConnectionEdge &
    Edge & {
      __typename?: 'TaxonomyRegionToCustomerStoryConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: CustomerStory;
    };

/** Page Info on the &quot;TaxonomyRegionToCustomerStoryConnection&quot; */
export type TaxonomyRegionToCustomerStoryConnectionPageInfo =
  CustomerStoryConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyRegionToCustomerStoryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomyRegionToCustomerStoryConnection connection */
export type TaxonomyRegionToCustomerStoryConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomyRegion type and the TaxonomyRegion type */
export type TaxonomyRegionToParentTaxonomyRegionConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyRegionConnectionEdge & {
    __typename?: 'TaxonomyRegionToParentTaxonomyRegionConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: TaxonomyRegion;
  };

/** Connection between the TaxonomyRegion type and the Taxonomy type */
export type TaxonomyRegionToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TaxonomyRegionToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Connection between the TaxonomyRegion type and the TaxonomyRegion type */
export type TaxonomyRegionToTaxonomyRegionConnection = Connection &
  TaxonomyRegionConnection & {
    __typename?: 'TaxonomyRegionToTaxonomyRegionConnection';
    /** Edges for the TaxonomyRegionToTaxonomyRegionConnection connection */
    edges: Array<TaxonomyRegionToTaxonomyRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyRegion>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyRegionToTaxonomyRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyRegionToTaxonomyRegionConnectionEdge = Edge &
  TaxonomyRegionConnectionEdge & {
    __typename?: 'TaxonomyRegionToTaxonomyRegionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyRegion;
  };

/** Page Info on the &quot;TaxonomyRegionToTaxonomyRegionConnection&quot; */
export type TaxonomyRegionToTaxonomyRegionConnectionPageInfo = PageInfo &
  TaxonomyRegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyRegionToTaxonomyRegionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomyRegionToTaxonomyRegionConnection connection */
export type TaxonomyRegionToTaxonomyRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaxonomySeo = {
  __typename?: 'TaxonomySEO';
  breadcrumbs?: Maybe<Array<Maybe<SeoPostTypeBreadcrumbs>>>;
  canonical?: Maybe<Scalars['String']['output']>;
  cornerstone?: Maybe<Scalars['Boolean']['output']>;
  focuskw?: Maybe<Scalars['String']['output']>;
  fullHead?: Maybe<Scalars['String']['output']>;
  metaDesc?: Maybe<Scalars['String']['output']>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  metaRobotsNofollow?: Maybe<Scalars['String']['output']>;
  metaRobotsNoindex?: Maybe<Scalars['String']['output']>;
  opengraphAuthor?: Maybe<Scalars['String']['output']>;
  opengraphDescription?: Maybe<Scalars['String']['output']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<Scalars['String']['output']>;
  opengraphPublishedTime?: Maybe<Scalars['String']['output']>;
  opengraphPublisher?: Maybe<Scalars['String']['output']>;
  opengraphSiteName?: Maybe<Scalars['String']['output']>;
  opengraphTitle?: Maybe<Scalars['String']['output']>;
  opengraphType?: Maybe<Scalars['String']['output']>;
  opengraphUrl?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<SeoTaxonomySchema>;
  title?: Maybe<Scalars['String']['output']>;
  twitterDescription?: Maybe<Scalars['String']['output']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']['output']>;
};

/** The TaxonomySolution type */
export type TaxonomySolution = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'TaxonomySolution';
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<TaxonomySolutionToAncestorsTaxonomySolutionConnection>;
    /** Connection between the TaxonomySolution type and its children TaxonomySolutions. */
    children?: Maybe<TaxonomySolutionToTaxonomySolutionConnection>;
    /** Connection between the TaxonomySolution type and the ContentNode type */
    contentNodes?: Maybe<TaxonomySolutionToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars['Int']['output']>;
    /** Connection between the TaxonomySolution type and the CustomerStory type */
    customerStories?: Maybe<TaxonomySolutionToCustomerStoryConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** The description of the object */
    description?: Maybe<Scalars['String']['output']>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The globally unique ID for the object */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** TaxonomySolution language */
    language?: Maybe<Language>;
    /** TaxonomySolution language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The link to the term */
    link?: Maybe<Scalars['String']['output']>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomySolution type and its parent TaxonomySolution. */
    parent?: Maybe<TaxonomySolutionToParentTaxonomySolutionConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the Solutions taxonomy. */
    seo?: Maybe<TaxonomySeo>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the TaxonomySolution type and the Taxonomy type */
    taxonomy?: Maybe<TaxonomySolutionToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    taxonomySolutionId?: Maybe<Scalars['Int']['output']>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars['Int']['output']>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars['Int']['output']>;
    /** TaxonomySolution translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** TaxonomySolution translations */
    translations?: Maybe<Array<Maybe<TaxonomySolution>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The TaxonomySolution type */
export type TaxonomySolutionAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomySolution type */
export type TaxonomySolutionChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomySolutionToTaxonomySolutionConnectionWhereArgs>;
};

/** The TaxonomySolution type */
export type TaxonomySolutionContentNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomySolutionToContentNodeConnectionWhereArgs>;
};

/** The TaxonomySolution type */
export type TaxonomySolutionCustomerStoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaxonomySolutionToCustomerStoryConnectionWhereArgs>;
};

/** The TaxonomySolution type */
export type TaxonomySolutionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The TaxonomySolution type */
export type TaxonomySolutionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to TaxonomySolution Nodes */
export type TaxonomySolutionConnection = {
  /** A list of edges (relational context) between RootQuery and connected TaxonomySolution Nodes */
  edges: Array<TaxonomySolutionConnectionEdge>;
  /** A list of connected TaxonomySolution Nodes */
  nodes: Array<TaxonomySolution>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomySolutionConnectionPageInfo;
};

/** Edge between a Node and a connected TaxonomySolution */
export type TaxonomySolutionConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected TaxonomySolution Node */
  node: TaxonomySolution;
};

/** Page Info on the connected TaxonomySolutionConnectionEdge */
export type TaxonomySolutionConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TaxonomySolutionIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TaxonomySolution type and the TaxonomySolution type */
export type TaxonomySolutionToAncestorsTaxonomySolutionConnection = Connection &
  TaxonomySolutionConnection & {
    __typename?: 'TaxonomySolutionToAncestorsTaxonomySolutionConnection';
    /** Edges for the TaxonomySolutionToAncestorsTaxonomySolutionConnection connection */
    edges: Array<TaxonomySolutionToAncestorsTaxonomySolutionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomySolution>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomySolutionToAncestorsTaxonomySolutionConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomySolutionToAncestorsTaxonomySolutionConnectionEdge = Edge &
  TaxonomySolutionConnectionEdge & {
    __typename?: 'TaxonomySolutionToAncestorsTaxonomySolutionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomySolution;
  };

/** Page Info on the &quot;TaxonomySolutionToAncestorsTaxonomySolutionConnection&quot; */
export type TaxonomySolutionToAncestorsTaxonomySolutionConnectionPageInfo =
  PageInfo &
    TaxonomySolutionConnectionPageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomySolutionToAncestorsTaxonomySolutionConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the TaxonomySolution type and the ContentNode type */
export type TaxonomySolutionToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TaxonomySolutionToContentNodeConnection';
    /** Edges for the TaxonomySolutionToContentNodeConnection connection */
    edges: Array<TaxonomySolutionToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomySolutionToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomySolutionToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'TaxonomySolutionToContentNodeConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;TaxonomySolutionToContentNodeConnection&quot; */
export type TaxonomySolutionToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomySolutionToContentNodeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomySolutionToContentNodeConnection connection */
export type TaxonomySolutionToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<
    Array<InputMaybe<ContentTypesOfTaxonomySolutionEnum>>
  >;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomySolution type and the CustomerStory type */
export type TaxonomySolutionToCustomerStoryConnection = Connection &
  CustomerStoryConnection & {
    __typename?: 'TaxonomySolutionToCustomerStoryConnection';
    /** Edges for the TaxonomySolutionToCustomerStoryConnection connection */
    edges: Array<TaxonomySolutionToCustomerStoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CustomerStory>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomySolutionToCustomerStoryConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomySolutionToCustomerStoryConnectionEdge =
  CustomerStoryConnectionEdge &
    Edge & {
      __typename?: 'TaxonomySolutionToCustomerStoryConnectionEdge';
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars['String']['output']>;
      /** The item at the end of the edge */
      node: CustomerStory;
    };

/** Page Info on the &quot;TaxonomySolutionToCustomerStoryConnection&quot; */
export type TaxonomySolutionToCustomerStoryConnectionPageInfo =
  CustomerStoryConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomySolutionToCustomerStoryConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Arguments for filtering the TaxonomySolutionToCustomerStoryConnection connection */
export type TaxonomySolutionToCustomerStoryConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the TaxonomySolution type and the TaxonomySolution type */
export type TaxonomySolutionToParentTaxonomySolutionConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomySolutionConnectionEdge & {
    __typename?: 'TaxonomySolutionToParentTaxonomySolutionConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: TaxonomySolution;
  };

/** Connection between the TaxonomySolution type and the Taxonomy type */
export type TaxonomySolutionToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TaxonomySolutionToTaxonomyConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Connection between the TaxonomySolution type and the TaxonomySolution type */
export type TaxonomySolutionToTaxonomySolutionConnection = Connection &
  TaxonomySolutionConnection & {
    __typename?: 'TaxonomySolutionToTaxonomySolutionConnection';
    /** Edges for the TaxonomySolutionToTaxonomySolutionConnection connection */
    edges: Array<TaxonomySolutionToTaxonomySolutionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomySolution>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomySolutionToTaxonomySolutionConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomySolutionToTaxonomySolutionConnectionEdge = Edge &
  TaxonomySolutionConnectionEdge & {
    __typename?: 'TaxonomySolutionToTaxonomySolutionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TaxonomySolution;
  };

/** Page Info on the &quot;TaxonomySolutionToTaxonomySolutionConnection&quot; */
export type TaxonomySolutionToTaxonomySolutionConnectionPageInfo = PageInfo &
  TaxonomySolutionConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomySolutionToTaxonomySolutionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TaxonomySolutionToTaxonomySolutionConnection connection */
export type TaxonomySolutionToTaxonomySolutionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Taxonomy type and the ContentType type */
export type TaxonomyToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: 'TaxonomyToContentTypeConnection';
    /** Edges for the TaxonomyToContentTypeConnection connection */
    edges: Array<TaxonomyToContentTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentType>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyToContentTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: 'TaxonomyToContentTypeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentType;
  };

/** Page Info on the &quot;TaxonomyToContentTypeConnection&quot; */
export type TaxonomyToContentTypeConnectionPageInfo =
  ContentTypeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyToContentTypeConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the Taxonomy type and the TermNode type */
export type TaxonomyToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'TaxonomyToTermNodeConnection';
    /** Edges for the TaxonomyToTermNodeConnection connection */
    edges: Array<TaxonomyToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'TaxonomyToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;TaxonomyToTermNodeConnection&quot; */
export type TaxonomyToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNode = {
  /** The number of objects connected to the object */
  count?: Maybe<Scalars['Int']['output']>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars['Int']['output'];
  /** The description of the object */
  description?: Maybe<Scalars['String']['output']>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The link to the term */
  link?: Maybe<Scalars['String']['output']>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars['String']['output']>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars['String']['output']>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars['String']['output']>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars['Int']['output']>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars['Int']['output']>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection to TermNode Nodes */
export type TermNodeConnection = {
  /** A list of edges (relational context) between RootQuery and connected TermNode Nodes */
  edges: Array<TermNodeConnectionEdge>;
  /** A list of connected TermNode Nodes */
  nodes: Array<TermNode>;
  /** Information about pagination in a connection. */
  pageInfo: TermNodeConnectionPageInfo;
};

/** Edge between a Node and a connected TermNode */
export type TermNodeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected TermNode Node */
  node: TermNode;
};

/** Page Info on the connected TermNodeConnectionEdge */
export type TermNodeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Connection between the TermNode type and the EnqueuedScript type */
export type TermNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'TermNodeToEnqueuedScriptConnection';
    /** Edges for the TermNodeToEnqueuedScriptConnection connection */
    edges: Array<TermNodeToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: TermNodeToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type TermNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'TermNodeToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;TermNodeToEnqueuedScriptConnection&quot; */
export type TermNodeToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TermNodeToEnqueuedScriptConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the TermNode type and the EnqueuedStylesheet type */
export type TermNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnection';
    /** Edges for the TermNodeToEnqueuedStylesheetConnection connection */
    edges: Array<TermNodeToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type TermNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;TermNodeToEnqueuedStylesheetConnection&quot; */
export type TermNodeToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TermNodeToEnqueuedStylesheetConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = 'COUNT',
  /** Order the connection by description. */
  Description = 'DESCRIPTION',
  /** Order the connection by name. */
  Name = 'NAME',
  /** Order the connection by slug. */
  Slug = 'SLUG',
  /** Order the connection by term group. */
  TermGroup = 'TERM_GROUP',
  /** Order the connection by term id. */
  TermId = 'TERM_ID',
  /** Order the connection by term order. */
  TermOrder = 'TERM_ORDER',
}

/** The Testimonial type */
export type Testimonial = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Testimonial';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Testimonial_Acfpagestatus>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the testimonial object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the testimonial object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The password for the testimonial object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Testimonial type and the Testimonial type */
    preview?: Maybe<TestimonialToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** Connection between the Testimonial type and the TaxonomyIndustry type */
    taxonomyIndustries?: Maybe<TestimonialToTaxonomyIndustryConnection>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Testimonial type and the TermNode type */
    terms?: Maybe<TestimonialToTermNodeConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Testimonial&quot; was set to Show in GraphQL. */
    testimonial?: Maybe<Testimonial_Testimonial>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    testimonialId: Scalars['Int']['output'];
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Testimonial>>>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Testimonial type */
export type TestimonialEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Testimonial type */
export type TestimonialEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Testimonial type */
export type TestimonialTaxonomyIndustriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TestimonialToTaxonomyIndustryConnectionWhereArgs>;
};

/** The Testimonial type */
export type TestimonialTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TestimonialToTermNodeConnectionWhereArgs>;
};

/** The Testimonial type */
export type TestimonialTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Testimonial Nodes */
export type TestimonialConnection = {
  /** A list of edges (relational context) between RootQuery and connected Testimonial Nodes */
  edges: Array<TestimonialConnectionEdge>;
  /** A list of connected Testimonial Nodes */
  nodes: Array<Testimonial>;
  /** Information about pagination in a connection. */
  pageInfo: TestimonialConnectionPageInfo;
};

/** Edge between a Node and a connected Testimonial */
export type TestimonialConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Testimonial Node */
  node: Testimonial;
};

/** Page Info on the connected TestimonialConnectionEdge */
export type TestimonialConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TestimonialIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Testimonial to TaxonomyIndustries */
export type TestimonialTaxonomyIndustriesInput = {
  /** If true, this will append the TaxonomyIndustry to existing related TaxonomyIndustries. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<TestimonialTaxonomyIndustriesNodeInput>>>;
};

/** List of TaxonomyIndustries to connect the Testimonial to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type TestimonialTaxonomyIndustriesNodeInput = {
  /** The description of the TaxonomyIndustry. This field is used to set a description of the TaxonomyIndustry if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyIndustry. If present, this will be used to connect to the Testimonial. If no existing TaxonomyIndustry exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomyIndustry. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomyIndustry. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Testimonial type and the Testimonial type */
export type TestimonialToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  TestimonialConnectionEdge & {
    __typename?: 'TestimonialToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Testimonial;
  };

/** Connection between the Testimonial type and the TaxonomyIndustry type */
export type TestimonialToTaxonomyIndustryConnection = Connection &
  TaxonomyIndustryConnection & {
    __typename?: 'TestimonialToTaxonomyIndustryConnection';
    /** Edges for the TestimonialToTaxonomyIndustryConnection connection */
    edges: Array<TestimonialToTaxonomyIndustryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyIndustry>;
    /** Information about pagination in a connection. */
    pageInfo: TestimonialToTaxonomyIndustryConnectionPageInfo;
  };

/** An edge in a connection */
export type TestimonialToTaxonomyIndustryConnectionEdge = Edge &
  TaxonomyIndustryConnectionEdge & {
    __typename?: 'TestimonialToTaxonomyIndustryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary industry */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyIndustry;
  };

/** Page Info on the &quot;TestimonialToTaxonomyIndustryConnection&quot; */
export type TestimonialToTaxonomyIndustryConnectionPageInfo = PageInfo &
  TaxonomyIndustryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TestimonialToTaxonomyIndustryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TestimonialToTaxonomyIndustryConnection connection */
export type TestimonialToTaxonomyIndustryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Testimonial type and the TermNode type */
export type TestimonialToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'TestimonialToTermNodeConnection';
    /** Edges for the TestimonialToTermNodeConnection connection */
    edges: Array<TestimonialToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: TestimonialToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TestimonialToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'TestimonialToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;TestimonialToTermNodeConnection&quot; */
export type TestimonialToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TestimonialToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TestimonialToTermNodeConnection connection */
export type TestimonialToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Field Group */
export type Testimonial_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Testimonial_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Testimonial_Testimonial = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<Array<Maybe<Testimonial_Testimonial_Quote>>>;
};

export type Testimonial_Testimonial_Quote =
  | Testimonial_Testimonial_Quote_Author
  | Testimonial_Testimonial_Quote_Chip
  | Testimonial_Testimonial_Quote_Editor
  | Testimonial_Testimonial_Quote_Image
  | Testimonial_Testimonial_Quote_Link
  | Testimonial_Testimonial_Quote_Logo
  | Testimonial_Testimonial_Quote_Rating
  | Testimonial_Testimonial_Quote_Title
  | Testimonial_Testimonial_Quote_Video;

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Author = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Author';
  author?: Maybe<Testimonial_Testimonial_Quote_Author_Author>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Testimonial_Testimonial_Quote_Author_Author = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Author_Author';
  avatar?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Chip = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Chip';
  chip?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Editor = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Editor';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Image = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Testimonial_Testimonial_Quote_Image_Image>;
};

/** Field Group */
export type Testimonial_Testimonial_Quote_Image_Image = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Image_Image';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  image?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Link = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Link';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  link?: Maybe<AcfLink>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Logo = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Logo';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Testimonial_Testimonial_Quote_Logo_Logo>;
};

/** Field Group */
export type Testimonial_Testimonial_Quote_Logo_Logo = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Logo_Logo';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  logo?: Maybe<MediaItem>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Rating = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Rating';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Title = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Title';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Video = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Video';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Array<Maybe<Testimonial_Testimonial_Quote_Video_Video>>>;
};

export type Testimonial_Testimonial_Quote_Video_Video =
  | Testimonial_Testimonial_Quote_Video_Video_Wistia
  | Testimonial_Testimonial_Quote_Video_Video_Youtube;

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Video_Video_Wistia = AcfFieldGroup & {
  __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Wistia';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  wistia?: Maybe<Testimonial_Testimonial_Quote_Video_Video_Wistia_Wistia>;
};

/** Field Group */
export type Testimonial_Testimonial_Quote_Video_Video_Wistia_Wistia =
  AcfFieldGroup & {
    __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Wistia_Wistia';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** Group within the flex field */
export type Testimonial_Testimonial_Quote_Video_Video_Youtube =
  AcfFieldGroup & {
    __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Testimonial_Testimonial_Quote_Video_Video_Youtube_Youtube>;
  };

/** Field Group */
export type Testimonial_Testimonial_Quote_Video_Video_Youtube_Youtube =
  AcfFieldGroup & {
    __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Youtube_Youtube';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
  };

/** A theme object */
export type Theme = Node & {
  __typename?: 'Theme';
  /** Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ). */
  author?: Maybe<Scalars['String']['output']>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ). */
  authorUri?: Maybe<Scalars['String']['output']>;
  /** The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ). */
  description?: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier of the theme object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ). */
  name?: Maybe<Scalars['String']['output']>;
  /** The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot(). */
  screenshot?: Maybe<Scalars['String']['output']>;
  /** The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet(). */
  slug?: Maybe<Scalars['String']['output']>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ). */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ). */
  themeUri?: Maybe<Scalars['String']['output']>;
  /** The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ). */
  version?: Maybe<Scalars['String']['output']>;
};

/** Connection to Theme Nodes */
export type ThemeConnection = {
  /** A list of edges (relational context) between RootQuery and connected Theme Nodes */
  edges: Array<ThemeConnectionEdge>;
  /** A list of connected Theme Nodes */
  nodes: Array<Theme>;
  /** Information about pagination in a connection. */
  pageInfo: ThemeConnectionPageInfo;
};

/** Edge between a Node and a connected Theme */
export type ThemeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Theme Node */
  node: Theme;
};

/** Page Info on the connected ThemeConnectionEdge */
export type ThemeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Topic type */
export type Topic = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Topic';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Atoms: Multiple Authors&quot; was set to Show in GraphQL. */
    acfAuthors?: Maybe<Topic_Acfauthors>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Featured Template&quot; was set to Show in GraphQL. */
    acfFeaturedTemplate?: Maybe<Topic_Acffeaturedtemplate>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Settings: Page Status&quot; was set to Show in GraphQL. */
    acfPageStatus?: Maybe<Topic_Acfpagestatus>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Related Templates&quot; was set to Show in GraphQL. */
    acfRelatedTemplates?: Maybe<Topic_Acfrelatedtemplates>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Molecules: Related Templates (Simple)&quot; was set to Show in GraphQL. */
    acfRelatedTemplatesSimple?: Maybe<Topic_Acfrelatedtemplatessimple>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: Topic&quot; was set to Show in GraphQL. */
    acfTopic?: Maybe<Topic_Acftopic>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars['ID']['output']>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars['String']['output'];
    /** The unique identifier stored in the database */
    databaseId: Scalars['Int']['output'];
    /** Post publishing date. */
    date?: Maybe<Scalars['String']['output']>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars['String']['output']>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars['String']['output']>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars['String']['output']>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars['ID']['output']>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars['String']['output']>;
    /** Whether the topics object is password protected. */
    hasPassword?: Maybe<Scalars['Boolean']['output']>;
    /** The globally unique identifier of the topics object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** Topic language */
    language?: Maybe<Language>;
    /** Topic language code */
    languageCode?: Maybe<Scalars['String']['output']>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars['String']['output']>;
    /** WPML translation link */
    locale?: Maybe<Locale>;
    /** WPML localized url of the page/post */
    localizedWpmlUrl?: Maybe<Scalars['String']['output']>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars['Int']['output']>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars['String']['output']>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars['String']['output']>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars['ID']['output']>;
    /** The password for the topics object. */
    password?: Maybe<Scalars['String']['output']>;
    /** Connection between the Topic type and the Topic type */
    preview?: Maybe<TopicToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars['Int']['output']>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars['ID']['output']>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Topic type and the Topic type */
    revisions?: Maybe<TopicToRevisionConnection>;
    /** The Yoast SEO data of the ContentNode */
    seo?: Maybe<PostTypeSeo>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars['String']['output']>;
    /** The current status of the object */
    status?: Maybe<Scalars['String']['output']>;
    /** Connection between the Topic type and the TaxonomyCategory type */
    taxonomyCategories?: Maybe<TopicToTaxonomyCategoryConnection>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Topic type and the TermNode type */
    terms?: Maybe<TopicToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars['String']['output']>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    topicId: Scalars['Int']['output'];
    /** WPML translated versions of the same post */
    translated?: Maybe<Array<Maybe<Topic>>>;
    /** Topic translation group ID */
    translationGroupId?: Maybe<Scalars['ID']['output']>;
    /** WPML translations */
    translations?: Maybe<Array<Maybe<Translation>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
  };

/** The Topic type */
export type TopicAncestorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The Topic type */
export type TopicChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The Topic type */
export type TopicEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Topic type */
export type TopicEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The Topic type */
export type TopicRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TopicToRevisionConnectionWhereArgs>;
};

/** The Topic type */
export type TopicTaxonomyCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TopicToTaxonomyCategoryConnectionWhereArgs>;
};

/** The Topic type */
export type TopicTermsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TopicToTermNodeConnectionWhereArgs>;
};

/** The Topic type */
export type TopicTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to Topic Nodes */
export type TopicConnection = {
  /** A list of edges (relational context) between RootQuery and connected Topic Nodes */
  edges: Array<TopicConnectionEdge>;
  /** A list of connected Topic Nodes */
  nodes: Array<Topic>;
  /** Information about pagination in a connection. */
  pageInfo: TopicConnectionPageInfo;
};

/** Edge between a Node and a connected Topic */
export type TopicConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected Topic Node */
  node: Topic;
};

/** Page Info on the connected TopicConnectionEdge */
export type TopicConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TopicIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the Topic to TaxonomyCategories */
export type TopicTaxonomyCategoriesInput = {
  /** If true, this will append the TaxonomyCategory to existing related TaxonomyCategories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars['Boolean']['input']>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<TopicTaxonomyCategoriesNodeInput>>>;
};

/** List of TaxonomyCategories to connect the Topic to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type TopicTaxonomyCategoriesNodeInput = {
  /** The description of the TaxonomyCategory. This field is used to set a description of the TaxonomyCategory if a new one is created during the mutation. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyCategory. If present, this will be used to connect to the Topic. If no existing TaxonomyCategory exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the TaxonomyCategory. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the TaxonomyCategory. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Topic type and the Topic type */
export type TopicToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  TopicConnectionEdge & {
    __typename?: 'TopicToPreviewConnectionEdge';
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The node of the connection, without the edges */
    node: Topic;
  };

/** Connection between the Topic type and the Topic type */
export type TopicToRevisionConnection = Connection &
  TopicConnection & {
    __typename?: 'TopicToRevisionConnection';
    /** Edges for the TopicToRevisionConnection connection */
    edges: Array<TopicToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Topic>;
    /** Information about pagination in a connection. */
    pageInfo: TopicToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type TopicToRevisionConnectionEdge = Edge &
  TopicConnectionEdge & {
    __typename?: 'TopicToRevisionConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Topic;
  };

/** Page Info on the &quot;TopicToRevisionConnection&quot; */
export type TopicToRevisionConnectionPageInfo = PageInfo &
  TopicConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TopicToRevisionConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TopicToRevisionConnection connection */
export type TopicToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the Topic type and the TaxonomyCategory type */
export type TopicToTaxonomyCategoryConnection = Connection &
  TaxonomyCategoryConnection & {
    __typename?: 'TopicToTaxonomyCategoryConnection';
    /** Edges for the TopicToTaxonomyCategoryConnection connection */
    edges: Array<TopicToTaxonomyCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TaxonomyCategory>;
    /** Information about pagination in a connection. */
    pageInfo: TopicToTaxonomyCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type TopicToTaxonomyCategoryConnectionEdge = Edge &
  TaxonomyCategoryConnectionEdge & {
    __typename?: 'TopicToTaxonomyCategoryConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The Yoast SEO Primary categories */
    isPrimary?: Maybe<Scalars['Boolean']['output']>;
    /** The item at the end of the edge */
    node: TaxonomyCategory;
  };

/** Page Info on the &quot;TopicToTaxonomyCategoryConnection&quot; */
export type TopicToTaxonomyCategoryConnectionPageInfo = PageInfo &
  TaxonomyCategoryConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TopicToTaxonomyCategoryConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TopicToTaxonomyCategoryConnection connection */
export type TopicToTaxonomyCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Connection between the Topic type and the TermNode type */
export type TopicToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'TopicToTermNodeConnection';
    /** Edges for the TopicToTermNodeConnection connection */
    edges: Array<TopicToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: TopicToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TopicToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'TopicToTermNodeConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;TopicToTermNodeConnection&quot; */
export type TopicToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TopicToTermNodeConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the TopicToTermNodeConnection connection */
export type TopicToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars['String']['input']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars['Int']['input']>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars['String']['input']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Field Group */
export type Topic_Acfauthors = AcfFieldGroup & {
  __typename?: 'Topic_Acfauthors';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  multipleAuthors?: Maybe<Topic_Acfauthors_MultipleAuthors>;
};

/** Field Group */
export type Topic_Acfauthors_MultipleAuthors = AcfFieldGroup & {
  __typename?: 'Topic_Acfauthors_MultipleAuthors';
  authors?: Maybe<Array<Maybe<Topic_Acfauthors_MultipleAuthors_Authors>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acfauthors_MultipleAuthors_Authors = AcfFieldGroup & {
  __typename?: 'Topic_Acfauthors_MultipleAuthors_authors';
  /** Any addition authors to this post, list down below */
  authorName?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acffeaturedtemplate = AcfFieldGroup & {
  __typename?: 'Topic_Acffeaturedtemplate';
  featuredTemplate?: Maybe<Topic_Acffeaturedtemplate_FeaturedTemplate>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acffeaturedtemplate_FeaturedTemplate = AcfFieldGroup & {
  __typename?: 'Topic_Acffeaturedtemplate_FeaturedTemplate';
  cta?: Maybe<Topic_Acffeaturedtemplate_FeaturedTemplate_Cta>;
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<MediaItem>;
  previewLinks?: Maybe<Topic_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acffeaturedtemplate_FeaturedTemplate_Cta = AcfFieldGroup & {
  __typename?: 'Topic_Acffeaturedtemplate_FeaturedTemplate_Cta';
  ctaText?: Maybe<Scalars['String']['output']>;
  ctaUrl?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks =
  AcfFieldGroup & {
    __typename?: 'Topic_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks';
    digitalReport?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    pdfReport?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfpagestatus = AcfFieldGroup & {
  __typename?: 'Topic_Acfpagestatus';
  /**
   * Set to &quot;Yes&quot; in order to have the page in Draft mode, this would set the page to &quot;noindex&quot; and exclude it from Archive pages such as &quot;/checklists/&quot;, &quot;/topics/&quot; etc. &lt;br/&gt;
   * Use it to imitate the default WordPress &quot;Draft&quot; post status. &lt;/br&gt;
   * &lt;strong style=&quot;color: red;&quot;&gt;To render the page in &quot;Draft&quot; mode you still have to set the status to &quot;Publish&quot;&lt;/strong&gt;
   */
  draftPublic?: Maybe<Scalars['Boolean']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acfrelatedtemplates = AcfFieldGroup & {
  __typename?: 'Topic_Acfrelatedtemplates';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  relatedTemplatesEditor?: Maybe<Scalars['String']['output']>;
  relatedTemplatesTemplates?: Maybe<
    Array<Maybe<Topic_Acfrelatedtemplates_RelatedTemplatesTemplates>>
  >;
  relatedTemplatesTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acfrelatedtemplates_RelatedTemplatesTemplates =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplates_relatedTemplatesTemplates';
    cta?: Maybe<Topic_Acfrelatedtemplates_RelatedTemplatesTemplates_Cta>;
    downloadType?: Maybe<Scalars['String']['output']>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    previewLinks?: Maybe<Topic_Acfrelatedtemplates_RelatedTemplatesTemplates_PreviewLinks>;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfrelatedtemplates_RelatedTemplatesTemplates_Cta =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplates_relatedTemplatesTemplates_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfrelatedtemplates_RelatedTemplatesTemplates_PreviewLinks =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplates_relatedTemplatesTemplates_PreviewLinks';
    digitalReport?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    pdfReport?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfrelatedtemplatessimple = AcfFieldGroup & {
  __typename?: 'Topic_Acfrelatedtemplatessimple';
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  relatedTemplatesTemplates?: Maybe<
    Array<Maybe<Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates>>
  >;
  relatedTemplatesTitle?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates';
    cta?: Maybe<Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Cta>;
    editor?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    links?: Maybe<
      Array<
        Maybe<Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links>
      >
    >;
    title?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Cta =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates_Cta';
    ctaText?: Maybe<Scalars['String']['output']>;
    ctaUrl?: Maybe<Scalars['String']['output']>;
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    templateId?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links_Icon>;
    link?: Maybe<AcfLink>;
    style?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acfrelatedtemplatessimple_RelatedTemplatesTemplates_Links_Icon =
  AcfFieldGroup & {
    __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links_Icon';
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars['String']['output']>;
    icon?: Maybe<Scalars['String']['output']>;
    position?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type Topic_Acftopic = AcfFieldGroup & {
  __typename?: 'Topic_Acftopic';
  authorShow?: Maybe<Scalars['Boolean']['output']>;
  contentEditor?: Maybe<Scalars['String']['output']>;
  faqFrequentlyAskedQuestions?: Maybe<
    Array<Maybe<Topic_Acftopic_FaqFrequentlyAskedQuestions>>
  >;
  faqTitle?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  heroEditor?: Maybe<Scalars['String']['output']>;
  heroTitle?: Maybe<Scalars['String']['output']>;
  introEditor?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type Topic_Acftopic_FaqFrequentlyAskedQuestions = AcfFieldGroup & {
  __typename?: 'Topic_Acftopic_faqFrequentlyAskedQuestions';
  editor?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Translation (WPML) */
export type Translation = {
  __typename?: 'Translation';
  /** the relative link to the translated content (WPML) */
  href?: Maybe<Scalars['String']['output']>;
  /** the id of the referenced translation (WPML) */
  id: Scalars['ID']['output'];
  /** Language code (WPML) */
  locale?: Maybe<Scalars['String']['output']>;
  /** the title of the translated page (WPML) */
  post_title?: Maybe<Scalars['String']['output']>;
};

/** Any node that has a URI */
export type UniformResourceIdentifiable = {
  /** The globally unique ID for the object */
  id: Scalars['ID']['output'];
  /** Whether the node is a Comment */
  isComment: Scalars['Boolean']['output'];
  /** Whether the node is a Content Node */
  isContentNode: Scalars['Boolean']['output'];
  /** Whether the node represents the front page. */
  isFrontPage: Scalars['Boolean']['output'];
  /** Whether  the node represents the blog page. */
  isPostsPage: Scalars['Boolean']['output'];
  /** Whether the node is a Term */
  isTermNode: Scalars['Boolean']['output'];
  /** The unique resource identifier path */
  uri?: Maybe<Scalars['String']['output']>;
};

/** Input for the updateApp mutation. */
export type UpdateAppInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the App object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the App and TaxonomyCategories */
  taxonomyCategories?: InputMaybe<AppTaxonomyCategoriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateApp mutation. */
export type UpdateAppPayload = {
  __typename?: 'UpdateAppPayload';
  /** The Post object mutation type. */
  app?: Maybe<App>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the updateCategory mutation. */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the category object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category object to update */
  id: Scalars['ID']['input'];
  /** The name of the category object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateCategory mutation. */
export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  /** The created category */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the updateChecklist mutation. */
export type UpdateChecklistInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Checklist object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Checklist and TaxonomyCategories */
  taxonomyCategories?: InputMaybe<ChecklistTaxonomyCategoriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateChecklist mutation. */
export type UpdateChecklistPayload = {
  __typename?: 'UpdateChecklistPayload';
  /** The Post object mutation type. */
  checklist?: Maybe<Checklist>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Input for the updateComment mutation. */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars['String']['input']>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars['String']['input']>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars['Int']['input']>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the comment being updated. */
  id: Scalars['ID']['input'];
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** The approval status of the comment */
  status?: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateComment mutation. */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Input for the updateCustomerStory mutation. */
export type UpdateCustomerStoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the CustomerStory object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the CustomerStory and TaxonomyIndustries */
  taxonomyIndustries?: InputMaybe<CustomerStoryTaxonomyIndustriesInput>;
  /** Set connections between the CustomerStory and TaxonomyRegions */
  taxonomyRegions?: InputMaybe<CustomerStoryTaxonomyRegionsInput>;
  /** Set connections between the CustomerStory and TaxonomySolutions */
  taxonomySolutions?: InputMaybe<CustomerStoryTaxonomySolutionsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateCustomerStory mutation. */
export type UpdateCustomerStoryPayload = {
  __typename?: 'UpdateCustomerStoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  customerStory?: Maybe<CustomerStory>;
};

/** Input for the updateEbook mutation. */
export type UpdateEbookInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Ebook object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateEbook mutation. */
export type UpdateEbookPayload = {
  __typename?: 'UpdateEbookPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  ebook?: Maybe<Ebook>;
};

/** Input for the updateEvent mutation. */
export type UpdateEventInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Event object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateEvent mutation. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  event?: Maybe<Event>;
};

/** Input for the updateGraphqlDocumentGroup mutation. */
export type UpdateGraphqlDocumentGroupInput = {
  /** The slug that the graphql_document_group will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the graphql_document_group object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the graphqlDocumentGroup object to update */
  id: Scalars['ID']['input'];
  /** The name of the graphql_document_group object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateGraphqlDocumentGroup mutation. */
export type UpdateGraphqlDocumentGroupPayload = {
  __typename?: 'UpdateGraphqlDocumentGroupPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created graphql_document_group */
  graphqlDocumentGroup?: Maybe<GraphqlDocumentGroup>;
};

/** Input for the updateGraphqlDocument mutation. */
export type UpdateGraphqlDocumentInput = {
  /** Alias names for saved GraphQL query documents */
  alias?: InputMaybe<Array<Scalars['String']['input']>>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Description for the saved GraphQL document */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Allow, deny or default access grant for specific query */
  grant?: InputMaybe<Scalars['String']['input']>;
  /** Set connections between the graphqlDocument and graphqlDocumentGroups */
  graphqlDocumentGroups?: InputMaybe<GraphqlDocumentGraphqlDocumentGroupsInput>;
  /** The ID of the graphqlDocument object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** HTTP Cache-Control max-age directive for a saved GraphQL document */
  maxAgeHeader?: InputMaybe<Scalars['Int']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateGraphqlDocument mutation. */
export type UpdateGraphqlDocumentPayload = {
  __typename?: 'UpdateGraphqlDocumentPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  graphqlDocument?: Maybe<GraphqlDocument>;
};

/** Input for the updateLegal mutation. */
export type UpdateLegalInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Legal object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateLegal mutation. */
export type UpdateLegalPayload = {
  __typename?: 'UpdateLegalPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  legal?: Maybe<Legal>;
};

/** Input for the updateMediaItem mutation. */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars['String']['input']>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars['String']['input']>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars['String']['input']>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars['ID']['input'];
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateMediaItem mutation. */
export type UpdateMediaItemPayload = {
  __typename?: 'UpdateMediaItemPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the updatePage mutation. */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the page object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePage mutation. */
export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  page?: Maybe<Page>;
};

/** Input for the updatePartnership mutation. */
export type UpdatePartnershipInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Partnership object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePartnership mutation. */
export type UpdatePartnershipPayload = {
  __typename?: 'UpdatePartnershipPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  partnership?: Maybe<Partnership>;
};

/** Input for the updatePostFormat mutation. */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the postFormat object to update */
  id: Scalars['ID']['input'];
  /** The name of the post_format object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updatePostFormat mutation. */
export type UpdatePostFormatPayload = {
  __typename?: 'UpdatePostFormatPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created post_format */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the updatePost mutation. */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars['String']['input']>;
  /** The content of the object */
  content?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the post object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars['String']['input']>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The payload for the updatePost mutation. */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  post?: Maybe<Post>;
};

/** Input for the updateSettings mutation. */
export type UpdateSettingsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: InputMaybe<
    Scalars['String']['input']
  >;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars['String']['input']>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: InputMaybe<Scalars['String']['input']>;
  /** Site tagline. */
  generalSettingsDescription?: InputMaybe<Scalars['String']['input']>;
  /** WordPress locale code. */
  generalSettingsLanguage?: InputMaybe<Scalars['String']['input']>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: InputMaybe<Scalars['Int']['input']>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: InputMaybe<Scalars['String']['input']>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: InputMaybe<Scalars['String']['input']>;
  /** Site title. */
  generalSettingsTitle?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront?: InputMaybe<Scalars['Int']['input']>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: InputMaybe<Scalars['Int']['input']>;
  /** What to show on the front page */
  readingSettingsShowOnFront?: InputMaybe<Scalars['String']['input']>;
  /** Default post category. */
  writingSettingsDefaultCategory?: InputMaybe<Scalars['Int']['input']>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars['String']['input']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The payload for the updateSettings mutation. */
export type UpdateSettingsPayload = {
  __typename?: 'UpdateSettingsPayload';
  /** Update all settings. */
  allSettings?: Maybe<Settings>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Update the DiscussionSettings setting. */
  discussionSettings?: Maybe<DiscussionSettings>;
  /** Update the GeneralSettings setting. */
  generalSettings?: Maybe<GeneralSettings>;
  /** Update the ReadingSettings setting. */
  readingSettings?: Maybe<ReadingSettings>;
  /** Update the WritingSettings setting. */
  writingSettings?: Maybe<WritingSettings>;
};

/** Input for the updateTag mutation. */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the tag object to update */
  id: Scalars['ID']['input'];
  /** The name of the post_tag object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTag mutation. */
export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created post_tag */
  tag?: Maybe<Tag>;
};

/** Input for the updateTaxonomyCategory mutation. */
export type UpdateTaxonomyCategoryInput = {
  /** The slug that the categories will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the categories object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyCategory object to update */
  id: Scalars['ID']['input'];
  /** The name of the categories object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the categories that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTaxonomyCategory mutation. */
export type UpdateTaxonomyCategoryPayload = {
  __typename?: 'UpdateTaxonomyCategoryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created categories */
  taxonomyCategory?: Maybe<TaxonomyCategory>;
};

/** Input for the updateTaxonomyIndustry mutation. */
export type UpdateTaxonomyIndustryInput = {
  /** The slug that the industry will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the industry object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyIndustry object to update */
  id: Scalars['ID']['input'];
  /** The name of the industry object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the industry that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTaxonomyIndustry mutation. */
export type UpdateTaxonomyIndustryPayload = {
  __typename?: 'UpdateTaxonomyIndustryPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created industry */
  taxonomyIndustry?: Maybe<TaxonomyIndustry>;
};

/** Input for the updateTaxonomyRegion mutation. */
export type UpdateTaxonomyRegionInput = {
  /** The slug that the region will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the region object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomyRegion object to update */
  id: Scalars['ID']['input'];
  /** The name of the region object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the region that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTaxonomyRegion mutation. */
export type UpdateTaxonomyRegionPayload = {
  __typename?: 'UpdateTaxonomyRegionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created region */
  taxonomyRegion?: Maybe<TaxonomyRegion>;
};

/** Input for the updateTaxonomySolution mutation. */
export type UpdateTaxonomySolutionInput = {
  /** The slug that the solution will be an alias of */
  aliasOf?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The description of the solution object */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the TaxonomySolution object to update */
  id: Scalars['ID']['input'];
  /** The name of the solution object to mutate */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the solution that should be set as the parent */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTaxonomySolution mutation. */
export type UpdateTaxonomySolutionPayload = {
  __typename?: 'UpdateTaxonomySolutionPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The created solution */
  taxonomySolution?: Maybe<TaxonomySolution>;
};

/** Input for the updateTestimonial mutation. */
export type UpdateTestimonialInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Testimonial object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Testimonial and TaxonomyIndustries */
  taxonomyIndustries?: InputMaybe<TestimonialTaxonomyIndustriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTestimonial mutation. */
export type UpdateTestimonialPayload = {
  __typename?: 'UpdateTestimonialPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  testimonial?: Maybe<Testimonial>;
};

/** Input for the updateTopic mutation. */
export type UpdateTopicInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars['ID']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the Topic object */
  id: Scalars['ID']['input'];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars['Boolean']['input']>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Topic and TaxonomyCategories */
  taxonomyCategories?: InputMaybe<TopicTaxonomyCategoriesInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateTopic mutation. */
export type UpdateTopicPayload = {
  __typename?: 'UpdateTopicPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The Post object mutation type. */
  topic?: Maybe<Topic>;
};

/** Input for the updateUser mutation. */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars['String']['input']>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user */
  id: Scalars['ID']['input'];
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** User's locale. */
  locale?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars['String']['input']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars['String']['input']>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars['String']['input']>;
  /** If true, this will revoke the users JWT secret. If false, this will unrevoke the JWT secret AND issue a new one. To revoke, the user must have proper capabilities to edit users JWT secrets. */
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']['input']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars['String']['input']>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars['String']['input']>;
};

/** The payload for the updateUser mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** A User object */
export type User = Commenter &
  DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'User';
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Templates: User&quot; was set to Show in GraphQL. */
    acfUser?: Maybe<User_Acfuser>;
    /** Connection between the User type and the App type */
    apps?: Maybe<UserToAppConnection>;
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Avatar>;
    /** User metadata option name. Usually it will be &quot;wp_capabilities&quot;. */
    capKey?: Maybe<Scalars['String']['output']>;
    /** A list of capabilities (permissions) granted to the user */
    capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** Connection between the User type and the Checklist type */
    checklists?: Maybe<UserToChecklistConnection>;
    /** Connection between the User type and the Comment type */
    comments?: Maybe<UserToCommentConnection>;
    /** Identifies the primary key from the database. */
    databaseId: Scalars['Int']['output'];
    /** Description of the user. */
    description?: Maybe<Scalars['String']['output']>;
    /** Email address of the user. This is equivalent to the WP_User-&gt;user_email property. */
    email?: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<UserToEnqueuedScriptConnection>;
    /** Connection between the User type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<UserToEnqueuedStylesheetConnection>;
    /** A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps. */
    extraCapabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    /** First name of the user. This is equivalent to the WP_User-&gt;user_first_name property. */
    firstName?: Maybe<Scalars['String']['output']>;
    /** The globally unique identifier for the user object. */
    id: Scalars['ID']['output'];
    /** Whether the node is a Comment */
    isComment: Scalars['Boolean']['output'];
    /** Whether the node is a Content Node */
    isContentNode: Scalars['Boolean']['output'];
    /** Whether the node represents the front page. */
    isFrontPage: Scalars['Boolean']['output'];
    /** Whether the JWT User secret has been revoked. If the secret has been revoked, auth tokens will not be issued until an admin, or user with proper capabilities re-issues a secret for the user. */
    isJwtAuthSecretRevoked: Scalars['Boolean']['output'];
    /** Whether  the node represents the blog page. */
    isPostsPage: Scalars['Boolean']['output'];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars['Boolean']['output']>;
    /** Whether the node is a Term */
    isTermNode: Scalars['Boolean']['output'];
    /** The expiration for the JWT Token for the user. If not set custom for the user, it will use the default sitewide expiration setting */
    jwtAuthExpiration?: Maybe<Scalars['String']['output']>;
    /** A JWT token that can be used in future requests for authentication/authorization */
    jwtAuthToken?: Maybe<Scalars['String']['output']>;
    /** A JWT token that can be used in future requests to get a refreshed jwtAuthToken. If the refresh token used in a request is revoked or otherwise invalid, a valid Auth token will NOT be issued in the response headers. */
    jwtRefreshToken?: Maybe<Scalars['String']['output']>;
    /** A unique secret tied to the users JWT token that can be revoked or refreshed. Revoking the secret prevents JWT tokens from being issued to the user. Refreshing the token invalidates previously issued tokens, but allows new tokens to be issued. */
    jwtUserSecret?: Maybe<Scalars['String']['output']>;
    /** Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property. */
    lastName?: Maybe<Scalars['String']['output']>;
    /** The preferred language locale set for the user. Value derived from get_user_locale(). */
    locale?: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the mediaItem type */
    mediaItems?: Maybe<UserToMediaItemConnection>;
    /** Display name of the user. This is equivalent to the WP_User-&gt;display_name property. */
    name?: Maybe<Scalars['String']['output']>;
    /** The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename */
    nicename?: Maybe<Scalars['String']['output']>;
    /** Nickname of the user. */
    nickname?: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the page type */
    pages?: Maybe<UserToPageConnection>;
    /** Connection between the User type and the Partnership type */
    partnerships?: Maybe<UserToPartnershipConnection>;
    /** Connection between the User type and the post type */
    posts?: Maybe<UserToPostConnection>;
    /** The date the user registered or was created. The field follows a full ISO8601 date string format. */
    registeredDate?: Maybe<Scalars['String']['output']>;
    /** Connection between the User and Revisions authored by the user */
    revisions?: Maybe<UserToRevisionsConnection>;
    /** Connection between the User type and the UserRole type */
    roles?: Maybe<UserToUserRoleConnection>;
    /** The Yoast SEO data of a user */
    seo?: Maybe<SeoUser>;
    /** Whether the Toolbar should be displayed when the user is viewing the site. */
    shouldShowAdminToolbar?: Maybe<Scalars['Boolean']['output']>;
    /** The slug for the user. This field is equivalent to WP_User-&gt;user_nicename */
    slug?: Maybe<Scalars['String']['output']>;
    /** Connection between the User type and the Topic type */
    topics?: Maybe<UserToTopicConnection>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars['String']['output']>;
    /** A website url that is associated with the user. */
    url?: Maybe<Scalars['String']['output']>;
    /**
     * The Id of the user. Equivalent to WP_User-&gt;ID
     * @deprecated Deprecated in favor of the databaseId field
     */
    userId?: Maybe<Scalars['Int']['output']>;
    /** Username for the user. This field is equivalent to WP_User-&gt;user_login. */
    username?: Maybe<Scalars['String']['output']>;
  };

/** A User object */
export type UserAppsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToAppConnectionWhereArgs>;
};

/** A User object */
export type UserAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']['input']>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserChecklistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToChecklistConnectionWhereArgs>;
};

/** A User object */
export type UserCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToCommentConnectionWhereArgs>;
};

/** A User object */
export type UserEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToMediaItemConnectionWhereArgs>;
};

/** A User object */
export type UserPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToPageConnectionWhereArgs>;
};

/** A User object */
export type UserPartnershipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToPartnershipConnectionWhereArgs>;
};

/** A User object */
export type UserPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToPostConnectionWhereArgs>;
};

/** A User object */
export type UserRevisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToRevisionsConnectionWhereArgs>;
};

/** A User object */
export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A User object */
export type UserTopicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserToTopicConnectionWhereArgs>;
};

/** Connection to User Nodes */
export type UserConnection = {
  /** A list of edges (relational context) between RootQuery and connected User Nodes */
  edges: Array<UserConnectionEdge>;
  /** A list of connected User Nodes */
  nodes: Array<User>;
  /** Information about pagination in a connection. */
  pageInfo: UserConnectionPageInfo;
};

/** Edge between a Node and a connected User */
export type UserConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected User Node */
  node: User;
};

/** Page Info on the connected UserConnectionEdge */
export type UserConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The Email of the User */
  Email = 'EMAIL',
  /** The hashed Global ID */
  Id = 'ID',
  /** The slug of the User */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
  /** The username the User uses to login with */
  Username = 'USERNAME',
}

/** A user role object */
export type UserRole = Node & {
  __typename?: 'UserRole';
  /** The capabilities that belong to this role */
  capabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The display name of the role */
  displayName?: Maybe<Scalars['String']['output']>;
  /** The globally unique identifier for the user role object. */
  id: Scalars['ID']['output'];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  /** The registered name of the role */
  name?: Maybe<Scalars['String']['output']>;
};

/** Connection to UserRole Nodes */
export type UserRoleConnection = {
  /** A list of edges (relational context) between RootQuery and connected UserRole Nodes */
  edges: Array<UserRoleConnectionEdge>;
  /** A list of connected UserRole Nodes */
  nodes: Array<UserRole>;
  /** Information about pagination in a connection. */
  pageInfo: UserRoleConnectionPageInfo;
};

/** Edge between a Node and a connected UserRole */
export type UserRoleConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** The connected UserRole Node */
  node: UserRole;
};

/** Page Info on the connected UserRoleConnectionEdge */
export type UserRoleConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  Author = 'AUTHOR',
  /** User role with specific capabilities */
  Contributor = 'CONTRIBUTOR',
  /** User role with specific capabilities */
  Editor = 'EDITOR',
  /** User role with specific capabilities */
  SeoEditor = 'SEO_EDITOR',
  /** User role with specific capabilities */
  SeoManager = 'SEO_MANAGER',
  /** User role with specific capabilities */
  Subscriber = 'SUBSCRIBER',
}

/** Connection between the User type and the App type */
export type UserToAppConnection = AppConnection &
  Connection & {
    __typename?: 'UserToAppConnection';
    /** Edges for the UserToAppConnection connection */
    edges: Array<UserToAppConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<App>;
    /** Information about pagination in a connection. */
    pageInfo: UserToAppConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToAppConnectionEdge = AppConnectionEdge &
  Edge & {
    __typename?: 'UserToAppConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: App;
  };

/** Page Info on the &quot;UserToAppConnection&quot; */
export type UserToAppConnectionPageInfo = AppConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToAppConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToAppConnection connection */
export type UserToAppConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the Checklist type */
export type UserToChecklistConnection = ChecklistConnection &
  Connection & {
    __typename?: 'UserToChecklistConnection';
    /** Edges for the UserToChecklistConnection connection */
    edges: Array<UserToChecklistConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Checklist>;
    /** Information about pagination in a connection. */
    pageInfo: UserToChecklistConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToChecklistConnectionEdge = ChecklistConnectionEdge &
  Edge & {
    __typename?: 'UserToChecklistConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Checklist;
  };

/** Page Info on the &quot;UserToChecklistConnection&quot; */
export type UserToChecklistConnectionPageInfo = ChecklistConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToChecklistConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToChecklistConnection connection */
export type UserToChecklistConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the Comment type */
export type UserToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'UserToCommentConnection';
    /** Edges for the UserToCommentConnection connection */
    edges: Array<UserToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: UserToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'UserToCommentConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;UserToCommentConnection&quot; */
export type UserToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToCommentConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars['String']['input']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars['String']['input']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars['ID']['input']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars['String']['input']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars['Int']['input']>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars['Int']['input']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Connection between the User type and the EnqueuedScript type */
export type UserToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'UserToEnqueuedScriptConnection';
    /** Edges for the UserToEnqueuedScriptConnection connection */
    edges: Array<UserToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: UserToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'UserToEnqueuedScriptConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;UserToEnqueuedScriptConnection&quot; */
export type UserToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'UserToEnqueuedScriptConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the User type and the EnqueuedStylesheet type */
export type UserToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'UserToEnqueuedStylesheetConnection';
    /** Edges for the UserToEnqueuedStylesheetConnection connection */
    edges: Array<UserToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: UserToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'UserToEnqueuedStylesheetConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;UserToEnqueuedStylesheetConnection&quot; */
export type UserToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'UserToEnqueuedStylesheetConnectionPageInfo';
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars['String']['output']>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars['Boolean']['output'];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars['Boolean']['output'];
      /** Raw schema for page */
      seo?: Maybe<SeoPostTypePageInfo>;
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars['String']['output']>;
    };

/** Connection between the User type and the mediaItem type */
export type UserToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'UserToMediaItemConnection';
    /** Edges for the UserToMediaItemConnection connection */
    edges: Array<UserToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: UserToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'UserToMediaItemConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;UserToMediaItemConnection&quot; */
export type UserToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToMediaItemConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the page type */
export type UserToPageConnection = Connection &
  PageConnection & {
    __typename?: 'UserToPageConnection';
    /** Edges for the UserToPageConnection connection */
    edges: Array<UserToPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPageConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'UserToPageConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;UserToPageConnection&quot; */
export type UserToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToPageConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the Partnership type */
export type UserToPartnershipConnection = Connection &
  PartnershipConnection & {
    __typename?: 'UserToPartnershipConnection';
    /** Edges for the UserToPartnershipConnection connection */
    edges: Array<UserToPartnershipConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Partnership>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPartnershipConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPartnershipConnectionEdge = Edge &
  PartnershipConnectionEdge & {
    __typename?: 'UserToPartnershipConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Partnership;
  };

/** Page Info on the &quot;UserToPartnershipConnection&quot; */
export type UserToPartnershipConnectionPageInfo = PageInfo &
  PartnershipConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToPartnershipConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToPartnershipConnection connection */
export type UserToPartnershipConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the post type */
export type UserToPostConnection = Connection &
  PostConnection & {
    __typename?: 'UserToPostConnection';
    /** Edges for the UserToPostConnection connection */
    edges: Array<UserToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'UserToPostConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;UserToPostConnection&quot; */
export type UserToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToPostConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars['String']['input']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the ContentNode type */
export type UserToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'UserToRevisionsConnection';
    /** Edges for the UserToRevisionsConnection connection */
    edges: Array<UserToRevisionsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: UserToRevisionsConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'UserToRevisionsConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;UserToRevisionsConnection&quot; */
export type UserToRevisionsConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToRevisionsConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToRevisionsConnection connection */
export type UserToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the Topic type */
export type UserToTopicConnection = Connection &
  TopicConnection & {
    __typename?: 'UserToTopicConnection';
    /** Edges for the UserToTopicConnection connection */
    edges: Array<UserToTopicConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Topic>;
    /** Information about pagination in a connection. */
    pageInfo: UserToTopicConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToTopicConnectionEdge = Edge &
  TopicConnectionEdge & {
    __typename?: 'UserToTopicConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: Topic;
  };

/** Page Info on the &quot;UserToTopicConnection&quot; */
export type UserToTopicConnectionPageInfo = PageInfo &
  TopicConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToTopicConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Arguments for filtering the UserToTopicConnection connection */
export type UserToTopicConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars['Int']['input']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars['String']['input']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars['Boolean']['input']>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Title of the object */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Connection between the User type and the UserRole type */
export type UserToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: 'UserToUserRoleConnection';
    /** Edges for the UserToUserRoleConnection connection */
    edges: Array<UserToUserRoleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<UserRole>;
    /** Information about pagination in a connection. */
    pageInfo: UserToUserRoleConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: 'UserToUserRoleConnectionEdge';
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars['String']['output']>;
    /** The item at the end of the edge */
    node: UserRole;
  };

/** Page Info on the &quot;UserToUserRoleConnection&quot; */
export type UserToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToUserRoleConnectionPageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** Raw schema for page */
    seo?: Maybe<SeoPostTypePageInfo>;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
  };

/** Field Group */
export type User_Acfuser = AcfFieldGroup & {
  __typename?: 'User_Acfuser';
  avatar?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  translations?: Maybe<User_Acfuser_Translations>;
};

/** Field Group */
export type User_Acfuser_Translations = AcfFieldGroup & {
  __typename?: 'User_Acfuser_Translations';
  de?: Maybe<User_Acfuser_Translations_De>;
  es?: Maybe<User_Acfuser_Translations_Es>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<User_Acfuser_Translations_Fr>;
  nl?: Maybe<User_Acfuser_Translations_Nl>;
  ptBr?: Maybe<User_Acfuser_Translations_PtBr>;
};

/** Field Group */
export type User_Acfuser_Translations_De = AcfFieldGroup & {
  __typename?: 'User_Acfuser_Translations_De';
  bio?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type User_Acfuser_Translations_Es = AcfFieldGroup & {
  __typename?: 'User_Acfuser_Translations_Es';
  bio?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type User_Acfuser_Translations_Fr = AcfFieldGroup & {
  __typename?: 'User_Acfuser_Translations_Fr';
  bio?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type User_Acfuser_Translations_Nl = AcfFieldGroup & {
  __typename?: 'User_Acfuser_Translations_Nl';
  bio?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Field Group */
export type User_Acfuser_Translations_PtBr = AcfFieldGroup & {
  __typename?: 'User_Acfuser_Translations_PtBr';
  bio?: Maybe<Scalars['String']['output']>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = 'DISPLAY_NAME',
  /** Order by email address */
  Email = 'EMAIL',
  /** Order by login */
  Login = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = 'LOGIN_IN',
  /** Order by nice name */
  NiceName = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = 'NICE_NAME_IN',
  /** Order by registration date */
  Registered = 'REGISTERED',
  /** Order by URL */
  Url = 'URL',
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
};

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = 'EMAIL',
  /** The globally unique ID. */
  Id = 'ID',
  /** The username the User uses to login with. */
  Login = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = 'NICENAME',
  /** The URL of the user's website. */
  Url = 'URL',
}

/** Information about pagination in a connection. */
export type WpPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Raw schema for page */
  seo?: Maybe<SeoPostTypePageInfo>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The writing setting type */
export type WritingSettings = {
  __typename?: 'WritingSettings';
  /** Default post category. */
  defaultCategory?: Maybe<Scalars['Int']['output']>;
  /** Default post format. */
  defaultPostFormat?: Maybe<Scalars['String']['output']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  useSmilies?: Maybe<Scalars['Boolean']['output']>;
};

export type MenuFragmentFragment = {
  __typename?: 'RootQueryToMenuConnection';
  nodes: Array<{
    __typename?: 'Menu';
    menuItems?: {
      __typename?: 'MenuToMenuItemConnection';
      nodes: Array<{
        __typename?: 'MenuItem';
        id: string;
        label?: string | null;
        parentId?: string | null;
        target?: string | null;
        url?: string | null;
        description?: string | null;
        cssClasses?: Array<string | null> | null;
        childItems?: {
          __typename?: 'MenuItemToMenuItemConnection';
          nodes: Array<{
            __typename?: 'MenuItem';
            id: string;
            label?: string | null;
            parentId?: string | null;
            target?: string | null;
            url?: string | null;
            description?: string | null;
            cssClasses?: Array<string | null> | null;
            childItems?: {
              __typename?: 'MenuItemToMenuItemConnection';
              nodes: Array<{
                __typename?: 'MenuItem';
                id: string;
                label?: string | null;
                parentId?: string | null;
                target?: string | null;
                url?: string | null;
                description?: string | null;
                cssClasses?: Array<string | null> | null;
              }>;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
  }>;
};

export type GetAppsCursorsPagedQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsCursorsPagedQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    edges: Array<{
      __typename?: 'RootQueryToAppConnectionEdge';
      cursor?: string | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToAppConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAppsCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsCursorsQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    edges: Array<{
      __typename?: 'RootQueryToAppConnectionEdge';
      cursor?: string | null;
    }>;
  } | null;
};

export type GetAppsCursorsTaxonomyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paged?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsCursorsTaxonomyQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      apps?: {
        __typename?: 'TaxonomyCategoryToAppConnection';
        edges: Array<{
          __typename?: 'TaxonomyCategoryToAppConnectionEdge';
          cursor?: string | null;
        }>;
        pageInfo: {
          __typename?: 'TaxonomyCategoryToAppConnectionPageInfo';
          hasNextPage: boolean;
          endCursor?: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type GetAppsTaxonomyCategoriesCursorsQueryVariables = Exact<{
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsTaxonomyCategoriesCursorsQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      apps?: {
        __typename?: 'TaxonomyCategoryToAppConnection';
        edges: Array<{
          __typename?: 'TaxonomyCategoryToAppConnectionEdge';
          cursor?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetAuthorsCursorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuthorsCursorsQuery = {
  __typename?: 'RootQuery';
  users?: {
    __typename?: 'RootQueryToUserConnection';
    edges: Array<{
      __typename?: 'RootQueryToUserConnectionEdge';
      cursor?: string | null;
    }>;
  } | null;
};

export type GetChecklistsCategoryCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetChecklistsCategoryCursorsQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      checklists?: {
        __typename?: 'TaxonomyCategoryToChecklistConnection';
        edges: Array<{
          __typename?: 'TaxonomyCategoryToChecklistConnectionEdge';
          cursor?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetChecklistsCursorsPagedQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistsCursorsPagedQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    edges: Array<{
      __typename?: 'RootQueryToChecklistConnectionEdge';
      cursor?: string | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToChecklistConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetChecklistsCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistsCursorsQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    edges: Array<{
      __typename?: 'RootQueryToChecklistConnectionEdge';
      cursor?: string | null;
    }>;
  } | null;
};

export type GetChecklistsCursorsTaxonomyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paged?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistsCursorsTaxonomyQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      checklists?: {
        __typename?: 'TaxonomyCategoryToChecklistConnection';
        edges: Array<{
          __typename?: 'TaxonomyCategoryToChecklistConnectionEdge';
          cursor?: string | null;
        }>;
        pageInfo: {
          __typename?: 'TaxonomyCategoryToChecklistConnectionPageInfo';
          hasNextPage: boolean;
          endCursor?: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type GetEbooksCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEbooksCursorsQuery = {
  __typename?: 'RootQuery';
  ebooks?: {
    __typename?: 'RootQueryToEbookConnection';
    edges: Array<{
      __typename?: 'RootQueryToEbookConnectionEdge';
      cursor?: string | null;
    }>;
  } | null;
};

export type GetEventsCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEventsCursorsQuery = {
  __typename?: 'RootQuery';
  events?: {
    __typename?: 'RootQueryToEventConnection';
    edges: Array<{
      __typename?: 'RootQueryToEventConnectionEdge';
      cursor?: string | null;
    }>;
  } | null;
};

export type GetTopicsCursorsPagedQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsCursorsPagedQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    edges: Array<{
      __typename?: 'RootQueryToTopicConnectionEdge';
      cursor?: string | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToTopicConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetTopicsCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsCursorsQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    edges: Array<{
      __typename?: 'RootQueryToTopicConnectionEdge';
      cursor?: string | null;
    }>;
  } | null;
};

export type GetTopicsCursorsTaxonomyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  paged?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsCursorsTaxonomyQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      topics?: {
        __typename?: 'TaxonomyCategoryToTopicConnection';
        edges: Array<{
          __typename?: 'TaxonomyCategoryToTopicConnectionEdge';
          cursor?: string | null;
        }>;
        pageInfo: {
          __typename?: 'TaxonomyCategoryToTopicConnectionPageInfo';
          hasNextPage: boolean;
          endCursor?: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type GetTopicsTaxonomyCursorsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetTopicsTaxonomyCursorsQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      topics?: {
        __typename?: 'TaxonomyCategoryToTopicConnection';
        edges: Array<{
          __typename?: 'TaxonomyCategoryToTopicConnectionEdge';
          cursor?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetAppsGlobalsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppsGlobalsQuery = {
  __typename?: 'RootQuery';
  appsGlobals?: {
    __typename?: 'AppsGlobals';
    acfAppsGlobals?: {
      __typename?: 'AppsGlobals_Acfappsglobals';
      branchLink?: string | null;
      capterra?: {
        __typename?: 'AppsGlobals_Acfappsglobals_Capterra';
        badges?: {
          __typename?: 'AppsGlobals_Acfappsglobals_Capterra_Badges';
          badges?: Array<{
            __typename?: 'AppsGlobals_Acfappsglobals_Capterra_Badges_badges';
            badge?: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                width?: number | null;
                height?: number | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetCapteraQueryVariables = Exact<{ [key: string]: never }>;

export type GetCapteraQuery = {
  __typename?: 'RootQuery';
  acfOptionsCapterra?: {
    __typename?: 'AcfOptionsCapterra';
    acfCapterra?: {
      __typename?: 'AcfOptionsCapterra_Acfcapterra';
      capterra?: {
        __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra';
        de?: {
          __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_De';
          rating?: string | null;
        } | null;
        en?: {
          __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_En';
          rating?: string | null;
        } | null;
        es?: {
          __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_Es';
          rating?: string | null;
        } | null;
        fr?: {
          __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_Fr';
          rating?: string | null;
        } | null;
        nl?: {
          __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_Nl';
          rating?: string | null;
        } | null;
        ptBr?: {
          __typename?: 'AcfOptionsCapterra_Acfcapterra_Capterra_PtBr';
          rating?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetMenusQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMenusQuery = {
  __typename?: 'RootQuery';
  header?: {
    __typename?: 'RootQueryToMenuConnection';
    nodes: Array<{
      __typename?: 'Menu';
      menuItems?: {
        __typename?: 'MenuToMenuItemConnection';
        nodes: Array<{
          __typename?: 'MenuItem';
          id: string;
          label?: string | null;
          parentId?: string | null;
          target?: string | null;
          url?: string | null;
          description?: string | null;
          cssClasses?: Array<string | null> | null;
          childItems?: {
            __typename?: 'MenuItemToMenuItemConnection';
            nodes: Array<{
              __typename?: 'MenuItem';
              id: string;
              label?: string | null;
              parentId?: string | null;
              target?: string | null;
              url?: string | null;
              description?: string | null;
              cssClasses?: Array<string | null> | null;
              childItems?: {
                __typename?: 'MenuItemToMenuItemConnection';
                nodes: Array<{
                  __typename?: 'MenuItem';
                  id: string;
                  label?: string | null;
                  parentId?: string | null;
                  target?: string | null;
                  url?: string | null;
                  description?: string | null;
                  cssClasses?: Array<string | null> | null;
                }>;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
  footer?: {
    __typename?: 'RootQueryToMenuConnection';
    nodes: Array<{
      __typename?: 'Menu';
      menuItems?: {
        __typename?: 'MenuToMenuItemConnection';
        nodes: Array<{
          __typename?: 'MenuItem';
          id: string;
          label?: string | null;
          parentId?: string | null;
          target?: string | null;
          url?: string | null;
          description?: string | null;
          cssClasses?: Array<string | null> | null;
          childItems?: {
            __typename?: 'MenuItemToMenuItemConnection';
            nodes: Array<{
              __typename?: 'MenuItem';
              id: string;
              label?: string | null;
              parentId?: string | null;
              target?: string | null;
              url?: string | null;
              description?: string | null;
              cssClasses?: Array<string | null> | null;
              childItems?: {
                __typename?: 'MenuItemToMenuItemConnection';
                nodes: Array<{
                  __typename?: 'MenuItem';
                  id: string;
                  label?: string | null;
                  parentId?: string | null;
                  target?: string | null;
                  url?: string | null;
                  description?: string | null;
                  cssClasses?: Array<string | null> | null;
                }>;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
  legal?: {
    __typename?: 'RootQueryToMenuConnection';
    nodes: Array<{
      __typename?: 'Menu';
      menuItems?: {
        __typename?: 'MenuToMenuItemConnection';
        nodes: Array<{
          __typename?: 'MenuItem';
          id: string;
          label?: string | null;
          parentId?: string | null;
          target?: string | null;
          url?: string | null;
          description?: string | null;
          cssClasses?: Array<string | null> | null;
          childItems?: {
            __typename?: 'MenuItemToMenuItemConnection';
            nodes: Array<{
              __typename?: 'MenuItem';
              id: string;
              label?: string | null;
              parentId?: string | null;
              target?: string | null;
              url?: string | null;
              description?: string | null;
              cssClasses?: Array<string | null> | null;
              childItems?: {
                __typename?: 'MenuItemToMenuItemConnection';
                nodes: Array<{
                  __typename?: 'MenuItem';
                  id: string;
                  label?: string | null;
                  parentId?: string | null;
                  target?: string | null;
                  url?: string | null;
                  description?: string | null;
                  cssClasses?: Array<string | null> | null;
                }>;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetMoreAppsPostsQueryVariables = Exact<{
  taxonomyId?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMoreAppsPostsQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      apps?: {
        __typename?: 'TaxonomyCategoryToAppConnection';
        nodes: Array<{
          __typename?: 'App';
          slug?: string | null;
          title?: string | null;
          uri?: string | null;
          acfApp?: {
            __typename?: 'App_Acfapp';
            heroEditor?: string | null;
          } | null;
          acfPageStatus?: {
            __typename?: 'App_Acfpagestatus';
            draftPublic?: boolean | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          taxonomyCategories?: {
            __typename?: 'AppToTaxonomyCategoryConnection';
            nodes: Array<{
              __typename?: 'TaxonomyCategory';
              name?: string | null;
              parentId?: string | null;
              slug?: string | null;
              id: string;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetMoreChecklistsPostsQueryVariables = Exact<{
  taxonomyId?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMoreChecklistsPostsQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      checklists?: {
        __typename?: 'TaxonomyCategoryToChecklistConnection';
        nodes: Array<{
          __typename?: 'Checklist';
          slug?: string | null;
          title?: string | null;
          uri?: string | null;
          acfChecklist?: {
            __typename?: 'Checklist_Acfchecklist';
            heroEditor?: string | null;
          } | null;
          acfPageStatus?: {
            __typename?: 'Checklist_Acfpagestatus';
            draftPublic?: boolean | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          taxonomyCategories?: {
            __typename?: 'ChecklistToTaxonomyCategoryConnection';
            nodes: Array<{
              __typename?: 'TaxonomyCategory';
              name?: string | null;
              parentId?: string | null;
              slug?: string | null;
              id: string;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetMoreCustomerStoriesQueryVariables = Exact<{
  termTaxonomId?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  locale?: InputMaybe<Scalars['String']['input']>;
  postId?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
}>;

export type GetMoreCustomerStoriesQuery = {
  __typename?: 'RootQuery';
  taxonomyIndustries?: {
    __typename?: 'RootQueryToTaxonomyIndustryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyIndustry';
      customerStories?: {
        __typename?: 'TaxonomyIndustryToCustomerStoryConnection';
        nodes: Array<{
          __typename?: 'CustomerStory';
          uri?: string | null;
          title?: string | null;
          status?: string | null;
          id: string;
          acfCustomerStory?: {
            __typename?: 'CustomerStory_Acfcustomerstory';
            logo?: {
              __typename?: 'CustomerStory_Acfcustomerstory_Logo';
              image?: {
                __typename?: 'MediaItem';
                altText?: string | null;
                mediaItemUrl?: string | null;
                mediaDetails?: {
                  __typename?: 'MediaDetails';
                  height?: number | null;
                  width?: number | null;
                } | null;
              } | null;
            } | null;
            intro?: {
              __typename?: 'CustomerStory_Acfcustomerstory_Intro';
              editor?: string | null;
              title?: string | null;
            } | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          taxonomySolutions?: {
            __typename?: 'CustomerStoryToTaxonomySolutionConnection';
            nodes: Array<{
              __typename?: 'TaxonomySolution';
              id: string;
              name?: string | null;
              slug?: string | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetMoreTopicsQueryVariables = Exact<{
  taxonomyId?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMoreTopicsQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      topics?: {
        __typename?: 'TaxonomyCategoryToTopicConnection';
        nodes: Array<{
          __typename?: 'Topic';
          slug?: string | null;
          title?: string | null;
          uri?: string | null;
          acfTopic?: {
            __typename?: 'Topic_Acftopic';
            heroEditor?: string | null;
          } | null;
          acfPageStatus?: {
            __typename?: 'Topic_Acfpagestatus';
            draftPublic?: boolean | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          taxonomyCategories?: {
            __typename?: 'TopicToTaxonomyCategoryConnection';
            nodes: Array<{
              __typename?: 'TaxonomyCategory';
              name?: string | null;
              parentId?: string | null;
              slug?: string | null;
              id: string;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetNotificationBannerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNotificationBannerQuery = {
  __typename?: 'RootQuery';
  acfOptionsNotificationBanner?: {
    __typename?: 'AcfOptionsNotificationBanner';
    acfNotificationBanner?: {
      __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner';
      notificationBanner?: {
        __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner';
        status?: boolean | null;
        backgroundColor?: string | null;
        backgroundType?: string | null;
        textColor?: string | null;
        align?: string | null;
        text?: string | null;
        backgroundColorGradient?: {
          __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient';
          angle?: number | null;
          colors?: Array<{
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_BackgroundColorGradient_colors';
            color?: string | null;
          } | null> | null;
        } | null;
        image?: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        } | null;
        links?: Array<{
          __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_links';
          style?: string | null;
          icon?: {
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_links_Icon';
            icon?: string | null;
            position?: string | null;
          } | null;
          link?: {
            __typename?: 'AcfLink';
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
        } | null> | null;
        translations?: {
          __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations';
          de?: {
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De';
            text?: string | null;
            status?: boolean | null;
            links?: Array<{
              __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_links';
              style?: string | null;
              icon?: {
                __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_De_links_Icon';
                icon?: string | null;
                position?: string | null;
              } | null;
              link?: {
                __typename?: 'AcfLink';
                target?: string | null;
                title?: string | null;
                url?: string | null;
              } | null;
            } | null> | null;
          } | null;
          es?: {
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es';
            text?: string | null;
            status?: boolean | null;
            links?: Array<{
              __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_links';
              style?: string | null;
              icon?: {
                __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Es_links_Icon';
                icon?: string | null;
                position?: string | null;
              } | null;
              link?: {
                __typename?: 'AcfLink';
                target?: string | null;
                title?: string | null;
                url?: string | null;
              } | null;
            } | null> | null;
          } | null;
          fr?: {
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr';
            text?: string | null;
            status?: boolean | null;
            links?: Array<{
              __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_links';
              style?: string | null;
              icon?: {
                __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Fr_links_Icon';
                icon?: string | null;
                position?: string | null;
              } | null;
              link?: {
                __typename?: 'AcfLink';
                target?: string | null;
                title?: string | null;
                url?: string | null;
              } | null;
            } | null> | null;
          } | null;
          nl?: {
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl';
            text?: string | null;
            status?: boolean | null;
            links?: Array<{
              __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_links';
              style?: string | null;
              icon?: {
                __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_Nl_links_Icon';
                icon?: string | null;
                position?: string | null;
              } | null;
              link?: {
                __typename?: 'AcfLink';
                target?: string | null;
                title?: string | null;
                url?: string | null;
              } | null;
            } | null> | null;
          } | null;
          ptBr?: {
            __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr';
            text?: string | null;
            status?: boolean | null;
            links?: Array<{
              __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_links';
              style?: string | null;
              icon?: {
                __typename?: 'AcfOptionsNotificationBanner_Acfnotificationbanner_NotificationBanner_Translations_PtBr_links_Icon';
                icon?: string | null;
                position?: string | null;
              } | null;
              link?: {
                __typename?: 'AcfLink';
                target?: string | null;
                title?: string | null;
                url?: string | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetRecentCustomerStoriesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRecentCustomerStoriesQuery = {
  __typename?: 'RootQuery';
  customerStories?: {
    __typename?: 'RootQueryToCustomerStoryConnection';
    nodes: Array<{
      __typename?: 'CustomerStory';
      uri?: string | null;
      title?: string | null;
      link?: string | null;
      date?: string | null;
      acfCustomerStory?: {
        __typename?: 'CustomerStory_Acfcustomerstory';
        intro?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Intro';
          title?: string | null;
          editor?: string | null;
        } | null;
        image?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Image';
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              width?: number | null;
              height?: number | null;
            } | null;
          } | null;
        } | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'CustomerStory_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type GetTaxonomiesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTaxonomiesQuery = {
  __typename?: 'RootQuery';
  taxonomySolutions?: {
    __typename?: 'RootQueryToTaxonomySolutionConnection';
    nodes: Array<{
      __typename?: 'TaxonomySolution';
      name?: string | null;
      slug?: string | null;
      id: string;
    }>;
  } | null;
  taxonomyRegions?: {
    __typename?: 'RootQueryToTaxonomyRegionConnection';
    nodes: Array<{
      __typename?: 'TaxonomyRegion';
      name?: string | null;
      slug?: string | null;
      id: string;
    }>;
  } | null;
  taxonomyIndustries?: {
    __typename?: 'RootQueryToTaxonomyIndustryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyIndustry';
      name?: string | null;
      slug?: string | null;
      id: string;
    }>;
  } | null;
};

export type GePagesPathsNoLanguageCodeQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GePagesPathsNoLanguageCodeQuery = {
  __typename?: 'RootQuery';
  pages?: {
    __typename?: 'RootQueryToPageConnection';
    nodes: Array<{
      __typename?: 'Page';
      modified?: string | null;
      uri?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Page_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToPageConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAppsPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsPathsQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    nodes: Array<{
      __typename?: 'App';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToAppConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAppsTaxonomyPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsTaxonomyPathsQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    nodes: Array<{
      __typename?: 'App';
      modified?: string | null;
      uri?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      taxonomyCategories?: {
        __typename?: 'AppToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          count?: number | null;
          parentId?: string | null;
          slug?: string | null;
        }>;
      } | null;
      acfPageStatus?: {
        __typename?: 'App_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToAppConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAuthorsPathQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  paged?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAuthorsPathQuery = {
  __typename?: 'RootQuery';
  users?: {
    __typename?: 'RootQueryToUserConnection';
    nodes: Array<{
      __typename?: 'User';
      slug?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToUserConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetChecklistsPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistsPathsQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    nodes: Array<{
      __typename?: 'Checklist';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToChecklistConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetChecklistsTaxonomyPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistsTaxonomyPathsQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    nodes: Array<{
      __typename?: 'Checklist';
      modified?: string | null;
      uri?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      taxonomyCategories?: {
        __typename?: 'ChecklistToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          count?: number | null;
          parentId?: string | null;
          slug?: string | null;
        }>;
      } | null;
      acfPageStatus?: {
        __typename?: 'Checklist_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToChecklistConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetCustomerStoriesPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCustomerStoriesPathsQuery = {
  __typename?: 'RootQuery';
  customerStories?: {
    __typename?: 'RootQueryToCustomerStoryConnection';
    nodes: Array<{
      __typename?: 'CustomerStory';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'CustomerStory_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToCustomerStoryConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetEbooksPathsForArchiveQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEbooksPathsForArchiveQuery = {
  __typename?: 'RootQuery';
  ebooks?: {
    __typename?: 'RootQueryToEbookConnection';
    nodes: Array<{
      __typename?: 'Ebook';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Ebook_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToEbookConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetEventsPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEventsPathsQuery = {
  __typename?: 'RootQuery';
  events?: {
    __typename?: 'RootQueryToEventConnection';
    nodes: Array<{
      __typename?: 'Event';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Event_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToEventConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetLegalsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  paged?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetLegalsQuery = {
  __typename?: 'RootQuery';
  legals?: {
    __typename?: 'RootQueryToLegalConnection';
    nodes: Array<{
      __typename?: 'Legal';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Legal_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToLegalConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetPagesPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPagesPathsQuery = {
  __typename?: 'RootQuery';
  pages?: {
    __typename?: 'RootQueryToPageConnection';
    nodes: Array<{
      __typename?: 'Page';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Page_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToPageConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetPartnershipsPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPartnershipsPathsQuery = {
  __typename?: 'RootQuery';
  partnerships?: {
    __typename?: 'RootQueryToPartnershipConnection';
    nodes: Array<{
      __typename?: 'Partnership';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Partnership_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToPartnershipConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetTopicsPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsPathsQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    nodes: Array<{
      __typename?: 'Topic';
      modified?: string | null;
      uri?: string | null;
      languageCode?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToTopicConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetTopicsTaxonomyPathsQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsTaxonomyPathsQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    nodes: Array<{
      __typename?: 'Topic';
      modified?: string | null;
      uri?: string | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        metaRobotsNoindex?: string | null;
      } | null;
      taxonomyCategories?: {
        __typename?: 'TopicToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          count?: number | null;
          parentId?: string | null;
          slug?: string | null;
        }>;
      } | null;
      acfPageStatus?: {
        __typename?: 'Topic_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToTopicConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAppQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    nodes: Array<{
      __typename?: 'App';
      modified?: string | null;
      date?: string | null;
      slug?: string | null;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfApp?: {
        __typename?: 'App_Acfapp';
        seoDownload?: boolean | null;
        seoCapterra?: boolean | null;
        overrideAppLinks?: boolean | null;
        heroTitle?: string | null;
        heroEditor?: string | null;
        featuredTitle?: string | null;
        contentEditor?: string | null;
        branchLink?: string | null;
        authorShow?: boolean | null;
        seoCapterraBadges?: {
          __typename?: 'App_Acfapp_SeoCapterraBadges';
          show?: boolean | null;
          badges?: Array<{
            __typename?: 'App_Acfapp_SeoCapterraBadges_badges';
            badge?: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                width?: number | null;
                height?: number | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        blocks?: Array<{
          __typename?: 'App_Acfapp_blocks';
          title?: string | null;
          text?: string | null;
          pricing?: string | null;
          platforms?: string | null;
          freeVersion?: string | null;
          additionalLinks?: Array<{
            __typename?: 'App_Acfapp_blocks_additionalLinks';
            nofollow?: boolean | null;
            text?: string | null;
            url?: string | null;
          } | null> | null;
          cta?: {
            __typename?: 'App_Acfapp_blocks_Cta';
            nofollow?: boolean | null;
            text?: string | null;
            url?: string | null;
          } | null;
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null> | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'App_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      parent?: {
        __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
        node:
          | { __typename?: 'App'; slug?: string | null; title?: string | null }
          | { __typename?: 'Checklist' }
          | { __typename?: 'CustomerStory' }
          | { __typename?: 'Ebook' }
          | { __typename?: 'Event' }
          | { __typename?: 'GraphqlDocument' }
          | { __typename?: 'Legal' }
          | { __typename?: 'MediaItem' }
          | { __typename?: 'Page' }
          | { __typename?: 'Partnership' }
          | { __typename?: 'Post' }
          | { __typename?: 'Testimonial' }
          | { __typename?: 'Topic' };
      } | null;
      acfAuthors?: {
        __typename?: 'App_Acfauthors';
        multipleAuthors?: {
          __typename?: 'App_Acfauthors_MultipleAuthors';
          authors?: Array<{
            __typename?: 'App_Acfauthors_MultipleAuthors_authors';
            authorName?: string | null;
          } | null> | null;
        } | null;
      } | null;
      taxonomyCategories?: {
        __typename?: 'AppToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          name?: string | null;
          parentId?: string | null;
          slug?: string | null;
          id: string;
          apps?: {
            __typename?: 'TaxonomyCategoryToAppConnection';
            nodes: Array<{
              __typename?: 'App';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
          checklists?: {
            __typename?: 'TaxonomyCategoryToChecklistConnection';
            nodes: Array<{
              __typename?: 'Checklist';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
          topics?: {
            __typename?: 'TaxonomyCategoryToTopicConnection';
            nodes: Array<{
              __typename?: 'Topic';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
        }>;
      } | null;
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge';
        node: {
          __typename?: 'User';
          description?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          slug?: string | null;
          acfUser?: {
            __typename?: 'User_Acfuser';
            linkedin?: string | null;
            title?: string | null;
            avatar?: {
              __typename?: 'MediaItem';
              mediaItemUrl?: string | null;
            } | null;
            translations?: {
              __typename?: 'User_Acfuser_Translations';
              de?: {
                __typename?: 'User_Acfuser_Translations_De';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              es?: {
                __typename?: 'User_Acfuser_Translations_Es';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              fr?: {
                __typename?: 'User_Acfuser_Translations_Fr';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              nl?: {
                __typename?: 'User_Acfuser_Translations_Nl';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              ptBr?: {
                __typename?: 'User_Acfuser_Translations_PtBr';
                bio?: string | null;
                jobTitle?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          } | null;
          avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
        };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'App';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetAppsCategoryQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetAppsCategoryQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      apps?: {
        __typename?: 'TaxonomyCategoryToAppConnection';
        nodes: Array<{
          __typename?: 'App';
          id: string;
          title?: string | null;
          uri?: string | null;
          acfApp?: {
            __typename?: 'App_Acfapp';
            heroEditor?: string | null;
          } | null;
          acfPageStatus?: {
            __typename?: 'App_Acfpagestatus';
            draftPublic?: boolean | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          seo?: {
            __typename?: 'PostTypeSEO';
            canonical?: string | null;
            metaDesc?: string | null;
            metaRobotsNofollow?: string | null;
            metaRobotsNoindex?: string | null;
            title?: string | null;
          } | null;
          taxonomyCategories?: {
            __typename?: 'AppToTaxonomyCategoryConnection';
            nodes: Array<{
              __typename?: 'TaxonomyCategory';
              id: string;
              name?: string | null;
              slug?: string | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetAppsForArchiveQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppsForArchiveQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    nodes: Array<{
      __typename?: 'App';
      id: string;
      title?: string | null;
      uri?: string | null;
      acfApp?: { __typename?: 'App_Acfapp'; heroEditor?: string | null } | null;
      acfPageStatus?: {
        __typename?: 'App_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      seo?: { __typename?: 'PostTypeSEO'; metaDesc?: string | null } | null;
      taxonomyCategories?: {
        __typename?: 'AppToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetAuthorAppsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  paged?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAuthorAppsQuery = {
  __typename?: 'RootQuery';
  apps?: {
    __typename?: 'RootQueryToAppConnection';
    nodes: Array<{
      __typename?: 'App';
      modified?: string | null;
      title?: string | null;
      uri?: string | null;
      acfApp?: { __typename?: 'App_Acfapp'; heroEditor?: string | null } | null;
      acfPageStatus?: {
        __typename?: 'App_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      seo?: { __typename?: 'PostTypeSEO'; metaDesc?: string | null } | null;
      taxonomyCategories?: {
        __typename?: 'AppToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToAppConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAuthorChecklistsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  paged?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAuthorChecklistsQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    nodes: Array<{
      __typename?: 'Checklist';
      modified?: string | null;
      title?: string | null;
      uri?: string | null;
      acfChecklist?: {
        __typename?: 'Checklist_Acfchecklist';
        heroEditor?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Checklist_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      seo?: { __typename?: 'PostTypeSEO'; metaDesc?: string | null } | null;
      taxonomyCategories?: {
        __typename?: 'ChecklistToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToChecklistConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAuthorQueryVariables = Exact<{
  slug: Scalars['ID']['input'];
}>;

export type GetAuthorQuery = {
  __typename?: 'RootQuery';
  user?: {
    __typename?: 'User';
    description?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    slug?: string | null;
    userId?: number | null;
    acfUser?: {
      __typename?: 'User_Acfuser';
      title?: string | null;
      linkedin?: string | null;
      avatar?: {
        __typename?: 'MediaItem';
        mediaItemUrl?: string | null;
        altText?: string | null;
        mediaDetails?: {
          __typename?: 'MediaDetails';
          height?: number | null;
          width?: number | null;
        } | null;
      } | null;
      translations?: {
        __typename?: 'User_Acfuser_Translations';
        de?: {
          __typename?: 'User_Acfuser_Translations_De';
          firstName?: string | null;
          jobTitle?: string | null;
          lastName?: string | null;
          bio?: string | null;
        } | null;
        es?: {
          __typename?: 'User_Acfuser_Translations_Es';
          firstName?: string | null;
          jobTitle?: string | null;
          lastName?: string | null;
          bio?: string | null;
        } | null;
        fr?: {
          __typename?: 'User_Acfuser_Translations_Fr';
          firstName?: string | null;
          jobTitle?: string | null;
          lastName?: string | null;
          bio?: string | null;
        } | null;
        nl?: {
          __typename?: 'User_Acfuser_Translations_Nl';
          firstName?: string | null;
          jobTitle?: string | null;
          lastName?: string | null;
          bio?: string | null;
        } | null;
        ptBr?: {
          __typename?: 'User_Acfuser_Translations_PtBr';
          jobTitle?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          bio?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetAuthorTopicsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  paged?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAuthorTopicsQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    nodes: Array<{
      __typename?: 'Topic';
      modified?: string | null;
      title?: string | null;
      uri?: string | null;
      acfTopic?: {
        __typename?: 'Topic_Acftopic';
        heroEditor?: string | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Topic_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      seo?: { __typename?: 'PostTypeSEO'; metaDesc?: string | null } | null;
      taxonomyCategories?: {
        __typename?: 'TopicToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToTopicConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAuthorsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAuthorsQuery = {
  __typename?: 'RootQuery';
  users?: {
    __typename?: 'RootQueryToUserConnection';
    nodes: Array<{
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
      slug?: string | null;
      acfUser?: {
        __typename?: 'User_Acfuser';
        title?: string | null;
        avatar?: {
          __typename?: 'MediaItem';
          mediaItemUrl?: string | null;
          altText?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        } | null;
        translations?: {
          __typename?: 'User_Acfuser_Translations';
          de?: {
            __typename?: 'User_Acfuser_Translations_De';
            firstName?: string | null;
            jobTitle?: string | null;
            lastName?: string | null;
          } | null;
          es?: {
            __typename?: 'User_Acfuser_Translations_Es';
            firstName?: string | null;
            jobTitle?: string | null;
            lastName?: string | null;
          } | null;
          fr?: {
            __typename?: 'User_Acfuser_Translations_Fr';
            firstName?: string | null;
            jobTitle?: string | null;
            lastName?: string | null;
          } | null;
          nl?: {
            __typename?: 'User_Acfuser_Translations_Nl';
            firstName?: string | null;
            jobTitle?: string | null;
            lastName?: string | null;
          } | null;
          ptBr?: {
            __typename?: 'User_Acfuser_Translations_PtBr';
            jobTitle?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetChecklistQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    nodes: Array<{
      __typename?: 'Checklist';
      modified?: string | null;
      date?: string | null;
      slug?: string | null;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfChecklist?: {
        __typename?: 'Checklist_Acfchecklist';
        authorShow?: boolean | null;
        contentEditor?: string | null;
        heroEditor?: string | null;
        heroTitle?: string | null;
        introEditor?: string | null;
        faqTitle?: string | null;
        featuredTemplateStatus?: boolean | null;
        faqFrequentlyAskedQuestions?: Array<{
          __typename?: 'Checklist_Acfchecklist_faqFrequentlyAskedQuestions';
          editor?: string | null;
          title?: string | null;
        } | null> | null;
      } | null;
      acfAuthors?: {
        __typename?: 'Checklist_Acfauthors';
        multipleAuthors?: {
          __typename?: 'Checklist_Acfauthors_MultipleAuthors';
          authors?: Array<{
            __typename?: 'Checklist_Acfauthors_MultipleAuthors_authors';
            authorName?: string | null;
          } | null> | null;
        } | null;
      } | null;
      acfFeaturedTemplate?: {
        __typename?: 'Checklist_Acffeaturedtemplate';
        featuredTemplate?: {
          __typename?: 'Checklist_Acffeaturedtemplate_FeaturedTemplate';
          editor?: string | null;
          title?: string | null;
          cta?: {
            __typename?: 'Checklist_Acffeaturedtemplate_FeaturedTemplate_Cta';
            ctaText?: string | null;
            ctaUrl?: string | null;
            templateId?: string | null;
          } | null;
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          previewLinks?: {
            __typename?: 'Checklist_Acffeaturedtemplate_FeaturedTemplate_PreviewLinks';
            digitalReport?: string | null;
            pdfReport?: string | null;
          } | null;
        } | null;
      } | null;
      acfRelatedTemplates?: {
        __typename?: 'Checklist_Acfrelatedtemplates';
        relatedTemplatesEditor?: string | null;
        relatedTemplatesTitle?: string | null;
        relatedTemplatesTemplates?: Array<{
          __typename?: 'Checklist_Acfrelatedtemplates_relatedTemplatesTemplates';
          downloadType?: string | null;
          editor?: string | null;
          title?: string | null;
          cta?: {
            __typename?: 'Checklist_Acfrelatedtemplates_relatedTemplatesTemplates_Cta';
            ctaText?: string | null;
            ctaUrl?: string | null;
            templateId?: string | null;
          } | null;
          previewLinks?: {
            __typename?: 'Checklist_Acfrelatedtemplates_relatedTemplatesTemplates_PreviewLinks';
            digitalReport?: string | null;
            pdfReport?: string | null;
          } | null;
        } | null> | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Checklist_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      parent?: {
        __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
        node:
          | { __typename?: 'App' }
          | {
              __typename?: 'Checklist';
              slug?: string | null;
              title?: string | null;
            }
          | { __typename?: 'CustomerStory' }
          | { __typename?: 'Ebook' }
          | { __typename?: 'Event' }
          | { __typename?: 'GraphqlDocument' }
          | { __typename?: 'Legal' }
          | { __typename?: 'MediaItem' }
          | { __typename?: 'Page' }
          | { __typename?: 'Partnership' }
          | { __typename?: 'Post' }
          | { __typename?: 'Testimonial' }
          | { __typename?: 'Topic' };
      } | null;
      taxonomyCategories?: {
        __typename?: 'ChecklistToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          name?: string | null;
          parentId?: string | null;
          slug?: string | null;
          id: string;
          apps?: {
            __typename?: 'TaxonomyCategoryToAppConnection';
            nodes: Array<{
              __typename?: 'App';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
          checklists?: {
            __typename?: 'TaxonomyCategoryToChecklistConnection';
            nodes: Array<{
              __typename?: 'Checklist';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
          topics?: {
            __typename?: 'TaxonomyCategoryToTopicConnection';
            nodes: Array<{
              __typename?: 'Topic';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
        }>;
      } | null;
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge';
        node: {
          __typename?: 'User';
          description?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          slug?: string | null;
          email?: string | null;
          acfUser?: {
            __typename?: 'User_Acfuser';
            linkedin?: string | null;
            title?: string | null;
            avatar?: {
              __typename?: 'MediaItem';
              mediaItemUrl?: string | null;
            } | null;
            translations?: {
              __typename?: 'User_Acfuser_Translations';
              de?: {
                __typename?: 'User_Acfuser_Translations_De';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              es?: {
                __typename?: 'User_Acfuser_Translations_Es';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              fr?: {
                __typename?: 'User_Acfuser_Translations_Fr';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              nl?: {
                __typename?: 'User_Acfuser_Translations_Nl';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              ptBr?: {
                __typename?: 'User_Acfuser_Translations_PtBr';
                bio?: string | null;
                jobTitle?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          } | null;
          avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
        };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'Checklist';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetChecklistTaxonomyCategoryQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistTaxonomyCategoryQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      checklists?: {
        __typename?: 'TaxonomyCategoryToChecklistConnection';
        nodes: Array<{
          __typename?: 'Checklist';
          id: string;
          title?: string | null;
          uri?: string | null;
          acfChecklist?: {
            __typename?: 'Checklist_Acfchecklist';
            heroEditor?: string | null;
          } | null;
          acfPageStatus?: {
            __typename?: 'Checklist_Acfpagestatus';
            draftPublic?: boolean | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          seo?: {
            __typename?: 'PostTypeSEO';
            canonical?: string | null;
            metaDesc?: string | null;
            metaRobotsNofollow?: string | null;
            metaRobotsNoindex?: string | null;
            title?: string | null;
          } | null;
          taxonomyCategories?: {
            __typename?: 'ChecklistToTaxonomyCategoryConnection';
            nodes: Array<{
              __typename?: 'TaxonomyCategory';
              id: string;
              name?: string | null;
              slug?: string | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetChecklistsForArchiveQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetChecklistsForArchiveQuery = {
  __typename?: 'RootQuery';
  checklists?: {
    __typename?: 'RootQueryToChecklistConnection';
    nodes: Array<{
      __typename?: 'Checklist';
      id: string;
      title?: string | null;
      uri?: string | null;
      acfChecklist?: {
        __typename?: 'Checklist_Acfchecklist';
        authorShow?: boolean | null;
        contentEditor?: string | null;
        heroEditor?: string | null;
        heroTitle?: string | null;
        introEditor?: string | null;
        faqTitle?: string | null;
        faqFrequentlyAskedQuestions?: Array<{
          __typename?: 'Checklist_Acfchecklist_faqFrequentlyAskedQuestions';
          editor?: string | null;
          title?: string | null;
        } | null> | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Checklist_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      seo?: { __typename?: 'PostTypeSEO'; metaDesc?: string | null } | null;
      taxonomyCategories?: {
        __typename?: 'ChecklistToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetCustomerStoriesArchiveQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCustomerStoriesArchiveQuery = {
  __typename?: 'RootQuery';
  customerStoriesArchive?: {
    __typename?: 'CustomerStoriesArchive';
    acfCustomerStroriesArchive?: {
      __typename?: 'CustomerStoriesArchive_Acfcustomerstroriesarchive';
      featured?: {
        __typename?: 'CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured';
        featured?: Array<{
          __typename?: 'CustomerStoriesArchive_Acfcustomerstroriesarchive_Featured_featured';
          post?: {
            __typename?: 'CustomerStory';
            title?: string | null;
            uri?: string | null;
            acfCustomerStory?: {
              __typename?: 'CustomerStory_Acfcustomerstory';
              logo?: {
                __typename?: 'CustomerStory_Acfcustomerstory_Logo';
                image?: {
                  __typename?: 'MediaItem';
                  altText?: string | null;
                  mediaItemUrl?: string | null;
                  mediaDetails?: {
                    __typename?: 'MediaDetails';
                    height?: number | null;
                    width?: number | null;
                  } | null;
                } | null;
              } | null;
              intro?: {
                __typename?: 'CustomerStory_Acfcustomerstory_Intro';
                title?: string | null;
                editor?: string | null;
              } | null;
            } | null;
            featuredImage?: {
              __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
              node: {
                __typename?: 'MediaItem';
                altText?: string | null;
                mediaItemUrl?: string | null;
                mediaDetails?: {
                  __typename?: 'MediaDetails';
                  height?: number | null;
                  width?: number | null;
                } | null;
              };
            } | null;
            translated?: Array<{
              __typename?: 'CustomerStory';
              uri?: string | null;
              language?: { __typename?: 'Language'; code: string } | null;
              acfCustomerStory?: {
                __typename?: 'CustomerStory_Acfcustomerstory';
                intro?: {
                  __typename?: 'CustomerStory_Acfcustomerstory_Intro';
                  title?: string | null;
                  editor?: string | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetCustomerStoriesQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCustomerStoriesQuery = {
  __typename?: 'RootQuery';
  customerStories?: {
    __typename?: 'RootQueryToCustomerStoryConnection';
    nodes: Array<{
      __typename?: 'CustomerStory';
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfCustomerStory?: {
        __typename?: 'CustomerStory_Acfcustomerstory';
        intro?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Intro';
          editor?: string | null;
          title?: string | null;
        } | null;
        logo?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Logo';
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'CustomerStory_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      taxonomyIndustries?: {
        __typename?: 'CustomerStoryToTaxonomyIndustryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyIndustry';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      taxonomyRegions?: {
        __typename?: 'CustomerStoryToTaxonomyRegionConnection';
        nodes: Array<{
          __typename?: 'TaxonomyRegion';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      taxonomySolutions?: {
        __typename?: 'CustomerStoryToTaxonomySolutionConnection';
        nodes: Array<{
          __typename?: 'TaxonomySolution';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      translated?: Array<{
        __typename?: 'CustomerStory';
        slug?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToCustomerStoryConnectionPageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetCustomerStoryQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCustomerStoryQuery = {
  __typename?: 'RootQuery';
  customerStories?: {
    __typename?: 'RootQueryToCustomerStoryConnection';
    nodes: Array<{
      __typename?: 'CustomerStory';
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      id: string;
      acfCustomerStory?: {
        __typename?: 'CustomerStory_Acfcustomerstory';
        intro?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Intro';
          editor?: string | null;
          title?: string | null;
        } | null;
        logo?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Logo';
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        image?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Image';
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        highlights?: {
          __typename?: 'CustomerStory_Acfcustomerstory_Highlights';
          customise?: {
            __typename?: 'CustomerStory_Acfcustomerstory_Highlights_Customise';
            columns?: string | null;
            alignment?: string | null;
          } | null;
          highlights?: Array<{
            __typename?: 'CustomerStory_Acfcustomerstory_Highlights_highlights';
            highlight?: {
              __typename?: 'CustomerStory_Acfcustomerstory_Highlights_highlights_Highlight';
              editor?: string | null;
              customise?: {
                __typename?: 'CustomerStory_Acfcustomerstory_Highlights_highlights_Highlight_Customise';
                color?: string | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        section?: Array<
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Block';
              gallery?: Array<
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Image';
                    image?: Array<{
                      __typename?: 'MediaItem';
                      altText?: string | null;
                      mediaItemUrl?: string | null;
                      mediaDetails?: {
                        __typename?: 'MediaDetails';
                        height?: number | null;
                        width?: number | null;
                      } | null;
                    } | null> | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video';
                    video?: Array<{
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video';
                      video?: Array<
                        | {
                            __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Wistia';
                            wistia?: {
                              __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Wistia_Wistia';
                              id?: string | null;
                            } | null;
                          }
                        | {
                            __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Youtube';
                            youtube?: {
                              __typename?: 'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Youtube_Youtube';
                              id?: string | null;
                            } | null;
                          }
                        | null
                      > | null;
                      thumbnail?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    } | null> | null;
                  }
                | null
              > | null;
            }
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta';
              cta?: {
                __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta';
                id?: string | null;
                customise?: {
                  __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Customise';
                  background?: string | null;
                  fieldGroupName?: string | null;
                  width?: string | null;
                } | null;
                content?: Array<
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks';
                      checks?: Array<{
                        __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks_checks';
                        color?: string | null;
                        title?: string | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Editor';
                      editor?: string | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Embed';
                      embed?: string | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Eventbrite';
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList';
                      iconsList?: Array<{
                        __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList_iconsList';
                        title?: string | null;
                        icon?: {
                          __typename?: 'MediaItem';
                          mediaItemUrl?: string | null;
                          altText?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links';
                      links?: Array<{
                        __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_links';
                        style?: string | null;
                        icon?: {
                          __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links_links_Icon';
                          icon?: string | null;
                          position?: string | null;
                        } | null;
                        link?: {
                          __typename?: 'AcfLink';
                          target?: string | null;
                          title?: string | null;
                          url?: string | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Product';
                      product?: string | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Search';
                      search?: string | null;
                    }
                  | {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Title';
                      title?: string | null;
                    }
                  | null
                > | null;
              } | null;
            }
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Editor';
              editor?: string | null;
            }
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Image';
              image?: {
                __typename?: 'MediaItem';
                altText?: string | null;
                mediaItemUrl?: string | null;
                mediaDetails?: {
                  __typename?: 'MediaDetails';
                  height?: number | null;
                  width?: number | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote';
              quote?: Array<
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author';
                    author?: {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author_Author';
                      title?: string | null;
                      name?: string | null;
                      avatar?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Chip';
                    chip?: string | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Editor';
                    editor?: string | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Image';
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Link';
                    link?: {
                      __typename?: 'AcfLink';
                      target?: string | null;
                      title?: string | null;
                      url?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo';
                    logo?: {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo_Logo';
                      height?: number | null;
                      logo?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Rating';
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Title';
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Video';
                  }
                | null
              > | null;
            }
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Title';
              title?: string | null;
            }
          | {
              __typename?: 'CustomerStory_Acfcustomerstory_Section_Video';
              video?: Array<
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia';
                    wistia?: {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia_Wistia';
                      id?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube';
                    youtube?: {
                      __typename?: 'CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube_Youtube';
                      id?: string | null;
                    } | null;
                  }
                | null
              > | null;
            }
          | null
        > | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'CustomerStory_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      taxonomySolutions?: {
        __typename?: 'CustomerStoryToTaxonomySolutionConnection';
        nodes: Array<{
          __typename?: 'TaxonomySolution';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      taxonomyIndustries?: {
        __typename?: 'CustomerStoryToTaxonomyIndustryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyIndustry';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'CustomerStory';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
  taxonomySolutions?: {
    __typename?: 'RootQueryToTaxonomySolutionConnection';
    nodes: Array<{
      __typename?: 'TaxonomySolution';
      id: string;
      name?: string | null;
      slug?: string | null;
    }>;
  } | null;
};

export type GetEbookQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEbookQuery = {
  __typename?: 'RootQuery';
  ebooks?: {
    __typename?: 'RootQueryToEbookConnection';
    nodes: Array<{
      __typename?: 'Ebook';
      modified?: string | null;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfEbook?: {
        __typename?: 'Ebook_Acfebook';
        contentEditor?: string | null;
        heroEditor?: string | null;
        heroTitle?: string | null;
        hubspotForm?: {
          __typename?: 'Ebook_Acfebook_HubspotForm';
          title?: string | null;
          hubspotForm?: {
            __typename?: 'Ebook_Acfebook_HubspotForm_HubspotForm';
            message?: string | null;
            id?: {
              __typename?: 'Ebook_Acfebook_HubspotForm_HubspotForm_Id';
              portalId?: string | null;
              formId?: string | null;
              contentId?: string | null;
            } | null;
            redirect?: {
              __typename?: 'AcfLink';
              title?: string | null;
              url?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Ebook_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'Ebook';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetEbooksQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEbooksQuery = {
  __typename?: 'RootQuery';
  ebooks?: {
    __typename?: 'RootQueryToEbookConnection';
    nodes: Array<{
      __typename?: 'Ebook';
      title?: string | null;
      uri?: string | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      acfPageStatus?: {
        __typename?: 'Ebook_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type GetEventQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEventQuery = {
  __typename?: 'RootQuery';
  events?: {
    __typename?: 'RootQueryToEventConnection';
    nodes: Array<{
      __typename?: 'Event';
      eventId: number;
      modified?: string | null;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfEvent?: {
        __typename?: 'Event_Acfevent';
        heroTitle?: string | null;
        heroIntro?: string | null;
        background?: string | null;
        customiseRegisterPageUrl?: string | null;
        customiseEventProductArea?: Array<string | null> | null;
        customiseEventDateTo?: string | null;
        customiseEventDeliveryType?: string | null;
        customiseRegions?: Array<string | null> | null;
        customiseEventDate?: string | null;
        customiseEventTimeEnd?: string | null;
        customiseEventTimeStart?: string | null;
        customiseEventType?: string | null;
        customiseEventGmt?: string | null;
        customiseEventFeatured?: boolean | null;
        customiseEditor?: string | null;
        contentContent?: Array<
          | {
              __typename?: 'Event_Acfevent_ContentContent_Editor';
              editor?: string | null;
            }
          | {
              __typename?: 'Event_Acfevent_ContentContent_Eventbrite';
              eventbrite?: {
                __typename?: 'Event_Acfevent_ContentContent_Eventbrite_Eventbrite';
                height?: number | null;
                id?: string | null;
              } | null;
            }
          | {
              __typename?: 'Event_Acfevent_ContentContent_Image';
              image?: {
                __typename?: 'MediaItem';
                altText?: string | null;
                mediaItemUrl?: string | null;
                mediaDetails?: {
                  __typename?: 'MediaDetails';
                  height?: number | null;
                  width?: number | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Event_Acfevent_ContentContent_Table';
              table?: {
                __typename?: 'ACF_Table';
                body?: Array<Array<string | null> | null> | null;
                header?: Array<string | null> | null;
              } | null;
            }
          | {
              __typename?: 'Event_Acfevent_ContentContent_Title';
              title?: string | null;
            }
          | {
              __typename?: 'Event_Acfevent_ContentContent_Video';
              video?: Array<
                | {
                    __typename?: 'Event_Acfevent_ContentContent_Video_Video_Wistia';
                    wistia?: {
                      __typename?: 'Event_Acfevent_ContentContent_Video_Video_Wistia_Wistia';
                      id?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Event_Acfevent_ContentContent_Video_Video_Youtube';
                    youtube?: {
                      __typename?: 'Event_Acfevent_ContentContent_Video_Video_Youtube_Youtube';
                      id?: string | null;
                    } | null;
                  }
                | null
              > | null;
            }
          | null
        > | null;
        heroImage?: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        } | null;
        sidebarSidebar?: Array<
          | {
              __typename?: 'Event_Acfevent_SidebarSidebar_Editor';
              editor?: string | null;
            }
          | {
              __typename?: 'Event_Acfevent_SidebarSidebar_Eventbrite';
              eventbrite?: {
                __typename?: 'Event_Acfevent_SidebarSidebar_Eventbrite_Eventbrite';
                height?: number | null;
                id?: string | null;
              } | null;
            }
          | {
              __typename?: 'Event_Acfevent_SidebarSidebar_HubspotForm';
              hubspotForm?: {
                __typename?: 'Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm';
                message?: string | null;
                id?: {
                  __typename?: 'Event_Acfevent_SidebarSidebar_HubspotForm_HubspotForm_Id';
                  portalId?: string | null;
                  formId?: string | null;
                  contentId?: string | null;
                } | null;
                redirect?: {
                  __typename?: 'AcfLink';
                  title?: string | null;
                  url?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Event_Acfevent_SidebarSidebar_Link';
              link?: {
                __typename?: 'AcfLink';
                target?: string | null;
                title?: string | null;
                url?: string | null;
              } | null;
            }
          | {
              __typename?: 'Event_Acfevent_SidebarSidebar_Title';
              title?: string | null;
            }
          | {
              __typename?: 'Event_Acfevent_SidebarSidebar_Wpforms';
              wpforms?: string | null;
            }
          | null
        > | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Event_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'Event';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetEventsForArchiveQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetEventsForArchiveQuery = {
  __typename?: 'RootQuery';
  events?: {
    __typename?: 'RootQueryToEventConnection';
    nodes: Array<{
      __typename?: 'Event';
      title?: string | null;
      uri?: string | null;
      acfEvent?: {
        __typename?: 'Event_Acfevent';
        customiseEditor?: string | null;
        customiseEventProductArea?: Array<string | null> | null;
        customiseEventDateTo?: string | null;
        customiseEventDeliveryType?: string | null;
        customiseRegions?: Array<string | null> | null;
        customiseEventDate?: string | null;
        customiseEventTimeEnd?: string | null;
        customiseEventTimeStart?: string | null;
        customiseEventType?: string | null;
        customiseEventGmt?: string | null;
        customiseEventFeatured?: boolean | null;
        heroIntro?: string | null;
        customiseLocation?: string | null;
        customiseRegisterPageUrl?: string | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      acfPageStatus?: {
        __typename?: 'Event_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type GetLegalQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetLegalQuery = {
  __typename?: 'RootQuery';
  legals?: {
    __typename?: 'RootQueryToLegalConnection';
    nodes: Array<{
      __typename?: 'Legal';
      legalId: number;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfLegal?: {
        __typename?: 'Legal_Acflegal';
        section?: Array<
          | {
              __typename?: 'Legal_Acflegal_Section_Article';
              article?: {
                __typename?: 'Legal_Acflegal_Section_Article_Article';
                id?: string | null;
                content?: Array<{
                  __typename?: 'Legal_Acflegal_Section_Article_Article_Content_Editor';
                  editor?: string | null;
                } | null> | null;
                customise?: {
                  __typename?: 'Legal_Acflegal_Section_Article_Article_Customise';
                  archive?: boolean | null;
                  tableOfContents?: boolean | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Legal_Acflegal_Section_Form';
              id?: string | null;
              customise?: {
                __typename?: 'Legal_Acflegal_Section_Form_Customise';
                background?: string | null;
              } | null;
              form?: Array<
                | {
                    __typename?: 'Legal_Acflegal_Section_Form_Form_Chip';
                    chip?: string | null;
                  }
                | {
                    __typename?: 'Legal_Acflegal_Section_Form_Form_Disclaimer';
                    disclaimer?: string | null;
                  }
                | {
                    __typename?: 'Legal_Acflegal_Section_Form_Form_Editor';
                    editor?: string | null;
                  }
                | {
                    __typename?: 'Legal_Acflegal_Section_Form_Form_Form';
                    form?: Array<
                      | {
                          __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm';
                          hubspotForm?: {
                            __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm';
                            message?: string | null;
                            id?: {
                              __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm_HubspotForm_Id';
                              portalId?: string | null;
                              formId?: string | null;
                              contentId?: string | null;
                            } | null;
                            redirect?: {
                              __typename?: 'AcfLink';
                              target?: string | null;
                              title?: string | null;
                              url?: string | null;
                            } | null;
                          } | null;
                        }
                      | {
                          __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_KustomerForm';
                        }
                      | {
                          __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_SignUp';
                        }
                      | {
                          __typename?: 'Legal_Acflegal_Section_Form_Form_Form_Form_Wpforms';
                          wpforms?: string | null;
                        }
                      | null
                    > | null;
                  }
                | {
                    __typename?: 'Legal_Acflegal_Section_Form_Form_Title';
                    title?: string | null;
                  }
                | null
              > | null;
            }
          | null
        > | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Legal_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      children?: {
        __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
        nodes: Array<
          | { __typename?: 'App'; uri?: string | null }
          | { __typename?: 'Checklist'; uri?: string | null }
          | { __typename?: 'CustomerStory'; uri?: string | null }
          | { __typename?: 'Ebook'; uri?: string | null }
          | { __typename?: 'Event'; uri?: string | null }
          | { __typename?: 'GraphqlDocument'; uri?: string | null }
          | { __typename?: 'Legal'; title?: string | null; uri?: string | null }
          | { __typename?: 'MediaItem'; uri?: string | null }
          | { __typename?: 'Page'; uri?: string | null }
          | { __typename?: 'Partnership'; uri?: string | null }
          | { __typename?: 'Post'; uri?: string | null }
          | { __typename?: 'Testimonial'; uri?: string | null }
          | { __typename?: 'Topic'; uri?: string | null }
        >;
      } | null;
      parent?: {
        __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
        node:
          | { __typename?: 'App' }
          | { __typename?: 'Checklist' }
          | { __typename?: 'CustomerStory' }
          | { __typename?: 'Ebook' }
          | { __typename?: 'Event' }
          | { __typename?: 'GraphqlDocument' }
          | {
              __typename?: 'Legal';
              slug?: string | null;
              title?: string | null;
              parent?: {
                __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
                node:
                  | { __typename?: 'App' }
                  | { __typename?: 'Checklist' }
                  | { __typename?: 'CustomerStory' }
                  | { __typename?: 'Ebook' }
                  | { __typename?: 'Event' }
                  | { __typename?: 'GraphqlDocument' }
                  | {
                      __typename?: 'Legal';
                      slug?: string | null;
                      title?: string | null;
                    }
                  | { __typename?: 'MediaItem' }
                  | { __typename?: 'Page' }
                  | { __typename?: 'Partnership' }
                  | { __typename?: 'Post' }
                  | { __typename?: 'Testimonial' }
                  | { __typename?: 'Topic' };
              } | null;
            }
          | { __typename?: 'MediaItem' }
          | { __typename?: 'Page' }
          | { __typename?: 'Partnership' }
          | { __typename?: 'Post' }
          | { __typename?: 'Testimonial' }
          | { __typename?: 'Topic' };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'Legal';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetPageContentQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPageContentQuery = {
  __typename?: 'RootQuery';
  pages?: {
    __typename?: 'RootQueryToPageConnection';
    nodes: Array<{
      __typename?: 'Page';
      pageId: number;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfPage?: {
        __typename?: 'Page_Acfpage';
        section?: Array<
          | {
              __typename?: 'Page_Acfpage_Section_AppFeatures';
              appFeatures?: {
                __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures';
                featuresTitle?: string | null;
                cards?: Array<{
                  __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards';
                  region?: Array<string | null> | null;
                  card?: Array<
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Chip';
                        chip?: string | null;
                        color?: string | null;
                      }
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Editor';
                        editor?: string | null;
                      }
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight';
                        highlight?: {
                          __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight_Highlight';
                          editor?: string | null;
                          customise?: {
                            __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight_Highlight_Customise';
                            color?: string | null;
                          } | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Icon';
                        icon?: {
                          __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Icon_Icon';
                          border?: boolean | null;
                          height?: number | null;
                          icon?: {
                            __typename?: 'MediaItem';
                            altText?: string | null;
                            mediaItemUrl?: string | null;
                            mediaDetails?: {
                              __typename?: 'MediaDetails';
                              height?: number | null;
                              width?: number | null;
                            } | null;
                          } | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Image';
                        image?: {
                          __typename?: 'MediaItem';
                          altText?: string | null;
                          mediaItemUrl?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      }
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links';
                        links?: Array<{
                          __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links_links';
                          style?: string | null;
                          link?: {
                            __typename?: 'AcfLink';
                            target?: string | null;
                            title?: string | null;
                            url?: string | null;
                          } | null;
                          icon?: {
                            __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links_links_Icon';
                            position?: string | null;
                            icon?: string | null;
                          } | null;
                        } | null> | null;
                      }
                    | {
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title';
                        title?: string | null;
                        titleOptions?: {
                          __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title_TitleOptions';
                          badge?: {
                            __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title_TitleOptions_Badge';
                            background?: string | null;
                            text?: string | null;
                          } | null;
                          icon?: {
                            __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title_TitleOptions_Icon';
                            border?: boolean | null;
                            height?: number | null;
                            icon?: {
                              __typename?: 'MediaItem';
                              altText?: string | null;
                              mediaItemUrl?: string | null;
                              mediaDetails?: {
                                __typename?: 'MediaDetails';
                                height?: number | null;
                                width?: number | null;
                              } | null;
                            } | null;
                          } | null;
                        } | null;
                      }
                    | null
                  > | null;
                  customise?: {
                    __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Customise';
                    alignment?: string | null;
                    link?: {
                      __typename?: 'AcfLink';
                      target?: string | null;
                      title?: string | null;
                      url?: string | null;
                    } | null;
                  } | null;
                } | null> | null;
                content?: Array<
                  | {
                      __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Editor';
                      editor?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links';
                      links?: Array<{
                        __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_links';
                        style?: string | null;
                        link?: {
                          __typename?: 'AcfLink';
                          target?: string | null;
                          title?: string | null;
                          url?: string | null;
                        } | null;
                        icon?: {
                          __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links_links_Icon';
                          position?: string | null;
                          icon?: string | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Title';
                      title?: string | null;
                    }
                  | null
                > | null;
                customise?: {
                  __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_Customise';
                  alignment?: string | null;
                  columns?: string | null;
                } | null;
                features?: Array<{
                  __typename?: 'Page_Acfpage_Section_AppFeatures_AppFeatures_features';
                  new?: boolean | null;
                  region?: Array<string | null> | null;
                  icon?: {
                    __typename?: 'MediaItem';
                    altText?: string | null;
                    mediaItemUrl?: string | null;
                    mediaDetails?: {
                      __typename?: 'MediaDetails';
                      height?: number | null;
                      width?: number | null;
                    } | null;
                  } | null;
                  button?: {
                    __typename?: 'AcfLink';
                    target?: string | null;
                    title?: string | null;
                    url?: string | null;
                  } | null;
                } | null> | null;
                leftImage?: {
                  __typename?: 'MediaItem';
                  altText?: string | null;
                  mediaItemUrl?: string | null;
                  mediaDetails?: {
                    __typename?: 'MediaDetails';
                    height?: number | null;
                    width?: number | null;
                  } | null;
                } | null;
                middleImage?: {
                  __typename?: 'MediaItem';
                  altText?: string | null;
                  mediaItemUrl?: string | null;
                  mediaDetails?: {
                    __typename?: 'MediaDetails';
                    height?: number | null;
                    width?: number | null;
                  } | null;
                } | null;
                rightImage?: {
                  __typename?: 'MediaItem';
                  altText?: string | null;
                  mediaItemUrl?: string | null;
                  mediaDetails?: {
                    __typename?: 'MediaDetails';
                    height?: number | null;
                    width?: number | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Article';
              article?: {
                __typename?: 'Page_Acfpage_Section_Article_Article';
                id?: string | null;
                content?: Array<{
                  __typename?: 'Page_Acfpage_Section_Article_Article_Content_Editor';
                  editor?: string | null;
                } | null> | null;
                customise?: {
                  __typename?: 'Page_Acfpage_Section_Article_Article_Customise';
                  archive?: boolean | null;
                  tableOfContents?: boolean | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Block';
              block?: {
                __typename?: 'Page_Acfpage_Section_Block_Block';
                id?: string | null;
                content?: Array<
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box';
                      box?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box';
                        content?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Chip';
                              chip?: string | null;
                              color?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Editor';
                              editor?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon';
                              icon?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon_Icon';
                                border?: boolean | null;
                                height?: number | null;
                                icon?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links';
                              links?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links_links';
                                style?: string | null;
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Title';
                              title?: string | null;
                            }
                          | null
                        > | null;
                        customise?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Customise';
                          background?: string | null;
                          reverse?: boolean | null;
                        } | null;
                        media?: Array<{
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Box_Box_Media_Image';
                          image?: {
                            __typename?: 'MediaItem';
                            altText?: string | null;
                            mediaItemUrl?: string | null;
                            mediaDetails?: {
                              __typename?: 'MediaDetails';
                              height?: number | null;
                              width?: number | null;
                            } | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards';
                      cards?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards';
                        card?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Chip';
                              chip?: string | null;
                              color?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Editor';
                              editor?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight';
                              highlight?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight_Highlight';
                                editor?: string | null;
                                customise?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight_Highlight_Customise';
                                  color?: string | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Icon';
                              icon?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Icon_Icon';
                                border?: boolean | null;
                                height?: number | null;
                                icon?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Image';
                              image?: {
                                __typename?: 'MediaItem';
                                altText?: string | null;
                                mediaItemUrl?: string | null;
                                mediaDetails?: {
                                  __typename?: 'MediaDetails';
                                  height?: number | null;
                                  width?: number | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Links';
                              links?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Links_links';
                                style?: string | null;
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Title';
                              title?: string | null;
                            }
                          | null
                        > | null;
                        customise?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Customise';
                          alignment?: string | null;
                          link?: {
                            __typename?: 'AcfLink';
                            target?: string | null;
                            title?: string | null;
                            url?: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Cards_Customise';
                        alignment?: string | null;
                        columns?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Chip';
                      chip?: string | null;
                      color?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns';
                      columns?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns_Columns';
                        columns?: Array<{
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns_Columns_columns';
                          editor?: string | null;
                        } | null> | null;
                        customise?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Columns_Columns_Customise';
                          columns?: string | null;
                        } | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Disclaimer';
                      disclaimer?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Editor';
                      editor?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Embed';
                      embed?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Eventbrite';
                      eventbrite?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Eventbrite_Eventbrite';
                        id?: string | null;
                        height?: number | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Faq';
                      faq?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Faq_faq';
                        editor?: string | null;
                        title?: string | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features';
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_Customise';
                        alignment?: string | null;
                        columns?: string | null;
                      } | null;
                      features?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features';
                        customise?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Customise';
                          hollow?: boolean | null;
                          background?: {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Customise_Background';
                            background?: string | null;
                          } | null;
                          border?: {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Customise_Border';
                            border?: string | null;
                          } | null;
                        } | null;
                        feature?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Editor';
                              editor?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Icon';
                              icon?: {
                                __typename?: 'MediaItem';
                                altText?: string | null;
                                mediaItemUrl?: string | null;
                                mediaDetails?: {
                                  __typename?: 'MediaDetails';
                                  height?: number | null;
                                  width?: number | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Links';
                              links?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Links_links';
                                style?: string | null;
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Note';
                              note?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Note_Note';
                                background?: string | null;
                                editor?: string | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Title';
                              title?: string | null;
                            }
                          | null
                        > | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery';
                      gallery?: Array<
                        | {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Image';
                            image?: Array<{
                              __typename?: 'MediaItem';
                              altText?: string | null;
                              mediaItemUrl?: string | null;
                              mediaDetails?: {
                                __typename?: 'MediaDetails';
                                height?: number | null;
                                width?: number | null;
                              } | null;
                            } | null> | null;
                          }
                        | {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video';
                            video?: Array<{
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video';
                              thumbnail?: {
                                __typename?: 'MediaItem';
                                altText?: string | null;
                                mediaItemUrl?: string | null;
                                mediaDetails?: {
                                  __typename?: 'MediaDetails';
                                  height?: number | null;
                                  width?: number | null;
                                } | null;
                              } | null;
                              video?: Array<
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Wistia';
                                    wistia?: {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Wistia_Wistia';
                                      id?: string | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Youtube';
                                    youtube?: {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Youtube_Youtube';
                                      id?: string | null;
                                    } | null;
                                  }
                                | null
                              > | null;
                            } | null> | null;
                          }
                        | null
                      > | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights';
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_Customise';
                        alignment?: string | null;
                        columns?: string | null;
                      } | null;
                      highlights?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_highlights';
                        highlight?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_highlights_Highlight';
                          editor?: string | null;
                          customise?: {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Highlights_highlights_Highlight_Customise';
                            color?: string | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_HubspotForm';
                      hubspotForm?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm';
                        message?: string | null;
                        id?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_HubspotForm_HubspotForm_Id';
                          portalId?: string | null;
                          formId?: string | null;
                          contentId?: string | null;
                        } | null;
                        redirect?: {
                          __typename?: 'AcfLink';
                          title?: string | null;
                          url?: string | null;
                        } | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_IconsList';
                      iconsList?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_IconsList_iconsList';
                        title?: string | null;
                        icon?: {
                          __typename?: 'MediaItem';
                          altText?: string | null;
                          mediaItemUrl?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Image';
                      image?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Image_Image';
                        height?: number | null;
                        image?: {
                          __typename?: 'MediaItem';
                          altText?: string | null;
                          mediaItemUrl?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Lever';
                      lever?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Lever_Lever';
                        allJobs?: boolean | null;
                        maxJobs?: number | null;
                        url?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Links';
                      links?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Links_links';
                        style?: string | null;
                        link?: {
                          __typename?: 'AcfLink';
                          target?: string | null;
                          title?: string | null;
                          url?: string | null;
                        } | null;
                        icon?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Links_links_Icon';
                          position?: string | null;
                          icon?: string | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns';
                      linksColumns?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns';
                        editor?: string | null;
                        layout?: string | null;
                        links?: Array<{
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_links';
                          style?: string | null;
                          link?: {
                            __typename?: 'AcfLink';
                            title?: string | null;
                            url?: string | null;
                            target?: string | null;
                          } | null;
                          icon?: {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_LinksColumns_LinksColumns_links_Icon';
                            icon?: string | null;
                            position?: string | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Logos';
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Logos_Customise';
                        alignment?: string | null;
                        grayscale?: boolean | null;
                        size?: string | null;
                      } | null;
                      logos?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Logos_logos';
                        region?: Array<string | null> | null;
                        logo?: {
                          __typename?: 'MediaItem';
                          altText?: string | null;
                          mediaItemUrl?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Marketo';
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Product';
                      product?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes';
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_Customise';
                        alignment?: string | null;
                        columns?: string | null;
                      } | null;
                      quotes?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes';
                        quote?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Author';
                              author?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Author_Author';
                                name?: string | null;
                                title?: string | null;
                                avatar?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Chip';
                              chip?: string | null;
                              color?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Editor';
                              editor?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Image';
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Link';
                              link?: {
                                __typename?: 'AcfLink';
                                target?: string | null;
                                title?: string | null;
                                url?: string | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Logo';
                              logo?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Logo_Logo';
                                height?: number | null;
                                logo?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Rating';
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Title';
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Video';
                            }
                          | null
                        > | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_RecentPosts';
                      header?: string | null;
                      postType?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_RoiCalculator';
                      roiCalculator?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_RoiCalculator_RoiCalculator';
                        afterCalculatorDisclaimer?: string | null;
                        afterCalculatorDisclaimerTitle?: string | null;
                        emailSectionTitle?: string | null;
                        resultsDisclaimer?: string | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_SignUp';
                      signUpForm?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm';
                        redirect?: string | null;
                        couponCode?: string | null;
                        buttonText?: string | null;
                        excludeFromSafetycultureOnboardingComms?: string | null;
                        preloadTemplatesBy?: string | null;
                        salesforceCampaignId?: string | null;
                        salesforceCampaignTag?: string | null;
                        salesforceLeadSource?: string | null;
                        showAddressFields?: string | null;
                        showMembershipId?: string | null;
                        templateSource?: string | null;
                        termsAndConditions?: string | null;
                        formId?: string | null;
                        title?: string | null;
                        areYouAWoolGrower?: string | null;
                        preloadTemplates?: Array<{
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_SignUp_SignUpForm_preloadTemplates';
                          id?: string | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spacer';
                      spacer?: number | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split';
                      split?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split';
                        content?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion';
                              accordion?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion';
                                title?: string | null;
                                content?: Array<
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Checks';
                                      checks?: Array<{
                                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Checks_checks';
                                        color?: string | null;
                                        title?: string | null;
                                      } | null> | null;
                                    }
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Editor';
                                      editor?: string | null;
                                    }
                                  | null
                                > | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks';
                              checks?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks_checks';
                                color?: string | null;
                                title?: string | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Chip';
                              chip?: string | null;
                              color?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Disclaimer';
                              disclaimer?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Editor';
                              editor?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature';
                              feature?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature';
                                customise?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise';
                                  hollow?: boolean | null;
                                  background?: {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Background';
                                    background?: string | null;
                                  } | null;
                                  border?: {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Customise_Border';
                                    border?: string | null;
                                  } | null;
                                } | null;
                                feature?: Array<
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Editor';
                                      editor?: string | null;
                                    }
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Icon';
                                      icon?: {
                                        __typename?: 'MediaItem';
                                        altText?: string | null;
                                        mediaItemUrl?: string | null;
                                        mediaDetails?: {
                                          __typename?: 'MediaDetails';
                                          height?: number | null;
                                          width?: number | null;
                                        } | null;
                                      } | null;
                                    }
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links';
                                      links?: Array<{
                                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_links';
                                        style?: string | null;
                                        link?: {
                                          __typename?: 'AcfLink';
                                          target?: string | null;
                                          title?: string | null;
                                          url?: string | null;
                                        } | null;
                                        icon?: {
                                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links_links_Icon';
                                          position?: string | null;
                                          icon?: string | null;
                                        } | null;
                                      } | null> | null;
                                    }
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Note';
                                    }
                                  | {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Title';
                                      title?: string | null;
                                    }
                                  | null
                                > | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight';
                              highlight?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight';
                                editor?: string | null;
                                customise?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight_Highlight_Customise';
                                  color?: string | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm';
                              hubspotForm?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm';
                                message?: string | null;
                                id?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm_HubspotForm_Id';
                                  portalId?: string | null;
                                  formId?: string | null;
                                  contentId?: string | null;
                                } | null;
                                redirect?: {
                                  __typename?: 'AcfLink';
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links';
                              links?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_links';
                                style?: string | null;
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                                icon?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links_links_Icon';
                                  position?: string | null;
                                  icon?: string | null;
                                } | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos';
                              customise?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_Customise';
                                alignment?: string | null;
                                grayscale?: boolean | null;
                                size?: string | null;
                              } | null;
                              logos?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos_logos';
                                region?: Array<string | null> | null;
                                logo?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Marketo';
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Product';
                              product?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote';
                              quote?: Array<
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author';
                                    author?: {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author_Author';
                                      name?: string | null;
                                      title?: string | null;
                                      avatar?: {
                                        __typename?: 'MediaItem';
                                        altText?: string | null;
                                        mediaItemUrl?: string | null;
                                        mediaDetails?: {
                                          __typename?: 'MediaDetails';
                                          height?: number | null;
                                          width?: number | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Chip';
                                    chip?: string | null;
                                    color?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Editor';
                                    editor?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Image';
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Link';
                                    link?: {
                                      __typename?: 'AcfLink';
                                      target?: string | null;
                                      title?: string | null;
                                      url?: string | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo';
                                    logo?: {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo_Logo';
                                      height?: number | null;
                                      logo?: {
                                        __typename?: 'MediaItem';
                                        altText?: string | null;
                                        mediaItemUrl?: string | null;
                                        mediaDetails?: {
                                          __typename?: 'MediaDetails';
                                          height?: number | null;
                                          width?: number | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Rating';
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Title';
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Video';
                                  }
                                | null
                              > | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Spacer';
                              spacer?: number | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Table';
                              table?: {
                                __typename?: 'ACF_Table';
                                body?: Array<Array<
                                  string | null
                                > | null> | null;
                                header?: Array<string | null> | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Title';
                              title?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Wpforms';
                              wpforms?: string | null;
                            }
                          | null
                        > | null;
                        customise?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Customise';
                          reverse?: boolean | null;
                          vertical?: string | null;
                          width?: string | null;
                          disableReverseOnMobile?: boolean | null;
                        } | null;
                        media?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card';
                              card?: Array<
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Chip';
                                    chip?: string | null;
                                    color?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Editor';
                                    editor?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight';
                                    highlight?: {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight';
                                      editor?: string | null;
                                      customise?: {
                                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight_Highlight_Customise';
                                        color?: string | null;
                                      } | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon';
                                    icon?: {
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon_Icon';
                                      border?: boolean | null;
                                      height?: number | null;
                                      icon?: {
                                        __typename?: 'MediaItem';
                                        altText?: string | null;
                                        mediaItemUrl?: string | null;
                                        mediaDetails?: {
                                          __typename?: 'MediaDetails';
                                          height?: number | null;
                                          width?: number | null;
                                        } | null;
                                      } | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Image';
                                    image?: {
                                      __typename?: 'MediaItem';
                                      altText?: string | null;
                                      mediaItemUrl?: string | null;
                                      mediaDetails?: {
                                        __typename?: 'MediaDetails';
                                        height?: number | null;
                                        width?: number | null;
                                      } | null;
                                    } | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links';
                                    links?: Array<{
                                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_links';
                                      style?: string | null;
                                      link?: {
                                        __typename?: 'AcfLink';
                                        target?: string | null;
                                        title?: string | null;
                                        url?: string | null;
                                      } | null;
                                      icon?: {
                                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links_links_Icon';
                                        position?: string | null;
                                        icon?: string | null;
                                      } | null;
                                    } | null> | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title';
                                    title?: string | null;
                                  }
                                | null
                              > | null;
                              customise?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Customise';
                                alignment?: string | null;
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_CustomerStoryLink';
                              linkText?: string | null;
                              url?: string | null;
                              companyName?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Embed';
                              embed?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite';
                              eventbrite?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite_Eventbrite';
                                id?: string | null;
                                height?: number | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form';
                              customise?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Customise';
                                background?: string | null;
                              } | null;
                              form?: Array<
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Chip';
                                    chip?: string | null;
                                    color?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Disclaimer';
                                    disclaimer?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Editor';
                                    editor?: string | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form';
                                    form?: Array<
                                      | {
                                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm';
                                          hubspotForm?: {
                                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm';
                                            message?: string | null;
                                            id?: {
                                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm_HubspotForm_Id';
                                              formId?: string | null;
                                              portalId?: string | null;
                                              contentId?: string | null;
                                            } | null;
                                            redirect?: {
                                              __typename?: 'AcfLink';
                                              target?: string | null;
                                              title?: string | null;
                                            } | null;
                                          } | null;
                                        }
                                      | {
                                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm';
                                          kustomerForm?: {
                                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm';
                                            fileSelectText?: string | null;
                                            fileUpload?: boolean | null;
                                            formWebHook?: string | null;
                                            heading?: string | null;
                                            message?: string | null;
                                            submitButtonText?: string | null;
                                            onSubmitModal?: {
                                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm_KustomerForm_OnSubmitModal';
                                              heading?: string | null;
                                              text?: string | null;
                                              cta?: {
                                                __typename?: 'AcfLink';
                                                target?: string | null;
                                                title?: string | null;
                                                url?: string | null;
                                              } | null;
                                            } | null;
                                          } | null;
                                        }
                                      | {
                                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_Marketo';
                                        }
                                      | {
                                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp';
                                          signUpForm?: {
                                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm';
                                            redirect?: string | null;
                                            couponCode?: string | null;
                                            buttonText?: string | null;
                                            excludeFromSafetycultureOnboardingComms?:
                                              | string
                                              | null;
                                            preloadTemplatesBy?: string | null;
                                            salesforceCampaignId?:
                                              | string
                                              | null;
                                            salesforceCampaignTag?:
                                              | string
                                              | null;
                                            salesforceLeadSource?:
                                              | string
                                              | null;
                                            showAddressFields?: string | null;
                                            showMembershipId?: string | null;
                                            templateSource?: string | null;
                                            termsAndConditions?: string | null;
                                            formId?: string | null;
                                            title?: string | null;
                                            areYouAWoolGrower?: string | null;
                                            preloadTemplates?: Array<{
                                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp_SignUpForm_preloadTemplates';
                                              id?: string | null;
                                            } | null> | null;
                                          } | null;
                                        }
                                      | {
                                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_Wpforms';
                                          wpforms?: string | null;
                                        }
                                      | null
                                    > | null;
                                  }
                                | {
                                    __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Title';
                                    title?: string | null;
                                  }
                                | null
                              > | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Image';
                              image?: {
                                __typename?: 'MediaItem';
                                altText?: string | null;
                                mediaItemUrl?: string | null;
                                mediaDetails?: {
                                  __typename?: 'MediaDetails';
                                  height?: number | null;
                                  width?: number | null;
                                } | null;
                              } | null;
                            }
                          | null
                        > | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights';
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_Customise';
                        alignment?: string | null;
                        columns?: string | null;
                      } | null;
                      spotlights?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights';
                        customise?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Customise';
                          alignment?: string | null;
                          link?: {
                            __typename?: 'AcfLink';
                            target?: string | null;
                            title?: string | null;
                            url?: string | null;
                          } | null;
                        } | null;
                        spotlight?: Array<
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Chip';
                              chip?: string | null;
                              color?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Editor';
                              editor?: string | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight';
                              highlight?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight_Highlight';
                                editor?: string | null;
                                customise?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight_Highlight_Customise';
                                  color?: string | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Icon';
                              icon?: {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Icon_Icon';
                                height?: number | null;
                                placement?: string | null;
                                icon?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Image';
                              image?: {
                                __typename?: 'MediaItem';
                                altText?: string | null;
                                mediaItemUrl?: string | null;
                                mediaDetails?: {
                                  __typename?: 'MediaDetails';
                                  height?: number | null;
                                  width?: number | null;
                                } | null;
                              } | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links';
                              links?: Array<{
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links_links';
                                style?: string | null;
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                                icon?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links_links_Icon';
                                  position?: string | null;
                                  icon?: string | null;
                                } | null;
                              } | null> | null;
                            }
                          | {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Title';
                              title?: string | null;
                            }
                          | null
                        > | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Stats';
                      customise?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Stats_Customise';
                        alignment?: string | null;
                        columns?: string | null;
                      } | null;
                      stats?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Stats_stats';
                        border?: string | null;
                        number?: string | null;
                        title?: string | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Table';
                      table?: {
                        __typename?: 'ACF_Table';
                        body?: Array<Array<string | null> | null> | null;
                        header?: Array<string | null> | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta';
                      testimonialCta?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta';
                        editor?: string | null;
                        author?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Author';
                          name?: string | null;
                          title?: string | null;
                          avatar?: {
                            __typename?: 'MediaItem';
                            altText?: string | null;
                            mediaItemUrl?: string | null;
                            mediaDetails?: {
                              __typename?: 'MediaDetails';
                              height?: number | null;
                              width?: number | null;
                            } | null;
                          } | null;
                        } | null;
                        cta?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialCta_TestimonialCta_Cta';
                          ctaText?: string | null;
                          ctaUrl?: string | null;
                        } | null;
                        image?: {
                          __typename?: 'MediaItem';
                          altText?: string | null;
                          mediaItemUrl?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials';
                      design?: string | null;
                      testimonials?: Array<{
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials';
                        testimonial?: {
                          __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial';
                          content?: Array<
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Author';
                                author?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Author_Author';
                                  name?: string | null;
                                  title?: string | null;
                                  avatar?: {
                                    __typename?: 'MediaItem';
                                    altText?: string | null;
                                    mediaItemUrl?: string | null;
                                    mediaDetails?: {
                                      __typename?: 'MediaDetails';
                                      height?: number | null;
                                      width?: number | null;
                                    } | null;
                                  } | null;
                                } | null;
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Chip';
                                chip?: string | null;
                                color?: string | null;
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Editor';
                                editor?: string | null;
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Link';
                                link?: {
                                  __typename?: 'AcfLink';
                                  target?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                                } | null;
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Stats';
                                stats?: Array<{
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Stats_stats';
                                  border?: string | null;
                                  number?: string | null;
                                  title?: string | null;
                                } | null> | null;
                              }
                            | null
                          > | null;
                          media?: Array<
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Icon';
                                icon?: string | null;
                                title?: string | null;
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Image';
                                image?: {
                                  __typename?: 'MediaItem';
                                  altText?: string | null;
                                  mediaItemUrl?: string | null;
                                  mediaDetails?: {
                                    __typename?: 'MediaDetails';
                                    height?: number | null;
                                    width?: number | null;
                                  } | null;
                                } | null;
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Logo';
                                logo?: {
                                  __typename?: 'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Logo_Logo';
                                  height?: number | null;
                                  logo?: {
                                    __typename?: 'MediaItem';
                                    altText?: string | null;
                                    mediaItemUrl?: string | null;
                                    mediaDetails?: {
                                      __typename?: 'MediaDetails';
                                      height?: number | null;
                                      width?: number | null;
                                    } | null;
                                  } | null;
                                } | null;
                              }
                            | null
                          > | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialsNew';
                      testimonialsNew?: {
                        __typename?: 'Page_Acfpage_Section_Block_Block_Content_TestimonialsNew_TestimonialsNew';
                        testimonials?: Array<{
                          __typename?: 'Testimonial';
                          testimonial?: {
                            __typename?: 'Testimonial_Testimonial';
                            quote?: Array<
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Author';
                                  author?: {
                                    __typename?: 'Testimonial_Testimonial_Quote_Author_Author';
                                    name?: string | null;
                                    title?: string | null;
                                    avatar?: {
                                      __typename?: 'MediaItem';
                                      altText?: string | null;
                                      mediaItemUrl?: string | null;
                                      mediaDetails?: {
                                        __typename?: 'MediaDetails';
                                        width?: number | null;
                                        height?: number | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Chip';
                                  chip?: string | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Editor';
                                  editor?: string | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Image';
                                  image?: {
                                    __typename?: 'Testimonial_Testimonial_Quote_Image_Image';
                                    height?: number | null;
                                    image?: {
                                      __typename?: 'MediaItem';
                                      altText?: string | null;
                                      mediaItemUrl?: string | null;
                                      mediaDetails?: {
                                        __typename?: 'MediaDetails';
                                        width?: number | null;
                                        height?: number | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Link';
                                  link?: {
                                    __typename?: 'AcfLink';
                                    title?: string | null;
                                    url?: string | null;
                                    target?: string | null;
                                  } | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Logo';
                                  logo?: {
                                    __typename?: 'Testimonial_Testimonial_Quote_Logo_Logo';
                                    height?: number | null;
                                    logo?: {
                                      __typename?: 'MediaItem';
                                      altText?: string | null;
                                      mediaItemUrl?: string | null;
                                      mediaDetails?: {
                                        __typename?: 'MediaDetails';
                                        width?: number | null;
                                        height?: number | null;
                                      } | null;
                                    } | null;
                                  } | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Rating';
                                  rating?: number | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Title';
                                  title?: string | null;
                                }
                              | {
                                  __typename?: 'Testimonial_Testimonial_Quote_Video';
                                  video?: Array<
                                    | {
                                        __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Wistia';
                                        wistia?: {
                                          __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Wistia_Wistia';
                                          id?: string | null;
                                        } | null;
                                      }
                                    | {
                                        __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Youtube';
                                        youtube?: {
                                          __typename?: 'Testimonial_Testimonial_Quote_Video_Video_Youtube_Youtube';
                                          id?: string | null;
                                        } | null;
                                      }
                                    | null
                                  > | null;
                                }
                              | null
                            > | null;
                          } | null;
                        } | null> | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Title';
                      title?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video';
                      video?: Array<
                        | {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia';
                            wistia?: {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia_Wistia';
                              id?: string | null;
                            } | null;
                          }
                        | {
                            __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube';
                            youtube?: {
                              __typename?: 'Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube_Youtube';
                              id?: string | null;
                            } | null;
                          }
                        | null
                      > | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Block_Block_Content_Wpforms';
                      wpforms?: string | null;
                    }
                  | null
                > | null;
                customise?: {
                  __typename?: 'Page_Acfpage_Section_Block_Block_Customise';
                  alignment?: string | null;
                  background?: string | null;
                  overflow?: boolean | null;
                  layout?: string | null;
                  classnames?: string | null;
                  cover?: {
                    __typename?: 'MediaItem';
                    altText?: string | null;
                    mediaItemUrl?: string | null;
                    mediaDetails?: {
                      __typename?: 'MediaDetails';
                      height?: number | null;
                      width?: number | null;
                    } | null;
                  } | null;
                  display?: {
                    __typename?: 'Page_Acfpage_Section_Block_Block_Customise_Display';
                    mobile?: boolean | null;
                    desktop?: boolean | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_ContentNavigation';
              contentNavigation?: boolean | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Cta';
              cta?: {
                __typename?: 'Page_Acfpage_Section_Cta_Cta';
                id?: string | null;
                content?: Array<
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Checks';
                      checks?: Array<{
                        __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Checks_checks';
                        color?: string | null;
                        title?: string | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Editor';
                      editor?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Embed';
                      embed?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Eventbrite';
                      eventbrite?: {
                        __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Eventbrite_Eventbrite';
                        id?: string | null;
                        height?: number | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_IconsList';
                      iconsList?: Array<{
                        __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_IconsList_iconsList';
                        title?: string | null;
                        icon?: {
                          __typename?: 'MediaItem';
                          altText?: string | null;
                          mediaItemUrl?: string | null;
                          mediaDetails?: {
                            __typename?: 'MediaDetails';
                            height?: number | null;
                            width?: number | null;
                          } | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Links';
                      links?: Array<{
                        __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Links_links';
                        style?: string | null;
                        link?: {
                          __typename?: 'AcfLink';
                          target?: string | null;
                          title?: string | null;
                          url?: string | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Product';
                      product?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Search';
                      search?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Cta_Cta_Content_Title';
                      title?: string | null;
                    }
                  | null
                > | null;
                customise?: {
                  __typename?: 'Page_Acfpage_Section_Cta_Cta_Customise';
                  background?: string | null;
                  width?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Hero';
              hero?: {
                __typename?: 'Page_Acfpage_Section_Hero_Hero';
                id?: string | null;
                content?: Array<
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Chip';
                      chip?: string | null;
                      color?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Editor';
                      editor?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Links';
                      links?: Array<{
                        __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Links_links';
                        style?: string | null;
                        link?: {
                          __typename?: 'AcfLink';
                          target?: string | null;
                          title?: string | null;
                          url?: string | null;
                        } | null;
                        icon?: {
                          __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Links_links_Icon';
                          position?: string | null;
                          icon?: string | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Product';
                      product?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Content_Title';
                      title?: string | null;
                    }
                  | null
                > | null;
                media?: Array<
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Media_Cover';
                      cover?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Media_Image';
                      background?: string | null;
                      cover?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                      image?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Hero_Hero_Media_Pill';
                      border?: string | null;
                      pill?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    }
                  | null
                > | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Media';
              id?: string | null;
              media?: Array<
                | {
                    __typename?: 'Page_Acfpage_Section_Media_Media_Embed';
                    embed?: string | null;
                  }
                | {
                    __typename?: 'Page_Acfpage_Section_Media_Media_Eventbrite';
                    eventbrite?: {
                      __typename?: 'Page_Acfpage_Section_Media_Media_Eventbrite_Eventbrite';
                      id?: string | null;
                      height?: number | null;
                    } | null;
                  }
                | {
                    __typename?: 'Page_Acfpage_Section_Media_Media_Form';
                    customise?: {
                      __typename?: 'Page_Acfpage_Section_Media_Media_Form_Customise';
                      background?: string | null;
                    } | null;
                    form?: Array<
                      | {
                          __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Chip';
                          chip?: string | null;
                          color?: string | null;
                        }
                      | {
                          __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Disclaimer';
                          disclaimer?: string | null;
                        }
                      | {
                          __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Editor';
                          editor?: string | null;
                        }
                      | {
                          __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form';
                          form?: Array<
                            | {
                                __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_HubspotForm';
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_KustomerForm';
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_Marketo';
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_SignUp';
                              }
                            | {
                                __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_Wpforms';
                                wpforms?: string | null;
                              }
                            | null
                          > | null;
                        }
                      | {
                          __typename?: 'Page_Acfpage_Section_Media_Media_Form_Form_Title';
                          title?: string | null;
                        }
                      | null
                    > | null;
                  }
                | {
                    __typename?: 'Page_Acfpage_Section_Media_Media_Image';
                    image?: {
                      __typename?: 'Page_Acfpage_Section_Media_Media_Image_Image';
                      height?: number | null;
                      image?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    } | null;
                  }
                | null
              > | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Notification';
              notification?: {
                __typename?: 'Page_Acfpage_Section_Notification_Notification';
                id?: string | null;
                content?: {
                  __typename?: 'Page_Acfpage_Section_Notification_Notification_Content';
                  title?: string | null;
                  link?: {
                    __typename?: 'AcfLink';
                    target?: string | null;
                    title?: string | null;
                    url?: string | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Pricing';
              pricing?: {
                __typename?: 'Page_Acfpage_Section_Pricing_Pricing';
                id?: string | null;
                pricingTitle?: string | null;
                comparison?: {
                  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison';
                  editor?: string | null;
                  disclaimer?: string | null;
                  title?: string | null;
                  fullSeat?: {
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_FullSeat';
                    editor?: string | null;
                    title?: string | null;
                  } | null;
                  liteSeat?: {
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_LiteSeat';
                    editor?: string | null;
                    title?: string | null;
                  } | null;
                  tables?: Array<{
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_tables';
                    heading?: string | null;
                    row?: Array<{
                      __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_tables_row';
                      tds?: Array<{
                        __typename?: 'Page_Acfpage_Section_Pricing_Pricing_Comparison_tables_row_tds';
                        td?: string | null;
                      } | null> | null;
                    } | null> | null;
                  } | null> | null;
                } | null;
                payAsYouGo?: {
                  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo';
                  active?: boolean | null;
                  editor?: string | null;
                  featureTitle?: string | null;
                  monthlyPrice?: string | null;
                  title?: string | null;
                  yearlyPrice?: string | null;
                  features?: Array<{
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PayAsYouGo_features';
                    feature?: string | null;
                    included?: boolean | null;
                  } | null> | null;
                } | null;
                planFeatures?: {
                  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures';
                  title?: string | null;
                  ctas?: Array<{
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_ctas';
                    premium?: boolean | null;
                    title?: string | null;
                    cta?: {
                      __typename?: 'AcfLink';
                      title?: string | null;
                      target?: string | null;
                      url?: string | null;
                    } | null;
                  } | null> | null;
                  tables?: Array<{
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_tables';
                    heading?: string | null;
                    row?: Array<{
                      __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_tables_row';
                      tds?: Array<{
                        __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PlanFeatures_tables_row_tds';
                        td?: string | null;
                      } | null> | null;
                    } | null> | null;
                  } | null> | null;
                } | null;
                priceCalculated?: {
                  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PriceCalculated';
                  title?: string | null;
                  active?: boolean | null;
                  contentColumns?: Array<{
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_PriceCalculated_contentColumns';
                    editor?: string | null;
                    title?: string | null;
                    cta?: {
                      __typename?: 'AcfLink';
                      url?: string | null;
                      target?: string | null;
                      title?: string | null;
                    } | null;
                    icon?: {
                      __typename?: 'MediaItem';
                      altText?: string | null;
                      mediaItemUrl?: string | null;
                      mediaDetails?: {
                        __typename?: 'MediaDetails';
                        height?: number | null;
                        width?: number | null;
                      } | null;
                    } | null;
                  } | null> | null;
                  cta?: {
                    __typename?: 'AcfLink';
                    target?: string | null;
                    title?: string | null;
                    url?: string | null;
                  } | null;
                } | null;
                pricingTable?: Array<{
                  __typename?: 'Page_Acfpage_Section_Pricing_Pricing_pricingTable';
                  editor?: string | null;
                  featureTitle?: string | null;
                  monthlyPrice?: string | null;
                  premium?: boolean | null;
                  title?: string | null;
                  yearlyPrice?: string | null;
                  cta?: {
                    __typename?: 'AcfLink';
                    target?: string | null;
                    title?: string | null;
                    url?: string | null;
                  } | null;
                  features?: Array<{
                    __typename?: 'Page_Acfpage_Section_Pricing_Pricing_pricingTable_features';
                    feature?: string | null;
                    included?: boolean | null;
                  } | null> | null;
                } | null> | null;
                pricingTableCta?: {
                  __typename?: 'AcfLink';
                  target?: string | null;
                  title?: string | null;
                  url?: string | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Page_Acfpage_Section_Slider';
              slider?: {
                __typename?: 'Page_Acfpage_Section_Slider_Slider';
                content?: Array<
                  | {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Chip';
                      chip?: string | null;
                      color?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Editor';
                      editor?: string | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Links';
                      links?: Array<{
                        __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Links_links';
                        style?: string | null;
                        link?: {
                          __typename?: 'AcfLink';
                          target?: string | null;
                          title?: string | null;
                          url?: string | null;
                        } | null;
                        icon?: {
                          __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Links_links_Icon';
                          position?: string | null;
                          icon?: string | null;
                        } | null;
                      } | null> | null;
                    }
                  | {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_Content_Title';
                      title?: string | null;
                    }
                  | null
                > | null;
                slides?: Array<{
                  __typename?: 'Page_Acfpage_Section_Slider_Slider_slides';
                  mainSlide?: string | null;
                  region?: Array<string | null> | null;
                  caption?: {
                    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_Caption';
                    jobTitle?: string | null;
                    name?: string | null;
                    cta?: {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_Caption_Cta';
                      ctaText?: string | null;
                      ctaUrl?: string | null;
                    } | null;
                  } | null;
                  features?: Array<{
                    __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features';
                    imageLocation?: string | null;
                    positioning?: {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features_Positioning';
                      desktop?: {
                        __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features_Positioning_Desktop';
                        left?: number | null;
                        right?: number | null;
                        top?: number | null;
                      } | null;
                    } | null;
                    button?: {
                      __typename?: 'Page_Acfpage_Section_Slider_Slider_slides_features_Button';
                      iconLocation?: string | null;
                      text?: string | null;
                      url?: string | null;
                      icon?: {
                        __typename?: 'MediaItem';
                        altText?: string | null;
                        mediaItemUrl?: string | null;
                        mediaDetails?: {
                          __typename?: 'MediaDetails';
                          height?: number | null;
                          width?: number | null;
                        } | null;
                      } | null;
                    } | null;
                    image?: {
                      __typename?: 'MediaItem';
                      altText?: string | null;
                      mediaItemUrl?: string | null;
                      mediaDetails?: {
                        __typename?: 'MediaDetails';
                        height?: number | null;
                        width?: number | null;
                      } | null;
                    } | null;
                  } | null> | null;
                  image?: {
                    __typename?: 'MediaItem';
                    altText?: string | null;
                    mediaItemUrl?: string | null;
                    mediaDetails?: {
                      __typename?: 'MediaDetails';
                      height?: number | null;
                      width?: number | null;
                    } | null;
                  } | null;
                } | null> | null;
                mobileImage?: {
                  __typename?: 'MediaItem';
                  altText?: string | null;
                  mediaItemUrl?: string | null;
                  mediaDetails?: {
                    __typename?: 'MediaDetails';
                    height?: number | null;
                    width?: number | null;
                  } | null;
                } | null;
              } | null;
            }
          | null
        > | null;
      } | null;
      acfProduct?: {
        __typename?: 'Page_Acfproduct';
        product?: {
          __typename?: 'Page_Acfproduct_Product';
          override?: boolean | null;
          edapp?: {
            __typename?: 'Page_Acfproduct_Product_Edapp';
            appStore?: string | null;
            googlePlay?: string | null;
            override?: boolean | null;
          } | null;
          safetyculture?: {
            __typename?: 'Page_Acfproduct_Product_Safetyculture';
            appStore?: string | null;
            googlePlay?: string | null;
            override?: boolean | null;
          } | null;
          sheqsy?: {
            __typename?: 'Page_Acfproduct_Product_Sheqsy';
            appStore?: string | null;
            googlePlay?: string | null;
            override?: boolean | null;
          } | null;
        } | null;
      } | null;
      acfStore?: {
        __typename?: 'Page_Acfstore';
        store?: {
          __typename?: 'Page_Acfstore_Store';
          banner?: boolean | null;
          getApp?: string | null;
          override?: boolean | null;
        } | null;
      } | null;
      acfPageSettings?: {
        __typename?: 'Page_Acfpagesettings';
        pageSettings?: {
          __typename?: 'Page_Acfpagesettings_PageSettings';
          breadcrumbsStatus?: boolean | null;
          footerMenuStatus?: boolean | null;
          footerLegalMenuStatus?: boolean | null;
          headerMenuStatus?: boolean | null;
          languageToggle?: boolean | null;
          loginAndSignUpButtonsStatus?: boolean | null;
        } | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Page_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      children?: {
        __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
        nodes: Array<
          | { __typename?: 'App'; uri?: string | null }
          | { __typename?: 'Checklist'; uri?: string | null }
          | { __typename?: 'CustomerStory'; uri?: string | null }
          | { __typename?: 'Ebook'; uri?: string | null }
          | { __typename?: 'Event'; uri?: string | null }
          | { __typename?: 'GraphqlDocument'; uri?: string | null }
          | { __typename?: 'Legal'; uri?: string | null }
          | { __typename?: 'MediaItem'; uri?: string | null }
          | { __typename?: 'Page'; title?: string | null; uri?: string | null }
          | { __typename?: 'Partnership'; uri?: string | null }
          | { __typename?: 'Post'; uri?: string | null }
          | { __typename?: 'Testimonial'; uri?: string | null }
          | { __typename?: 'Topic'; uri?: string | null }
        >;
      } | null;
      parent?: {
        __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
        node:
          | { __typename?: 'App' }
          | { __typename?: 'Checklist' }
          | { __typename?: 'CustomerStory' }
          | { __typename?: 'Ebook' }
          | { __typename?: 'Event' }
          | { __typename?: 'GraphqlDocument' }
          | { __typename?: 'Legal' }
          | { __typename?: 'MediaItem' }
          | {
              __typename?: 'Page';
              slug?: string | null;
              title?: string | null;
              parent?: {
                __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
                node:
                  | { __typename?: 'App' }
                  | { __typename?: 'Checklist' }
                  | { __typename?: 'CustomerStory' }
                  | { __typename?: 'Ebook' }
                  | { __typename?: 'Event' }
                  | { __typename?: 'GraphqlDocument' }
                  | { __typename?: 'Legal' }
                  | { __typename?: 'MediaItem' }
                  | {
                      __typename?: 'Page';
                      slug?: string | null;
                      title?: string | null;
                    }
                  | { __typename?: 'Partnership' }
                  | { __typename?: 'Post' }
                  | { __typename?: 'Testimonial' }
                  | { __typename?: 'Topic' };
              } | null;
            }
          | { __typename?: 'Partnership' }
          | { __typename?: 'Post' }
          | { __typename?: 'Testimonial' }
          | { __typename?: 'Topic' };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
        opengraphImage?: {
          __typename?: 'MediaItem';
          mediaItemUrl?: string | null;
        } | null;
      } | null;
      translated?: Array<{
        __typename?: 'Page';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetPartnershipQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPartnershipQuery = {
  __typename?: 'RootQuery';
  partnerships?: {
    __typename?: 'RootQueryToPartnershipConnection';
    nodes: Array<{
      __typename?: 'Partnership';
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfPartnership?: {
        __typename?: 'Partnership_Acfpartnership';
        intro?: {
          __typename?: 'Partnership_Acfpartnership_Intro';
          editor?: string | null;
          title?: string | null;
        } | null;
        header?: {
          __typename?: 'Partnership_Acfpartnership_Header';
          logo?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            guid?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        hero?: {
          __typename?: 'Partnership_Acfpartnership_Hero';
          image?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            guid?: string | null;
            mediaDetails?: {
              __typename?: 'MediaDetails';
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        salesContactDetails?: {
          __typename?: 'Partnership_Acfpartnership_SalesContactDetails';
          showSalesPerson?: string | null;
          fullName?: string | null;
          jobTitle?: string | null;
          email?: {
            __typename?: 'AcfLink';
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          profilePhoto?: {
            __typename?: 'MediaItem';
            altText?: string | null;
            guid?: string | null;
          } | null;
        } | null;
        section?: Array<
          | {
              __typename?: 'Partnership_Acfpartnership_Section_Block';
              editor?: string | null;
            }
          | {
              __typename?: 'Partnership_Acfpartnership_Section_Image';
              image?: {
                __typename?: 'Partnership_Acfpartnership_Section_Image_Image';
                height?: number | null;
                image?: {
                  __typename?: 'MediaItem';
                  altText?: string | null;
                  guid?: string | null;
                  mediaDetails?: {
                    __typename?: 'MediaDetails';
                    height?: number | null;
                    width?: number | null;
                  } | null;
                } | null;
              } | null;
            }
          | {
              __typename?: 'Partnership_Acfpartnership_Section_Title';
              title?: string | null;
            }
          | {
              __typename?: 'Partnership_Acfpartnership_Section_Video';
              video?: Array<
                | {
                    __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Wistia';
                    wistia?: {
                      __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Wistia_Wistia';
                      id?: string | null;
                    } | null;
                  }
                | {
                    __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Youtube';
                    youtube?: {
                      __typename?: 'Partnership_Acfpartnership_Section_Video_Video_Youtube_Youtube';
                      id?: string | null;
                    } | null;
                  }
                | null
              > | null;
            }
          | null
        > | null;
        signUpForm?: {
          __typename?: 'Partnership_Acfpartnership_SignUpForm';
          signUpForm?: {
            __typename?: 'Partnership_Acfpartnership_SignUpForm_SignUpForm';
            redirect?: string | null;
            couponCode?: boolean | null;
            buttonText?: string | null;
            excludeFromSafetycultureOnboardingComms?: string | null;
            preloadTemplatesBy?: string | null;
            salesforceCampaignId?: string | null;
            salesforceCampaignTag?: string | null;
            salesforceLeadSource?: string | null;
            showAddressFields?: string | null;
            showMembershipId?: string | null;
            templateSource?: string | null;
            termsAndConditions?: string | null;
            formId?: string | null;
            title?: string | null;
            areYouAWoolGrower?: string | null;
            preloadTemplates?: Array<{
              __typename?: 'Partnership_Acfpartnership_SignUpForm_SignUpForm_preloadTemplates';
              id?: string | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Partnership_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'Partnership';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge';
        node: {
          __typename?: 'User';
          description?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          acfUser?: {
            __typename?: 'User_Acfuser';
            linkedin?: string | null;
            title?: string | null;
            avatar?: {
              __typename?: 'MediaItem';
              mediaItemUrl?: string | null;
            } | null;
            translations?: {
              __typename?: 'User_Acfuser_Translations';
              de?: {
                __typename?: 'User_Acfuser_Translations_De';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              es?: {
                __typename?: 'User_Acfuser_Translations_Es';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              fr?: {
                __typename?: 'User_Acfuser_Translations_Fr';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              nl?: {
                __typename?: 'User_Acfuser_Translations_Nl';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              ptBr?: {
                __typename?: 'User_Acfuser_Translations_PtBr';
                bio?: string | null;
                jobTitle?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          } | null;
          avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
        };
      } | null;
    }>;
  } | null;
};

export type GetTopicsPagedQueryVariables = Exact<{
  paged?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsPagedQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    nodes: Array<{
      __typename?: 'Topic';
      id: string;
      title?: string | null;
      uri?: string | null;
      acfTopic?: {
        __typename?: 'Topic_Acftopic';
        authorShow?: boolean | null;
        contentEditor?: string | null;
        heroEditor?: string | null;
        heroTitle?: string | null;
        introEditor?: string | null;
        faqTitle?: string | null;
        faqFrequentlyAskedQuestions?: Array<{
          __typename?: 'Topic_Acftopic_faqFrequentlyAskedQuestions';
          editor?: string | null;
          title?: string | null;
        } | null> | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      seo?: { __typename?: 'PostTypeSEO'; metaDesc?: string | null } | null;
      acfPageStatus?: {
        __typename?: 'Topic_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      taxonomyCategories?: {
        __typename?: 'TopicToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetTopicQueryVariables = Exact<{
  path?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicQuery = {
  __typename?: 'RootQuery';
  topics?: {
    __typename?: 'RootQueryToTopicConnection';
    nodes: Array<{
      __typename?: 'Topic';
      modified?: string | null;
      date?: string | null;
      slug?: string | null;
      status?: string | null;
      title?: string | null;
      uri?: string | null;
      acfTopic?: {
        __typename?: 'Topic_Acftopic';
        authorShow?: boolean | null;
        contentEditor?: string | null;
        heroEditor?: string | null;
        heroTitle?: string | null;
        introEditor?: string | null;
        faqTitle?: string | null;
        faqFrequentlyAskedQuestions?: Array<{
          __typename?: 'Topic_Acftopic_faqFrequentlyAskedQuestions';
          editor?: string | null;
          title?: string | null;
        } | null> | null;
      } | null;
      acfAuthors?: {
        __typename?: 'Topic_Acfauthors';
        multipleAuthors?: {
          __typename?: 'Topic_Acfauthors_MultipleAuthors';
          authors?: Array<{
            __typename?: 'Topic_Acfauthors_MultipleAuthors_authors';
            authorName?: string | null;
          } | null> | null;
        } | null;
      } | null;
      acfRelatedTemplatesSimple?: {
        __typename?: 'Topic_Acfrelatedtemplatessimple';
        relatedTemplatesTitle?: string | null;
        relatedTemplatesTemplates?: Array<{
          __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates';
          editor?: string | null;
          title?: string | null;
          cta?: {
            __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates_Cta';
            ctaText?: string | null;
            ctaUrl?: string | null;
            templateId?: string | null;
          } | null;
          links?: Array<{
            __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links';
            style?: string | null;
            icon?: {
              __typename?: 'Topic_Acfrelatedtemplatessimple_relatedTemplatesTemplates_links_Icon';
              icon?: string | null;
              position?: string | null;
            } | null;
            link?: {
              __typename?: 'AcfLink';
              target?: string | null;
              title?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      acfPageStatus?: {
        __typename?: 'Topic_Acfpagestatus';
        draftPublic?: boolean | null;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: 'MediaDetails';
            height?: number | null;
            width?: number | null;
          } | null;
        };
      } | null;
      parent?: {
        __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
        node:
          | { __typename?: 'App' }
          | { __typename?: 'Checklist' }
          | { __typename?: 'CustomerStory' }
          | { __typename?: 'Ebook' }
          | { __typename?: 'Event' }
          | { __typename?: 'GraphqlDocument' }
          | { __typename?: 'Legal' }
          | { __typename?: 'MediaItem' }
          | { __typename?: 'Page' }
          | { __typename?: 'Partnership' }
          | { __typename?: 'Post' }
          | { __typename?: 'Testimonial' }
          | {
              __typename?: 'Topic';
              slug?: string | null;
              title?: string | null;
            };
      } | null;
      taxonomyCategories?: {
        __typename?: 'TopicToTaxonomyCategoryConnection';
        nodes: Array<{
          __typename?: 'TaxonomyCategory';
          name?: string | null;
          parentId?: string | null;
          slug?: string | null;
          id: string;
          apps?: {
            __typename?: 'TaxonomyCategoryToAppConnection';
            nodes: Array<{
              __typename?: 'App';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
          checklists?: {
            __typename?: 'TaxonomyCategoryToChecklistConnection';
            nodes: Array<{
              __typename?: 'Checklist';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
          topics?: {
            __typename?: 'TaxonomyCategoryToTopicConnection';
            nodes: Array<{
              __typename?: 'Topic';
              slug?: string | null;
              title?: string | null;
              uri?: string | null;
            }>;
          } | null;
        }>;
      } | null;
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge';
        node: {
          __typename?: 'User';
          description?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          slug?: string | null;
          acfUser?: {
            __typename?: 'User_Acfuser';
            linkedin?: string | null;
            title?: string | null;
            avatar?: {
              __typename?: 'MediaItem';
              mediaItemUrl?: string | null;
            } | null;
            translations?: {
              __typename?: 'User_Acfuser_Translations';
              de?: {
                __typename?: 'User_Acfuser_Translations_De';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              es?: {
                __typename?: 'User_Acfuser_Translations_Es';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              fr?: {
                __typename?: 'User_Acfuser_Translations_Fr';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              nl?: {
                __typename?: 'User_Acfuser_Translations_Nl';
                bio?: string | null;
                firstName?: string | null;
                jobTitle?: string | null;
                lastName?: string | null;
              } | null;
              ptBr?: {
                __typename?: 'User_Acfuser_Translations_PtBr';
                bio?: string | null;
                jobTitle?: string | null;
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            } | null;
          } | null;
          avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
        };
      } | null;
      locale?: { __typename?: 'Locale'; id: string } | null;
      seo?: {
        __typename?: 'PostTypeSEO';
        canonical?: string | null;
        metaDesc?: string | null;
        metaRobotsNofollow?: string | null;
        metaRobotsNoindex?: string | null;
        title?: string | null;
      } | null;
      translated?: Array<{
        __typename?: 'Topic';
        uri?: string | null;
        locale?: { __typename?: 'Locale'; id: string } | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetTopicsTaxonomyQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTopicsTaxonomyQuery = {
  __typename?: 'RootQuery';
  taxonomyCategories?: {
    __typename?: 'RootQueryToTaxonomyCategoryConnection';
    nodes: Array<{
      __typename?: 'TaxonomyCategory';
      topics?: {
        __typename?: 'TaxonomyCategoryToTopicConnection';
        nodes: Array<{
          __typename?: 'Topic';
          id: string;
          title?: string | null;
          uri?: string | null;
          acfTopic?: {
            __typename?: 'Topic_Acftopic';
            authorShow?: boolean | null;
            contentEditor?: string | null;
            heroEditor?: string | null;
            heroTitle?: string | null;
            introEditor?: string | null;
            faqTitle?: string | null;
            faqFrequentlyAskedQuestions?: Array<{
              __typename?: 'Topic_Acftopic_faqFrequentlyAskedQuestions';
              editor?: string | null;
              title?: string | null;
            } | null> | null;
          } | null;
          acfPageStatus?: {
            __typename?: 'Topic_Acfpagestatus';
            draftPublic?: boolean | null;
          } | null;
          featuredImage?: {
            __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
            node: {
              __typename?: 'MediaItem';
              altText?: string | null;
              mediaItemUrl?: string | null;
              mediaDetails?: {
                __typename?: 'MediaDetails';
                height?: number | null;
                width?: number | null;
              } | null;
            };
          } | null;
          seo?: {
            __typename?: 'PostTypeSEO';
            canonical?: string | null;
            metaDesc?: string | null;
            metaRobotsNofollow?: string | null;
            metaRobotsNoindex?: string | null;
            title?: string | null;
          } | null;
          taxonomyCategories?: {
            __typename?: 'TopicToTaxonomyCategoryConnection';
            nodes: Array<{
              __typename?: 'TaxonomyCategory';
              id: string;
              name?: string | null;
              slug?: string | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export const MenuFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MenuFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RootQueryToMenuConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'menuItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'childItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'childItems',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nodes',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'label',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'parentId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'description',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'cssClasses',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'label',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'parentId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'target',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cssClasses',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'target' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cssClasses' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MenuFragmentFragment, unknown>;
export const GetAppsCursorsPagedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsCursorsPaged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppsCursorsPagedQuery,
  GetAppsCursorsPagedQueryVariables
>;
export const GetAppsCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAppsCursorsQuery, GetAppsCursorsQueryVariables>;
export const GetAppsCursorsTaxonomyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsCursorsTaxonomy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'paged' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppsCursorsTaxonomyQuery,
  GetAppsCursorsTaxonomyQueryVariables
>;
export const GetAppsTaxonomyCategoriesCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsTaxonomyCategoriesCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '1000' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppsTaxonomyCategoriesCursorsQuery,
  GetAppsTaxonomyCategoriesCursorsQueryVariables
>;
export const GetAuthorsCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthorsCursors' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hasPublishedPosts' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'APP' },
                          { kind: 'EnumValue', value: 'CHECKLISTS' },
                          { kind: 'EnumValue', value: 'TOPICS' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAuthorsCursorsQuery,
  GetAuthorsCursorsQueryVariables
>;
export const GetChecklistsCategoryCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsCategoryCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checklists' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '1000' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsCategoryCursorsQuery,
  GetChecklistsCategoryCursorsQueryVariables
>;
export const GetChecklistsCursorsPagedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsCursorsPaged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsCursorsPagedQuery,
  GetChecklistsCursorsPagedQueryVariables
>;
export const GetChecklistsCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsCursorsQuery,
  GetChecklistsCursorsQueryVariables
>;
export const GetChecklistsCursorsTaxonomyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsCursorsTaxonomy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checklists' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'paged' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsCursorsTaxonomyQuery,
  GetChecklistsCursorsTaxonomyQueryVariables
>;
export const GetEbooksCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEbooksCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ebooks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '100' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEbooksCursorsQuery,
  GetEbooksCursorsQueryVariables
>;
export const GetEventsCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEventsCursorsQuery,
  GetEventsCursorsQueryVariables
>;
export const GetTopicsCursorsPagedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsCursorsPaged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopicsCursorsPagedQuery,
  GetTopicsCursorsPagedQueryVariables
>;
export const GetTopicsCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1000' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopicsCursorsQuery,
  GetTopicsCursorsQueryVariables
>;
export const GetTopicsCursorsTaxonomyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsCursorsTaxonomy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topics' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'paged' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopicsCursorsTaxonomyQuery,
  GetTopicsCursorsTaxonomyQueryVariables
>;
export const GetTopicsTaxonomyCursorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsTaxonomyCursors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topics' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '1000' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cursor' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopicsTaxonomyCursorsQuery,
  GetTopicsTaxonomyCursorsQueryVariables
>;
export const GetAppsGlobalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsGlobals' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appsGlobals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acfAppsGlobals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branchLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'capterra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'badges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'badges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'badge',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAppsGlobalsQuery, GetAppsGlobalsQueryVariables>;
export const GetCapteraDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCaptera' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acfOptionsCapterra' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acfCapterra' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'capterra' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'de' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'en' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'es' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fr' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nl' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ptBr' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rating' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCapteraQuery, GetCapteraQueryVariables>;
export const GetMenusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMenus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'header' },
            name: { kind: 'Name', value: 'menus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: { kind: 'EnumValue', value: 'HEADER' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'language' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MenuFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'footer' },
            name: { kind: 'Name', value: 'menus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: { kind: 'EnumValue', value: 'FOOTER' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'language' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MenuFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'legal' },
            name: { kind: 'Name', value: 'menus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'location' },
                      value: { kind: 'EnumValue', value: 'LEGAL' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'language' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MenuFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MenuFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RootQueryToMenuConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nodes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'menuItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'childItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'childItems',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nodes',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'id',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'label',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'parentId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'description',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'cssClasses',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'label',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'parentId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'target',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'description',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cssClasses',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'target' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cssClasses' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMenusQuery, GetMenusQueryVariables>;
export const GetMoreAppsPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMoreAppsPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxonomyId' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'termTaxonomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taxonomyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '6' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfApp' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroEditor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfPageStatus',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'draftPublic',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomyCategories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'parentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMoreAppsPostsQuery,
  GetMoreAppsPostsQueryVariables
>;
export const GetMoreChecklistsPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMoreChecklistsPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxonomyId' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'termTaxonomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taxonomyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checklists' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '6' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfChecklist',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroEditor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfPageStatus',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'draftPublic',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomyCategories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'parentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMoreChecklistsPostsQuery,
  GetMoreChecklistsPostsQueryVariables
>;
export const GetMoreCustomerStoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMoreCustomerStories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'termTaxonomId' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postId' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyIndustries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'termTaxonomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'termTaxonomId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customerStories' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'orderby' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'field' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'DATE',
                                        },
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'order' },
                                        value: {
                                          kind: 'EnumValue',
                                          value: 'ASC',
                                        },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'stati' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'EnumValue', value: 'PUBLISH' },
                                    ],
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'notIn' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'postId' },
                                  },
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'wpmlLanguage' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '6' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfCustomerStory',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'image',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'altText',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaDetails',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'width',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaItemUrl',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'intro',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'editor',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomySolutions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMoreCustomerStoriesQuery,
  GetMoreCustomerStoriesQueryVariables
>;
export const GetMoreTopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMoreTopics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxonomyId' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'termTaxonomId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taxonomyId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topics' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '6' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfTopic' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroEditor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfPageStatus',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'draftPublic',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomyCategories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'parentId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMoreTopicsQuery, GetMoreTopicsQueryVariables>;
export const GetNotificationBannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotificationBanner' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acfOptionsNotificationBanner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acfNotificationBanner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'notificationBanner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'status' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'backgroundColor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'backgroundType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'backgroundColorGradient',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'angle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colors' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'color',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'textColor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'align' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'links' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'icon' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'icon' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'position',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'link' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'target',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'style' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'translations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'de' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'links',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'icon',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'icon',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'position',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'link',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'style',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'es' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'links',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'icon',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'icon',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'position',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'link',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'style',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fr' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'links',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'icon',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'icon',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'position',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'link',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'style',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nl' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'links',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'icon',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'icon',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'position',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'link',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'style',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ptBr' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'status',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'links',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'icon',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'icon',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'position',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'link',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'style',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationBannerQuery,
  GetNotificationBannerQueryVariables
>;
export const GetRecentCustomerStoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRecentCustomerStories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerStories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '6' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: { kind: 'EnumValue', value: 'PUBLISH' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfCustomerStory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'intro' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaItemUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRecentCustomerStoriesQuery,
  GetRecentCustomerStoriesQueryVariables
>;
export const GetTaxonomiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTaxonomies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomySolutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '100' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyRegions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '100' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyIndustries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '100' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTaxonomiesQuery, GetTaxonomiesQueryVariables>;
export const GePagesPathsNoLanguageCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GePagesPathsNoLanguageCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GePagesPathsNoLanguageCodeQuery,
  GePagesPathsNoLanguageCodeQueryVariables
>;
export const GetAppsPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAppsPathsQuery, GetAppsPathsQueryVariables>;
export const GetAppsTaxonomyPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsTaxonomyPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '20' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'hideEmpty' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppsTaxonomyPathsQuery,
  GetAppsTaxonomyPathsQueryVariables
>;
export const GetAuthorsPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthorsPath' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hasPublishedPosts' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'APP' },
                          { kind: 'EnumValue', value: 'CHECKLISTS' },
                          { kind: 'EnumValue', value: 'TOPICS' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthorsPathQuery, GetAuthorsPathQueryVariables>;
export const GetChecklistsPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsPathsQuery,
  GetChecklistsPathsQueryVariables
>;
export const GetChecklistsTaxonomyPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsTaxonomyPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '20' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'hideEmpty' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsTaxonomyPathsQuery,
  GetChecklistsTaxonomyPathsQueryVariables
>;
export const GetCustomerStoriesPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerStoriesPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerStories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerStoriesPathsQuery,
  GetCustomerStoriesPathsQueryVariables
>;
export const GetEbooksPathsForArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEbooksPathsForArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ebooks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEbooksPathsForArchiveQuery,
  GetEbooksPathsForArchiveQueryVariables
>;
export const GetEventsPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventsPathsQuery, GetEventsPathsQueryVariables>;
export const GetLegalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLegals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLegalsQuery, GetLegalsQueryVariables>;
export const GetPagesPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPagesPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPagesPathsQuery, GetPagesPathsQueryVariables>;
export const GetPartnershipsPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnershipsPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerships' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPartnershipsPathsQuery,
  GetPartnershipsPathsQueryVariables
>;
export const GetTopicsPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicsPathsQuery, GetTopicsPathsQueryVariables>;
export const GetTopicsTaxonomyPathsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsTaxonomyPaths' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '20' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'hideEmpty' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopicsTaxonomyPathsQuery,
  GetTopicsTaxonomyPathsQueryVariables
>;
export const GetAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfApp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'seoDownload' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'seoCapterra' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'seoCapterraBadges',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'show' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'badges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'badge',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'overrideAppLinks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'featuredTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'branchLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorShow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pricing' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'platforms' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'freeVersion',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalLinks',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nofollow',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nofollow',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaItemUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'App' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfAuthors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'multipleAuthors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authors' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'authorName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'apps' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklists' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topics' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfUser' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'linkedin',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'translations',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'de',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'es',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nl',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ptBr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: {
                                          kind: 'IntValue',
                                          value: '300',
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAppQuery, GetAppQueryVariables>;
export const GetAppsCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '12' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfApp' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroEditor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfPageStatus',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'draftPublic',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'canonical',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaDesc',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaRobotsNofollow',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaRobotsNoindex',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomyCategories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppsCategoryQuery,
  GetAppsCategoryQueryVariables
>;
export const GetAppsForArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppsForArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '12' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfApp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppsForArchiveQuery,
  GetAppsForArchiveQueryVariables
>;
export const GetAuthorAppsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthorApps' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'author' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfApp' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthorAppsQuery, GetAuthorAppsQueryVariables>;
export const GetAuthorChecklistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthorChecklists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'author' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfChecklist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAuthorChecklistsQuery,
  GetAuthorChecklistsQueryVariables
>;
export const GetAuthorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'idType' },
                value: { kind: 'EnumValue', value: 'SLUG' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'slug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acfUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaItemUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mediaDetails' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'height' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'width' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'altText' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'de' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bio' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'es' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bio' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fr' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bio' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nl' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bio' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ptBr' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bio' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedin' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthorQuery, GetAuthorQueryVariables>;
export const GetAuthorTopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthorTopics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'author' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfTopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAuthorTopicsQuery,
  GetAuthorTopicsQueryVariables
>;
export const GetAuthorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthors' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hasPublishedPosts' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'EnumValue', value: 'APP' },
                          { kind: 'EnumValue', value: 'CHECKLISTS' },
                          { kind: 'EnumValue', value: 'TOPICS' },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'translations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'de' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'jobTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'es' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'jobTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fr' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'jobTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nl' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'jobTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ptBr' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'jobTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'firstName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuthorsQuery, GetAuthorsQueryVariables>;
export const GetChecklistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfChecklist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorShow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'introEditor' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'faqFrequentlyAskedQuestions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'faqTitle' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'featuredTemplateStatus',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfAuthors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'multipleAuthors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authors' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'authorName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfFeaturedTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'featuredTemplate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ctaText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ctaUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'templateId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaItemUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'previewLinks',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'digitalReport',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'pdfReport',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfRelatedTemplates' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'relatedTemplatesEditor',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'relatedTemplatesTitle',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'relatedTemplatesTemplates',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ctaText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ctaUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'templateId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'downloadType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'previewLinks',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'digitalReport',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'pdfReport',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Checklist',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'apps' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklists' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topics' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfUser' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'linkedin',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'translations',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'de',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'es',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nl',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ptBr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: {
                                          kind: 'IntValue',
                                          value: '300',
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetChecklistQuery, GetChecklistQueryVariables>;
export const GetChecklistTaxonomyCategoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistTaxonomyCategory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'checklists' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '12' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfChecklist',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroEditor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfPageStatus',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'draftPublic',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'canonical',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaDesc',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaRobotsNofollow',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaRobotsNoindex',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomyCategories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistTaxonomyCategoryQuery,
  GetChecklistTaxonomyCategoryQueryVariables
>;
export const GetChecklistsForArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChecklistsForArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checklists' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '12' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfChecklist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorShow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'introEditor' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'faqFrequentlyAskedQuestions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'faqTitle' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetChecklistsForArchiveQuery,
  GetChecklistsForArchiveQueryVariables
>;
export const GetCustomerStoriesArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerStoriesArchive' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerStoriesArchive' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acfCustomerStroriesArchive' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featured' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'featured' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'post' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: {
                                              kind: 'Name',
                                              value: 'CustomerStory',
                                            },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'acfCustomerStory',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'logo',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'image',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'intro',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'featuredImage',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'node',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'altText',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaDetails',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'width',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaItemUrl',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'translated',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'language',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'code',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'uri',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'acfCustomerStory',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'intro',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'editor',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerStoriesArchiveQuery,
  GetCustomerStoriesArchiveQueryVariables
>;
export const GetCustomerStoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerStories' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerStories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfCustomerStory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'intro' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaItemUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyIndustries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyRegions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomySolutions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerStoriesQuery,
  GetCustomerStoriesQueryVariables
>;
export const GetCustomerStoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerStory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerStories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfCustomerStory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'intro' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaItemUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaItemUrl',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'highlights' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customise' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'columns',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'alignment',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'highlights' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'highlight',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'editor',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'color',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'section' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Block',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'gallery',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Image',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'image',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'altText',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaDetails',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'width',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaItemUrl',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'video',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'video',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'InlineFragment',
                                                                  typeCondition:
                                                                    {
                                                                      kind: 'NamedType',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Wistia',
                                                                      },
                                                                    },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'wistia',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'id',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'InlineFragment',
                                                                  typeCondition:
                                                                    {
                                                                      kind: 'NamedType',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'CustomerStory_Acfcustomerstory_Section_Block_Gallery_Video_video_Video_Youtube',
                                                                      },
                                                                    },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'youtube',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'id',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'thumbnail',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Title',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Quote',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'quote',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Author',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'author',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'name',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'avatar',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Chip',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'chip',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Editor',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'editor',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Link',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'link',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'target',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Quote_Quote_Logo',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'logo',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'logo',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Editor',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'editor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Image',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'image',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Video',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'video',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Video_Video_Wistia',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'wistia',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'CustomerStory_Acfcustomerstory_Section_Video_Video_Youtube',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'youtube',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'CustomerStory_Acfcustomerstory_Section_Cta',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'cta' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'background',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fieldGroupName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Checks',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'checks',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'color',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Embed',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'embed',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Links',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'links',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'position',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'style',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'link',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'target',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Product',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'product',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Search',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'search',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_Title',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'CustomerStory_Acfcustomerstory_Section_Cta_Cta_Content_IconsList',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'iconsList',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomySolutions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyIndustries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomySolutions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCustomerStoryQuery,
  GetCustomerStoryQueryVariables
>;
export const GetEbookDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEbook' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ebooks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfEbook' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hubspotForm' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hubspotForm',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'portalId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'formId',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'contentId',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'message',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirect',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEbookQuery, GetEbookQueryVariables>;
export const GetEbooksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEbooks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ebooks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEbooksQuery, GetEbooksQueryVariables>;
export const GetEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfEvent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentContent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_ContentContent_Editor',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'editor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_ContentContent_Image',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'image',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_ContentContent_Table',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'table',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'body',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'header',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_ContentContent_Title',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_ContentContent_Video',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'video',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Event_Acfevent_ContentContent_Video_Video_Wistia',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'wistia',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Event_Acfevent_ContentContent_Video_Video_Youtube',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'youtube',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_ContentContent_Eventbrite',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'eventbrite',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroIntro' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'background' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseRegisterPageUrl',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventProductArea',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventDateTo',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventDeliveryType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customiseRegions' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventDate',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventTimeEnd',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventTimeStart',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventGmt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventFeatured',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customiseEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sidebarSidebar' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_SidebarSidebar_Editor',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'editor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_SidebarSidebar_Link',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'link' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'target',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_SidebarSidebar_Title',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_SidebarSidebar_Wpforms',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'wpforms',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_SidebarSidebar_HubspotForm',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hubspotForm',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'portalId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'formId',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'contentId',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'message',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'redirect',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Event_Acfevent_SidebarSidebar_Eventbrite',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'eventbrite',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEventQuery, GetEventQueryVariables>;
export const GetEventsForArchiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventsForArchive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfEvent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customiseEditor' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventProductArea',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventDateTo',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventDeliveryType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customiseRegions' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventDate',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventTimeEnd',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventTimeStart',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventType',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventGmt',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseEventFeatured',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroIntro' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customiseEditor' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseLocation',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'customiseRegisterPageUrl',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetEventsForArchiveQuery,
  GetEventsForArchiveQueryVariables
>;
export const GetLegalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLegal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfLegal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'section' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Legal_Acflegal_Section_Article',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'article',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Legal_Acflegal_Section_Article_Article_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'archive',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'tableOfContents',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Legal_Acflegal_Section_Form',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'customise',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'background',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'form' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Legal_Acflegal_Section_Form_Form_Chip',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'chip',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Legal_Acflegal_Section_Form_Form_Disclaimer',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'disclaimer',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Legal_Acflegal_Section_Form_Form_Editor',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'editor',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Legal_Acflegal_Section_Form_Form_Form',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'form',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Legal_Acflegal_Section_Form_Form_Form_Form_HubspotForm',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'hubspotForm',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'id',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'portalId',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'formId',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'contentId',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'message',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'redirect',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'target',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'url',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Legal_Acflegal_Section_Form_Form_Form_Form_Wpforms',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'wpforms',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Legal_Acflegal_Section_Form_Form_Title',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Legal' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'legalId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Legal' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'parent',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'Legal',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'slug',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLegalQuery, GetLegalQueryVariables>;
export const GetPageContentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPageContent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'section' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Page_Acfpage_Section_ContentNavigation',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'contentNavigation',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Article',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'article',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Article_Article_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'archive',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value:
                                                          'tableOfContents',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Block',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'block',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_TestimonialsNew',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'testimonialsNew',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'testimonials',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Testimonial',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'testimonial',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'quote',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Title',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'title',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Author',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'author',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'avatar',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'altText',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'mediaItemUrl',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'mediaDetails',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'width',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'height',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'name',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'title',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Chip',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'chip',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Link',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'link',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'title',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'url',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'target',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Logo',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'logo',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'height',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'logo',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'altText',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'mediaItemUrl',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'mediaDetails',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'width',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'height',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Image',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'image',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'height',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'image',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'altText',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'mediaItemUrl',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'mediaDetails',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'width',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'height',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Video',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'video',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'InlineFragment',
                                                                                                                              typeCondition:
                                                                                                                                {
                                                                                                                                  kind: 'NamedType',
                                                                                                                                  name: {
                                                                                                                                    kind: 'Name',
                                                                                                                                    value:
                                                                                                                                      'Testimonial_Testimonial_Quote_Video_Video_Wistia',
                                                                                                                                  },
                                                                                                                                },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'wistia',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'id',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'InlineFragment',
                                                                                                                              typeCondition:
                                                                                                                                {
                                                                                                                                  kind: 'NamedType',
                                                                                                                                  name: {
                                                                                                                                    kind: 'Name',
                                                                                                                                    value:
                                                                                                                                      'Testimonial_Testimonial_Quote_Video_Video_Youtube',
                                                                                                                                  },
                                                                                                                                },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'youtube',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'id',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Rating',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'rating',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Testimonial_Testimonial_Quote_Editor',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'editor',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Eventbrite',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'eventbrite',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_IconsList',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'iconsList',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_RoiCalculator',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'roiCalculator',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'afterCalculatorDisclaimer',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'afterCalculatorDisclaimerTitle',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'emailSectionTitle',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'resultsDisclaimer',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Embed',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'embed',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_TestimonialCta',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'testimonialCta',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'editor',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'author',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'name',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'avatar',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'altText',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'mediaDetails',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'width',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'mediaItemUrl',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'cta',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'ctaText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'ctaUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'image',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Box',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'box',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'content',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Chip',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'chip',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Editor',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'editor',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Icon',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'border',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'icon',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Links',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'links',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'style',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Box_Box_Content_Title',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'customise',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'background',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'reverse',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'media',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Box_Box_Media_Image',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'image',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Cards',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'cards',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'card',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Chip',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'chip',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Editor',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'editor',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Highlight',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'highlight',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'customise',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'color',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'editor',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Icon',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'border',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'icon',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Image',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'image',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Links',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'links',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'style',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Cards_cards_Card_Title',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'customise',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'alignment',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'link',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'target',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'url',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Chip',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'chip',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'color',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Columns',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'columns',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'editor',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'customise',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'columns',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Disclaimer',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'disclaimer',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Faq',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'faq',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'editor',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Features',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'features',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'customise',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'background',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'background',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'border',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'border',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'hollow',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'feature',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Editor',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'editor',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Icon',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Links',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'links',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'style',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Note',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'note',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'background',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'editor',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Features_features_Feature_Title',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Gallery',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'gallery',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'InlineFragment',
                                                                  typeCondition:
                                                                    {
                                                                      kind: 'NamedType',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Image',
                                                                      },
                                                                    },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'image',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'altText',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'mediaDetails',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'width',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'mediaItemUrl',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'InlineFragment',
                                                                  typeCondition:
                                                                    {
                                                                      kind: 'NamedType',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video',
                                                                      },
                                                                    },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'video',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'thumbnail',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'video',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Wistia',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'wistia',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'id',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Gallery_Gallery_Video_video_Video_Youtube',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'youtube',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'id',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Highlights',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'highlights',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'highlight',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'customise',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'editor',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Image',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'image',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'image',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Links',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'links',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'link',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'target',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'position',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'style',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Logos',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'grayscale',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'size',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'logos',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'region',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'logo',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_HubspotForm',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'hubspotForm',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'portalId',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'formId',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'contentId',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'message',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'redirect',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_SignUp',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'signUpForm',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'redirect',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'couponCode',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'buttonText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'excludeFromSafetycultureOnboardingComms',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'preloadTemplates',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'id',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'preloadTemplatesBy',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'salesforceCampaignId',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'salesforceCampaignTag',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'salesforceLeadSource',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'showAddressFields',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'showMembershipId',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'templateSource',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'termsAndConditions',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'formId',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'areYouAWoolGrower',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Product',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'product',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Quotes',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'quotes',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'quote',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Author',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'author',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'avatar',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'name',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'title',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Chip',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'chip',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Editor',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'editor',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Link',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'link',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'target',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'title',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'url',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Quotes_quotes_Quote_Logo',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'logo',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'logo',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Spacer',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'spacer',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Split',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'split',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'content',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'accordion',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'content',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Editor',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'editor',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Accordion_accordion_Content_Checks',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'checks',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'color',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'title',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'title',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Checks',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'checks',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'color',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'title',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Chip',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'chip',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Disclaimer',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'disclaimer',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Editor',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'editor',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'feature',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'customise',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'background',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'background',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'border',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'border',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'hollow',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'feature',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Editor',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'editor',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Icon',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'icon',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'altText',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaDetails',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'height',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'width',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaItemUrl',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Links',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'links',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'link',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'target',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'title',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'url',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'style',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'icon',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'position',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'icon',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'InlineFragment',
                                                                                                          typeCondition:
                                                                                                            {
                                                                                                              kind: 'NamedType',
                                                                                                              name: {
                                                                                                                kind: 'Name',
                                                                                                                value:
                                                                                                                  'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Feature_Feature_Feature_Title',
                                                                                                              },
                                                                                                            },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'title',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Highlight',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'highlight',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'customise',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'color',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'editor',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Links',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'links',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'style',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'icon',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'position',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'icon',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Logos',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'customise',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'alignment',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'grayscale',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'size',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'logos',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'region',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'logo',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_HubspotForm',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'hubspotForm',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'id',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'portalId',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'formId',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'contentId',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'message',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'redirect',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Product',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'product',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'quote',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Author',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'author',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'avatar',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'altText',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaDetails',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'height',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'width',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaItemUrl',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'name',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'title',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Chip',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'chip',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'color',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Editor',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'editor',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Link',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'link',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'target',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'title',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'url',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Quote_Quote_Logo',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'logo',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'logo',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'altText',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaDetails',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'height',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'width',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaItemUrl',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Spacer',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'spacer',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Table',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'table',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'body',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'header',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Title',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Content_Wpforms',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'wpforms',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'customise',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'reverse',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'vertical',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'disableReverseOnMobile',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'media',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_CustomerStoryLink',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'linkText',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'url',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'companyName',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'card',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Chip',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'chip',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'color',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Editor',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'editor',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Highlight',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'highlight',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'editor',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'customise',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'color',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Icon',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'icon',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'border',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'icon',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'altText',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaDetails',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'height',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'width',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'mediaItemUrl',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Image',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'image',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'altText',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'mediaDetails',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'height',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'width',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'mediaItemUrl',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Links',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'links',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'link',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'target',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'title',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'url',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'icon',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'position',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'icon',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'style',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Card_Card_Title',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'customise',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'alignment',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Eventbrite',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'eventbrite',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'id',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Embed',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'embed',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'customise',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'background',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'form',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Chip',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'chip',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'color',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Disclaimer',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'disclaimer',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Editor',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'editor',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'form',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'InlineFragment',
                                                                                                                    typeCondition:
                                                                                                                      {
                                                                                                                        kind: 'NamedType',
                                                                                                                        name: {
                                                                                                                          kind: 'Name',
                                                                                                                          value:
                                                                                                                            'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_HubspotForm',
                                                                                                                        },
                                                                                                                      },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'hubspotForm',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'id',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'formId',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'portalId',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'contentId',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'message',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'redirect',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'target',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'title',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'InlineFragment',
                                                                                                                    typeCondition:
                                                                                                                      {
                                                                                                                        kind: 'NamedType',
                                                                                                                        name: {
                                                                                                                          kind: 'Name',
                                                                                                                          value:
                                                                                                                            'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_KustomerForm',
                                                                                                                        },
                                                                                                                      },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'kustomerForm',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'fileSelectText',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'fileUpload',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'formWebHook',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'heading',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'message',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'onSubmitModal',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'cta',
                                                                                                                                                  },
                                                                                                                                                  selectionSet:
                                                                                                                                                    {
                                                                                                                                                      kind: 'SelectionSet',
                                                                                                                                                      selections:
                                                                                                                                                        [
                                                                                                                                                          {
                                                                                                                                                            kind: 'Field',
                                                                                                                                                            name: {
                                                                                                                                                              kind: 'Name',
                                                                                                                                                              value:
                                                                                                                                                                'target',
                                                                                                                                                            },
                                                                                                                                                          },
                                                                                                                                                          {
                                                                                                                                                            kind: 'Field',
                                                                                                                                                            name: {
                                                                                                                                                              kind: 'Name',
                                                                                                                                                              value:
                                                                                                                                                                'title',
                                                                                                                                                            },
                                                                                                                                                          },
                                                                                                                                                          {
                                                                                                                                                            kind: 'Field',
                                                                                                                                                            name: {
                                                                                                                                                              kind: 'Name',
                                                                                                                                                              value:
                                                                                                                                                                'url',
                                                                                                                                                            },
                                                                                                                                                          },
                                                                                                                                                        ],
                                                                                                                                                    },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'heading',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'text',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'submitButtonText',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'InlineFragment',
                                                                                                                    typeCondition:
                                                                                                                      {
                                                                                                                        kind: 'NamedType',
                                                                                                                        name: {
                                                                                                                          kind: 'Name',
                                                                                                                          value:
                                                                                                                            'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_Wpforms',
                                                                                                                        },
                                                                                                                      },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'wpforms',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'InlineFragment',
                                                                                                                    typeCondition:
                                                                                                                      {
                                                                                                                        kind: 'NamedType',
                                                                                                                        name: {
                                                                                                                          kind: 'Name',
                                                                                                                          value:
                                                                                                                            'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Form_Form_SignUp',
                                                                                                                        },
                                                                                                                      },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'signUpForm',
                                                                                                                              },
                                                                                                                              selectionSet:
                                                                                                                                {
                                                                                                                                  kind: 'SelectionSet',
                                                                                                                                  selections:
                                                                                                                                    [
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'redirect',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'couponCode',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'buttonText',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'excludeFromSafetycultureOnboardingComms',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'preloadTemplates',
                                                                                                                                        },
                                                                                                                                        selectionSet:
                                                                                                                                          {
                                                                                                                                            kind: 'SelectionSet',
                                                                                                                                            selections:
                                                                                                                                              [
                                                                                                                                                {
                                                                                                                                                  kind: 'Field',
                                                                                                                                                  name: {
                                                                                                                                                    kind: 'Name',
                                                                                                                                                    value:
                                                                                                                                                      'id',
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                              ],
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'preloadTemplatesBy',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'salesforceCampaignId',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'salesforceCampaignTag',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'salesforceLeadSource',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'showAddressFields',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'showMembershipId',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'templateSource',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'termsAndConditions',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'formId',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'title',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                      {
                                                                                                                                        kind: 'Field',
                                                                                                                                        name: {
                                                                                                                                          kind: 'Name',
                                                                                                                                          value:
                                                                                                                                            'areYouAWoolGrower',
                                                                                                                                        },
                                                                                                                                      },
                                                                                                                                    ],
                                                                                                                                },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'InlineFragment',
                                                                                                typeCondition:
                                                                                                  {
                                                                                                    kind: 'NamedType',
                                                                                                    name: {
                                                                                                      kind: 'Name',
                                                                                                      value:
                                                                                                        'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Form_Form_Title',
                                                                                                    },
                                                                                                  },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Split_Split_Media_Image',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'image',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Spotlights',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'spotlights',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'customise',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'alignment',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'link',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'target',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'url',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'spotlight',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Chip',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'chip',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Editor',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'editor',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Highlight',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'highlight',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'customise',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'color',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'editor',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Icon',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'icon',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'placement',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Image',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'image',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Links',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'links',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'icon',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'position',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'icon',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'style',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Block_Block_Content_Spotlights_spotlights_Spotlight_Title',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Stats',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'customise',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'alignment',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'columns',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'stats',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'border',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'number',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Table',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'table',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'body',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'header',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Testimonials',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'testimonials',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'testimonial',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'content',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Author',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'author',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'avatar',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'altText',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'mediaDetails',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'height',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'width',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'mediaItemUrl',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'name',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Chip',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'chip',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'color',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Editor',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'editor',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Link',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'link',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'target',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'url',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Content_Stats',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'stats',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'border',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'number',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'title',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'media',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Icon',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'icon',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'title',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Image',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'image',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'altText',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaDetails',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'height',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'width',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'mediaItemUrl',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'InlineFragment',
                                                                                      typeCondition:
                                                                                        {
                                                                                          kind: 'NamedType',
                                                                                          name: {
                                                                                            kind: 'Name',
                                                                                            value:
                                                                                              'Page_Acfpage_Section_Block_Block_Content_Testimonials_testimonials_Testimonial_Media_Logo',
                                                                                          },
                                                                                        },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'logo',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'logo',
                                                                                                          },
                                                                                                          selectionSet:
                                                                                                            {
                                                                                                              kind: 'SelectionSet',
                                                                                                              selections:
                                                                                                                [
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'altText',
                                                                                                                    },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'mediaDetails',
                                                                                                                    },
                                                                                                                    selectionSet:
                                                                                                                      {
                                                                                                                        kind: 'SelectionSet',
                                                                                                                        selections:
                                                                                                                          [
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'height',
                                                                                                                              },
                                                                                                                            },
                                                                                                                            {
                                                                                                                              kind: 'Field',
                                                                                                                              name: {
                                                                                                                                kind: 'Name',
                                                                                                                                value:
                                                                                                                                  'width',
                                                                                                                              },
                                                                                                                            },
                                                                                                                          ],
                                                                                                                      },
                                                                                                                  },
                                                                                                                  {
                                                                                                                    kind: 'Field',
                                                                                                                    name: {
                                                                                                                      kind: 'Name',
                                                                                                                      value:
                                                                                                                        'mediaItemUrl',
                                                                                                                    },
                                                                                                                  },
                                                                                                                ],
                                                                                                            },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'design',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Title',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Video',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'video',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'InlineFragment',
                                                                  typeCondition:
                                                                    {
                                                                      kind: 'NamedType',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'Page_Acfpage_Section_Block_Block_Content_Video_Video_Wistia',
                                                                      },
                                                                    },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'wistia',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'id',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'InlineFragment',
                                                                  typeCondition:
                                                                    {
                                                                      kind: 'NamedType',
                                                                      name: {
                                                                        kind: 'Name',
                                                                        value:
                                                                          'Page_Acfpage_Section_Block_Block_Content_Video_Video_Youtube',
                                                                      },
                                                                    },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'youtube',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'id',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Wpforms',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'wpforms',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_Lever',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'lever',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'allJobs',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'maxJobs',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_RecentPosts',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'header',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'postType',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Block_Block_Content_LinksColumns',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'linksColumns',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'editor',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'layout',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'links',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'link',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'url',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'target',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'position',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'style',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'alignment',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'background',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'overflow',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'cover',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'altText',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaDetails',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'width',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaItemUrl',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'layout',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'display',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'mobile',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'desktop',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'classnames',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Cta',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'cta' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Eventbrite',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'eventbrite',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value: 'id',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_IconsList',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'iconsList',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Checks',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'checks',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'color',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Embed',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'embed',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Links',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'links',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'link',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'target',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'style',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Product',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'product',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Search',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'search',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Cta_Cta_Content_Title',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'background',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Hero',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hero' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Content_Chip',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'chip',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'color',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Content_Links',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'links',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'link',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'target',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'position',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'style',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Content_Product',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'product',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Content_Title',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'media',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Media_Cover',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'cover',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Media_Image',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'background',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'cover',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'image',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Hero_Hero_Media_Pill',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'border',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'pill',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Media',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'media',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Page_Acfpage_Section_Media_Media_Eventbrite',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'eventbrite',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Page_Acfpage_Section_Media_Media_Embed',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'embed',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Page_Acfpage_Section_Media_Media_Form',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'customise',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'background',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'form',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_Media_Media_Form_Form_Chip',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'chip',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'color',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_Media_Media_Form_Form_Disclaimer',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'disclaimer',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_Media_Media_Form_Form_Editor',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'editor',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_Media_Media_Form_Form_Form',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'form',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'InlineFragment',
                                                                            typeCondition:
                                                                              {
                                                                                kind: 'NamedType',
                                                                                name: {
                                                                                  kind: 'Name',
                                                                                  value:
                                                                                    'Page_Acfpage_Section_Media_Media_Form_Form_Form_Form_Wpforms',
                                                                                },
                                                                              },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'wpforms',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_Media_Media_Form_Form_Title',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Page_Acfpage_Section_Media_Media_Image',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'image',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'image',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Page_Acfpage_Section_Notification',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'notification',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'link',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'target',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Page_Acfpage_Section_AppFeatures',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appFeatures',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'cards',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'card',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Chip',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'chip',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'color',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Editor',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'editor',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Highlight',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'highlight',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'customise',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'color',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'editor',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Icon',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'border',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'altText',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'mediaDetails',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'height',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'width',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'mediaItemUrl',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Image',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'image',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Links',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'links',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'link',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'target',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'title',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'url',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'position',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'style',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'InlineFragment',
                                                            typeCondition: {
                                                              kind: 'NamedType',
                                                              name: {
                                                                kind: 'Name',
                                                                value:
                                                                  'Page_Acfpage_Section_AppFeatures_AppFeatures_cards_Card_Title',
                                                              },
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'titleOptions',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'badge',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'background',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'text',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'border',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'icon',
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: 'SelectionSet',
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'altText',
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaDetails',
                                                                                                },
                                                                                                selectionSet:
                                                                                                  {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections:
                                                                                                      [
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'height',
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          kind: 'Field',
                                                                                                          name: {
                                                                                                            kind: 'Name',
                                                                                                            value:
                                                                                                              'width',
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                  },
                                                                                              },
                                                                                              {
                                                                                                kind: 'Field',
                                                                                                name: {
                                                                                                  kind: 'Name',
                                                                                                  value:
                                                                                                    'mediaItemUrl',
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'customise',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'alignment',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'link',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'target',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'region',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Title',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_AppFeatures_AppFeatures_Content_Links',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'links',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'link',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'target',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'position',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'style',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'customise',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'alignment',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'columns',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'features',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'icon',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'altText',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaDetails',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'width',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaItemUrl',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'new',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'region',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'button',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'target',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'featuresTitle',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'leftImage',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'altText',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaDetails',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'width',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaItemUrl',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'middleImage',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'altText',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaDetails',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'width',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaItemUrl',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'rightImage',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'altText',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaDetails',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'width',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaItemUrl',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Slider',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'slider',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'content',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Slider_Slider_Content_Chip',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'chip',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'color',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Slider_Slider_Content_Title',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Slider_Slider_Content_Editor',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value:
                                                            'Page_Acfpage_Section_Slider_Slider_Content_Links',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'links',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'link',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'target',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'title',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'url',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'position',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'icon',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'style',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slides',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'caption',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'cta',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'ctaText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'ctaUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'jobTitle',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'name',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'features',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'positioning',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'desktop',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'left',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'right',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'top',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'button',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'icon',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'altText',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaDetails',
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: 'SelectionSet',
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'height',
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: 'Field',
                                                                                      name: {
                                                                                        kind: 'Name',
                                                                                        value:
                                                                                          'width',
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'mediaItemUrl',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'iconLocation',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'text',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'imageLocation',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'image',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'image',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'altText',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaDetails',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'height',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'width',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'mediaItemUrl',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mainSlide',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'region',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mobileImage',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'altText',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaDetails',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'width',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaItemUrl',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'Page_Acfpage_Section_Pricing',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'pricing',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'comparison',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'editor',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'disclaimer',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'fullSeat',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'liteSeat',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'tables',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'heading',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'row',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'tds',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'td',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'payAsYouGo',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'active',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'editor',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'featureTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'features',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'feature',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'included',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'monthlyPrice',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'yearlyPrice',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'planFeatures',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'ctas',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'cta',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'target',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'premium',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'tables',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'heading',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'row',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'tds',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'td',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'priceCalculated',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'contentColumns',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'cta',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'url',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'target',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'title',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'editor',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'icon',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'altText',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaDetails',
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: 'SelectionSet',
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'height',
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: 'Field',
                                                                            name: {
                                                                              kind: 'Name',
                                                                              value:
                                                                                'width',
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'mediaItemUrl',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'cta',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'target',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'active',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'pricingTable',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'titleBeforeCta',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'cta',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'target',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'editor',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'featureTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'features',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'feature',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'included',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'usageFeatures',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'feature',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'included',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'monthlyPrice',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'premium',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'yearlyPrice',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'pricingTableCta',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'target',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'title',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'url',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'pricingTitle',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfProduct' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edapp' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appStore',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'googlePlay',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'override',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'override' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'safetyculture',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appStore',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'googlePlay',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'override',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sheqsy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appStore',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'googlePlay',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'override',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfStore' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'store' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'banner' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'getApp' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'override' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageSettings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'breadcrumbsStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'footerMenuStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'footerLegalMenuStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'headerMenuStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'languageToggle',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'loginAndSignUpButtonsStatus',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Page' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Page' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'parent',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'node',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {
                                                          kind: 'Name',
                                                          value: 'Page',
                                                        },
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'slug',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'title',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'opengraphImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPageContentQuery, GetPageContentQueryVariables>;
export const GetPartnershipDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnership' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerships' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPartnership' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'intro' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'header' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'logo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hero' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'mediaDetails',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'width',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'salesContactDetails',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'showSalesPerson',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'target',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'profilePhoto',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'altText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'section' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Partnership_Acfpartnership_Section_Title',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Partnership_Acfpartnership_Section_Block',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'editor',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Partnership_Acfpartnership_Section_Image',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'image',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'height',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'image',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'altText',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'guid',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'mediaDetails',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'height',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'width',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value:
                                          'Partnership_Acfpartnership_Section_Video',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'video',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Partnership_Acfpartnership_Section_Video_Video_Wistia',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'wistia',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: {
                                                    kind: 'Name',
                                                    value:
                                                      'Partnership_Acfpartnership_Section_Video_Video_Youtube',
                                                  },
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'youtube',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'id',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'signUpForm' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signUpForm' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirect',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'couponCode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'buttonText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'excludeFromSafetycultureOnboardingComms',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'preloadTemplates',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'preloadTemplatesBy',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salesforceCampaignId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salesforceCampaignTag',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salesforceLeadSource',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'showAddressFields',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'showMembershipId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'templateSource',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'termsAndConditions',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'formId',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'areYouAWoolGrower',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfUser' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'linkedin',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'translations',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'de',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'es',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nl',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ptBr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: {
                                          kind: 'IntValue',
                                          value: '300',
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPartnershipQuery, GetPartnershipQueryVariables>;
export const GetTopicsPagedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsPaged' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paged' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paged' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfTopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorShow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'introEditor' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'faqFrequentlyAskedQuestions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'faqTitle' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicsPagedQuery, GetTopicsPagedQueryVariables>;
export const GetTopicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'path' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stati' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'PUBLISH' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'path' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfTopic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'authorShow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contentEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroEditor' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'heroTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'introEditor' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'faqFrequentlyAskedQuestions',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'faqTitle' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfAuthors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'multipleAuthors' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authors' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'authorName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'acfRelatedTemplatesSimple',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'relatedTemplatesTitle',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'relatedTemplatesTemplates',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cta' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ctaText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ctaUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'templateId',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'editor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'links' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'icon' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'icon',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'position',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'link' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'target',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'style',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acfPageStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftPublic' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'altText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaDetails',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mediaItemUrl',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'modified' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Topic' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taxonomyCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'apps' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checklists' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topics' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'first' },
                                        value: { kind: 'IntValue', value: '6' },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'uri',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfUser' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'avatar',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'linkedin',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'translations',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'de',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'es',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'fr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'nl',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'ptBr',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'bio',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'jobTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'firstName',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'lastName',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: {
                                          kind: 'IntValue',
                                          value: '300',
                                        },
                                      },
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canonical' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaDesc' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNofollow',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'metaRobotsNoindex',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'translated' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locale' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uri' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicQuery, GetTopicQueryVariables>;
export const GetTopicsTaxonomyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTopicsTaxonomy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taxonomyCategories' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'wpmlLanguage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'slug' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'hideEmpty' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topics' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '12' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'acfTopic' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'authorShow',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'contentEditor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroEditor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'heroTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'introEditor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'faqFrequentlyAskedQuestions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'editor',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'faqTitle',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'acfPageStatus',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'draftPublic',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'featuredImage',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'altText',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaDetails',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'height',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'width',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'mediaItemUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uri' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'canonical',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaDesc',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaRobotsNofollow',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metaRobotsNoindex',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'taxonomyCategories',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'nodes',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'slug',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTopicsTaxonomyQuery,
  GetTopicsTaxonomyQueryVariables
>;
