import styles from './Buttons.module.scss';

import ArrowSquareUpright from '@/icons/maggie/ArrowSquareUpright';
import Button from '@/components/atoms/Button';
import cn from 'classnames';
import typeButton from '@/utilities/types/Button';
import typeLink from '@/utilities/types/Link';
import ArrowRight from '@/icons/maggie/ArrowRight';
import ChevronDown from '@/icons/maggie/ChevronDown';

export interface ButtonsProps {
  buttons: ButtonsButtonProps[];
  className?: string;
  style?: typeButton['style'];
  size?: ButtonsButtonProps['size'];
}

const Buttons = (props: ButtonsProps) => {
  const { buttons, className, style, size } = props;

  return (
    <div className={cn(className, styles.root)}>
      <ul>
        {buttons.map((button, index) => {
          if (button.link && button.link.url) {
            return (
              <li key={index}>
                <ButtonsButton {...button} size={size} />
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export interface ButtonsButtonProps {
  link: typeLink;
  style: typeButton['style'];
  icon?: {
    position: string;
    icon: string;
  };
  size?: 'small' | 'medium' | 'large';
}

export const ButtonsButton = (props: ButtonsButtonProps) => {
  const { icon, style, link, size } = props;

  return (
    <Button
      before={
        icon && icon.icon && icon.position === 'before' ? (
          icon.icon === 'arrow-right' ? (
            <ArrowRight width={12} height={12} />
          ) : icon.icon === 'chevron-down' ? (
            <ChevronDown width={16} height={16} />
          ) : null
        ) : undefined
      }
      after={
        icon && icon.icon && icon.position === 'after' ? (
          icon.icon === 'arrow-right' ? (
            <ArrowRight width={12} height={12} />
          ) : icon.icon === 'chevron-down' ? (
            <ChevronDown width={16} height={16} />
          ) : null
        ) : link.target ? (
          <ArrowSquareUpright width={16} height={16} />
        ) : style === 'link' ? (
          <ArrowRight width={12} height={12} />
        ) : undefined
      }
      external={link.target ? true : false}
      href={link.url}
      size={size ? size : 'large'}
      style={style}
      aria={link?.aria}
    >
      {link.title}
    </Button>
  );
};

export default Buttons;
