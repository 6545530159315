import { gql } from '@/utilities/api/v2/graphql/generated';

const GetNotificationBannerQuery = gql(/* GraphQL */ `
  query GetNotificationBanner {
    acfOptionsNotificationBanner {
      acfNotificationBanner {
        notificationBanner {
          status
          backgroundColor
          backgroundType
          backgroundColorGradient {
            angle
            colors {
              color
            }
          }
          textColor
          align
          text
          image {
            altText
            mediaDetails {
              height
              width
            }
            mediaItemUrl
          }
          links {
            icon {
              icon
              position
            }
            link {
              target
              title
              url
            }
            style
          }
          translations {
            de {
              text
              status
              links {
                icon {
                  icon
                  position
                }
                link {
                  target
                  title
                  url
                }
                style
              }
            }
            es {
              text
              status
              links {
                icon {
                  icon
                  position
                }
                link {
                  target
                  title
                  url
                }
                style
              }
            }
            fr {
              text
              status
              links {
                icon {
                  icon
                  position
                }
                link {
                  target
                  title
                  url
                }
                style
              }
            }
            nl {
              text
              status
              links {
                icon {
                  icon
                  position
                }
                link {
                  target
                  title
                  url
                }
                style
              }
            }
            ptBr {
              text
              status
              links {
                icon {
                  icon
                  position
                }
                link {
                  target
                  title
                  url
                }
                style
              }
            }
          }
        }
      }
    }
  }
`);

export default GetNotificationBannerQuery;
