import styles from './NotificationBanner.module.scss';
import navStyles from '@/components/organisms/Header/Header.module.scss';
import { NOTIFICATION_BANNER } from '@/utilities/constants/NotificationBanner';
import { setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';
import { useLocale } from '@/utilities/contexts/Locale';
import { useNotificationBanner } from '@/utilities/contexts/NotificationBanner';
import Button from '@/components/atoms/Button';
import Buttons from '@/components/molecules/Buttons';
import cn from 'classnames';
import Image from 'next/image';
import Times from '@/icons/maggie/Times';
import typeNotificationBanner from '@/utilities/types/NotificationBanner';
import Typography from '@/components/atoms/Typography';

interface NotificationBannerProps {
  content: typeNotificationBanner;
}

const hexToRgba = (hex: string, alpha: number) => {
  const hexColor = hex.replace('#', '');
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const NotificationBanner = (props: NotificationBannerProps) => {
  const { content } = props;

  const locale: string = useLocale();

  const isNotificationBannerCookie: boolean = useNotificationBanner();

  const [isCookie, setIsCookie] = useState<boolean | undefined>(true);

  useEffect(() => {
    setIsCookie(isNotificationBannerCookie);
  }, [isNotificationBannerCookie]);

  const tranlsationLocale = locale === 'pt-br' ? 'ptBr' : locale;
  const bannerText =
    locale === 'en'
      ? content.text
      : content.translations[tranlsationLocale] &&
          content.translations[tranlsationLocale].text
        ? content.translations[tranlsationLocale].text
        : content.text;
  const bannerLinks =
    locale === 'en'
      ? content.links
      : content.translations[tranlsationLocale] &&
          content.translations[tranlsationLocale].links &&
          content.translations[tranlsationLocale].links.length > 0 &&
          content.translations[tranlsationLocale].links[0].link
        ? content.translations[tranlsationLocale].links
        : content.links;

  const closeBanner = () => {
    setCookie('sc-notification-banner', 'true', {
      maxAge: 60 * 60 * 24 * 365,
    } as any);
    setIsCookie(true);
  };

  let backgroundStyling = '';

  if (
    content.backgroundType === 'gradient' &&
    content.backgroundColorGradient?.colors
  ) {
    const colorsCount = content.backgroundColorGradient.colors.length;

    const colors = content.backgroundColorGradient.colors
      .map(
        (color, index) =>
          `${hexToRgba(color.color, 1)} ${
            index === 0 ? '0' : Math.ceil((100 / colorsCount) * index)
          }%`,
      )
      .join(', ');

    backgroundStyling = `
      background: ${hexToRgba(
        content.backgroundColorGradient.colors[0]?.color,
        1,
      )};
      background: linear-gradient(${
        content.backgroundColorGradient.angle
      }deg, ${colors});
    `;
  } else {
    backgroundStyling = `background-color: ${content.backgroundColor};`;
  }

  return (
    <div
      className={cn(
        'notification-banner',
        styles.root,
        !isCookie && styles.isShown,
      )}
    >
      <style jsx>{`
        .notification-banner {
          ${backgroundStyling}
        }
      `}</style>
      <div
        className={cn(
          navStyles.container,
          styles.container2,
          styles[content.textColor],
        )}
      >
        <div className={styles.closeWrapper}>
          <Button
            icon={true}
            style="link"
            size="medium"
            type="button"
            className={styles.closeButton}
            onClick={closeBanner}
            aria={NOTIFICATION_BANNER.close[locale]}
          >
            <Times width={24} height={24} />
          </Button>
        </div>
        <div className={cn(styles[content.align], styles.content)}>
          <div className={styles.textWrapper}>
            {content.image && content.image.mediaItemUrl && (
              <Image
                alt={content.image.altText}
                height={40}
                priority
                src={content.image.mediaItemUrl}
                width={40}
                className={styles.image}
              />
            )}
            {bannerText && (
              <Typography
                tag="span"
                family="noto-sans"
                xs={1}
                md={2}
                className={cn(styles.text, styles[content.textColor])}
              >
                {bannerText}
              </Typography>
            )}
          </div>
          <div className={styles.buttonsWrapper}>
            {bannerLinks && (
              <Buttons
                buttons={bannerLinks}
                className={cn(styles.buttons)}
                size="small"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
